<template>
  <AddBeneficiaryModal
    :open="isBeneficiaryModalOpen"
    @close-modal="isBeneficiaryModalOpen = false"
    @handle-add-beneficiary="populateBeneficialOwnersByEntity"
  />
  <div class="flex flex-col w-full h-full lg:my-6">
    <button
      type="button"
      @click="handleBack"
      class="md:hidden flex items-center justify-center gap-1.5 text-primary-6 absolute top-24 left-5"
    >
      <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
      <p>Back</p>
    </button>
    <div
      class="flex flex-col grow items-center lg:pt-10 lg:pb-8 lg:border border-neutral-gray-4 w-full rounded-md h-full"
    >
      {{ investorStore.hasSpecialError }}
      <div class="w-full lg:w-[70%] mx-auto flex flex-col justify-between h-full">
        <div>
          <h1 class="font-bold text-xl uppercase text-primary-6">
            Step {{ investmentStore.currentStep - 2 }}: Entity Information
          </h1>
          <p class="text-lighter text-sm mt-2 mb-6">
            Investment Type: {{ investmentStore.currentInvestment.type }} • Offering Type:
            {{ investmentStore.selectedOffering.type }}
            ({{ investmentStore.selectedOffering.interest_rate }}% -
            {{
              investmentStore.selectedOffering.bond_term === 12
                ? "1 Year"
                : investmentStore.selectedOffering.bond_term / 12 + " Years"
            }}
            - {{ investmentStore.currentInvestment.is_compounded ? "Compounded" : "Paid Monthly" }})
            • Amount to Invest: ${{ investmentStore.currentInvestment.amount }}
          </p>
          <div v-if="investmentStore.hasSignedEntityInvestment && !isEditMode">
            <h3 class="font-bold text-lg mb-8">Select Entity</h3>
            <div class="bg-neutral-gray-2 bg-opacity-70 p-4 rounded-lg flex flex-col gap-6">
              <label
                v-for="(entity_account, index) in investorStore.entity_accounts"
                :key="entity_account.name"
                class="bg-white border border-platform-outlines flex items-center justify-between h-18 rounded-md p-3 pl-6 cursor-pointer"
                :class="{
                  'border-gold': investorStore.active_entity_account?.name === entity_account.name,
                }"
                @click="investmentStore.active_entity_account = entity_account"
              >
                <RadioOption
                  :id="index"
                  name="activeEntity"
                  :value="entity_account"
                  v-model="investorStore.active_entity_account"
                  :label="entity_account.name"
                  :description="entity_account.entity_type"
                />
                <div class="flex gap-4 items-center">
                  <p
                    class="whitespace-nowrap"
                    v-if="
                      entity_account?._id?.toString() ===
                      investorStore.active_entity_account?._id?.toString()
                    "
                  >
                    <span class="font-semibold">{{
                      numberOfSignedInvestmentsForActiveEntity
                    }}</span>
                    Signed Investment<span v-if="numberOfSignedInvestmentsForActiveEntity !== 1"
                      >s</span
                    >
                  </p>
                  <Button
                    @click="
                      (isEditMode = true), (investorStore.active_entity_account = entity_account)
                    "
                    label
                    variant="default-gray-outlined"
                  >
                    <template #label>Edit</template>
                  </Button>
                </div>
              </label>
              <label
                class="bg-white border border-platform-outlines flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
                :class="{ 'border-gold': investorStore.active_entity_account?.name === '' }"
                @click="investmentStore.active_entity_account = ENTITY_TEMPLATE"
              >
                <RadioOption
                  id="newEntity"
                  name="newEntity"
                  :value="ENTITY_TEMPLATE"
                  v-model="investorStore.active_entity_account"
                  label="New Entity"
                  description="Create another Entity"
                />
              </label>
            </div>
          </div>
          <div v-else>
            <template v-for="(schema, index) of schemaToUse" :key="index">
              <Form
                :fields="schema.fields"
                :record="investorStore.active_entity_account"
                :header="schema.group_name"
                :subHeader="
                  index === 0
                    ? 'Please provide information about your entity.'
                    : index === 1
                      ? 'Phoenix requires a physical address. PO Boxes and mail drops are not valid. If you use a PO Box for receiving mail, please reach out to Investor Relations at (303) 376-9778 to have your account updated.'
                      : ''
                "
                type="entity"
                :isNew="!investorStore.active_entity_account?._id"
              />

              <div class="border-b border-neutral-gray-4 w-full my-6"></div>
            </template>
            <BeneficialOwners
              @open-modal="isBeneficiaryModalOpen = true"
              :active_entity="investorStore.active_entity_account"
              :beneficial_owners="
                investorStore.active_entity_account?._id
                  ? investorStore.beneficial_owner_contacts
                  : investorStore.tempBeneficialOwners
              "
            />
          </div>
        </div>
        <div
          class="w-full flex gap-4 flex-col-reverse md:flex-row justify-between items-center border-t border-neutral-gray-4 pt-6 mt-6"
        >
          <button @click="handleBack" type="button" class="flex items-center text-primary-6 gap-2">
            <Icon name="ChevronLeft" class="hidden md:block h-5 w-5 text-primary-6 mb-0.5" />
            <p>Back</p>
          </button>
          <Button
            @click="handleNext"
            :disabled="!isFormValid"
            label
            icon
            size="large"
            class="w-full md:w-value-42"
          >
            <template #label>Next</template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { cloneDeep } from "lodash";

import { useInvestmentStore, useInvestorStore } from "@/stores";
import { Button, Icon, Form, RadioOption } from "@/components";
import { ENTITY_TEMPLATE } from "@/constants";
import { getObjectDifferences, validateField } from "@/utilities";
import BeneficialOwners from "./BeneficialOwners.vue";
import AddBeneficiaryModal from "./AddBeneficiaryModal.vue";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

const original_active_entity = ref({});
const isEditMode = ref(false);
const isBeneficiaryModalOpen = ref(false);

onMounted(async () => {
  window.scrollTo(0, 0);
  original_active_entity.value = cloneDeep(investorStore.active_entity_account);
  if (investorStore.active_entity_account.is_new) {
    isEditMode.value = true;
  }
});

const schemaToUse = computed(() => {
  return investmentStore.entity_info_schema;
});

const numberOfSignedInvestmentsForActiveEntity = computed(() => {
  return investmentStore.transactionsData.filter((investment) => {
    return (
      investment.entity_account_id &&
      investment.entity_account_id?.toString() ===
        investorStore.active_entity_account?._id?.toString()
    );
  }).length;
});

const isFormValid = computed(() => {
  let validationErrors = [];

  // Check if schema exists
  if (!schemaToUse.value) {
    validationErrors.push({ message: "Schema is missing or undefined." });
  }

  // Check if it's a new entity and not in edit mode
  if (
    investorStore.active_entity_account?.is_new &&
    !isEditMode.value &&
    investmentStore.hasSignedEntityInvestment
  ) {
    return true;
  }

  // Special error check for "ein"
  if (investorStore.hasSpecialError === "ein") {
    validationErrors.push({ message: "Special error related to EIN detected." });
  }

  // beneficial owner validation
  if (
    !investorStore.isOwnerBeneficialOwner &&
    investorStore.beneficial_owner_contacts.length === 0 &&
    investorStore.tempBeneficialOwners.length === 0
  ) {
    validationErrors.push({ message: "At least one beneficial owner is required." });
  }

  // Get required fields from the schema
  const currentSchema = schemaToUse.value.flatMap((s) => s.fields);
  const fieldsToValidate = currentSchema.filter((f) => f.is_required);

  // Validate each required field and track any that fail
  fieldsToValidate.forEach((field) => {
    const value =
      field.belongs_to_nested_object && field.nested_object_name
        ? investorStore.active_entity_account[field.nested_object_name]?.[field.field_name]
        : investorStore.active_entity_account[field.field_name];

    // If the field is invalid, collect it in validationErrors
    if (!validateField(field.field_name, value)) {
      validationErrors.push({
        field: field.field_name,
        value,
        message: `Field ${field.field_name} is invalid or missing.`,
      });
    }
  });

  // Return false if there are any validation errors, otherwise true
  return validationErrors.length === 0;
});

const hasAnyValueChanged = computed(() => {
  const differences = getObjectDifferences(
    investorStore.active_entity_account,
    original_active_entity.value
  );

  if (Object.keys(differences).length > 0) {
    return true;
  }

  return false;
});

watch(
  () => investorStore.active_entity_account,
  (newEntity) => {
    original_active_entity.value = cloneDeep(newEntity);
    const allBeneficialOwnerContacts = investorStore.allContacts.filter((contact) => {
      const stringEntityIds = contact?.entity_ids?.map((id) => id.toString());
      if (
        contact.type === "Regular" &&
        stringEntityIds?.includes(investorStore.active_entity_account?._id?.toString())
      ) {
        investorStore.isOwnerBeneficialOwner = true;
      } else if (
        contact.type === "Regular" &&
        !stringEntityIds?.includes(investorStore.active_entity_account?._id?.toString())
      ) {
        investorStore.isOwnerBeneficialOwner = false;
      }
      return (
        contact.type !== "Regular" &&
        stringEntityIds?.includes(investorStore.active_entity_account?._id?.toString())
      );
    });
    if (allBeneficialOwnerContacts) {
      investorStore.beneficial_owner_contacts = allBeneficialOwnerContacts;
    }
  },
  { immediate: true }
);

function populateBeneficialOwnersByEntity() {
  investorStore.entity_accounts.forEach((entity) => {
    const owners = investorStore.allContacts.filter((contact) => {
      if (Array.isArray(contact?.entity_ids)) {
        const stringEntityIds = contact?.entity_ids?.map((id) => id.toString());
        return stringEntityIds.includes(entity?._id.toString()) && contact.type !== "Regular";
      }
      return false;
    });

    investorStore.beneficial_owner_contacts = owners;
  });
}

async function handleBack() {
  if (isEditMode.value && investmentStore.hasSignedEntityInvestment) {
    isEditMode.value = false;
    return;
  }
  investmentStore.currentStep -= 1;
  if (hasAnyValueChanged.value) {
    original_active_entity.value = cloneDeep(investorStore.active_entity_account);
  }
}

async function handleNext() {
  if (
    investorStore.active_entity_account.is_new &&
    !isEditMode.value &&
    investmentStore.hasSignedEntityInvestment
  ) {
    isEditMode.value = true;
    return;
  }

  if (!investorStore.active_entity_account?._id) {
    original_active_entity.value = cloneDeep(investorStore.original_active_entity);
    investmentStore.currentStep += 1;
    await investorStore.upsertEntityAccount(investorStore.active_entity_account);
    await investmentStore.updateInvestmentIds();
    return;
  }

  // Check if we can allow the skipping the acknowledgement step
  if (investorStore.doesActiveEntityHaveSignedInvestments && !isEditMode.value) {
    const reg_d_acks = investorStore.active_entity_account?.reg_d_entity_acknowledgements || [];

    const hasCompletedESignatureAcks =
      reg_d_acks.slice(0, 14).some((ack) => ack) && reg_d_acks.slice(-2).some((ack) => ack);

    // Handle if Reg D offering is selected but acks are incomplete
    if (investmentStore.selectedOffering.type === "Reg D" && !hasCompletedESignatureAcks) {
      investmentStore.currentStep += 1;
      await investmentStore.updateInvestmentIds();
      return;
    }

    investmentStore.currentStep = investmentStore.investmentSteps.length + 2;
    await investmentStore.updateInvestmentIds();
    return;
  }

  investmentStore.currentStep += 1;

  original_active_entity.value = cloneDeep(investorStore.original_active_entity);
  await investorStore.upsertEntityAccount(investorStore.active_entity_account);
  await investmentStore.updateInvestmentIds();
}
</script>
