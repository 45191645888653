<template>
  <div>
    <!-- Banner with BG Image -->
    <div
      class="relative w-full bg-cover bg-center bg-no-repeat rounded-md lg:h-60 flex items-center"
      :style="{ backgroundImage: `url(${bgImg})` }"
    >
      <div class="absolute inset-0 bg-primary-6 opacity-80 rounded-md z-0"></div>
      <div class="relative z-10 w-full flex items-center p-10 flex-col lg:flex-row">
        <div class="relative z-10 w-full flex flex-col items-start">
          <img :src="phxLogo" alt="" class="h-8 object-contain mb-6" />
          <h3 class="text-white text-2xl md:text-[2.125rem] xl:text-[2.625rem] mb-4 font-bold">
            Want to get more?
          </h3>
        </div>
        <div
          class="relative w-full overflow-hidden mt-3 flex flex-col lg:flex-row gap-4 justify-between"
        >
          <p class="text-primary-2 lg:max-w-[60%] leading-[1.25]">
            We are offering exclusive investment opportunities designed specifically for our most
            special investors, promising unparalleled returns and unique market access.
          </p>
          <a
            href="https://phxcapitalgroup.com/contact-us-about-it-support/"
            target="_blank"
            class="flex items-center justify-center transition whitespace-nowrap cursor-pointer h-10.5 px-4 font-medium rounded-md bg-white hover:bg-neutral-gray-4 active:bg-neutral-gray-5 text-primary box-border"
          >
            Contact
          </a>
        </div>
      </div>
    </div>
    <div class="border-b border-platform-outlines w-full my-6"></div>
    <div class="relative">
      <h3 class="font-bold text-2xl uppercase mb-6 text-primary-6">TrustPilot Reviews</h3>
      <!-- Left Arrow for Desktop -->
      <Button
        v-if="!isMobile"
        icon
        variant="default-gray-outlined"
        size="small"
        class="hidden md:flex absolute left-0 top-1/2 transform -translate-y-1/2 z-10 mt-2"
        @click="scrollReviews(-1)"
      >
        <template #icon>
          <Icon name="ChevronLeft" class="h-3.5 w-3.5 text-primary-6" :stroke-width="2" />
        </template>
      </Button>

      <!-- Reviews Container -->
      <div
        ref="reviewsContainer"
        class="flex gap-4 overflow-x-auto lg:overflow-hidden scroll-smooth md:mx-12 no-scrollbar"
      >
        <div
          v-for="review in trustPilotReviews"
          :key="review.name"
          class="border border-neutral-gray-4 p-5 h-[13.625rem] rounded-[8px] min-w-75 md:min-w-62"
        >
          <div class="flex gap-2 items-center">
            <div class="flex h-[2.125rem] w-[2.125rem] items-center justify-center rounded-full">
              <img :src="review.img" alt="" class="h-[2.125rem] object-fill rounded-full" />
            </div>
            <div class="flex items-center">
              <span v-for="n in 5" :key="n" class="star" :class="{ filled: n <= review.stars }" />
            </div>
          </div>
          <p class="mt-2 font-semibold">{{ review.name }}</p>
          <p class="mt-1 font-semibold line-clamp-2">"{{ review.quote }}"</p>
          <p class="text-lighter text-sm line-clamp-3 overflow-hidden">{{ review.full_text }}</p>
        </div>
      </div>
      <!-- Right Arrow for Desktop -->
      <Button
        v-if="!isMobile"
        icon
        variant="default-gray-outlined"
        size="small"
        class="hidden md:flex absolute right-0 top-1/2 transform -translate-y-1/2 z-10 mt-2"
        @click="scrollReviews(1)"
      >
        <template #icon>
          <Icon name="ChevronRight" class="h-3.5 w-3.5 text-primary-6" :stroke-width="2" />
        </template>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import { Button, Icon } from "@/components";
import { TRUST_PILOT_REVIEWS } from "@/constants";

import phxLogo from "@/assets/images/PHX_Revised_Logo.png";
import bgImg from "@/assets/images/admin_bg_small.png";

const bgColors = ref([
  "#EC407A", // Pink
  "#42A5F5", // Blue
  "#66BB6A", // Green
  "#FFA726", // Orange
  "#AB47BC", // Purple
  "#26A69A", // Teal
  "#FF7043", // Deep Orange
  "#8D6E63", // Brown
  "#7E57C2", // Indigo
  "#FFCA28", // Yellow
]);

const trustPilotReviews = ref(TRUST_PILOT_REVIEWS);

const reviewsContainer = ref(null);
const isMobile = ref(false);

onMounted(() => {
  isMobile.value = window.innerWidth < 768;
});

function getRandomBgColor() {
  const randomIndex = Math.floor(Math.random() * bgColors.value.length);
  return bgColors.value[randomIndex];
}

function scrollReviews(direction) {
  const container = reviewsContainer.value;
  const scrollAmount = 240 * direction; // Adjust scroll amount based on your requirements
  container.scrollBy({ left: scrollAmount, behavior: "smooth" });
}
</script>

<style scoped>
.star {
  width: 1rem;
  height: 1rem;
  background-color: #00b67a;
  position: relative;
  margin-right: 0.2rem;
  display: inline-block;
}
.star::before {
  content: "";
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background-color: white;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  display: block;
}
.star.filled::before {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}
</style>
