<template>
  <div>
    <h1 class="font-bold text-xl uppercase text-primary-6">
      Step {{ investmentStore.currentStep - 2 }}: Banking
    </h1>
    <p class="text-lighter text-sm mt-2 mb-6">
      Investment Type: {{ investmentStore.currentInvestment.type }} • Offering Type:
      {{ investmentStore.selectedOffering.type }}
      ({{ investmentStore.selectedOffering.interest_rate }}% -
      {{
        investmentStore.selectedOffering.bond_term === 12
          ? "1 Year"
          : investmentStore.selectedOffering.bond_term / 12 + " Years"
      }}
      - {{ investmentStore.currentInvestment.is_compounded ? "Compounded" : "Paid Monthly" }}) •
      Amount to Invest: ${{ investmentStore.currentInvestment.amount }}
    </p>
    <!-- Non IRA Investments -->
    <div v-if="!investmentStore.currentInvestment?.type.includes('IRA')">
      <!-- Funding Accounts -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="mb-4 col-span-1 md:col-span-2 flex flex-col md:flex-row justify-between">
          <div class="w-full md:w-1/2 lg:w-full">
            <h2 class="text-lg font-semibold">1. Funding Account</h2>
            <p class="text-lighter text-sm lg:text-base mb-2 md:mb-0 w-2/3">
              Phoenix prefers to receive funds via an ACH transfer. Select
              <span @click="handleConnectBankClick" class="font-bold cursor-pointer"
                >Connect Bank Account</span
              >
              to create an ACH connection to your checking or savings account using Plaid. You can
              also fund your investment by sending us a check or transferring the funds with a wire
              from your financial institution.
            </p>
          </div>
          <PlaidLink accountType="Funding" />
        </div>

        <div class="flex flex-col gap-4 md:col-span-2 col-span-1 w-full">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 bg-neutral-gray-2 rounded-md p-3">
            <label
              class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 cursor-pointer"
              :class="
                investmentStore.currentInvestment.payment_method === 'Check'
                  ? 'border-[#AC813D]'
                  : 'border-platform-outlines'
              "
              @click="
                (investmentStore.currentInvestment.payment_method = 'Check'),
                  setBankAccountsInactive('Funding')
              "
            >
              <RadioOption
                id="check"
                name="paymentMethod"
                value="Check"
                v-model="investmentStore.currentInvestment.payment_method"
                label="Check"
                description="Mail us a check"
              />
              <div class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                <Icon
                  name="ArrowRightLeft"
                  class="h-12 w-12 text-neutral-gray-8-"
                  :stroke-width="1"
                />
              </div>
            </label>
            <label
              class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 cursor-pointer"
              :class="
                investmentStore.currentInvestment.payment_method === 'Wire'
                  ? 'border-[#AC813D]'
                  : 'border-platform-outlines'
              "
              @click="
                (investmentStore.currentInvestment.payment_method = 'Wire'),
                  setBankAccountsInactive('Funding')
              "
            >
              <RadioOption
                id="wire"
                name="paymentMethod"
                value="Wire"
                v-model="investmentStore.currentInvestment.payment_method"
                label="Wire"
                description="Initiate a transfer of funds from your bank"
              />
              <div class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                <Icon
                  name="ArrowRightLeft"
                  class="h-12 w-12 text-neutral-gray-8-"
                  :stroke-width="1"
                />
              </div>
            </label>
            <label
              v-if="filteredRolloverInvestments.length"
              class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 cursor-pointer"
              :class="
                investmentStore.currentInvestment.payment_method === 'Existing Investment'
                  ? 'border-[#AC813D]'
                  : 'border-platform-outlines'
              "
              @click="
                (investmentStore.currentInvestment.payment_method = 'Existing Investment'),
                  setBankAccountsInactive('Funding')
              "
            >
              <RadioOption
                id="existingInvestment"
                name="paymentMethod"
                value="Existing Investment"
                v-model="investmentStore.currentInvestment.payment_method"
                label="Existing Investment"
                description="Use money from an existing investment nearing maturity"
              />
              <div class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                <Icon
                  name="CircleDollarSign"
                  class="h-12 w-12 text-neutral-gray-8-"
                  :stroke-width="1"
                />
              </div>
            </label>
            <div
              v-for="(bankingOption, index) in !showMoreFunding
                ? investmentStore.bankAccounts.slice(0, 4)
                : investmentStore.bankAccounts"
              :key="bankingOption._id"
            >
              <!-- Plaid Accounts will go here -->
              <label
                class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 cursor-pointer"
                :class="
                  bankingOption._id === investmentStore.currentInvestment.funding_account &&
                  investmentStore.currentInvestment.payment_method === 'ACH'
                    ? 'border-[#AC813D]'
                    : 'border-platform-outlines'
                "
                @click="investmentStore.setActiveBankAccount(bankingOption, 'Funding')"
              >
                <RadioOption
                  :id="'Funding' + bankingOption._id"
                  :name="'FundingAccount' + index"
                  :value="bankingOption._id"
                  v-model="investmentStore.currentInvestment.funding_account"
                  :label="bankingOption.financial_institution_name"
                  :description="bankingOption.account_type"
                  :account_num="bankingOption.account_number"
                />
                <img
                  v-if="bankingOption.logo"
                  class="h-16 w-16"
                  :src="'data:image/png;base64, ' + bankingOption.logo + ''"
                  alt="Red dot"
                />
                <div v-else class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                  <Icon name="Landmark" class="h-12 w-12 text-neutral-gray-8-" :stroke-width="1" />
                </div>
              </label>
            </div>
            <button
              v-if="investmentStore.bankAccounts.length > 4"
              @click="showMoreFunding = !showMoreFunding"
              class="text-center lg:col-span-2"
            >
              <span v-if="!showMoreFunding"
                >Show More ({{
                  investmentStore.bankAccounts.slice(4, investmentStore.bankAccounts.length).length
                }})</span
              >
              <span v-else>Show Less</span>
            </button>
          </div>
          <div
            v-if="
              investmentStore.currentInvestment.payment_method &&
              investmentStore.currentInvestment.payment_method === 'Existing Investment'
            "
          >
            <h3 class="text-lg text-primary-6 my-6 font-bold">Pick Existing Investment</h3>
            <BaseInput
              v-model="investmentStore.currentInvestment.amount"
              placeholder="0.00"
              @input="formatAmount"
              @blur="roundInvestmentAmount"
              label="Investment Amount"
              size="large"
              maxLength="8"
              formatRule="amount"
              required
              class="w-full lg:w-72 mb-2"
            />
            <div v-if="isInvalidRolloverAmount" class="text-red-500 text-sm mt-1">
              Amount must be less than or equal to the available rollover amount.
            </div>
            <Table
              v-if="filteredRolloverInvestments.length > 0"
              :tableData="filteredRolloverInvestments"
              :headers="rollover_table_headers"
              class="w-full overflow-auto"
              :fieldsWithCustomStyles="['select', 'interest_rate', 'bond_term']"
              selectAllIsDisabled
              isWhiteHeader
            >
              <template v-slot:select="entry">
                <div class="px-4 flex items-center gap-2">
                  <Button @click="handleSelectRollover(entry.row)" label size="small">
                    <template #label>
                      <p class="font-normal">Select</p>
                    </template>
                  </Button>
                  <Icon
                    v-if="
                      investmentStore.selected_rollover_investment &&
                      investmentStore.selected_rollover_investment._id === entry.row._id
                    "
                    name="CircleCheck"
                    class="h-6 w-6 text-functional-success-default"
                    :stroke-width="2"
                  />
                </div>
              </template>
              <template v-slot:interest_rate="entry">
                <div class="px-4">
                  <p>{{ entry.row.interest_rate * 100 }}%</p>
                </div>
              </template>
              <template v-slot:bond_term="entry">
                <div class="px-4">
                  <p>{{ entry.row.bond_term }} Year<span v-if="entry.row.bond_term > 1">s</span></p>
                </div>
              </template>
            </Table>
            <p v-else class="p-4 bg-white">No investments available for rollover</p>
          </div>
          <div
            v-if="
              investmentStore.currentInvestment.payment_method &&
              investmentStore.currentInvestment.payment_method !== 'ACH' &&
              investmentStore.currentInvestment.payment_method !== 'Existing Investment'
            "
            class="p-4 border-cTeal border bg-cTeal bg-opacity-10 rounded-md flex flex-col gap-6"
          >
            <div class="flex items-start gap-4">
              <Icon name="File" class="h-6 w-6 text-cTeal text-opacity-50" :stroke-width="2" />
              <div>
                <p class="font-semibold">
                  {{
                    investmentStore.currentInvestment.payment_method === "Wire"
                      ? "Please wire funds to:"
                      : "Make check payable to:"
                  }}
                </p>
                <p class="text-lighter text-sm lg:text-base">Phoenix Capital Group Holdings, LLC</p>
              </div>
            </div>
            <div class="pl-10">
              <p class="font-semibold">
                {{
                  investmentStore.currentInvestment.payment_method === "Wire"
                    ? "Bank Name: Amarillo National Bank ABA Number: 111300958 Account Number: 319279"
                    : "Send the check through FexEx, UPS, or regular mail to:"
                }}
              </p>
              <p class="text-lighter text-sm lg:text-base">
                {{
                  investmentStore.currentInvestment.payment_method === "Wire"
                    ? "Reference: Phoenix Capital Group, Suite 830, 18575 Jamboree Rd, Irvine, CA 92612"
                    : "Phoenix Capital Group, Suite 830, 18575 Jamboree Rd, Irvine, CA 92612"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="border-b border-neutral-gray-4 w-full my-6"></div>
      <!-- Distribution Accounts -->
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div class="mb-4 col-span-1 md:col-span-2 flex flex-col md:flex-row justify-between">
          <div class="w-full md:w-1/2 lg:w-full">
            <h2 class="text-lg font-semibold">2. Distribution Account</h2>
            <p class="text-lighter text-sm lg:text-base mb-2 md:mb-0 w-full md:w-3/4">
              Phoenix distributes interest monthly on the 10th of each month. We prefer to make
              payments via ACH. Select the method and account where you want the interest payment
              deposited. If you wish to change the account, click
              <span @click="handleConnectBankClick" class="font-bold cursor-pointer"
                >Connect Bank Account</span
              >
              to add another account. Only checking and savings accounts are supported.
            </p>
          </div>
          <PlaidLink accountType="Distribution" />
        </div>

        <div class="flex flex-col gap-4 md:col-span-2 col-span-1 w-full">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 bg-neutral-gray-2 rounded-md p-3">
            <label
              class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 cursor-pointer"
              :class="
                investmentStore.currentInvestment.distribution_method === 'Check'
                  ? 'border-[#AC813D]'
                  : 'border-platform-outlines'
              "
              @click="
                (investmentStore.currentInvestment.distribution_method = 'Check'),
                  (investmentStore.currentInvestment.voided_check = false),
                  setBankAccountsInactive('Distribution')
              "
            >
              <RadioOption
                id="distributionCheck"
                name="distributionMethod"
                value="Check"
                v-model="investmentStore.currentInvestment.distribution_method"
                label="Check"
                description="Receive payment by check"
              />
              <div class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                <Icon
                  name="ArrowRightLeft"
                  class="h-12 w-12 text-neutral-gray-8-"
                  :stroke-width="1"
                />
              </div>
            </label>
            <label
              class="bg-white border border-platform-outlines flex items-center justify-between h-32 rounded-md p-3 px-6 cursor-pointer"
              :class="
                investmentStore.currentInvestment.voided_check
                  ? 'border-[#AC813D]'
                  : 'border-platform-outlines'
              "
              @click="
                (investmentStore.currentInvestment.distribution_method = 'ACH'),
                  setBankAccountsInactive('Distribution')
              "
            >
              <RadioOption
                id="voidedCheck"
                name="voidedCheck"
                :value="true"
                v-model="investmentStore.currentInvestment.voided_check"
                label="Unable to select your bank account?"
                description="Upload voided check or bank statement"
              />
              <div class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                <Icon name="CloudUpload" class="h-12 w-12 text-neutral-gray-8-" :stroke-width="1" />
              </div>
            </label>
            <div
              v-for="(bankingOption, index) in !showMoreDistribution
                ? investmentStore.bankAccounts.slice(0, 4)
                : investmentStore.bankAccounts"
              :key="bankingOption._id"
            >
              <!-- Plaid Accounts will go here -->
              <label
                class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 cursor-pointer"
                :class="
                  bankingOption._id === investmentStore.currentInvestment.distribution_account &&
                  investmentStore.currentInvestment.distribution_method === 'ACH'
                    ? 'border-[#AC813D]'
                    : 'border-platform-outlines'
                "
                @click="investmentStore.setActiveBankAccount(bankingOption, 'Distribution')"
              >
                <RadioOption
                  :id="'distribution' + bankingOption._id"
                  :name="'DistributionAccount' + index"
                  :value="bankingOption._id"
                  v-model="investmentStore.currentInvestment.distribution_account"
                  :label="bankingOption.financial_institution_name"
                  :description="bankingOption.account_type"
                  :account_num="bankingOption.account_number"
                />
                <img
                  v-if="bankingOption.logo"
                  class="h-16 w-16"
                  :src="'data:image/png;base64, ' + bankingOption.logo + ''"
                  alt="Red dot"
                />
                <div v-else class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                  <Icon name="Landmark" class="h-12 w-12 text-neutral-gray-8-" :stroke-width="1" />
                </div>
              </label>
            </div>
            <button
              v-if="investmentStore.bankAccounts.length > 4"
              @click="showMoreDistribution = !showMoreDistribution"
              class="text-center lg:col-span-2"
            >
              <span v-if="!showMoreDistribution"
                >Show More ({{
                  investmentStore.bankAccounts.slice(4, investmentStore.bankAccounts.length).length
                }})</span
              >
              <span v-else>Show Less</span>
            </button>
          </div>
          <div
            v-if="investmentStore.currentInvestment.distribution_method === 'Check'"
            class="p-4 border-cTeal border bg-cTeal bg-opacity-10 rounded-md flex flex-col gap-6"
          >
            <div class="flex items-start gap-4">
              <Icon name="File" class="h-6 w-6 text-cTeal text-opacity-50" :stroke-width="2" />
              <div>
                <p class="font-semibold">Expect a check from:</p>
                <p class="text-lighter text-sm lg:text-base">Phoenix Capital Group Holdings, LLC</p>
              </div>
            </div>
            <div class="pl-10">
              <p class="font-semibold">Around the time of your distribution date each month.</p>
            </div>
          </div>
          <div
            v-else-if="investmentStore.currentInvestment.voided_check"
            class="p-4 border-cTeal border bg-cTeal bg-opacity-10 rounded-md flex flex-col gap-6"
          >
            <div class="flex items-start gap-4">
              <Icon
                name="File"
                class="h-6 w-6 min-h-6 min-w-6 text-cTeal text-opacity-50"
                :stroke-width="2"
              />
              <div>
                <p class="font-semibold">Upload photo of a voided check or bank statement</p>
                <p class="text-lighter text-sm lg:text-base">
                  Upload PDF, JPEG, JPG, PNG, or DOCX files. If you choose bank statement, the photo
                  must show your name, the bank’s name; and at a minimum, the last 4 digits of the
                  account number. Reach out to Investor Relations at 303-376-3599 to receive the
                  Manual Bank Account Form to complete the process.
                </p>
              </div>
            </div>
            <FileDropper
              v-if="!investmentStore.selectedVoidedCheckFile"
              @files-selected="handleFile"
              :selectOne="true"
            />
            <div
              v-else
              class="flex items-center justify-between border-2 px-5 py-[.688rem] bg-[#06253f14] border-[#516679] border-dashed rounded-md"
            >
              <div class="flex items-center space-x-2">
                <Icon name="FileUp" class="h-6 w-6 text-cTeal text-opacity-50" :stroke-width="2" />
                <div>
                  {{ investmentStore.selectedVoidedCheckFile.name }}
                </div>
              </div>
              <Button
                @click="investmentStore.selectedVoidedCheckFile = null"
                icon
                isIconOnlyOnMobile
                size="medium"
                variant="error-color-outlined"
              >
                <template #icon>
                  <Icon name="Trash" class="h-5 w-5 text-red-600" :stroke-width="2"
                /></template>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- IRA Investments -->
    <div v-else class="text-primary-6">
      <div class="mb-6">
        <h2 class="font-bold mb-4">IRA Questionnaire</h2>
        <p class="text-lighter">
          To help us assist you in completing your IRA investment, please complete this brief
          questionnaire.
        </p>
      </div>
      <BaseInput
        label="Investment Type"
        is_required
        v-model="investmentStore.currentInvestment.type"
        size="large"
        type="select"
        :options="['Traditional IRA', 'Roth IRA', 'SEP IRA']"
        class="w-full md:w-1/2"
      />
      <div class="border-b border-neutral-gray-4 w-full my-6"></div>
      <div v-for="(question, index) in IRA_QUESTIONNAIRE" :key="question" class="mt-4">
        <div v-if="question.type === 'radio'" class="flex flex-col gap-2 mb-4">
          <p>
            {{ index + 1 }}. {{ question.question }}
            <span class="text-functional-error-default">*</span>
          </p>
          <div class="flex gap-4 items-center mt-4">
            <RadioOption
              v-for="(option, index) in question.options"
              :id="`${question.field_name}-${index}`"
              :name="question.field_name"
              :value="option.value"
              v-model="investorStore.contact.ira_questionnaire[question.field_name]"
              :label="option.label"
              class="w-full md:w-1/4"
            />
          </div>
        </div>
        <div v-else class="my-4">
          <p class="mb-6">{{ index + 1 }}. {{ question.question }}</p>
          <BaseInput
            v-model="investorStore.contact.ira_questionnaire[question.field_name]"
            size="large"
            class="w-full md:w-1/2"
          />
        </div>
        <div
          v-if="index !== IRA_QUESTIONNAIRE.length - 1"
          class="border-b border-neutral-gray-4 w-full my-6"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";

import { useInvestmentStore, useInvestorStore, useCrudStore, useAuthStore } from "@/stores";
import { Button, Icon, RadioOption, BaseInput, FileDropper, Table } from "@/components";
import { IRA_QUESTIONNAIRE } from "@/constants";
import { commaSeparateThousands_2 } from "@/utilities";
import PlaidLink from "./PlaidLink.vue";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const crudStore = useCrudStore();
const authStore = useAuthStore();

const showMoreFunding = ref(false);
const showMoreDistribution = ref(false);
const rollover_table_headers = ref([
  { field_name: "select", label: "" },
  { field_name: "type", label: "Type" },
  { field_name: "rollover_available", label: "Rollover Available", number_type: "currency" },
  { field_name: "interest_rate", label: "Rate" },
  { field_name: "bond_term", label: "Term" },
  { field_name: "maturity_date", label: "Maturity", field_type: "date" },
]);
const plaidFundingRef = ref(null);

onMounted(async () => {
  if (investmentStore.currentInvestment.type.includes("IRA")) {
    // remove bank account info for IRA investments
    investmentStore.currentInvestment.payment_method = investmentStore.selected_rollover_investment
      ? "Existing Investment"
      : "Wire";
    investmentStore.currentInvestment.distribution_method = "";
    investmentStore.currentInvestment.distribution_account = null;
    investmentStore.currentInvestment.funding_account = null;
    investmentStore.currentInvestment.voided_check = false;
  }
  investmentStore.bankAccounts = await crudStore.aggregate("BankAccounts", [
    {
      $match: {
        user_id: authStore.currentUser.id,
      },
    },
    { $sort: { created_date: -1 } },
  ]);
});

const filteredRolloverInvestments = computed(() => {
  const rolloverInvestments = investmentStore.transactionsData.filter((i) => {
    return i.able_to_rollover && i.status === "Settled" && i.rollover_available > 0;
  });
  return rolloverInvestments;
});

const isInvalidRolloverAmount = computed(() => {
  if (investmentStore.selected_rollover_investment) {
    return (
      Number(investmentStore.currentInvestment.amount.replace(/,/g, "")) >
      investmentStore.selected_rollover_investment.rollover_available
    );
  }
  return false;
});

function setBankAccountsInactive(accountType) {
  if (accountType === "Funding") {
    investmentStore.currentInvestment.funding_account = null;
    investmentStore.bankAccounts.forEach((account) => {
      account.is_active = false;
    });
  } else {
    investmentStore.currentInvestment.distribution_account = null;
    investmentStore.bankAccounts.forEach((account) => {
      account.is_active = false;
    });
  }
}

async function handleFile(files) {
  investmentStore.selectedVoidedCheckFile = files[0];
}

function handleSelectRollover(investment) {
  investmentStore.selected_rollover_investment = investment;
  investmentStore.currentInvestment.amount = commaSeparateThousands_2(
    investment.rollover_available
  );
}

function roundInvestmentAmount() {
  const numberAmount = Number(investmentStore.currentInvestment.amount.replace(/,/g, ""));
  const roundedAmount = Math.round(numberAmount / 1000) * 1000;
  const formattedAmount = commaSeparateThousands_2(roundedAmount.toString());
  investmentStore.currentInvestment.amount = formattedAmount;
}

function formatAmount() {
  investmentStore.currentInvestment.amount = commaSeparateThousands_2(
    investmentStore.currentInvestment.amount
  );
}

function handleConnectBankClick() {
  const plaidButton = document.getElementById("plaid-link-button");
  if (plaidButton) {
    plaidButton.click();
  }
}
</script>
