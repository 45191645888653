<template>
  <div class="flex flex-col w-full h-full lg:my-6">
    <button
      type="button"
      @click="handleBack"
      class="md:hidden flex items-center justify-center gap-1.5 text-primary-6 absolute top-24 left-5"
    >
      <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
      <p>Back</p>
    </button>
    <div
      class="flex flex-col grow items-center lg:pt-10 lg:pb-8 lg:border border-neutral-gray-4 w-full rounded-md h-full"
    >
      <div class="w-full lg:w-[70%] mx-auto flex flex-col justify-between h-full">
        <div>
          <h1 class="font-bold text-primary-6 text-xl uppercase">
            Step {{ investmentStore.currentStep - 2 }}-{{ pi_substep }}:
            {{ investmentStore.investmentSteps[investmentStore.currentStep - 3] }}
          </h1>
          <p class="text-lighter text-sm mt-2 mb-6">
            Investment Type: {{ investmentStore.currentInvestment.type }} • Offering Type:
            {{ investmentStore.selectedOffering.type }}
            ({{ investmentStore.selectedOffering.interest_rate }}% -
            {{
              investmentStore.selectedOffering.bond_term === 12
                ? "1 Year"
                : investmentStore.selectedOffering.bond_term / 12 + " Years"
            }}
            - {{ investmentStore.currentInvestment.is_compounded ? "Compounded" : "Paid Monthly" }})
            • Amount to Invest: ${{ investmentStore.currentInvestment.amount }}
          </p>
          <template v-for="(schema, index) of schemaToUse" :key="index">
            <Form
              v-show="pi_substep === index + 1"
              :fields="schema.fields"
              :record="investorStore.contact"
              :header="schema.group_name"
              :subHeader="
                index === 1
                  ? 'Phoenix requires a physical address. PO Boxes and mail drops are not valid. If you use a PO Box for receiving mail, please reach out to Investor Relations at (303) 376-9778 to have your account updated.'
                  : index === 2
                    ? 'Your passport or government issued ID (driver\'s license).'
                    : ''
              "
              type="personal"
            />
          </template>
        </div>
        <div
          class="w-full flex gap-4 flex-col-reverse md:flex-row justify-between items-center border-t border-neutral-gray-4 pt-6 mt-6"
        >
          <button @click="handleBack" type="button" class="flex items-center text-primary-6 gap-2">
            <Icon name="ChevronLeft" class="hidden md:block h-5 w-5 text-primary-6 mb-0.5" />
            <p>Back</p>
          </button>
          <Button
            @click="handleNext"
            :disabled="!isFormValid"
            label
            icon
            size="large"
            class="w-full md:w-value-42"
          >
            <template #label>Next</template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { cloneDeep } from "lodash";

import { useInvestmentStore, useInvestorStore } from "@/stores";
import { Button, Icon, Form } from "@/components";
import { getObjectDifferences, validateField } from "@/utilities";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

const pi_substep = ref(1);
const original_owner_contact = ref({});
const schemaToUse = computed(() => {
  return investmentStore.selectedOffering.type === "Reg D"
    ? investmentStore.accredited_owner_contact_schema
    : investmentStore.non_accredited_owner_contact_schema;
});

onMounted(async () => {
  window.scrollTo(0, 0);
  original_owner_contact.value = cloneDeep(investorStore.contact);
});

const isAccreditedOffering = computed(() => {
  return investmentStore.selectedOffering?.type === "Reg D";
});

const isFormValid = computed(() => {
  if (!schemaToUse.value || schemaToUse.value.length < pi_substep.value) {
    return false;
  }

  const currentSchema = schemaToUse.value[pi_substep.value - 1]; // Get the schema for the current step
  const fieldsToValidate = currentSchema.fields.filter((f) => f.is_required);

  if (
    investorStore.hasSpecialError === "ssn" ||
    investorStore.hasSpecialError === "email" ||
    investorStore.hasSpecialError === "id_number"
  ) {
    return false;
  }

  return fieldsToValidate.every((field) => {
    const value =
      field.belongs_to_nested_object && field.nested_object_name
        ? investorStore.contact[field.nested_object_name]?.[field.field_name]
        : investorStore.contact[field.field_name];

    return validateField(field.field_name, value);
  });
});

function hasAnyValueChanged() {
  const differences = getObjectDifferences(investorStore.contact, original_owner_contact.value);

  if (Object.keys(differences).length > 0) {
    return true;
  } else {
    return false;
  }
}

watch(
  () => pi_substep.value,
  () => {
    window.scrollTo(0, 0);
  }
);

async function handleBack() {
  if (pi_substep.value === 1) {
    investmentStore.currentStep = 2;
  } else {
    pi_substep.value -= 1;
  }
}

async function handleNext() {
  const hasChange = hasAnyValueChanged();
  if (isAccreditedOffering.value && pi_substep.value === 3) {
    investmentStore.currentStep = 4;
  } else if (!isAccreditedOffering.value && pi_substep.value === 2) {
    investmentStore.currentStep = 4;
  } else {
    pi_substep.value += 1;
  }
  if (hasChange) {
    original_owner_contact.value = cloneDeep(investorStore.contact);
    await investorStore.updateContactAndAccount();
  }
}
</script>
