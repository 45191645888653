<template>
  <div class="flex gap-1 w-full mb-4 md:mb-9 justify-start">
    <div
      v-for="step in stepsArray"
      :key="step"
      @click="handleClick(step)"
      class="h-1 w-full rounded-md cursor-pointer"
      :class="authStore.activeSignupStep >= step ? 'bg-primary-6' : 'bg-neutral-gray-4'"
    ></div>
  </div>
</template>

<script setup>
import { computed } from "vue";

import { useAuthStore } from "@/stores";

const authStore = useAuthStore();

const props = defineProps({
  numSteps: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["set-active-step"]);

// Create an array with the number of steps
const stepsArray = computed(() => Array.from({ length: props.numSteps }, (_, i) => i + 1));

// Handle the click event and emit the step number
const handleClick = (step) => {
  emit("set-active-step", step);
};
</script>
