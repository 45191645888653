<template>
  <DocumentPreviewModal
    :open="previewModalOpen"
    @close-modal="previewModalOpen = false"
    :file="previewDoc"
  />
  <div class="py-5 md:px-[1.375rem] lg:px-10 md:py-6">
    <div class="px-3 md:px-0 mb-4 md:mt-0">
      <p class="text-xs leading-tight font-medium text-primary-2 uppercase">
        Welcome back,
        <span v-if="investorStore.contact">
          {{ investorStore.contact?.first_name + " " + investorStore.contact?.last_name }}
        </span>
      </p>
      <p class="text-2xl leading-tight font-bold text-text-dark uppercase">your PORTFOLIO</p>
    </div>
    <div v-if="isLoading">
      <LoadingSpinner :show="isLoading" message="Loading..." hideOverlay />
    </div>
    <div v-if="investmentStore.transactionsData?.length && !isLoading">
      <div
        class="rounded-md md:border border-neutral-gray-5 w-full block lg:flex justify-between mb-10"
      >
        <div
          class="px-3 md:px-8 md:py-6 lg:p-10 lg:border-b-0 lg:border-r border-neutral-gray-5 lg:w-value-36 w-full grid grid-cols-2 lg:grid-cols-1 flex-none gap-x-4"
        >
          <div>
            <div class="py-2.5">
              <p class="text-xs leading-tight font-medium text-primary-2 uppercase">
                Total Invested
              </p>
              <p class="text-xl md:text-2xl leading-tight font-bold text-text-dark uppercase">
                ${{
                  investorStore.account?.total_invested != null &&
                  !isNaN(investorStore.account.total_invested)
                    ? commaSeparateThousands(investorStore.account.total_invested?.toFixed(2))
                    : "0"
                }}
              </p>
            </div>
            <hr class="py-2.5 border-neutral-gray-5" />
          </div>
          <div>
            <div class="py-2.5">
              <p class="text-xs leading-tight font-medium text-primary-2 uppercase">
                Total Pending
              </p>
              <p class="text-xl md:text-2xl leading-tight font-bold text-text-dark uppercase">
                ${{
                  investorStore.account?.total_pending != null &&
                  !isNaN(investorStore.account.total_pending)
                    ? commaSeparateThousands(investorStore.account.total_pending?.toFixed(2))
                    : "0"
                }}
              </p>
            </div>
            <hr class="py-2.5 border-neutral-gray-5" />
          </div>
          <div>
            <div class="py-2.5">
              <p class="text-xs leading-tight font-medium text-primary-2 uppercase">
                Total Earnings
              </p>
              <p class="text-xl md:text-2xl leading-tight font-bold text-text-dark uppercase">
                ${{
                  investorStore.account?.distributions_inception_to_date != null &&
                  !isNaN(investorStore.account.distributions_inception_to_date)
                    ? commaSeparateThousands(
                        (
                          Math.round(investorStore.account.distributions_inception_to_date * 100) /
                          100
                        )?.toFixed(2)
                      )
                    : "0.00"
                }}
              </p>
            </div>
            <hr class="pt-2.5 lg:py-2.5 border-neutral-gray-5" />
          </div>
          <div>
            <div class="py-2.5">
              <p class="text-xs leading-tight font-medium text-primary-2 uppercase">
                Next Statement Date
              </p>
              <p class="text-xl md:text-2xl leading-tight font-bold text-dark uppercase">
                {{
                  investorStore.account?.next_distribution_date
                    ? new Date(investorStore.account?.next_distribution_date).toLocaleDateString(
                        "en-US",
                        {
                          timeZone: "UTC",
                        }
                      )
                    : "N/A"
                }}
              </p>
            </div>
            <hr class="pt-2.5 lg:py-2.5 border-neutral-gray-5 lg:hidden" />
          </div>
        </div>
        <div
          class="w-full md:border-t mt-6 md:mt-0 lg:mt-5 lg:border-none border-platform-outlines"
        >
          <div class="flex items-center justify-between border-b border-platform-outlines relative">
            <div
              v-if="showScrollLeft"
              class="absolute left-0 top-0 z-50 flex h-12 items-center lg:ml-value-1"
            >
              <button
                type="button"
                @click="scrollLeft"
                class="flex h-9.5 w-12 items-center justify-center bg-white"
              >
                <Icon name="ChevronLeft" class="h-5 w-5" />
                <IconChevronLeft class="h-5 w-5" />
              </button>
              <div class="gradient-right h-9.5 w-8"></div>
            </div>
            <div v-if="showScrollRight" class="absolute right-0 top-0 z-50 flex h-12 items-center">
              <div class="gradient-left h-9.5 w-8"></div>
              <button
                type="button"
                @click="scrollRight"
                class="flex h-9.5 w-12 items-center justify-center bg-white"
              >
                <Icon name="ChevronRight" class="h-5 w-5" />
              </button>
            </div>
            <div
              class="flex items-center overflow-auto pl-3 no-scrollbar"
              @scroll="handleScroll()"
              ref="scrollContainer"
            >
              <button
                @click="setActiveChartTab('portfolio_dynamics')"
                class="md:px-value-37 md:py-3.5 px-2 py-value-38 uppercase flex items-center md:space-x-2"
                :class="
                  activeChartTab === 'portfolio_dynamics'
                    ? 'border-b-[3px] border-tab-gold-border text-xs leading-tight font-bold text-primary-6'
                    : 'border-b-[3px] border-platform-outlines text-xs leading-tight font-bold text-primary-2'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-chart-column-increasing hidden md:block"
                >
                  <path d="M13 17V9" />
                  <path d="M18 17v-3" />
                  <path d="M3 3v16a2 2 0 0 0 2 2h16" />
                  <path d="M8 17V5" />
                </svg>
                <p class="whitespace-nowrap">Portfolio Dynamics</p>
              </button>
              <button
                @click="setActiveChartTab('total_earnings')"
                class="md:px-value-37 md:py-3.5 px-2 py-value-38 uppercase flex items-center md:space-x-2"
                :class="
                  activeChartTab === 'total_earnings'
                    ? 'border-b-[3px] border-tab-gold-border text-xs leading-tight font-bold text-primary-6'
                    : 'border-b-[3px] border-platform-outlines text-xs leading-tight font-bold text-primary-2'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-dollar-sign hidden md:block"
                >
                  <line x1="12" x2="12" y1="2" y2="22" />
                  <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
                </svg>
                <p class="whitespace-nowrap">Total Earnings</p></button
              ><button
                @click="setActiveChartTab('portfolio_structure')"
                class="md:px-value-37 md:py-3.5 px-2 py-value-38 uppercase flex items-center md:space-x-2"
                :class="
                  activeChartTab === 'portfolio_structure'
                    ? 'border-b-[3px] border-tab-gold-border text-xs leading-tight font-bold text-primary-6'
                    : 'border-b-[3px] border-platform-outlines text-xs leading-tight font-bold text-primary-2'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-chart-pie hidden md:block"
                >
                  <path
                    d="M21 12c.552 0 1.005-.449.95-.998a10 10 0 0 0-8.953-8.951c-.55-.055-.998.398-.998.95v8a1 1 0 0 0 1 1z"
                  />
                  <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                </svg>

                <p class="whitespace-nowrap">Portfolio Structure</p>
              </button>
            </div>
          </div>
          <div class="w-full h-value-39">
            <PieChart
              v-if="activeChartTab === 'portfolio_structure'"
              :chartData="investmentStore.chartData"
              :chartOptions="investmentStore.chartOptions"
            />
            <BarChart
              v-else
              :chartData="investmentStore.chartData"
              :chartOptions="investmentStore.chartOptions"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="px-3 md:px-0">
          <p class="text-2xl leading-tight font-bold text-text-dark uppercase">Your Transactions</p>
          <p class="text-sm text-lighter">
            For specific details including earnings and bank information for each investment select
            the Details link.
          </p>
          <div class="flex items-center justify-between">
            <div class="flex items-center relative">
              <button
                @click="setActiveTab('purchases')"
                class="px-value-37 py-3.5 uppercase flex items-center space-x-2"
                :class="
                  activeTab === 'purchases'
                    ? 'border-b-[3px] border-tab-gold-border text-xs leading-tight font-bold text-primary-6'
                    : 'border-b-[3px] border-platform-outlines text-xs leading-tight font-bold text-primary-2'
                "
              >
                <Icon
                  name="ShoppingCart"
                  class="h-5 w-5 fill-none hidden md:block"
                  :strokeWidth="2"
                />
                <p>Purchases</p>
              </button>
              <button
                @click="setActiveTab('earnings')"
                class="px-value-37 py-3.5 uppercase flex items-center space-x-2"
                :class="
                  activeTab === 'earnings'
                    ? 'border-b-[3px] border-tab-gold-border text-xs leading-tight font-bold text-primary-6'
                    : 'border-b-[3px] border-platform-outlines text-xs leading-tight font-bold text-primary-2'
                "
              >
                <Icon
                  name="DollarSign"
                  class="h-5 w-5 fill-none hidden md:block"
                  :strokeWidth="2"
                />
                <p>Earnings</p>
              </button>
              <!-- <div class="absolute -right-2">
              <Icon
                @click="isEarningsInfoOpen = !isEarningsInfoOpen"
                name="CircleHelp"
                class="circle-help text-lighter h-4 w-4 cursor-pointer absolute -top-4 right-0"
              />
              <div
                v-if="isEarningsInfoOpen"
                class="bg-neutral-gray-4 rounded text-sm text-lighter p-3 flex absolute -top-28 -left-20 sm:-top-20 sm:left-2 w-64 animate-slideup z-50 open-info shadow-lg"
              >
                <div class="relative">
                  <p>
                    If you want to see earnings for individual investments - click 'View' from the
                    purchases tab.
                  </p>
                  <Icon
                    name="X"
                    class="text-lighter h-3.5 w-3.5 absolute right-0 top-0 cursor-pointer"
                    @click="isEarningsInfoOpen = !isEarningsInfoOpen"
                  />
                </div>
              </div>
            </div> -->
            </div>
            <Button
              @click="downloadStatement"
              label
              icon
              is-icon-only-on-mobile
              variant="default-gray-outlined"
              :disabled="!statement"
            >
              <template #label>Download Statement</template>
              <template #icon>
                <Icon name="Download" :stroke-width="2" class="h-4 w-4" />
              </template>
            </Button>
          </div>
        </div>

        <!-- <div class="border-b border-platform-outlines w-full"></div> -->
        <Table
          v-if="paginatedData?.length > 0"
          @setSortHeader="(header, props) => setSortHeader(header, props, activeTab)"
          :headers="tableHeaders"
          :tableData="paginatedData"
          :fieldsWithCustomStyles="[
            'status',
            'details',
            'table-ctas',
            '_id',
            'is_compounded',
            'associated_to',
          ]"
          actions_always_sticky
          class="max-w-full"
        >
          <template v-slot:status="entry">
            <div class="ml-4 truncate">
              <div
                class="bg-opacity-10 rounded-xl py-1 px-2 w-fit flex items-center justify-center"
                :class="
                  entry.row.status == 'Created'
                    ? 'bg-custom-light-blue text-custom-light-blue'
                    : entry.row.status == 'Settled'
                      ? 'bg-custom-lime-green text-custom-lime-green'
                      : entry.row.status === 'Canceled'
                        ? 'bg-functional-error-default text-functional-error-hover'
                        : 'bg-functional-warning-default text-functional-warning-hover'
                "
              >
                {{ entry.row.status }}
              </div>
            </div>
          </template>
          <template v-slot:is_compounded="entry">
            <div class="ml-4 truncate text-sm">
              {{ entry.row.is_compounded ? "Yes" : "No" }}
            </div>
          </template>
          <template v-slot:associated_to="entry">
            <div class="ml-4 truncate text-sm">
              {{ getAssociatedTo(entry.row) }}
            </div>
          </template>
          <template v-slot:details="entry">
            <RouterLink
              :to="{
                path: '/investment-details',
                query: { investmentId: entry.row._id },
              }"
              @click="investmentStore.investmentDetails = entry.row"
              class="text-sm font-medium text-primary-6 p-4 underline cursor-pointer"
            >
              Details
            </RouterLink>
          </template>
          <template v-slot:table-ctas="entry">
            <div class="flex items-center space-x-2">
              <Button
                @click="downloadSubAgreement(entry)"
                size="small"
                variant="default-gray-outlined"
                icon
                :disabled="entry.entry.signature_date === null"
              >
                <template #icon>
                  <Icon name="Download" class="h-4 w-4 fill-none" :strokeWidth="2" />
                </template>
              </Button>
              <Button
                @click="previewSubAgreement(entry)"
                size="small"
                variant="default-gray-outlined"
                icon
                :disabled="entry.entry.signature_date === null"
              >
                <template #icon>
                  <Icon name="Eye" class="h-4 w-4 fill-none" :strokeWidth="2" />
                </template>
              </Button>
            </div>
          </template>
          <template v-slot:_id="entry">
            <div class="px-4">
              <p class="text-sm uppercase">{{ entry.row._id.slice(-4) }}</p>
            </div>
          </template>
        </Table>
        <div class="px-3 md:px-0">
          <Pagination
            v-if="paginatedData?.length > 0"
            show-count
            :total-category-results="
              activeTab === 'purchases'
                ? investmentStore.transactionsData?.length
                : investmentStore.earningsData?.length
            "
            :page-size="pageSize"
            :page-num="currentPage"
            @change-page="currentPage = $event"
            class="mt-4"
          />
        </div>
      </div>
    </div>
    <div
      v-if="activeTab === 'purchases' && !investmentStore.transactionsData?.length && !isLoading"
      class="mt-16 px-3 md:px-0"
    >
      <div class="flex flex-col justify-center items-center min-h-40">
        <div
          class="p-2 bg-primary-1-light bg-opacity-50 rounded-full flex justify-center items-center"
        >
          <div class="h-18 w-18 bg-primary-1-light rounded-full flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-chart-line"
            >
              <path d="M3 3v16a2 2 0 0 0 2 2h16" />
              <path d="m19 9-5 5-4-4-3 3" />
            </svg>
          </div>
        </div>
        <p class="text-primary-2 text-lg mt-4 text-center">
          Once you start investing, your investments will appear here.
        </p>
        <p class="text-primary-2 text-center text-lg">
          Get started and make your money work for you!
        </p>
        <Button @click="router.push('/make-an-investment')" label size="large" class="mx-auto mt-4">
          <template #label>Make an Investment</template>
        </Button>
      </div>
    </div>
    <div
      v-if="activeTab === 'earnings' && !investmentStore.earningsData?.length && !isLoading"
      class="mt-8 px-3 md:px-0 text-center"
    >
      <EmptyState
        description="Once you start receiving distributions, your earnings will appear here."
        icon_name="HandCoins"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import {
  useUIStore,
  useAuthStore,
  useInvestmentStore,
  useInvestorStore,
  useCrudStore,
} from "@/stores";
import {
  Button,
  Icon,
  Table,
  Pagination,
  DocumentPreviewModal,
  LoadingSpinner,
  EmptyState,
} from "@/components";
import { commaSeparateThousands } from "@/utilities";
import useFileUtility from "@/composables/useFileUtility";
import BarChart from "./BarChart.vue";
import PieChart from "./PieChart.vue";
import { MONTHS } from "@/constants";
import { useGradientScroll } from "@/composables/useGradientScroll";

const { scrollContainer, showScrollRight, showScrollLeft, scrollRight, scrollLeft, handleScroll } =
  useGradientScroll();

const {
  downloadFile_R2,

  fetchFileForPreview_R2,
} = useFileUtility();

const router = useRouter();

const UIStore = useUIStore();
const investorStore = useInvestorStore();
const authStore = useAuthStore();
const investmentStore = useInvestmentStore();
const crudStore = useCrudStore();

const activeChartTab = ref("portfolio_dynamics");
const currentPage = ref(1);
const pageSize = ref(10);

const activeTab = ref("purchases");
const tableData = ref([]);
const tableHeaders = ref([]);
// const statementModalOpen = ref(false);
// const statementMonth = ref(
//   new Date().getDate() < 10 ? MONTHS[new Date().getMonth() - 1] : MONTHS[new Date().getMonth()]
// );
// const statementYear = ref(new Date().getFullYear());
const statement = ref(null);
const previewDoc = ref(null);
const previewModalOpen = ref(false);
const noInvestments = ref(false);
const isLoading = ref(false);
const isEarningsInfoOpen = ref(false);

onMounted(async () => {
  document.addEventListener("click", closeInfoModals);
  if (!investmentStore.transactionsData?.length) {
    isLoading.value = true;
  }
  await getTableData("purchases"); // populate transactions and set it to active
  if (tableData.value?.length) {
    await investmentStore.getPortfolioDynamicsData();
    await investmentStore.getInvestorEarnings(); // populate earnings
    statement.value = await crudStore.findOne("Documents", {
      type: "Statements",
      user_id: authStore.currentUser.id,
    });
  }
  isLoading.value = false;
});

onUnmounted(() => {
  document.removeEventListener("click", closeInfoModals);
});

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;

  return activeTab.value === "purchases"
    ? investmentStore.transactionsData?.slice(start, end)
    : investmentStore.earningsData?.slice(start, end);
});

watch(
  () => activeTab.value,
  () => {
    currentPage.value = 1;
  }
);

async function getTableData(activeTab) {
  if (activeTab === "purchases") {
    tableHeaders.value = [
      { field_name: "details", label: "Details", field_type: "", number_type: "" },
      { field_name: "_id", label: "ID", field_type: "", number_type: "" },
      { field_name: "signature_date", label: "Transaction Date", field_type: "date" },
      { field_name: "type", label: "Type" },
      { field_name: "associated_to", label: "Associated To" },
      { field_name: "status", label: "Status" },
      { field_name: "amount", label: "Amount", field_type: "number", number_type: "currency" },
      { field_name: "bonds", label: "Bonds", field_type: "number" },
      {
        field_name: "interest_rate",
        label: "Rate",
        field_type: "number",
        number_type: "percentage",
      },
      { field_name: "bond_term", label: "Term", field_type: "number" },
      { field_name: "is_compounded", label: "Is Compounded?" },
      { field_name: "maturity_date", label: "Maturity Date", field_type: "date" },
      {
        field_name: "year_to_date_interest_paid",
        label: "YTD Earned",
        field_type: "number",
        number_type: "currency",
      },
      {
        field_name: "inception_to_date_interest_paid",
        label: "Total Earned",
        field_type: "number",
        number_type: "currency",
      },
      { field_name: "subscription_agreement", label: "Agreement" },
    ];
    tableData.value = await investmentStore.getInvestorTransactions();
    if (!investmentStore.transactionsData?.length) {
    } else {
      tableData.value = investmentStore.transactionsData;
    }
  } else {
    tableHeaders.value = [
      { field_name: "distribution_date", label: "Date", field_type: "date" },
      { field_name: "amount", label: "Amount", field_type: "number", number_type: "currency" },
    ];
    if (!investmentStore.earningsData.length) {
      tableData.value = await investmentStore.getInvestorEarnings();
    } else {
      tableData.value = investmentStore.earningsData;
    }
  }
}

async function setSortHeader(header, props, activeTab) {
  UIStore.setSortHeader(header, props);
  activeTab === "purchases"
    ? (tableData.value = await investmentStore.getInvestorTransactions())
    : (tableData.value = await investmentStore.getInvestorEarnings());
}

async function downloadStatement() {
  if (statement.value) {
    await downloadFile_R2(statement.value);
  }
}

async function downloadSubAgreement(entry) {
  let subAgreement = await crudStore.findOne("Documents", {
    type: "Sub Agreements",
    investment_id: entry.entry._id,
  });
  await downloadFile_R2(subAgreement);
}

async function previewSubAgreement(entry) {
  let subAgreement = await crudStore.findOne("Documents", {
    type: "Sub Agreements",
    investment_id: entry.entry._id,
  });
  const fileUrl = await fetchFileForPreview_R2(subAgreement);
  previewDoc.value = { ...subAgreement, file_url: fileUrl };
  previewModalOpen.value = true;
}

async function setActiveTab(tabName) {
  activeTab.value = tabName;
  await getTableData(tabName);
}

async function setActiveChartTab(tabName) {
  activeChartTab.value = tabName;
  if (tabName === "portfolio_dynamics") {
    await investmentStore.getPortfolioDynamicsData();
  } else if (tabName === "total_earnings") {
    investmentStore.getTotalEarningsData();
  } else if (tabName === "portfolio_structure") {
    investmentStore.getPortfolioStructureData();
  }
}

function closeInfoModals(event) {
  if (!event.target.closest(".open-info") && !event.target.closest(".circle-help")) {
    isEarningsInfoOpen.value = false;
  }
}

function getTypeInfo(entry) {
  if (entry.type === "Joint") {
    const jointContact = investorStore.joint_contacts.find(
      (contact) => contact._id?.toString() === entry.joint_contact_id?.toString()
    );

    const jointName = `${jointContact?.first_name[0]}. ${jointContact?.last_name}`;
    return `${entry.type} - ${jointName.length > 14 ? jointName?.slice(0, 14) + "..." : jointName}`;
  } else if (entry.type === "Entity") {
    const entityAccount = investorStore.entity_accounts.find(
      (entity) => entity._id?.toString() === entry.entity_account_id?.toString()
    );

    const entityName = entityAccount?.name;
    return `${entry.type} - ${
      entityName.length > 14 ? entityName?.slice(0, 14) + "..." : entityName
    }`;
  }
}

function getAssociatedTo(row) {
  if (row.type === "Joint") {
    const joint_contact = investorStore.joint_contacts.find(
      (c) => c?._id?.toString() === row.joint_contact_id?.toString()
    );
    return `${joint_contact?.first_name} ${joint_contact?.last_name}`;
  } else if (row.type === "Entity") {
    const entity = investorStore.entity_accounts.find(
      (entity) => entity?._id?.toString() === row.entity_account_id?.toString()
    );
    return entity?.name;
  } else {
    const ownerContact = investorStore.contact;
    return `${ownerContact?.first_name} ${ownerContact?.last_name}`;
  }
}
</script>
