<template>
  <div class="flex flex-col lg:flex-row-reverse items-start w-full h-full pb-10 lg:p-6">
    <div
      class="flex px-6 w-full lg:w-[65%] lg:border border-platform-outlines h-full rounded-md justify-center"
    >
      <div class="w-full md:w-[55%] lg:w-[45%] flex flex-col justify-center">
        <h3 class="font-bold text-auth-size my-8 lg:my-0 lg:mb-8 text-primary-6">
          Create an Account
        </h3>
        <div class="flex flex-col gap-4 mb-6 w-full">
          <BaseInput
            v-model="authStore.signUpFields.first_name"
            label="First Name"
            placeholder="Type Here"
            size="large"
            required
            @keydown.enter="handleNextStep"
            formatRule="first_name"
            errorMessage="First Name can only contain letters"
          />
          <BaseInput
            v-model="authStore.signUpFields.last_name"
            label="Last Name"
            placeholder="Type Here"
            size="large"
            required
            @keydown.enter="handleNextStep"
            formatRule="last_name"
            errorMessage="Last Name can only contain letters"
          />
          <BaseInput
            v-model="authStore.signUpFields.phone"
            label="Phone Number"
            placeholder="(999) 999-9999"
            size="large"
            formatRule="phone"
            errorMessage="Invalid phone number"
            required
            @keydown.enter="handleNextStep"
          />
          <BaseInput
            v-model="authStore.signUpFields.email"
            label="Email"
            placeholder="Type Here"
            size="large"
            formatRule="email"
            errorMessage="Invalid email"
            required
            @keydown.enter="handleNextStep"
          />
        </div>
        <!-- Turnstyle widget -->
        <div class="mb-4" data-theme="light" id="turnstile"></div>
        <!-- CTA For Form -->
        <div class="flex flex-col items-center w-full gap-4 mb-4">
          <Button
            @click="handleNextStep"
            :disabled="!isFormValid"
            label
            icon
            size="large"
            class="w-full"
            @keydown.enter="handleNextStep"
          >
            <template #label>Next</template>
          </Button>
          <Button
            @click="router.push('/sign-in')"
            label
            icon
            size="large"
            class="w-full"
            variant="white"
            @keydown.enter="handleNextStep"
          >
            <template #label>Sign In</template>
          </Button>
          <Transition
            enter-from-class="opacity-0 translate-x-4"
            enter-to-class="opacity-100 translate-x-0"
            leave-from-class="opacity-100 translate-x-0"
            leave-to-class="opacity-0 translate-x-4"
            enter-active-class="transition ease-in-out duration-300"
            leave-active-class="transition ease-in-out duration-300"
          >
            <Banner type="error" v-if="signupError" hideClose class="mb-4">
              <template #content>
                <p>{{ signupError }}</p>
              </template>
            </Banner>
          </Transition>
        </div>
      </div>
    </div>
    <InvestorTestimonials />
  </div>
</template>

<script setup>
import { reactive, ref, watch, onMounted, computed, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores";
import { BaseInput, Button, Banner } from "@/components";
import { isValidEmail } from "@/utilities";
import InvestorTestimonials from "./InvestorTestimonials.vue";

const router = useRouter();
const authStore = useAuthStore();
const formErrors = reactive({
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
});
const signupError = ref("");
const isFirstRun = ref(false);

onMounted(() => {
  window.scrollTo(0, 0);
  authStore.isSignup = true;
  turnstile.ready(function () {
    if (isFirstRun.value === false) {
      isFirstRun.value = true;
      turnstile.render("#turnstile", {
        sitekey:
          import.meta.env.VITE_ENV === "Production"
            ? "0x4AAAAAAAbuzA10_ip9GLl-"
            : "1x00000000000000000000AA",
        callback: function (token) {
          authStore.turnstileToken = token;
          authStore.isTurnstileValid = true;
        },
      });
    }
  });
});

onUnmounted(() => {
  authStore.isTurnstileValid = false;
});

let timeout = null;
watch(
  () => signupError.value,
  (newValue) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (newValue) {
      timeout = setTimeout(() => {
        signupError.value = false;
      }, 3000);
    }
  }
);

watch(
  () => [authStore.signUpFields.first_name, authStore.signUpFields.last_name],
  () => {
    authStore.signUpFields.name = `${authStore.signUpFields.first_name} ${authStore.signUpFields.last_name}`;
  }
);

const isFormValid = computed(() => {
  return (
    authStore.signUpFields.first_name &&
    authStore.signUpFields.last_name &&
    authStore.signUpFields.phone &&
    authStore.signUpFields.phone.length === 14 &&
    authStore.signUpFields.email &&
    isValidEmail(authStore.signUpFields.email) &&
    !Object.values(formErrors).some((error) => error)
  );
});

function handleNextStep() {
  if (authStore.isTurnstileValid) {
    if (!isFormValid.value) return;
    authStore.isSignup = true;
    authStore.activeSignupStep = 2;
    router.push("/sign-up/set-password");
  } else {
    signupError.value = "Please complete the security check above to confirm that you are a human.";
  }
}
</script>
