import { defineStore } from "pinia";
import * as Realm from "realm-web";
import { cloneDeep } from "lodash";

import { useCrudStore, useAuthStore, useUIStore, useInvestorStore, useSchemaStore } from "@/stores";
import {
  commaSeparateThousands,
  flattenSchema,
  fillMissingMonths,
  autoCapitalize,
  encryptField,
} from "@/utilities";
import { INVESTMENT_DOCUMENT_FIELD_ORDER, MONTHS, CHART_OPTIONS } from "@/constants";
import useFileUtility from "@/composables/useFileUtility";

const {
  BSON: { ObjectId },
} = Realm;

export const useInvestmentStore = defineStore("investmentStore", {
  state: () => ({
    // Purchase Flow Details
    currentStep: 1,
    investmentSteps: null,
    currentInvestment: {
      amount: "",
      type: "",
      is_compounded: false,
      payment_method: "",
      distribution_method: "",
      voided_check: false,
      funding_account: null,
      distribution_account: null,
    },
    selectedOffering: null,
    isReview: false,
    selectedVoidedCheckFile: null,
    selected_rollover_investment: null,
    signing_link: null,
    isAmountCollapsed: false,

    //for re-fetching signing links
    original_owner_contact: null,
    original_joint_contact: null,
    original_entity_account: null,
    original_current_investment: null,
    original_offering: null,
    original_acknowledgements: null,

    // General Investment Data
    investmentSchema: null, // TODO: Remove once schema refactor is done
    offerings: [],
    transactionsData: [],
    earningsData: [],
    bankAccounts: [],

    // Dashboard Charts
    runningTotalInvestments: [],
    runningTotalDistributions: [],
    investmentsByOffering: [],
    chartData: { labels: [""], datasets: [] },
    chartOptions: CHART_OPTIONS,

    // Schema Details
    non_accredited_owner_contact_schema: [],
    accredited_owner_contact_schema: [],
    non_accredited_joint_contact_schema: [],
    accredited_joint_contact_schema: [],
    entity_info_schema: [],

    // Investment Details
    investmentDetails: {},
    fetchedAccordionData: [],
    currentPageConfig: {},
    fetched_distributions_for_investment_earnings_tab: [],

    // Page Updates
    updateTic: 0,
  }),
  getters: {
    hasSignedInvestments: (state) => {
      if (!state.transactionsData) return false;
      return state.transactionsData.some((i) => i.signature_date);
    },
    hasSettledInvestments: (state) => {
      if (!state.transactionsData) return false;
      return state.transactionsData.some((i) => i.status === "Settled");
    },
    isAccreditedCurrentInvestment: (state) => {
      if (!state.selectedOffering) return false;
      return state.selectedOffering.type === "Reg D";
    },
    hasAccreditedInvestments: (state) => {
      if (!state.transactionsData || state.transactionsData.length === 0) return false;
      return state.transactionsData.some((i) => i.offering_type === "Reg D");
    },
    hasSignedEntityInvestment: (state) => {
      if (!state.transactionsData || state.transactionsData.length === 0) return false;
      return state.transactionsData.some((i) => i.type === "Entity");
    },
    hasSignedJointInvestment: (state) => {
      if (!state.transactionsData || state.transactionsData.length === 0) return false;
      return state.transactionsData.some((i) => i.type === "Joint");
    },
  },
  actions: {
    setInvestmentSteps() {
      const { type } = this.currentInvestment;
      const { selectedOffering } = this;
      if (!type || !selectedOffering) {
        this.investmentSteps = [];
        return;
      }

      const steps = {
        individual: {
          s1: ["Personal Information", "Banking", "Review and Sign Agreement"],
          reg_d: [
            "Personal Information",
            "Suitability Questionnaire",
            "Accreditation Details",
            "Banking",
            "Review and Sign Agreement",
          ],
        },
        joint: {
          s1: ["Personal Information", "Joint Information", "Banking", "Review and Sign Agreement"],
          reg_d: [
            "Personal Information",
            "Suitability Questionnaire",
            "Joint Information",
            "Accreditation Details",
            "Banking",
            "Review and Sign Agreement",
          ],
        },
        entity: {
          s1: [
            "Personal Information",
            "Entity Information",
            "Banking",
            "Review and Sign Agreement",
          ],
          reg_d: [
            "Personal Information",
            "Suitability Questionnaire",
            "Entity Information",
            "Accreditation Details",
            "Banking",
            "Review and Sign Agreement",
          ],
        },
        "traditional ira": {
          s1: ["Personal Information", "Banking", "Review and Sign Agreement"],
          reg_d: [
            "Personal Information",
            "Suitability Questionnaire",
            "Accreditation Details",
            "Banking",
            "Review and Sign Agreement",
          ],
        },
      };

      const offeringType =
        selectedOffering.name.toLowerCase() === "s1" ||
        selectedOffering.name.toLowerCase() === "reg a"
          ? "s1"
          : "reg_d";
      this.investmentSteps = steps[type.toLowerCase()][offeringType];
    },
    async setSchemaForInvestmentFlow() {
      const crudStore = useCrudStore();

      try {
        // Define the list of configuration references to fetch
        const listsToGrab = [
          "non_accredited_personal_info",
          "accredited_personal_info",
          "non_accredited_joint_info",
          "accredited_joint_info",
          "entity_information",
          "disabled_joint_contact_fields",
          "disabled_owner_contact_fields",
          "disabled_beneficial_owner_contact_fields",
          "disabled_entity_account_fields",
        ];

        // Fetch the configuration lists based on the references
        const configurationLists = await crudStore.find("Settings", {
          reference: { $in: listsToGrab },
        });

        this.disabled_beneficial_owner_contact_fields = configurationLists.find(
          (config) => config.reference === "disabled_beneficial_owner_contact_fields"
        ).options;
        this.disabled_joint_contact_fields = configurationLists.find(
          (config) => config.reference === "disabled_joint_contact_fields"
        ).options;
        this.disabled_owner_contact_fields = configurationLists.find(
          (config) => config.reference === "disabled_owner_contact_fields"
        ).options;
        this.disabled_entity_account_fields = configurationLists.find(
          (config) => config.reference === "disabled_entity_account_fields"
        ).options;

        // Fetch the schemas for Contacts and Accounts
        const schemaProjection = {
          field_name: 1,
          field_type: 1,
          record_detail_config: 1,
          accredited_only: 1,
          number_type: 1,
          label: 1,
          read_only: 1,
          is_object_id: 1,
          belongs_to_nested_object: 1,
          nested_object_name: 1,
          _id: 0,
        };
        const excludedFields = [
          "created_by_id",
          "updated_by_id",
          "created_by_name",
          "updated_by_name",
          "created_date",
          "updated_date",
          "_id",
        ];
        const [contactSchema, accountSchema] = await Promise.all([
          crudStore.find(
            "Schema",
            { collection_name: "Contacts", field_name: { $nin: excludedFields } },
            schemaProjection
          ),
          crudStore.find(
            "Schema",
            { collection_name: "Accounts", field_name: { $nin: excludedFields } },
            schemaProjection
          ),
        ]);

        // Helper function to map configuration to schema fields
        const mapConfigToSchema = (config, flattenedSchema, useAccreditedFields) =>
          config.options.map((groupName) => ({
            group_name: groupName,
            fields: flattenedSchema.filter(
              (field) =>
                field.record_detail_group === groupName &&
                (useAccreditedFields || !field.accredited_only)
            ),
          }));

        // Flatten the schemas based on different configurations
        const flattenedOwnerContactSchema = flattenSchema(contactSchema, "default");
        const flattenedJointContactSchema = flattenSchema(contactSchema, "joint");
        const flattenedEntitySchema = flattenSchema(accountSchema, "entity");

        this.all_contact_schema = cloneDeep(
          contactSchema.filter(
            (field) => !field.nested_object_name || field.nested_object_name === ""
          )
        );

        this.all_account_schema = cloneDeep(
          accountSchema.filter(
            (field) => !field.nested_object_name || field.nested_object_name === ""
          )
        );

        // Map configurations to schemas
        this.accredited_owner_contact_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "accredited_personal_info"),
          flattenedOwnerContactSchema,
          true // Use accredited fields
        );

        this.non_accredited_owner_contact_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "non_accredited_personal_info"),
          flattenedOwnerContactSchema,
          false // Do not use accredited fields
        );

        this.accredited_joint_contact_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "accredited_joint_info"),
          flattenedJointContactSchema,
          true // Use accredited fields
        );

        this.non_accredited_joint_contact_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "non_accredited_joint_info"),
          flattenedJointContactSchema,
          false // Do not use accredited fields
        );

        this.entity_info_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "entity_information"),
          flattenedEntitySchema,
          true // Use accredited fields
        );
      } catch (err) {
        console.error(err);
      }
    },
    async fetchOfferings() {
      const crudStore = useCrudStore();
      try {
        const result = await crudStore.find("Offerings", {
          visible: true,
          issuer: "PCG Holdings",
        });
        this.offerings = result;
      } catch (err) {
        console.error(err);
      }
    },
    async insertUpdateUnsignedInvestment() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investorStore = useInvestorStore();

      try {
        if (!this.investmentSchema) {
          this.investmentSchema = await crudStore.find(
            "Schema",
            {
              collection_name: "Investments",
              field_name: {
                $nin: [
                  "_id",
                  "created_date",
                  "created_by_id",
                  "created_by_name",
                  "updated_date",
                  "updated_by_id",
                  "updated_by_name",
                ],
              },
            },
            { field_name: 1, field_type: 1, record_detail_config: 1 }
          );
        }

        const createDocument = (schema, order) => {
          let doc = {};
          for (let fieldName of order) {
            let field = schema.find((s) => s.field_name === fieldName);
            if (field) {
              doc[field.field_name] =
                this.currentInvestment[field.field_name] ||
                field.record_detail_config.default.default_value ||
                getDefaultFieldValue(field.field_type);
            }
          }
          // Add remaining fields
          for (let field of schema) {
            if (!(field.field_name in doc)) {
              doc[field.field_name] =
                this.currentInvestment[field.field_name] ||
                field.record_detail_config.default.default_value ||
                getDefaultFieldValue(field.field_type);
            }
          }
          return doc;
        };

        const getDefaultFieldValue = (fieldType) => {
          switch (fieldType) {
            case "string":
            case "dropdown":
              return "";
            case "number":
              return 0;
            case "boolean":
              return false;
            case "array_of_ids":
            case "array":
              return [];
            case "date":
            case "object":
              return null;
            default:
              return "";
          }
        };

        // Create initial investment document
        const investmentDoc = createDocument(
          this.investmentSchema,
          INVESTMENT_DOCUMENT_FIELD_ORDER
        );
        investmentDoc.user_id = authStore.currentUser.id;
        investmentDoc.account_id = investorStore.account._id;
        investmentDoc.contact_id = investorStore.contact._id;
        investmentDoc.offering_id = this.selectedOffering._id;
        investmentDoc.is_compounded = this.currentInvestment.is_compounded;
        investmentDoc.amount = Number(this.currentInvestment.amount.replace(/,/g, ""));
        investmentDoc.bonds = Number(this.currentInvestment.amount.replace(/,/g, "")) / 1000;
        investmentDoc.current_nav = Number(this.currentInvestment.amount.replace(/,/g, ""));
        investmentDoc.funding_account = this.currentInvestment.funding_account;
        investmentDoc.payment_method = this.currentInvestment.payment_method;
        investmentDoc.distribution_account = this.currentInvestment.distribution_account;
        investmentDoc.distribution_method = this.currentInvestment.distribution_method;
        investmentDoc.voided_check = this.currentInvestment.voided_check;
        investmentDoc.updated_date = new Date();
        investmentDoc.updated_by_id = authStore.currentUser.id;
        investmentDoc.updated_by_name = `${investorStore.contact.first_name} ${investorStore.contact.last_name}`;

        // this means an investment was already inserted but not completed
        if (this.currentInvestment.hasOwnProperty("signature_date")) {
          const query = {
            signature_date: { $in: [null, ""] },
            user_id: authStore.currentUser.id,
            status: { $ne: "Pending" },
          };
          const update = { $set: investmentDoc };
          await crudStore.updateOne("Investments", query, update);
        } else {
          investmentDoc.created_date = new Date();
          investmentDoc.created_by_id = authStore.currentUser.id;
          investmentDoc.created_by_name = `${investorStore.contact.first_name} ${investorStore.contact.last_name}`;
          const { insertedId } = await crudStore.insertOne("Investments", investmentDoc);
          this.currentInvestment._id = insertedId;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async fetchUnsignedInvestment() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      try {
        const unsignedInvestment = await crudStore.findOne("Investments", {
          signature_date: { $in: [null, ""] },
          status: { $ne: "Pending" },
          user_id: authStore.currentUser.id,
        });
        if (unsignedInvestment) {
          this.currentInvestment = {
            ...unsignedInvestment,
            amount: commaSeparateThousands(unsignedInvestment.amount),
          };

          this.selectedOffering = this.offerings.find(
            (offering) => offering._id.toString() === unsignedInvestment.offering_id.toString()
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateInvestmentType() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investorStore = useInvestorStore();
      try {
        const query = {
          signature_date: { $in: [null, ""] },
          status: { $ne: "Pending" },
          user_id: authStore.currentUser.id,
        };

        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${investorStore.contact.first_name} ${investorStore.contact.last_name}`,
        };
        const update = { $set: { type: this.currentInvestment.type, ...updatedTimestamp } };
        return await crudStore.updateOne("Investments", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async signIRAInvestment() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investorStore = useInvestorStore();
      try {
        const now = new Date();
        const signatureDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        signatureDate.setUTCHours(0, 0, 0, 0);
        const query = {
          signature_date: { $in: [null, ""] },
          status: { $ne: "Pending" },
          user_id: authStore.currentUser.id,
        };
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${investorStore.contact.first_name} ${investorStore.contact.last_name}`,
        };
        const update = {
          $set: {
            signature_date: signatureDate,
            type: this.currentInvestment.type,
            ...updatedTimestamp,
          },
        };
        this.currentInvestment = {
          amount: "",
          type: "",
          payment_method: "",
          distribution_method: "",
          voided_check: false,
          funding_account: null,
          distribution_account: null,
        };
        return await crudStore.updateOne("Investments", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async updateInvestmentPaymentMethodAndJoinedFields() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investorStore = useInvestorStore();
      try {
        const query = {
          signature_date: { $in: [null, ""] },
          status: { $ne: "Pending" },
          user_id: authStore.currentUser.id,
        };
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${investorStore.contact.first_name} ${investorStore.contact.last_name}`,
        };
        const update = {
          $set: {
            owner_contact_full_name: `${investorStore.contact.first_name} ${investorStore.contact.last_name}`,
            offering_name: this.selectedOffering.name,
            offering_type: this.selectedOffering.type,
            payment_method: this.currentInvestment.payment_method,
            distribution_method: this.currentInvestment.distribution_method,
            funding_account: this.currentInvestment.funding_account,
            distribution_account: this.currentInvestment.distribution_account,
            voided_check: this.currentInvestment.voided_check,
            is_rollover: this.selected_rollover_investment ? true : false, // rollover related fields
            parent_investment:
              this.selected_rollover_investment &&
              this.currentInvestment.payment_method === "Existing Investment"
                ? this.selected_rollover_investment._id
                : null,
            ...updatedTimestamp,
          },
        };
        return await crudStore.updateOne("Investments", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async updateInvestmentIRAQuestionnaire() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investorStore = useInvestorStore();
      try {
        const query = { user_id: authStore.currentUser.id, type: "Regular" };
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${investorStore.contact.first_name} ${investorStore.contact.last_name}`,
        };
        const update = {
          $set: { ira_questionnaire: investorStore.contact.ira_questionnaire, ...updatedTimestamp },
        };
        return await crudStore.updateOne("Contacts", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async updateInvestmentIds() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investorStore = useInvestorStore();
      try {
        const query = {
          signature_date: { $in: [null, ""] },
          status: { $ne: "Pending" },
          user_id: authStore.currentUser.id,
        };
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${investorStore.contact.first_name} ${investorStore.contact.last_name}`,
        };

        let update = {
          $set: {
            ...updatedTimestamp,
            joint_contact_id: null,
            entity_account_id: null,
          },
        };

        // Set the appropriate field based on the investment type
        if (this.currentInvestment.type === "Joint") {
          this.currentInvestment.joint_contact_id = investorStore.active_joint_contact._id;
          update.$set.joint_contact_id = investorStore.active_joint_contact._id;
        } else if (this.currentInvestment.type === "Entity") {
          this.currentInvestment.entity_account_id = investorStore.active_entity_account._id;
          update.$set.entity_account_id = investorStore.active_entity_account._id;
        } else {
          this.currentInvestment.joint_contact_id = null;
          this.currentInvestment.entity_account_id = null;
        }

        return await crudStore.updateOne("Investments", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async cancelRolloverInvestment(id) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investorStore = useInvestorStore();
      try {
        const now = new Date();
        now.setUTCHours(0, 0, 0, 0);

        const query = { _id: id };
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${investorStore.contact.first_name} ${investorStore.contact.last_name}`,
        };
        const update = {
          $set: {
            status: "Canceled",
            cancelled_date: now,
            ...updatedTimestamp,
          },
        };
        await crudStore.updateOne("Investments", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async getInvestorTransactions() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const UIStore = useUIStore();
      try {
        let pipeline = [
          {
            $match: {
              user_id: authStore.currentUser.id,
              $or: [
                { signature_date: { $nin: [null, ""] } },
                {
                  signature_date: { $in: [null, ""] },
                  status: "Pending",
                },
              ],
              status: { $ne: "Canceled" },
            },
          },
          {
            // Add a field to prioritize "Pending" status
            $addFields: {
              pending_priority: {
                $cond: {
                  if: { $eq: ["$status", "Pending"] },
                  then: 0,
                  else: 1,
                },
              },
            },
          },
          {
            $sort: {
              pending_priority: 1,
              signature_date: -1,
              _id: -1,
            },
          },
          {
            $lookup: {
              from: "Offerings",
              localField: "offering_id",
              foreignField: "_id",
              as: "offering",
            },
          },
          {
            $unwind: {
              path: "$offering",
            },
          },
          {
            $addFields: {
              bond_term: { $divide: ["$offering.bond_term", 12] },
              interest_rate: { $divide: ["$offering.interest_rate", 100] },
            },
          },
        ];
        let sortObj = {};
        if (UIStore.sortHeader) {
          sortObj[UIStore.sortHeader.field_name] = UIStore.sortAscending ? 1 : -1;
          pipeline.splice(3, 0, {
            $sort: sortObj,
          });
        }

        this.transactionsData = await crudStore.aggregate("Investments", pipeline);
        return this.transactionsData;
      } catch (err) {
        console.error(err);
      }
    },
    async getInvestorEarnings() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const UIStore = useUIStore();

      try {
        let pipeline = [
          {
            $match: {
              user_id: authStore.currentUser.id,
              is_completed: true,
            },
          },
          {
            $group: {
              _id: "$distribution_date",
              amount: { $sum: "$amount" },
            },
          },
          {
            $addFields: {
              distribution_date: "$_id",
            },
          },
          { $sort: { distribution_date: -1 } },
        ];
        let sortObj = {};
        if (UIStore.sortHeader) {
          sortObj[UIStore.sortHeader.field_name] = UIStore.sortAscending ? 1 : -1;
          pipeline[3] = {
            $sort: sortObj,
          };
        }

        this.earningsData = await crudStore.aggregate("Distributions", pipeline);
        return this.earningsData;
      } catch (err) {
        console.error(err);
      }
    },
    async getPortfolioDynamicsData() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const authStore = useAuthStore();

      try {
        this.chartOptions.scales.x.stacked = true;
        this.chartOptions.scales.y.stacked = true;

        if (!this.runningTotalInvestments.length) {
          this.runningTotalInvestments = [];
          this.runningTotalDistributions = [];
          UIStore.isChartLoading = true;
          let investmentsByMonth = await crudStore.aggregate("Investments", [
            {
              $match: {
                signature_date: {
                  $ne: null,
                },
                user_id: authStore.currentUser.id,
                status: {
                  $ne: "Canceled",
                },
              },
            },
            {
              $group: {
                _id: {
                  year: {
                    $year: "$signature_date",
                  },
                  month: {
                    $month: "$signature_date",
                  },
                },
                totalAmount: {
                  $sum: "$amount",
                },
                signatureDate: {
                  $first: "$signature_date",
                },
              },
            },
            {
              $sort: {
                "_id.year": 1,
                "_id.month": 1,
              },
            },
            {
              $project: {
                _id: 0,
                year: "$_id.year",
                month: "$_id.month",
                totalAmount: 1,
                runningTotal: 1,
                signatureDate: 1,
              },
            },
          ]);
          let runningTotal = 0;
          investmentsByMonth.forEach((investment, index) => {
            runningTotal = investment.totalAmount + runningTotal;
            this.runningTotalInvestments.push({
              ...investment,
              ...{
                runningTotal: runningTotal,
              },
            });
          });

          let distributionsByMonth = await crudStore.aggregate("Distributions", [
            {
              $match: {
                is_completed: true,
                user_id: authStore.currentUser.id,
              },
            },
            {
              $group: {
                _id: {
                  year: {
                    $year: "$distribution_date",
                  },
                  month: {
                    $month: "$distribution_date",
                  },
                  is_compounded: "$is_compounded",
                },
                totalAmount: {
                  $sum: "$amount",
                },
                signatureDate: {
                  $first: "$distribution_date",
                },
              },
            },
            {
              $sort: {
                "_id.year": 1,
                "_id.month": 1,
              },
            },
            {
              $project: {
                _id: 0,
                year: "$_id.year",
                month: "$_id.month",
                is_compounded: "$_id.is_compounded",
                totalAmount: 1,
                distributionDate: 1,
              },
            },
          ]);

          let compoundedAmount = 0;
          let runningNonCompoundedAmount = 0;
          distributionsByMonth.forEach((distribution, index) => {
            compoundedAmount = distribution.is_compounded
              ? distribution.totalAmount + compoundedAmount
              : compoundedAmount;
            runningNonCompoundedAmount = distribution.is_compounded
              ? runningNonCompoundedAmount
              : distribution.totalAmount + runningNonCompoundedAmount;
            this.runningTotalDistributions.push({
              ...distribution,
              ...{
                compoundedAmount: compoundedAmount,
                runningNonCompoundedAmount: runningNonCompoundedAmount,
              },
            });
          });

          const currentDate = new Date();
          if (currentDate.getDate() < 10) {
            // if we haven't paid distribution for this month, add same amount as last month
            if (this.runningTotalDistributions.length) {
              this.runningTotalDistributions.push({
                ...this.runningTotalDistributions[this.runningTotalDistributions.length - 1],
                month: currentDate.getMonth() + 1,
              });
            }
          }
        }
        this.chartData.datasets = [
          {
            label: "Invested",
            data: [],
            backgroundColor: ["rgba(110, 159, 216, 0.5)"],
            borderColor: ["#3f87dc"],
            borderWidth: 1,
          },
          {
            label: "Simple Interest",
            data: [],
            backgroundColor: ["#426fa2"],
            borderColor: ["#3f87dc"],
            borderWidth: 1,
          },
          {
            label: "Compounding Interest",
            data: [],
            backgroundColor: ["#fbf6e1"],
            borderColor: ["#9e7400"],
            borderWidth: 1,
          },
        ];
        this.chartOptions.scales.y.title.display = true;
        this.chartOptions.scales.x.ticks.display = true;
        let investmentsWithAllMonths = fillMissingMonths(this.runningTotalInvestments);
        this.chartData.labels = this.convertToLabels(investmentsWithAllMonths);
        this.chartData.datasets[0].data = investmentsWithAllMonths.map((obj) => obj.runningTotal);
        // go through entire array, if new array does not have a value with that month/year, add it. if it does, if the current element is compounding, add compounding field
        let combined_distributions = [];
        this.runningTotalDistributions.forEach((distribution) => {
          let existing_combined_distribution = combined_distributions.findIndex(
            (obj) => obj.year === distribution.year && obj.month === distribution.month
          );
          if (existing_combined_distribution !== -1) {
            if (distribution.is_compounded) {
              combined_distributions[existing_combined_distribution].compoundedAmount =
                distribution.compoundedAmount;
            } else {
              combined_distributions[existing_combined_distribution].runningNonCompoundedAmount =
                distribution.runningNonCompoundedAmount;
            }
          } else {
            combined_distributions.push(distribution);
          }
        });
        this.runningTotalDistributions = combined_distributions;
        this.chartData.datasets[1].data = this.runningTotalDistributions.map(
          (obj) => obj.runningNonCompoundedAmount
        );
        this.chartData.datasets[2].data = this.runningTotalDistributions.map(
          (obj) => obj.compoundedAmount
        );
        UIStore.isChartLoading = false;
      } catch (err) {
        console.error(err);
      }
    },
    getTotalEarningsData() {
      if (this.runningTotalDistributions.length) {
        this.chartData.labels = this.convertToLabels(this.runningTotalDistributions);
      }
      this.chartData.datasets = [
        {
          label: "Total Earnings",
          data: this.runningTotalDistributions.map(
            (obj) => obj.compoundedAmount + obj.runningNonCompoundedAmount
          ),
          backgroundColor: ["rgba(110, 159, 216, 0.5)"],
          borderColor: ["#3f87dc"],
          borderWidth: 1,
        },
      ];
      this.chartOptions.scales.y.title.display = true;
      this.chartOptions.scales.x.ticks.display = true;
    },
    getPortfolioStructureData() {
      let investmentsByOffering = Object.values(
        this.transactionsData.reduce((acc, obj) => {
          // Extract the group key (_id from offering)
          const key = obj.offering._id;

          // Initialize the group if it doesn't exist
          if (!acc[key]) {
            // Make a shallow copy of the offering object to keep all its fields
            acc[key] = { ...obj.offering, amount: 0 };
          }

          // Sum the amount field within the group
          acc[key].amount += obj.amount;

          return acc;
        }, {})
      );
      this.chartOptions.scales.y.title.display = false;
      this.chartOptions.scales.x.ticks.display = false;
      this.chartData.labels = investmentsByOffering.map(
        (offering) =>
          offering.interest_rate +
          "% for " +
          offering.bond_term / 12 +
          (offering.bond_term / 12 > 1 ? " years" : " year")
      );
      this.chartData.datasets = [
        {
          label: "Total Earnings",
          data: investmentsByOffering.map((investment) => investment.amount),
          backgroundColor: ["rgba(110, 159, 216, 0.5)", "#426fa2", "#fbf6e1"],
          borderColor: ["#3f87dc", "#3f87dc", "#9e7400"],
          borderWidth: 1,
        },
      ];
    },
    convertToLabels(arr) {
      return arr.map((obj) => {
        const monthIndex = parseInt(obj.month, 10) - 1; // Convert month to zero-based index
        const monthName = MONTHS[monthIndex];
        return `${monthName} ${obj.year}`;
      });
    },
    async getSigningLink() {
      const authStore = useAuthStore();
      try {
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/generate-signing-links`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
              session_id: authStore.currentUser.session_id,
              user_id: authStore.currentUser?.id,
            },
            body: JSON.stringify({ investment_id: ObjectId(this.currentInvestment._id) }),
          }
        );
        return await response.json();
      } catch (err) {
        console.error(err);
      }
    },
    async getAccountsFromPlaid(accountType, accessToken) {
      const authStore = useAuthStore();
      const UIStore = useUIStore();
      const investorStore = useInvestorStore();
      const crudStore = useCrudStore();
      try {
        if (accountType === "Funding") {
          UIStore.fundingLoading = true;
        } else if (accountType === "Distribution") {
          UIStore.distributionLoading = true;
        }
        let method = "POST";
        const headers = {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          user_id: authStore.currentUser.id,
          session_id: authStore.currentUser.session_id,
        };
        let body = {
          endpoint: "auth",
          data: {
            user_id: authStore.currentUser.id,
            accountType: accountType,
            plaid_access_token: accessToken,
          },
        };
        let options = {
          method: method,
          headers,
        };
        if (method === "PUT" || method === "POST") {
          options.body = JSON.stringify(body);
        }
        let response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/call-plaid-endpoint`,
          options
        );
        let plaidAccounts = await response.json();

        //const plaidAccounts = await authStore.currentUser.functions.CallPlaidEndpoint('auth', {user_id: authStore.currentUser.id, accountType: accountType, plaid_access_token: accessToken});
        let accountsToAdd = [];
        for (let [index, plaidAccount] of plaidAccounts.entries()) {
          let accountToAdd = {};
          body = {
            endpoint: "get_institution_by_id",
            data: { institution_id: plaidAccount.institution_id },
          };
          options = {
            method: method,
            headers,
          };
          if (method === "PUT" || method === "POST") {
            options.body = JSON.stringify(body);
          }
          response = await fetch(
            `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/call-plaid-endpoint`,
            options
          );
          let institution = await response.json();
          //let institution = await authStore.currentUser.functions.CallPlaidEndpoint('get_institution_by_id', {institution_id: plaidAccount.institution_id});
          accountToAdd.user_id = authStore.currentUser.id;
          accountToAdd.account_nickname = "";
          accountToAdd.account_number = encryptField(plaidAccount.account_number);
          accountToAdd.account_type = autoCapitalize(plaidAccount.subtype);
          accountToAdd.mask = plaidAccount.mask ? plaidAccount.mask : null;
          accountToAdd.financial_institution_name = institution.name;
          accountToAdd.institution_id = plaidAccount.institution_id;
          accountToAdd.routing_number = encryptField(plaidAccount.routing_number);
          accountToAdd.plaid_account_id = plaidAccount.internal_account_id;
          accountToAdd.item_id = plaidAccount.item_id;
          accountToAdd.plaid_access_token = plaidAccount.plaid_access_token;
          accountToAdd.available_balance = plaidAccount.available_balance;
          accountToAdd.current_balance = plaidAccount.current_balance;
          accountToAdd.account_id = investorStore.account._id;
          accountToAdd.contact_id = investorStore.contact._id;
          accountToAdd.is_visible = true;
          accountToAdd.logo = institution.logo;
          accountToAdd.created_date = new Date();

          // insert accounts into collection here now, to have access to their _ids
          let insertedBankAccount = await crudStore.insertOne("BankAccounts", accountToAdd);
          if (insertedBankAccount.status !== 500) {
            accountToAdd._id = insertedBankAccount.insertedId;
            accountsToAdd.push(accountToAdd);
          }
        }

        // set active account to the first one from Plaid
        this.setActiveBankAccount(accountsToAdd[0], accountType);
        this.bankAccounts = [...accountsToAdd, ...this.bankAccounts];
        UIStore.fundingLoading = false;
        UIStore.distributionLoading = false;
      } catch (err) {
        console.error(err);
        UIStore.animateNotificationAlert({
          message: "There was an error retrieving your bank accounts. Please try again.",
          icon: "ShieldAlert",
          type: "error",
        });
      }
    },
    setActiveBankAccount(bankingOption, accountType) {
      if (accountType === "Funding") {
        this.currentInvestment.payment_method = "ACH";
        this.currentInvestment.funding_account = bankingOption._id;
      } else {
        this.currentInvestment.distribution_method = "ACH";
        this.currentInvestment.distribution_account = bankingOption._id;
        this.currentInvestment.voided_check = false;
      }
    },
    async uploadVoidedCheck() {
      const investorStore = useInvestorStore();
      const authStore = useAuthStore();
      const { uploadFile_R2 } = useFileUtility();
      try {
        if (this.selectedVoidedCheckFile) {
          let file = this.selectedVoidedCheckFile;
          await uploadFile_R2({
            file,
            type: "General Documents",
            details: {
              file_category: "Financial Account Verification",
              user_id: authStore.currentUser.id,
              contact_id: { $oid: investorStore.contact._id },
              account_id: { $oid: investorStore.contact.account_id },
            },
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    async setCurrentPageConfig() {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();

      try {
        await schemaStore.setSchema();

        this.currentPageConfig = await crudStore.findOne("Settings", {
          collection: this.currentPageCollection,
        });
        if (this.currentPageConfig.primary_key.includes("+")) {
          this.currentPageConfig.primary_key = this.currentPageConfig.primary_key
            .split("+")
            .map((key) => key.trim());
        } else {
          this.currentPageConfig.primary_key = [this.currentPageConfig.primary_key];
        }
      } catch (err) {
        console.error(err);
      }
    },
    async setNuggets(collection) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();

      try {
        let nuggetPipeline;

        let current_config = "?";
        if (this.currentPageConfig.has_multiple_configs == false) current_config = "default";

        let matchString = "record_detail_config." + current_config + ".is_info_nugget";
        let sortString = "record_detail_config." + current_config + ".info_nugget_order";
        let requiredString = "$record_detail_config." + current_config + ".is_required";

        nuggetPipeline = [
          {
            $match: {
              collection_name: collection,
              [matchString]: true,
            },
          },
          {
            $sort: {
              [sortString]: 1,
            },
          },
          {
            $project: {
              label: "$label",
              field_name: "$field_name",
              type: "$field_type",
              number_type: "$number_type",
              read_only: "$read_only",
              is_required: requiredString,
              associated_collection: "$associated_collection",
              associated_field_name: "$associated_field_name",
              is_association_field: "$is_association_field",
            },
          },
        ];

        let fetchedInfoNuggetData = await crudStore.aggregate("Schema", nuggetPipeline);

        for (const nugget of fetchedInfoNuggetData) {
          const newField = nugget.field_name;

          let currentValue = this.investmentDetails[newField];
          if (nugget.type === "string" && nugget.is_association_field) {
            currentValue = {
              name: this.investmentDetails[newField].assignedObject.name,
              route_to_push: `/${nugget.associated_collection?.toLowerCase()}?recordId=${
                this.investmentDetails[newField].assignedObject._id
              }`,
            };
          }
          if (nugget.field_name == "phone") {
            currentValue = this.formatPhoneNumber(currentValue);
          }
          if ((currentValue !== null && currentValue !== "") || currentValue === 0) {
            if (currentValue === true) {
              nugget.v_model = "Yes";
            } else if (currentValue === false) {
              nugget.v_model = "No";
            } else {
              if (nugget.type === "number") {
                if (typeof currentValue === "number") {
                  // Check if currentValue is a number
                  if (nugget.number_type === "currency") {
                    nugget.v_model = "$" + commaSeparateThousands(currentValue.toFixed(2));
                  } else if (nugget.number_type === "decimal") {
                    nugget.v_model = commaSeparateThousands(currentValue);
                  } else if (nugget.number_type === "integer") {
                    nugget.v_model = commaSeparateThousands(currentValue.toFixed(0));
                  } else if (nugget.number_type === "percentage") {
                    nugget.v_model = commaSeparateThousands((currentValue * 100).toFixed(2)) + "%";
                  } else {
                    nugget.v_model = commaSeparateThousands(currentValue);
                  }
                } else {
                  nugget.v_model = currentValue; // Handle non-numeric values differently if needed
                }
              } else if (nugget.type === "date") {
                let date_string = new Date(currentValue).toLocaleDateString("en-US", {
                  timeZone: "UTC",
                });
                nugget.v_model = date_string.includes("Invalid Date") ? "-" : date_string;
              } else {
                nugget.v_model = currentValue;
              }
            }
          } else {
            nugget.v_model = "-";
          }

          // if (!currentValue) {
          //   nugget.v_model = "-";
          // }
        }

        UIStore.headerNuggets = fetchedInfoNuggetData;
        return fetchedInfoNuggetData;
      } catch (err) {
        console.error(err);
      }
    },
    async setData(collection, object_id, deleted_record) {
      const crudStore = useCrudStore();

      try {
        let pageLayout = await crudStore.findOne("PageLayouts", {
          collection_name: collection,
        });

        this.currentPageLayout = pageLayout;

        let groupingOrder;

        //make grouping order conditional based on current card - find a card where name --- If there is a determing field on the card then look at the investmentDetails at that field and whatever the value is you need to look at the field map and figure out which config to use at that value - then set this.current_config_object
        let matchingModel = this.currentPageConfig;

        if (matchingModel.has_multiple_configs) {
          this.multiple_configs_boolean = true;
          let determining_field = matchingModel.config_determining_field;

          let match_field_map = matchingModel.field_map.find(
            (data) => data.value === this.investmentDetails[determining_field]
          );
          if (match_field_map) {
            this.current_config_object = match_field_map.config;
          } else {
            this.current_config_object = "default";
          }
        } else {
          this.multiple_configs_boolean = false;
          this.current_config_object = "default";
        }
        groupingOrder = pageLayout.record_detail_config[this.current_config_object].detail_sections;

        this.finalGroupingOrder = groupingOrder;

        let matchString =
          "record_detail_config." + this.current_config_object + ".record_detail_group";

        let sortString =
          "record_detail_config." + this.current_config_object + ".record_detail_group_order";

        let groupString =
          "$record_detail_config." + this.current_config_object + ".record_detail_group";

        let dropdownString =
          "$record_detail_config." + this.current_config_object + ".dropdown_options";
        let requiredString = "$record_detail_config." + this.current_config_object + ".is_required";

        let pipeline;

        pipeline = [
          {
            $match: {
              collection_name: collection,
              [matchString]: { $ne: "" },
              [matchString]: { $exists: true },
            },
          },
          {
            $sort: {
              [sortString]: 1,
            },
          },
          {
            $group: {
              _id: groupString,
              fields: {
                $push: {
                  name: "$label",
                  type: "$field_type",
                  field_name: "$field_name",
                  number_type: "$number_type",
                  read_only: "$read_only",
                  is_required: requiredString,
                  dropdown_options: dropdownString,
                  associated_collection: "$associated_collection",
                  associated_field_name: "$associated_field_name",
                  is_association_field: "$is_association_field",
                  associated_collection_query: "$associated_collection_query",
                  v_model: "",
                },
              },
            },
          },
          {
            $addFields: {
              groupOrderIndex: { $indexOfArray: [groupingOrder, "$_id"] },
            },
          },
          {
            $sort: { groupOrderIndex: 1 },
          },
          {
            $project: {
              name: "$_id",
              fields: 1,
              _id: 0,
            },
          },
        ];

        let fetchedAccordionData = await crudStore.aggregate("Schema", pipeline);

        // Step 1: Filter out objects not in groupingOrder
        const filteredAndOrderedData = fetchedAccordionData.filter((item) =>
          groupingOrder.includes(item.name)
        );

        // Modify the map operation to include a default object when find returns undefined
        const orderedData = groupingOrder.map(
          (orderItem) =>
            filteredAndOrderedData.find((item) => item.name === orderItem) || {
              name: orderItem,
              fields: [],
            }
        );

        for (const group of orderedData) {
          for (const field of group.fields) {
            const newField = field.field_name;

            const currentValue = this.investmentDetails[newField];

            if ((currentValue !== null && currentValue !== "") || currentValue === 0) {
              if (currentValue === true) {
                field.v_model = "Yes";
              } else if (currentValue === false) {
                field.v_model = "No";
              } else if (field.type === "number") {
                if (typeof currentValue === "number") {
                  if (field.number_type === "currency") {
                    field.v_model = "$" + commaSeparateThousands(currentValue.toFixed(2));
                  } else if (field.number_type === "decimal") {
                    field.v_model = commaSeparateThousands(currentValue);
                  } else if (field.number_type === "percentage") {
                    field.v_model = commaSeparateThousands((currentValue * 100).toFixed(2)) + "%";
                    this.recordEdits[newField] = (currentValue * 100).toFixed(2);
                  } else if (field.number_type === "integer") {
                    field.v_model = commaSeparateThousands(currentValue.toFixed(0));
                  } else {
                    field.v_model = commaSeparateThousands(currentValue);
                  }
                } else {
                  field.v_model = currentValue; // Handle non-numeric values differently if needed
                }
              } else if (Array.isArray(currentValue) && currentValue.length === 0) {
                field.v_model = "-";
              } else if (field.type === "date") {
                if (newField === "updated_date" || newField === "created_date") {
                  let date_string =
                    new Date(currentValue).toLocaleDateString("en-US", {
                      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }) +
                    " - " +
                    new Date(currentValue).toLocaleTimeString("en-US", {
                      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    });
                  field.v_model = date_string.includes("Invalid Date") ? "-" : date_string;
                } else {
                  let date_string = new Date(currentValue).toLocaleDateString("en-US", {
                    timeZone: "UTC",
                  });
                  field.v_model = date_string.includes("Invalid Date") ? "-" : date_string;
                }
              } else {
                field.v_model = currentValue;
              }
            } else {
              field.v_model = "-";
            }
            // if (!currentValue &&  && currentValue !== 0) {
            //   field.v_model = "-";
            // }

            if (field.type === "string" && field.is_association_field) {
              const nameFieldValue = this.investmentDetails[newField];
              const idFieldValue = this.investmentDetails[field.associated_field_name];

              this.recordEdits[newField] = {
                searchTerm: "",
                assignedObject: {
                  _id: idFieldValue,
                  name: nameFieldValue,
                },
                results: [],
                schema: field,
              };
              //From Kamary - I don't think this will cause any problems - had to set this so that the info nuggets route for association fields could be set correctly
              this.investmentDetails[newField] = {
                searchTerm: "",
                assignedObject: {
                  _id: idFieldValue,
                  name: nameFieldValue,
                },
                results: [],
                schema: field,
              };

              let path = convertToKebabCase(field.associated_collection);
              if (path === "title") {
                path = "title-assignments";
              }
              field.exact_route =
                "/" + path + "?recordId=" + this.recordEdits[field.associated_field_name];
            } else if (
              newField === "associated_record_name" &&
              collection === "Tasks" &&
              this.recordEdits.associated_record_type &&
              this.recordEdits.associated_record_id
            ) {
              let routesObject;
              if (this.recordEdits.associated_record_type === "Contact") {
                routesObject = "contacts";
              } else if (this.recordEdits.associated_record_type === "Account") {
                routesObject = "accounts";
              } else if (this.recordEdits.associated_record_type === "Land Holding") {
                routesObject = "land-holdings";
              } else if (this.recordEdits.associated_record_type === "Offer") {
                routesObject = "offers";
              } else if (this.recordEdits.associated_record_type === "Investment") {
                routesObject = "investments";
              }

              field.exact_route =
                "/" + routesObject + "?recordId=" + this.recordEdits.associated_record_id;
            } else if (field.type === "array" && field.associated_collection) {
              this.recordEdits[newField] = {
                searchTerm: "",
                selectedItems: this.recordEdits[newField],
                results: [],
                schema: field,
              };
              const path = this.convertToKebabCase(field.associated_collection);
              field.base_route = "/" + path + "?recordId=";
            }
          }
        }

        //this.setNuggets(collection);

        fetchedAccordionData = fetchedAccordionData.filter((item) => item.name !== "");
        this.fetchedAccordionData = orderedData;
        const corrected_record_edit = cloneDeep(this.recordEdits);
        this.finalized_record_edits = corrected_record_edit;

        //console.log("end");

        return orderedData;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchSubAgreements(investment_id) {
      // use crudstore to get subagreements with investment id
      const crudStore = useCrudStore();

      try {
        const subAgreements = await crudStore.find("Documents", {
          investment_id: investment_id,
          type: "Sub Agreements",
        });

        //console.log(subAgreements);

        return subAgreements;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchOffering(offering_id) {
      // use crudstore to get subagreements with investment id
      const crudStore = useCrudStore();

      try {
        const offering = await crudStore.find("Offerings", {
          _id: offering_id,
        });

        //console.log(subAgreements);

        return offering[0];
      } catch (err) {
        console.error(err);
      }
    },
    async getAnticipatedEarnings() {
      for (let offering of this.offerings) {
        const interestRate = offering.interest_rate / 100;
        const years = offering.bond_term / 12;
        const compoundingFrequency = 12;

        // If the investment amount or compounded status isn't set, use minimum investment
        if (!this.currentInvestment.amount) {
          offering.anticipated_earnings =
            (offering.minimum_investment * interestRate * years).toFixed(2) || 0;
        } else {
          const currentAmount = Number(this.currentInvestment.amount.replace(/,/g, ""));

          // Check if the investment is compounded
          if (this.currentInvestment.is_compounded) {
            // Calculate compound interest
            const compoundedAmount =
              currentAmount *
              Math.pow(1 + interestRate / compoundingFrequency, compoundingFrequency * years);
            const earnings = compoundedAmount - currentAmount; // Earnings is the interest earned
            offering.anticipated_earnings = earnings.toFixed(2);
          } else {
            // Calculate simple interest
            offering.anticipated_earnings = (currentAmount * interestRate * years).toFixed(2);
          }
        }
      }
    },
    async fetchBanks(funding_account_id, distrubution_account_id) {
      // use crudstore to get subagreements with investment id
      const crudStore = useCrudStore();

      try {
        const funding_account = await crudStore.findOne("BankAccounts", {
          _id: funding_account_id,
        });

        const distrubution_account = await crudStore.findOne("BankAccounts", {
          _id: distrubution_account_id,
        });

        //console.log(subAgreements);

        return {
          funding_account: funding_account,
          distrubution_account: distrubution_account,
        };
      } catch (err) {
        console.error(err);
      }
    },
    async setDistributionsForSingleInvestment(investment_id) {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      const UIStore = useUIStore();

      try {
        let projection_fields = ["distribution_date", "amount", "is_completed"];
        this.investment_earnings_tab_headers = schemaStore.getEarningsTabHeaders(projection_fields);

        let projection = {};

        for (const field of projection_fields) {
          projection[field] = 1;
        }

        let pipeline = [
          {
            $match: {
              investment_id: investment_id,
            },
          },
          {
            $project: projection,
          },
        ];

        let sortObj = {};
        if (UIStore.sortHeader) {
          sortObj[UIStore.sortHeader.field_name] = UIStore.sortAscending ? 1 : -1;
          pipeline.push({
            $sort: sortObj,
          });
        }

        let fetched_distributions = await crudStore.aggregate("Distributions", pipeline);
        this.fetched_distributions_for_investment_earnings_tab = fetched_distributions;
      } catch (err) {
        console.error(err);
      }
    },
  },
});
