<template>
  <TransitionRoot as="template" :show="open" class="z-50">
    <Dialog
      as="div"
      class="relative z-10"
      @close="keepModalOpen ? '' : $emit('close-modal')"
      id="main-modal"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral-gray-13 bg-opacity-80 transition-opacity lg:mt-14" />
      </TransitionChild>
      <div class="fixed inset-0 z-10">
        <div class="flex h-full items-end text-center lg:items-center lg:justify-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel id="modal" :class="inputStyles" class="flex flex-col">
              <div
                id="header"
                class="flex h-16 w-full items-center justify-between border-b border-neutral-gray-4 p-4"
              >
                <div>
                  <slot name="modal-header" />
                </div>

                <button
                  v-if="!UIStore.badTokenModalOpen"
                  @click="$emit('close-modal')"
                  class="px-3"
                >
                  <IconX class="h-6 w-6 fill-neutral-gray-6" />
                </button>
              </div>
              <slot name="modal-tabs" />
              <div class="grow overflow-auto bg-neutral-gray-2">
                <slot name="modal-body" />
              </div>
              <div
                v-if="!hideFooter"
                id="footer"
                class="relative h-17 w-full border-t border-neutral-gray-5 bg-neutral-gray-1 px-6 py-value-2"
              >
                <slot name="modal-footer" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { useUIStore } from "../../stores/useUIStore";
const UIStore = useUIStore();
import { Button } from "@/components";
import { IconX } from "@/components/icons";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  customHeight: {
    type: String,
    default: " h-[85%] lg:h-156 ",
  },
  customWidth: {
    type: String,
    default: "lg:w-250",
  },
  size: {
    type: String,
    default: "",
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  keepModalOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close-modal"]);

const inputStyles = computed(() => {
  let baseClass = `relative w-full transform overflow-hidden rounded-lg bg-neutral-gray-1 text-left shadow-xl transition-all `;
  return `${baseClass} ${props.customHeight} ${props.customWidth}`;
});
</script>
