<template>
  <Modal :hideModalTabs="true" @close-modal="$emit('close-modal')" :open="open" size="small">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-error-default bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-error-default"
            :is="iconMapping['IconDelete']"
          />
        </div>

        <div class="font-bold text-custom-navy-2">Delete Bank Account</div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div v-if="loading" class="h-full w-full flex items-center justify-center">
        <LoadingSpinner :show="true" :hide-overlay="true" position="absolute" />
      </div>
      <div v-else class="h-full w-full bg-white flex flex-col gap-4 items-center justify-center">
        <div
          v-if="is_account_on_other_investments"
          class="w-3/4 text-center p-4 text-functional-error-default"
        >
          <p>
            This bank account is currently being used in other investments. Please remove it from
            those investments before deleting it.
          </p>
        </div>
        <div v-else class="p-4 w-3/4 text-center">
          <p>Are you sure you want to delete this bank account?</p>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template>
        </Button>
        <Button size="large" :disabled="isSaveDisabled" label @click="handleDeleteBankAccount">
          <template #label> Delete </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";

import { Modal, Button, BaseInput, Banner, RadioOption, LoadingSpinner } from "@/components";
import { useCrudStore, useUIStore } from "@/stores";
import { iconMapping } from "@/constants/iconMapping";

const crudStore = useCrudStore();
const UIStore = useUIStore();

const is_account_on_other_investments = ref(false);
const loading = ref(false);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  account: {
    type: Object,
    required: false,
  },
});

const emit = defineEmits(["close-modal", "account-updated"]);

onMounted(async () => {});

const isSaveDisabled = computed(() => {
  return is_account_on_other_investments.value;
  return false;
});

async function handleDeleteBankAccount() {
  //console.log("handleUpdateBankAccount");
  //console.log(bankAccountEdits.value);
  loading.value = true;
  let filter = {
    _id: props.account._id,
  };

  var res = await crudStore.deleteOne("BankAccounts", filter);
  if (res.deletedCount == 1) {
    var alert = {
      type: "success",
      icon: "BadgeCheck",
      message: "Bank account deleted successfully",
    };
  } else {
    var alert = {
      type: "error",
      icon: "IconX",
      message: "Failed to delete bank account",
    };
  }

  UIStore.animateNotificationAlert(alert);

  emit("account-updated");
  emit("close-modal");
  loading.value = false;
}

// vue watcher to set custodianEdits when open prop changes
watch(props, async (value) => {
  if (value.open) {
    loading.value = true;
    // check if this account is on other investments
    let filter = {
      // where props.account._id is either distrubution_account or funding_account
      $or: [{ distribution_account: props.account._id }, { funding_account: props.account._id }],
    };
    var res = await crudStore.find("Investments", filter);

    if (res.length > 0) {
      is_account_on_other_investments.value = true;
    } else {
      is_account_on_other_investments.value = false;
    }
    loading.value = false;
  }
});
</script>
