<template>
  <Transition name="side-drawer">
    <aside v-if="open" id="side-drawer" :class="computedClass" class="rounded-t-lg lg:rounded-none">
      <div
        class="flex min-h-12.5 w-full items-center justify-start space-x-7 whitespace-nowrap rounded-t-lg border border-platform-outlines bg-neutral-gray-1 lg:rounded-none"
      >
        <Button
          @click="closeDrawer"
          icon
          size="medium"
          variant="default-gray-outlined"
          class="ml-4"
        >
          <template #icon>
            <Icon name="ChevronDown" :stroke-width="2" class="h-4 w-4 lg:hidden" />
            <Icon name="ChevronRight" :stroke-width="2" class="h-4 w-4 lg:block hidden" />
          </template>
        </Button>
        <div
          class="no-scrollbar flex w-full items-center justify-start space-x-3 overflow-x-auto whitespace-nowrap bg-neutral-gray-1 pr-4"
        >
          <div class="flex w-full items-center">
            <slot name="tabs"></slot>
          </div>
        </div>
      </div>

      <div v-if="!hideHeader" class="flex w-full items-center px-4 py-3">
        <slot name="header"></slot>
      </div>
      <div ref="mainContent" class="no-scrollbar grow bg-white overflow-y-auto">
        <slot name="main-content"></slot>
      </div>
      <div
        v-if="showFooter"
        class="sticky bottom-0 h-17 w-full border-t border-neutral-gray-5 bg-neutral-gray-1 px-6 py-value-2"
      >
        <slot name="footer"></slot>
      </div>
    </aside>
  </Transition>
  <Transition name="side-drawer-overlay">
    <div
      v-if="open"
      id="side-drawer-bg"
      as="template"
      class="fixed inset-0 z-30 mt-14 h-full w-full bg-neutral-gray-13 bg-opacity-80"
    />
  </Transition>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import { Button, Icon } from "@/components";

const props = defineProps({
  hideHeader: {
    type: Boolean,
    default: false,
  },
  customWidth: {
    type: String,
    default: "lg:w-2/3",
  },
  showFooter: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close-drawer"]);

const baseClasses =
  "fixed bottom-0 z-40 h-[91.5%] md:h-[85%] flex flex-col w-full overflow-hidden bg-platform-canvas transition-all duration-200 ease-in-out lg:bottom-auto lg:right-0 lg:top-0 lg:mt-14 lg:h-[calc(100%-3.5rem)]";

const computedClass = computed(() => {
  return `${baseClasses} ${props.customWidth}`;
});

const mainContent = ref(null);

watch(
  () => props.open,
  () => {
    if (props.open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  },
  { deep: true }
);

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

function closeDrawer() {
  emit("close-drawer");
}

function handleClickOutside(event) {
  if (
    !event.target.closest("#side-drawer") &&
    !event.target.closest("#main-modal") &&
    !event.target.closest("#select-option") &&
    !event.target.closest("#base-input") &&
    !event.target.closest(".password-toggle") &&
    !event.target.closest(".custom-radio")
  ) {
    closeDrawer();
  }
}
</script>

<style scoped>
.side-drawer-enter-active,
.side-drawer-leave-active {
  transition: transform 0.25s ease-in-out;
}

.side-drawer-enter-from,
.side-drawer-leave-to {
  transform: translateY(100%);
}

/* Transition for 'lg' screens and above - slide in/out from the right */
@media (min-width: 1024px) {
  .side-drawer-enter-from,
  .side-drawer-leave-to {
    transform: translateX(100%);
  }
}

.side-drawer-overlay-enter-active,
.side-drawer-overlay-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.side-drawer-overlay-enter-from,
.side-drawer-overlay-leave-to {
  opacity: 0;
}

@media (min-width: 1024px) {
  .side-drawer-overlay-enter-from,
  .side-drawer-overlay-leave-to {
    opacity: 0;
  }
}
</style>
