<template>
  <div v-if="investmentStore.fetched_distributions_for_investment_earnings_tab.length > 0">
    <Table
      @setSortHeader="(header, props) => setSortHeader(header, props)"
      :table-data="investmentStore.fetched_distributions_for_investment_earnings_tab"
      :headers="investmentStore.investment_earnings_tab_headers"
      :fieldsWithCustomStyles="['is_completed']"
    >
      <template v-slot:is_completed="entry">
        <div
          class="rounded-xl py-1 min-w-16 w-fit flex items-center justify-center"
          :class="
            entry.row.is_completed
              ? 'bg-opacity-15 bg-royal-blue text-royal-blue'
              : 'bg-opacity-20 bg-secondary-6 text-secondary-8'
          "
        >
          {{ entry.row.is_completed ? "Processed" : "Pending" }}
        </div>
      </template>
    </Table>
  </div>
  <div v-else class="mt-8 px-3 md:px-0 text-center">
    <EmptyState
      :description="`There are currently no distributions for this
        ${investmentStore.investmentDetails.status} investment.`"
      icon_name="HandCoins"
    />
  </div>
  <!-- <div v-else>
    <div class="flex justify-center items-center h-40">
      <p class="text-primary-2">
        There are currently no distributions for this
        {{ investmentStore.investmentDetails.status }} investment.
      </p>
    </div>
  </div> -->
</template>
<script setup>
import { Table, EmptyState } from "@/components";
import { useInvestmentStore, useUIStore } from "@/stores";

const investmentStore = useInvestmentStore();
const UIStore = useUIStore();

async function setSortHeader(header, props) {
  UIStore.setSortHeader(header, props);
  await investmentStore.setDistributionsForSingleInvestment(investmentStore.investmentDetails._id);
}
</script>
