<template>
  <div v-if="UIStore.isLoading">
    <LoadingSpinner :show="UIStore.isLoading" />
  </div>
  <div class="md:h-full w-full p-6">
    <div
      class="flex flex-col w-full md:border border-platform-outlines rounded-md h-full items-center md:justify-center mt-12 md:mt-0"
    >
      <button
        v-if="!investmentStore.is_post_signup_questionnaire"
        type="button"
        @click="handleBack"
        class="flex items-center justify-center gap-1.5 absolute top-24 left-6 md:left-10"
      >
        <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
        Back
      </button>
      <div class="w-full md:max-w-[23.813rem] mt-4 md:mt-0">
        <AuthStepper :numSteps="9" />
        <h3 class="font-bold text-[28px] mb-8">
          {{ active_question.question }}
        </h3>
        <div
          class="flex w-full gap-2"
          :class="active_question.steps === 'boolean' ? 'flex-row' : 'flex-col'"
        >
          <div
            v-for="(step, index) in active_question.steps === 'boolean'
              ? ['Yes', 'No']
              : active_question.steps"
            :key="index"
            @click="handleNextStep(step)"
            class="h-[72px] py-3 px-4 rounded-md bg-neutral-gray-2 flex items-center cursor-pointer hover:bg-neutral-gray-3 w-full"
          >
            {{ step }}
          </div>
        </div>
        <Button
          @click="router.push('/dashboard')"
          label
          size="large"
          class="w-full mt-2"
          variant="white"
        >
          <template #label>Skip Questionnaire</template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import AuthStepper from "./AuthStepper.vue";
import { useAuthStore, useInvestorStore, useUIStore, useInvestmentStore } from "@/stores";
import { Icon, Button, LoadingSpinner } from "@/components";

const router = useRouter();
const authStore = useAuthStore();
const investorStore = useInvestorStore();
const UIStore = useUIStore();
const investmentStore = useInvestmentStore();

onMounted(() => {
  window.scrollTo(0, 0);
  authStore.activeSignupStep = 5;
});

const questionnaire_steps = ref([
  {
    question: "Are you an accredited investor?",
    step: 5,
    steps: [
      "I am not an accredited investor",
      "I've earned $200k for each of the past 2 years",
      "I've earned 300k for each of the past 2 years with spouse",
      "Net Worth of $1M excluding primary residence",
      "I plan to invest through an accredited entity",
    ],
  },
  {
    question: "What is your main investment goal?",
    step: 6,
    steps: [
      "Build my retirement Portfolio",
      "Save for a large expense",
      "Minimize tax liabilities",
      "Diversification of my portfolio",
      "Generate passive income",
      "Other",
    ],
  },
  {
    question: "Do you have an IRA or other retirement savings accounts?",
    step: 7,
    steps: "boolean",
  },
  { question: "Do you currently have any annuities?", step: 8, steps: "boolean" },
  {
    question: "What is your anticipated investment range?",
    step: 9,
    steps: ["$5-$10k", "$10-$20k", "$25-$50k", "$50-$100k", "$100-$250k", "More than $250k"],
  },
]);
const active_question = ref(questionnaire_steps.value[0]);

async function handleNextStep(answer) {
  try {
    updateQuestionnaire(active_question.value.step, answer);
    const nextStepIndex = active_question.value.step - 4;
    if (nextStepIndex < questionnaire_steps.value.length) {
      active_question.value = questionnaire_steps.value[nextStepIndex];
      authStore.activeSignupStep = active_question.value.step;
    } else {
      UIStore.isLoading = true;
      await investorStore.upsertSignupQuestionnaire();
      router.push("/dashboard");
    }
  } catch (err) {
    console.error(err);
  } finally {
    UIStore.isLoading = false;
  }
}

function handleBack() {
  if (active_question.value.step > 5) {
    const prevStepIndex = active_question.value.step - 5;
    active_question.value = questionnaire_steps.value[prevStepIndex - 1];
    authStore.activeSignupStep = active_question.value.step;
  }
}

function updateQuestionnaire(step, answer) {
  switch (step) {
    case 5:
      authStore.signUpFields.signup_questionnaire.are_you_an_accredited_investor = answer;
      break;
    case 6:
      authStore.signUpFields.signup_questionnaire.main_investment_goal = answer;
      break;
    case 7:
      authStore.signUpFields.signup_questionnaire.ira_or_savings = answer;
      break;
    case 8:
      authStore.signUpFields.signup_questionnaire.has_annuities = answer;
      break;
    case 9:
      authStore.signUpFields.signup_questionnaire.anticipated_investment_range = answer;
      break;
  }
}
</script>
