<template>
  <InvestmentAmountModal
    :open="isShowAmountModal"
    @close-modal="isShowAmountModal = false"
    @set-investment-data="handleNext"
  />
  <div class="p-6 lg:p-10">
    <div
      class="border border-platform-outlines rounded-lg px-4 md:px-0 py-10 flex flex-col items-center gap-4"
    >
      <div
        class="bg-functional-success-default h-14 w-14 bg-opacity-30 flex items-center justify-center rounded-full"
      >
        <Icon
          name="CircleCheck"
          class="h-10 w-10 text-functional-success-default"
          :stroke-width="2"
        />
      </div>
      <h2 class="font-bold text-lg text-center">
        Thank you for investing with Phoenix Capital Group
      </h2>
      <p class="text-lighter text-center md:max-w-[30%]">
        If you have any questions, please reach out to Investor Relations at 303-376-3599 or
        <a href="mailto:investorrelations@phxcapitalgroup.com">
          investorrelations@phxcapitalgroup.com</a
        >.
      </p>

      <Button
        @click="router.push('/dashboard')"
        label
        size="large"
        variant="warning-filled"
        class="!bg-[#EBBC01]"
      >
        <template #label>Go to Portfolio</template>
      </Button>
    </div>
    <Banner v-if="wasJointInvestment" type="warning" class="mt-6" hideClose>
      <template #content>
        <div class="flex items-center">
          <p>
            For Joint Investments, a second signature will be required. Phoenix will send the
            agreement to the Joint Investors email address.
          </p>
        </div>
      </template>
    </Banner>
    <!-- Offerings For You -->
    <h2 class="font-bold text-xl mt-5 uppercase">Select your next investment</h2>
    <h3 class="font-bold text-lg my-5">Offerings for You</h3>
    <div class="relative">
      <div
        class="bg-neutral-gray-2 rounded-md w-full p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 overflow-x-auto md:overflow-hidden no-scrollbar"
      >
        <OfferingCard
          v-for="offering in suggested_offerings"
          :offering="offering"
          @select-offering="selectOffering(offering)"
          @handle-next="selectOffering(offering)"
          ignoreDisabled
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ObjectId } from "bson";

import { useInvestmentStore, useInvestorStore, useCrudStore, useAuthStore } from "@/stores";
import { Icon, Button, Banner } from "@/components";
import OfferingCard from "./OfferingCard.vue";
import InvestmentAmountModal from "./InvestmentAmountModal.vue";
import { commaSeparateThousands_2 } from "@/utilities";

const router = useRouter();
const route = useRoute();

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const crudStore = useCrudStore();
const authStore = useAuthStore();

const isShowAmountModal = ref(false);
const wasJointInvestment = ref(false);

const totalInvestedPlusFundedPlusAmount = computed(() => {
  const allFundedInvestmentsAmount = investmentStore.transactionsData.reduce((acc, transaction) => {
    if (transaction.status === "Funded") {
      return acc + transaction.amount;
    }
    return acc;
  }, 0);

  const totalInvested = investorStore.account?.total_invested || 0;

  return (
    totalInvested +
    allFundedInvestmentsAmount +
    Number(investmentStore.currentInvestment.amount.replace(/,/g, ""))
  );
});

const regDTier = computed(() => {
  if (totalInvestedPlusFundedPlusAmount.value >= 1000000) {
    return 3;
  } else if (totalInvestedPlusFundedPlusAmount.value >= 500000) {
    return 2;
  } else {
    return 1;
  }
});

// Computed properties for offerings
const s1_offerings = computed(() => {
  return investmentStore.offerings
    .filter((offering) => offering.type === "S1")
    .sort((a, b) => a.interest_rate - b.interest_rate);
});

const reg_d_offerings = computed(() => {
  const bondTerms = [1, 3, 5, 7, 11];

  return bondTerms
    .map((term) => {
      // Filter the offerings by bond term and tier
      const offeringsByTerm = investmentStore.offerings.filter(
        (offering) => offering.type === "Reg D" && offering.bond_term / 12 === term
      );

      // Find the appropriate tier for the bond term
      const tieredOffering = offeringsByTerm.find((offering) => offering.tier === regDTier.value);

      // If no exact tier match, fall back to the highest available tier
      return tieredOffering || offeringsByTerm.find((offering) => offering.tier <= regDTier.value);
    })
    .filter(Boolean); // Filter out undefined values
});

const suggested_offerings = computed(() => {
  // const s1First = s1_offerings.value[0];
  const regDFirst = reg_d_offerings.value[0];
  const regDSecond = reg_d_offerings.value[1];
  const regDLast = reg_d_offerings.value[reg_d_offerings.value.length - 1];
  return [regDFirst, regDSecond, regDLast].filter(Boolean); // Filter out undefined values
});

onMounted(async () => {
  window.scrollTo(0, 0);
  if (!route.query.investment_id) {
    router.push("/dashboard");
  }
  const investment = await crudStore.findOne("Investments", {
    _id: ObjectId(route.query.investment_id),
    user_id: authStore.currentUser.id,
  });

  if (!investment.type?.includes("IRA")) {
    await crudStore.updateOne(
      "Investments",
      { _id: ObjectId(route.query.investment_id), user_id: authStore.currentUser.id },
      {
        $set: { status: "Pending" },
      }
    );
  }

  if (investment.type === "Joint") {
    await investorStore.sendJointContactEmail(investment);
  }

  investmentStore.currentInvestment = {
    amount: "",
    type: "",
    is_compounded: false,
    payment_method: "",
    distribution_method: "",
    voided_check: false,
    funding_account: null,
    distribution_account: null,
  };
  const unsignedJointInvestment = await crudStore.findOne("Investments", {
    user_id: authStore.currentUser.id,
    type: "Joint",
    signature_date: null,
    status: "Pending",
  });
  if (unsignedJointInvestment) {
    wasJointInvestment.value = true;
  }
});

// TODO: This needs to account for rollovers not being new capital
watch(
  () => totalInvestedPlusFundedPlusAmount.value,
  () => {
    const selectedOffering = investmentStore.selectedOffering;

    if (selectedOffering) {
      const matchingOffering = reg_d_offerings.value.find(
        (offering) =>
          offering.bond_term === selectedOffering.bond_term && offering.tier === regDTier.value
      );

      if (
        matchingOffering &&
        matchingOffering._id !== selectedOffering._id &&
        selectedOffering.type == "Reg D"
      ) {
        investmentStore.selectedOffering = matchingOffering;
      }
    }
  }
);

async function selectOffering(offering) {
  investmentStore.selectedOffering = offering;
  investmentStore.currentInvestment.amount = commaSeparateThousands_2(offering.minimum_investment);
  isShowAmountModal.value = true;
}

async function handleNext() {
  investmentStore.currentStep = 2;
  await Promise.all([
    investmentStore.setSchemaForInvestmentFlow(),
    investmentStore.insertUpdateUnsignedInvestment(),
  ]);
  router.push("/make-an-investment");
}
</script>
