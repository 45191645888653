<template>
  <div class="flex flex-col lg:flex-row-reverse items-start w-full h-full pb-10 lg:p-6">
    <div
      class="flex px-6 w-full lg:w-[65%] lg:border border-platform-outlines h-full rounded-md justify-center"
    >
      <div class="w-full md:w-[55%] lg:w-[45%] flex flex-col justify-center">
        <h3 class="font-bold text-auth-size my-8 lg:my-0 lg:mb-8 text-primary-6">Sign In</h3>
        <div class="flex flex-col gap-4 mb-6 w-full">
          <BaseInput
            v-model="authStore.signInFields.email"
            label="Email"
            placeholder="Type Here"
            size="large"
            @keydown.enter="handleSignin"
            required
            formatRule="email"
            errorMessage="Invalid email"
          />
          <BaseInput
            v-model="authStore.signInFields.password"
            label="Password"
            placeholder="Type Here"
            size="large"
            type="password"
            @keydown.enter="handleSignin"
            required
          />
        </div>
        <!-- Turnstyle widget -->
        <div class="mb-4" data-theme="light" id="turnstile"></div>
        <!-- CTA For Form -->
        <div class="flex flex-col items-center w-full gap-4 mb-4">
          <Button
            @click="handleSignin"
            @keydown.enter="handleSignin"
            :disabled="!authStore.signInFields.email || !authStore.signInFields.password"
            label
            icon
            size="large"
            class="w-full"
          >
            <template v-if="isSigninLoading" #label>
              <IconPulseSpinner class="h-6 w-6 stroke-white" />
            </template>
            <template v-else #label> Sign In </template>
          </Button>
          <Button
            @click="router.push('/sign-up')"
            label
            icon
            size="large"
            class="w-full"
            variant="white"
          >
            <template #label>Create an Account</template>
          </Button>
          <Button
            @click="router.push('/forgot-password')"
            label
            icon
            size="large"
            class="w-full"
            variant="white"
          >
            <template #label> Forgot Password? </template>
          </Button>
        </div>
        <Transition
          enter-from-class="opacity-0 translate-x-4"
          enter-to-class="opacity-100 translate-x-0"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="opacity-0 translate-x-4"
          enter-active-class="transition ease-in-out duration-300"
          leave-active-class="transition ease-in-out duration-300"
        >
          <Banner type="error" v-if="signinError" hideClose class="mb-4">
            <template #content>
              <p>{{ signinError }}</p>
            </template>
          </Banner>
        </Transition>
      </div>
    </div>
    <InvestorTestimonials />
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores";
import { BaseInput, Button, Banner } from "@/components";
import { IconPulseSpinner } from "@/components/icons";
import InvestorTestimonials from "./InvestorTestimonials.vue";

const router = useRouter();
const authStore = useAuthStore();

const signinError = ref("");
const isSigninLoading = ref(false);
const isFirstRun = ref(false);

onMounted(() => {
  window.scrollTo(0, 0);
  authStore.isSignup = false;
  authStore.mfa_channel = null;
  turnstile.ready(function () {
    if (isFirstRun.value === false) {
      isFirstRun.value = true;
      turnstile.render("#turnstile", {
        sitekey:
          import.meta.env.VITE_ENV === "Production"
            ? "0x4AAAAAAAbuzA10_ip9GLl-"
            : "1x00000000000000000000AA",
        callback: function (token) {
          authStore.turnstileToken = token;
          authStore.isTurnstileValid = true;
        },
      });
    }
  });
});

onUnmounted(() => {
  authStore.isTurnstileValid = true;
});

async function handleSignin() {
  try {
    if (!authStore.isTurnstileValid) {
      signinError.value =
        "Please complete the security check above to confirm that you are a human.";
      return;
    }

    if (!authStore.signInFields.email || !authStore.signInFields.password) {
      return;
    }
    signinError.value = "";
    isSigninLoading.value = true;
    const isSigninSuccessful = await authStore.validateUser(
      "signin",
      "investor",
      authStore.signInFields.email.toLowerCase().trim(),
      authStore.signInFields.password
    );

    if (isSigninSuccessful.status === "approved") {
      authStore.signInFields.phone = isSigninSuccessful.phone_number;
      router.push("/sign-in/auth-verification");
    } else {
      // Show Error Message
      signinError.value = "Invalid email or password. Please try again.";
    }
  } catch (err) {
    signinError.value = "Error trying to sign in. Please try again.";
  } finally {
    isSigninLoading.value = false;
  }
}

let timeout = null;
watch(
  () => signinError.value,
  (newValue) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (newValue) {
      timeout = setTimeout(() => {
        signinError.value = false;
      }, 3000);
    }
  }
);
</script>
