import { ref, onMounted, onUnmounted, watch } from "vue";

export function useGradientScroll({ isLongScroll = false } = {}) {
  const scrollContainer = ref(null);
  const showScrollRight = ref(false);
  const showScrollLeft = ref(false);
  let resizeObserver;
  let mutationObserver;

  onMounted(async () => {
    // watches for changes in the container like new children so we can update when tabs are added and removed
    mutationObserver = new MutationObserver(handleScroll);

    // watches for resizing of the container
    resizeObserver = new ResizeObserver(handleScroll);
    if (scrollContainer.value) {
      resizeObserver.observe(scrollContainer.value);
      mutationObserver.observe(scrollContainer.value, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }
  });

  onUnmounted(() => {
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
  });

  watch(
    () => scrollContainer.value,
    (newValue, oldValue) => {
      if (oldValue) {
        resizeObserver.unobserve(oldValue);
        mutationObserver.disconnect();
      }
      if (newValue) {
        resizeObserver.observe(newValue);
        mutationObserver = new MutationObserver(handleScroll);
        mutationObserver.observe(scrollContainer.value, {
          attributes: true,
          childList: true,
          subtree: true,
        });
        handleScroll();
      }
    },
    { immediate: true }
  );

  function handleScroll() {
    if (!scrollContainer.value) return;
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.value;
    showScrollLeft.value = scrollLeft > 0;
    showScrollRight.value = scrollLeft + clientWidth < scrollWidth;
  }

  function scrollRight() {
    scrollContainer.value.scrollBy({ top: 0, left: isLongScroll ? 500 : 150, behavior: "smooth" });
  }

  function scrollLeft() {
    scrollContainer.value.scrollBy({
      top: 0,
      left: isLongScroll ? -500 : -150,
      behavior: "smooth",
    });
  }

  return {
    scrollContainer,
    showScrollRight,
    showScrollLeft,
    scrollRight,
    scrollLeft,
    handleScroll,
  };
}
