<template>
  <RolloverModal
    :open="isRolloverModalOpen"
    @close-modal="isRolloverModalOpen = false"
    @select-rollover="investmentStore.selected_rollover_investment = $event"
  />
  <div
    class="w-full border border-platform-outlines rounded-md transition-all duration-300 overflow-hidden ease-in-out"
  >
    <!-- Header Section w/ Collapsable Toggle -->
    <div
      class="flex justify-between bg-white h-[3.75rem] items-center p-4 border-b border-platform-outlines"
    >
      <h3 class="font-semibold text-[1.375rem] text-primary-6">Enter Investment Amount</h3>
    </div>
    <!-- Main Content -->
    <div class="w-full bg-neutral-gray-2 px-4 py-6">
      <div
        class="w-full flex flex-col"
        :class="{ 'border-b border-neutral-gray-5 pb-4': hasRolloversAvailable }"
      >
        <p class="text-lighter text-sm lg:text-base mb-2">One bond equals $1,000 USD</p>
        <BaseInput
          v-model="investmentStore.currentInvestment.amount"
          placeholder="0.00"
          @input="formatAmount"
          @blur="roundInvestmentAmount"
          label="Investment Amount"
          size="large"
          maxLength="8"
          formatRule="amount"
          required
          class="w-full"
          hideErrors
        />

        <p v-if="isInvalidRolloverAmount" class="text-red-500 text-sm mt-1">
          Amount must be less than or equal to the available rollover amount.
        </p>
        <div class="border-b border-neutral-gray-5 my-4"></div>
        <!-- Payment Frequency -->
        <div class="flex flex-col gap-3">
          <h3 class="font-medium text-lg">Payment Frequency</h3>
          <RadioOption
            id="compounded"
            name="paymentFrequency"
            :value="true"
            v-model="investmentStore.currentInvestment.is_compounded"
            label="Compounded Monthly"
            description="Interest and principal is paid at maturity"
            isInputTopAligned
            :disabled="
              investmentStore.selectedOffering && !investmentStore.selectedOffering?.can_compound
            "
          />
          <RadioOption
            id="monthly"
            name="paymentFrequency"
            :value="false"
            v-model="investmentStore.currentInvestment.is_compounded"
            label="Interest Paid Monthly"
            description="Interest is paid every month"
            isInputTopAligned
          />
        </div>
      </div>
      <!-- Conditional Rollover Section -->
      <div v-if="hasRolloversAvailable" class="w-full flex flex-col items-center pt-4">
        <div
          v-if="investmentStore.selected_rollover_investment"
          class="grid grid-cols-2 w-full gap-3 mb-4 md:mb-0"
        >
          <p class="col-span-2 mt-4 mb-3 font-medium">Rollover Investment Selected</p>
          <div>
            <p class="font-semibold">Available for rollover</p>
            <p class="text-lighter">
              ${{
                commaSeparateThousands_2(
                  investmentStore.selected_rollover_investment?.rollover_available
                )
              }}
            </p>
          </div>
          <div>
            <p class="font-semibold">Amount at Maturity</p>
            <p class="text-lighter">
              ${{
                commaSeparateThousands(
                  investmentStore.selected_rollover_investment?.amount_at_maturity
                )
              }}
            </p>
          </div>
          <div>
            <p class="font-semibold">Type</p>
            <p class="text-lighter">
              {{ investmentStore.selected_rollover_investment?.type }}
            </p>
          </div>
          <div>
            <p class="font-semibold">Maturity Date</p>
            <p class="text-lighter">
              {{
                new Date(
                  investmentStore.selected_rollover_investment?.maturity_date
                )?.toLocaleDateString("en-us", { timeZone: "UTC" })
              }}
            </p>
          </div>
          <div class="flex gap-4 col-span-2 w-full mt-3">
            <Button
              @click="isRolloverModalOpen = true"
              size="large"
              variant="default-color-outlined"
              label
              class="w-full"
            >
              <template #label>Select Investment</template>
            </Button>
            <Button
              @click="investmentStore.selected_rollover_investment = null"
              size="large"
              variant="error-color-outlined"
              icon
            >
              <template #icon>
                <Icon name="Trash" class="h-5 w-5 text-functional-error-default" />
              </template>
            </Button>
          </div>
        </div>
        <Banner
          v-if="!investmentStore.selected_rollover_investment"
          hideClose
          type="info-1"
          iconTop
        >
          <template #content>
            <div class="flex flex-col text-base">
              <p class="font-bold">
                {{ numInvestmentsAvailableForRollover }} Investment<span
                  v-if="numInvestmentsAvailableForRollover !== 1"
                  >s</span
                >
                <span>{{ numInvestmentsAvailableForRollover !== 1 ? " are" : " is" }}</span>
                Available for Rollover
              </p>
              <p class="text-light mt-1">
                ${{ commaSeparateThousands(totalAmountAvailableForRollover) }} available for
                reinvesting
              </p>
              <Button
                @click="isRolloverModalOpen = true"
                label
                class="w-[8.5rem] mt-4"
                variant="default-gray-outlined"
              >
                <template #label>Select Investment</template>
              </Button>
            </div>
          </template>
        </Banner>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";

import { useInvestmentStore } from "@/stores";
import { Button, Icon, BaseInput, RadioOption, Banner } from "@/components";
import { commaSeparateThousands_2, commaSeparateThousands } from "@/utilities";
import RolloverModal from "./RolloverModal.vue";

const investmentStore = useInvestmentStore();

const isAmountCollapsed = ref(false);
const isRolloverModalOpen = ref(false);

watch(
  () => [
    investmentStore.currentInvestment.rounded_amount,
    investmentStore.currentInvestment.is_compounded,
  ],
  () => {
    if (investmentStore.currentInvestment.amount.length >= 4) {
      investmentStore.getAnticipatedEarnings();
    }
  }
);

watch(
  () => investmentStore.selectedOffering,
  () => {
    if (investmentStore.selectedOffering && !investmentStore.selectedOffering?.can_compound) {
      investmentStore.currentInvestment.is_compounded = false;
    }
  }
);

const hasRolloversAvailable = computed(() => {
  if (!investmentStore.transactionsData.length) return false;

  return investmentStore.transactionsData.some(
    (investment) =>
      investment.able_to_rollover &&
      investment.status === "Settled" &&
      investment.rollover_available > 0
  );
});

const numInvestmentsAvailableForRollover = computed(() => {
  return investmentStore.transactionsData.filter(
    (investment) =>
      investment.able_to_rollover &&
      investment.status === "Settled" &&
      investment.rollover_available > 0
  ).length;
});

const totalAmountAvailableForRollover = computed(() => {
  return investmentStore.transactionsData
    .filter(
      (investment) =>
        investment.able_to_rollover &&
        investment.status === "Settled" &&
        investment.rollover_available > 0
    )
    .reduce((acc, investment) => acc + investment.rollover_available, 0);
});

const minimumInvestmentAmount = computed(() => {
  const selectedOffering = investmentStore.selectedOffering;

  if (!selectedOffering) return 0;

  // If it's Reg D and user has 0 signed Reg D, the minimum is 25k, otherwise it's 1k
  if (selectedOffering.type === "Reg D") {
    return investmentStore.hasAccreditedInvestments ? 1000 : 25000;
  }

  // For Reg A or S1, the minimum is always 5k
  return 1000;
});

const isInvalidAmount = computed(() => {
  const enteredAmount = Number(investmentStore.currentInvestment.amount.replace(/,/g, ""));
  return enteredAmount < minimumInvestmentAmount.value;
});

const isInvalidRolloverAmount = computed(() => {
  if (investmentStore.selected_rollover_investment) {
    return (
      Number(investmentStore.currentInvestment.amount.replace(/,/g, "")) >
      investmentStore.selected_rollover_investment.rollover_available
    );
  }
  return false;
});

function roundInvestmentAmount() {
  const numberAmount = Number(investmentStore.currentInvestment.amount.replace(/,/g, ""));
  const roundedAmount = Math.round(numberAmount / 1000) * 1000;
  const formattedAmount = commaSeparateThousands_2(roundedAmount.toString());
  investmentStore.currentInvestment.amount = formattedAmount;
  investmentStore.currentInvestment.rounded_amount = formattedAmount;
}

function formatAmount() {
  let amount = investmentStore.currentInvestment.amount.replace(/^0+(?=\d)/, "");
  investmentStore.currentInvestment.amount = commaSeparateThousands_2(amount);
}
</script>
