<template>
  <div
    class="w-full lg:w-[35%] lg:h-full rounded-md px-6 lg:px-0 lg:mr-6 flex items-center justify-center"
  >
    <div
      class="relative w-full h-full bg-cover bg-center bg-no-repeat rounded-md md:max-w-[55%] lg:max-w-none"
      :style="{ backgroundImage: `url(${bgImg})` }"
    >
      <div class="absolute inset-0 bg-primary-6 opacity-80 rounded-md z-0"></div>
      <div class="relative z-10 w-full h-full flex flex-col items-start px-4 py-6 lg:p-10">
        <img :src="phxLogo" alt="" class="hidden lg:block h-8 object-contain mt-6 mb-10" />
        <h3 class="text-white text-3xl md:text-4xl xl:text-[54px] mb-4 hidden lg:block font-bold">
          Take Flight
        </h3>
        <h3
          class="text-white text-3xl md:text-4xl xl:text-[54px] mb-6 lg:mb-12 hidden lg:block font-bold"
        >
          with Phoenix
        </h3>
        <h4 class="text-neutral-gray-4 text-2xl xl:text-2xl mb-6 font-bold uppercase">
          20,000 Investors Chose <br />
          Phoenix Capital
        </h4>

        <div class="relative w-full overflow-hidden" :style="{ height: fixedHeight + 'px' }">
          <transition name="fade-slide">
            <p :key="activeReviewIndex" class="text-primary-2 lg:max-w-[70%] leading-[1.25]">
              {{ investorReviews[activeReviewIndex].review }}
            </p>
          </transition>
        </div>

        <div class="flex flex-col gap-2 mt-4">
          <div class="flex gap-2 items-center">
            <div class="flex h-8 w-8 items-center justify-center rounded-full">
              <img
                :src="investorReviews[activeReviewIndex].img"
                alt=""
                class="h-8 object-fill rounded-full"
              />
            </div>
            <p class="text-light text-sm">
              {{ investorReviews[activeReviewIndex].name }}
            </p>
          </div>
          <div class="flex gap-1.5 mt-2">
            <div
              class="w-12 rounded-xl h-1.5"
              :class="activeReviewIndex === 0 ? 'bg-white' : 'bg-light'"
            ></div>
            <div
              class="w-12 rounded-xl h-1.5"
              :class="activeReviewIndex === 1 ? 'bg-white' : 'bg-light'"
            ></div>
            <div
              class="w-12 rounded-xl h-1.5"
              :class="activeReviewIndex === 2 ? 'bg-white' : 'bg-light'"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import phxLogo from "@/assets/images/PHX_Revised_Logo.png";
import bgImg from "@/assets/images/admin_bg_small.png";
import testimonial_img_1 from "@/assets/images/JeffP_Testimonial.png";
import testimonial_img_2 from "@/assets/images/Karen_Testimonial.png";
import testimonial_img_3 from "@/assets/images/Branden_Testimonial.png";

const activeReviewIndex = ref(0);
const investorReviews = ref([
  {
    index: 1,
    name: "Jeff P.",
    img: testimonial_img_1,
    review:
      "I was intrigued by the attractive yields Phoenix Capital Group offered. After Matt explained how the Company operates…I was more excited. While I typically focus on equity investments, this offers attractive yield in a low-yield market.",
  },
  {
    index: 2,
    name: "Karen W.",
    img: testimonial_img_2,
    review:
      "My dealings with Phoenix Capital Group have been professional yet simple and straightforward. My questions have been answered quickly by their knowledgeable staff.",
  },
  {
    index: 3,
    name: "Brandon F.",
    img: testimonial_img_3,
    review:
      "The Phoenix investment opportunity was brought to me by Matt, who I have known for a while. I was excited to see that I could get above-market yield…that generated good income-producing returns. It’s a great investment for us.",
  },
]);

const fixedHeight = 120; // Set this to the height you want for the text container

let interval = null;
onMounted(() => {
  if (interval) clearInterval(interval);
  interval = setInterval(() => {
    activeReviewIndex.value = activeReviewIndex.value === 2 ? 0 : activeReviewIndex.value + 1;
  }, 9000);
});
</script>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition:
    opacity 0.5s,
    transform 0.5s;
}
.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
