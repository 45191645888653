<template>
  <div :class="[bannerStyles]">
    <div class="flex gap-4">
      <Icon
        :name="customIconName ? customIconName : bannerIcon"
        :stroke-width="2"
        :class="iconStyles"
      />
      <slot name="content"></slot>
    </div>
    <Icon
      v-if="!hideClose"
      name="X"
      class="absolute right-3 top-3 cursor-pointer text-neutral-gray-8"
      @click="$emit('close')"
      :stroke-width="2"
    />
    <slot name="actions"></slot>
  </div>
</template>

<script setup>
import { computed } from "vue";

import { Icon } from "@/components";

const props = defineProps({
  type: {
    type: String,
    default: "info",
  },
  hideClose: {
    type: Boolean,
    default: false,
  },
  customIconName: {
    type: String,
    default: "",
  },
  iconTop: {
    type: Boolean,
    default: false,
  },
  hideIconOnMobile: {
    type: Boolean,
    default: false,
  },
});

const bannerStyles = computed(() => {
  let baseClass = `w-full rounded-md border relative flex flex-col md:flex-row p-3 justify-between h-auto ${props.iconTop ? "items-start" : "items-center"}`;

  switch (props.type) {
    case "info":
      return `${baseClass} border-cTeal border-opacity-30 bg-cTeal bg-opacity-10`;
    case "info-1":
      return `${baseClass} border-cBlue bg-cBlue bg-opacity-10`;
    case "warning":
      return `${baseClass} border-secondary-6 bg-secondary-1`;
    case "error":
      return `${baseClass} border-functional-error-default bg-functional-error-default bg-opacity-10`;
    case "success":
      return `${baseClass} border-functional-success-default bg-functional-success-default bg-opacity-10`;
    default:
      return `${baseClass} border-cTeal bg-cTeal bg-opacity-10`;
  }
});

const bannerIcon = computed(() => {
  switch (props.type) {
    case "info":
    case "info-1":
      return `MessageSquare`;
    case "warning":
      return `MessageSquareWarning`;
    case "error":
      return `TriangleAlert`;
    default:
      return `MessageSquare`;
  }
});

const iconStyles = computed(() => {
  switch (props.type) {
    case "info":
      return `text-cTeal text-opacity-40 min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
    case "info-1":
      return `text-cBlue min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
    case "warning":
      return `text-secondary-6 min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
    case "error":
      return `text-functional-error-default min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
    case "success":
      return `text-functional-success-default min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
    default:
      return `text-cTeal text-opacity-40 min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
  }
});
</script>
