import { defineStore } from "pinia";
import { watch } from "vue";

export const useUIStore = defineStore("UIStore", {
  state: () => ({
    //alerts
    alertList: [], // the animated alerts/notifications
    //general
    isLoading: false,
    isChartLoading: false,
    //table
    sortAscending: null,
    sortHeader: null,
  }),
  getters: {},
  actions: {
    setupAlertWatcher() {
      watch(
        () => this.alertList,
        () => {
          const nonPersistentAlerts = this.alertList.filter((alert) => !alert.does_persist);

          // If there are non-persistent alerts, schedule their removal
          if (nonPersistentAlerts.length > 0) {
            this.scheduleAlertRemoval(nonPersistentAlerts);
          }
        },
        { deep: true }
      );
    },
    scheduleAlertRemoval(alerts) {
      // Cancel any existing removal timer
      clearTimeout(this.removalTimer);

      this.removalTimer = setTimeout(() => {
        // Start removing alerts, last to first
        for (let i = alerts.length - 1; i >= 0; i--) {
          setTimeout(
            () => {
              this.removeNotification(alerts[i].id);
            },
            (alerts.length - 1 - i) * 500
          ); // 500ms-second stagger
        }
      }, 3000); // 3-second delay before starting removal
    },
    removeNotification(id) {
      const alertIndex = this.alertList.findIndex((alert) => alert.id === id);
      if (alertIndex !== -1) {
        this.alertList[alertIndex].show = false;
      }

      setTimeout(() => {
        this.alertList = this.alertList.filter((alert) => alert.id !== id);
      }, 200);
    },
    async animateNotificationAlert(doc) {
      const {
        type = "success",
        message = "Default Message For Notification",
        subText = "",
        is_read = false,
        does_persist = false,
        action = null,
        icon = "",
      } = doc;

      const alert_notification = {
        type,
        message,
        subText,
        is_read,
        does_persist,
        action,
        id: Date.now(),
        show: false,
        icon,
      };
      this.alertList.push(alert_notification);

      // This is to get the animation to work as expected
      setTimeout(() => {
        const index = this.alertList.findIndex((alert) => alert.id === alert_notification.id);
        if (index !== -1) {
          this.alertList[index].show = true;
        }
      }, 0);
    },
    async setSortHeader(header, props) {
      this.isLoading = true;
      // if active header is clicked again, toggle icon and ascending value
      if (header.field_name === this.sortHeader?.field_name) {
        this.sortAscending = !this.sortAscending;
      }
      this.sortHeader = header;
      this.isLoading = false;
    },
  },
});
