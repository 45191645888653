<template>
  <div>
    <Teleport to="body">
      <AddEntityJointSideDrawer
        @close-drawer="isSideDrawerOpen = false"
        @handle-save="handleAddNewContact"
        :open="isSideDrawerOpen"
        :record="newJointContactTemplate"
        type="Joint"
      />
    </Teleport>
    <ConfirmDeleteModal
      :open="isShowDeleteModal"
      @close-modal="isShowDeleteModal = false"
      @confirm-delete="handleDelete"
      message="Are you sure you want to delete this contact? This cannot be undone."
    />
    <div
      v-if="investorStore.active_profile_tab === 'Joint'"
      class="w-full flex flex-col justify-between h-full text-primary-6"
    >
      <div class="flex items-center justify-between mb-10">
        <p class="font-bold text-xl">Joint Contacts</p>
        <Button
          @click="(isSideDrawerOpen = true), (investorStore.supressErrors = false)"
          label
          variant="default-gray-outlined"
        >
          <template #label>Add Joint Contact</template>
        </Button>
      </div>
      <div class="flex flex-col gap-6">
        <div
          v-for="(contact, contactIndex) in investorStore.joint_contacts"
          :key="contactIndex"
          class="border border-platform-outlines rounded-md flex flex-col items-center p-4 cursor-pointer transition-all duration-500 overflow-hidden"
          :class="expandedContacts[contactIndex] ? 'max-h-[200vh]' : 'max-h-14'"
        >
          <div class="flex justify-between items-center w-full" @click="toggleExpand(contactIndex)">
            <div class="flex items-center gap-2">
              <p class="font-bold">
                {{ `${contact.first_name} ${contact.last_name}` }}
              </p>
              <p class="text-lighter text-sm">
                {{ getContactsSignedInvestmentCount(contact) }} signed investment<span>
                  {{ getContactsSignedInvestmentCount(contact) !== 1 ? "s" : "" }}</span
                >
              </p>
            </div>
            <Icon
              :name="expandedContacts[contactIndex] ? 'ChevronUp' : 'ChevronDown'"
              class="text-primary-6 h-6 w-6"
              :stroke-width="2"
            />
          </div>
          <div class="flex flex-col w-full mt-6">
            <template v-for="(schema, index) of schemaToUse" :key="index">
              <Form
                :fields="schema.fields"
                :record="contact"
                :header="schema.group_name"
                :subHeader="
                  index === 1
                    ? 'Phoenix requires a physical address. PO Boxes and mail drops are not valid. If you use a PO Box for receiving mail, please reach out to Investor Relations at (303) 376-9778 to have your account updated.'
                    : index === 2
                      ? 'Your passport or government issued ID (driver\'s license).'
                      : ''
                "
                :isDisabled="!editModeState[`${index}${contactIndex}`]"
                :isFormValid="isFormValid(contactIndex)"
                @toggleEditMode="toggleEditMode(`${index}${contactIndex}`, contactIndex)"
                @handleSave="handleSave(`${index}${contactIndex}`, contactIndex)"
                fromProfile
                isSmall
                type="joint"
              />
              <div
                v-if="index !== schemaToUse.length - 1"
                class="border-b border-neutral-gray-4 w-full my-4 md:my-8"
              ></div>
            </template>
            <Button
              v-if="getContactsSignedInvestmentCount(contact) === 0"
              @click="(isShowDeleteModal = true), (conact_to_delete = contact)"
              icon
              label
              variant="error-color-outlined"
              class="w-32 mt-4"
            >
              <template #label>
                <p class="text-functional-error-default mt-0.5">Delete Contact</p>
              </template>
              <template #icon>
                <Icon
                  name="Trash"
                  class="text-functional-error-default h-4 w-4"
                  :stroke-width="2.5"
                />
              </template>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { cloneDeep } from "lodash";

import { useInvestmentStore, useInvestorStore, useCrudStore } from "@/stores";
import { Button, Icon, Form, ConfirmDeleteModal } from "@/components";
import { JOINT_TEMPLATE } from "@/constants";
import { getObjectDifferences, validateField } from "@/utilities";
import AddEntityJointSideDrawer from "./AddEntityJointSideDrawer.vue";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const crudStore = useCrudStore();

const original_joint_contacts = ref([]);
const editModeState = ref({});
const expandedContacts = ref({});
const newJointContactTemplate = ref(JOINT_TEMPLATE);
const isSideDrawerOpen = ref(false);
const isShowDeleteModal = ref(false);
const conact_to_delete = ref(null);

onMounted(async () => {
  window.scrollTo(0, 0);
});

watch(
  () => investorStore.joint_contacts,
  () => {
    original_joint_contacts.value = [];
    investorStore.joint_contacts.forEach((contact, index) => {
      original_joint_contacts.value[index] = cloneDeep(contact);
    });
  },
  { immediate: true }
);

watch(
  () => investorStore.active_profile_tab,
  () => {
    original_joint_contacts.value.forEach((contact, index) => {
      investorStore.joint_contacts[index] = cloneDeep(contact);
      editModeState.value = {};
      expandedContacts.value[index] = false;
    });
  }
);

const schemaToUse = computed(() => {
  return investmentStore.hasAccreditedInvestments
    ? investmentStore.accredited_joint_contact_schema
    : investmentStore.non_accredited_joint_contact_schema;
});

const isFormValid = (index) => {
  const contact = investorStore.joint_contacts[index];
  if (!contact) {
    return false;
  }

  if (!schemaToUse.value || schemaToUse.value.length === 0) {
    return false;
  }

  const fieldsToValidate = schemaToUse.value.flatMap((schema) =>
    schema.fields.filter((f) => f.is_required)
  );

  if (investorStore.hasSpecialError === "ssn" || investorStore.hasSpecialError === "email") {
    return false;
  }

  const isValid = fieldsToValidate.every((field) => {
    const value =
      field.belongs_to_nested_object && field.nested_object_name
        ? contact[field.nested_object_name]?.[field.field_name]
        : contact[field.field_name];

    return validateField(field.field_name, value);
  });

  return isValid;
};

const hasAnyValueChanged = (index) => {
  const differences = getObjectDifferences(
    investorStore.joint_contacts[index],
    original_joint_contacts.value[index]
  );
  return Object.keys(differences).length > 0;
};

function toggleExpand(contactIndex) {
  expandedContacts.value[contactIndex] = !expandedContacts.value[contactIndex];
}

function toggleEditMode(index, contactIndex) {
  editModeState.value[index] = !editModeState.value[index];
  if (!editModeState.value[index]) {
    investorStore.supressErrors = true;
    investorStore.joint_contacts[contactIndex] = cloneDeep(
      original_joint_contacts.value[contactIndex]
    );
  } else {
    investorStore.supressErrors = false;
  }
}

async function handleSave(index, contactIndex) {
  editModeState.value[index] = false;
  if (hasAnyValueChanged(contactIndex)) {
    original_joint_contacts.value[contactIndex] = cloneDeep(
      investorStore.joint_contacts[contactIndex]
    );
    await investorStore.upsertJointContact(investorStore.joint_contacts[contactIndex]);
  }
}

function handleAddNewContact(newContact) {
  original_joint_contacts.value.push(cloneDeep(newContact));
}

async function handleDelete() {
  isShowDeleteModal.value = false;
  investorStore.joint_contacts = investorStore.joint_contacts.filter(
    (c) => c._id?.toString() !== conact_to_delete.value._id?.toString()
  );
  investorStore.allContacts = investorStore.allContacts.filter(
    (c) => c._id?.toString() !== conact_to_delete.value._id?.toString()
  );
  await crudStore.deleteOne("Contacts", { _id: conact_to_delete.value._id });
  conact_to_delete.value = null;
}

function getContactsSignedInvestmentCount(contact) {
  if (!contact?._id) {
    return 0;
  }

  return investmentStore.transactionsData.filter(
    (i) => i?.joint_contact_id?.toString() === contact?._id?.toString() && i?.status !== "Canceled"
  )?.length;
}
</script>
