<template>
  <div class="md:h-full w-full p-6">
    <div
      class="flex w-full md:border border-platform-outlines rounded-md h-full items-center md:justify-center mt-12 md:mt-0"
    >
      <button
        type="button"
        @click="handleBack"
        class="flex items-center justify-center gap-1.5 absolute top-24 left-6 md:left-10"
      >
        <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
        Back
      </button>
      <div
        class="w-full md:max-w-value-33 md:p-0 mt-4 lg:mt-0 h-full flex flex-col md:justify-center"
      >
        <div>
          <AuthStepper :numSteps="9" v-if="authStore.isSignup" />
          <div v-if="!authStore.isSignup && !authStore.mfa_channel">
            <h3 class="font-bold text-2xl mb-6 text-primary-6">Let's Verify It's You</h3>
            <p class="text-lighter text-sm">Choose a verification method</p>
            <div class="flex flex-col gap-2 mt-6">
              <Button
                v-for="method in ['Email', 'Text Message', 'Phone Call']"
                :key="method"
                @click="handleRequestVerificationCode(method)"
                label
                size="large"
                class="w-full"
              >
                <template v-if="isRequestCodeLoading && selectedMethod === method" #label>
                  <IconPulseSpinner class="h-6 w-6 stroke-white" />
                </template>
                <template v-else #label>{{ method }}</template>
              </Button>
            </div>
          </div>
          <div v-else>
            <div>
              <h3 class="font-bold text-2xl mb-6 text-primary-6">Let's Verify It's You</h3>
              <p class="text-lighter text-sm">
                A 6 digit verification code has been sent to
                {{ authStore.mfa_channel === "Email" ? `an email` : "a phone number" }}<br />
                {{
                  authStore.isSignup
                    ? authStore.signUpFields.email
                    : authStore.mfa_channel === "Email"
                      ? `${authStore.signInFields.email[0]}${"*".repeat(authStore.signInFields.email.split("@")[0].length - 1)}${authStore.signInFields.email.split("@")[0][authStore.signInFields.email.split("@")[0].length - 1]}@${authStore.signInFields.email.split("@")[1]}`
                      : `(***)***-${authStore.signInFields.phone.slice(-4)}`
                }}
              </p>

              <div class="flex flex-col my-4">
                <label for="verification-code" class="text-sm text-lighter font-medium mb-1">
                  Verification Code
                </label>
                <div class="flex gap-2" id="verification-code">
                  <input
                    v-for="(digit, index) in verification_code"
                    :key="index"
                    type="text"
                    inputmode="numeric"
                    maxlength="1"
                    placeholder="0"
                    class="w-value-34 h-11 text-center text-xl border border-neutral-gray-4 rounded focus:outline-none focus:ring-2 focus:ring-primary-6 placeholder:text-neutral-gray-5"
                    v-model="verification_code[index]"
                    @input="onInput(index)"
                    @keydown.backspace="onBackspace(index)"
                    @paste="onPaste($event)"
                    ref="inputs"
                  />
                </div>
              </div>
              <p v-show="timeUntilNextCodeRequest > 0" class="mb-8">
                Request a new code in ({{ timeUntilNextCodeRequest }}s)
              </p>
              <Banner type="error" v-if="sendCodeError" class="my-8" @close="sendCodeError = ''">
                <template #content>
                  <p class="max-w-[80%]">{{ sendCodeError }}</p>
                </template>
              </Banner>

              <Button
                @click="handleVerifyCode"
                :disabled="!isFormValid"
                label
                size="large"
                class="w-full mt-6 mb-2"
              >
                <template v-if="isVerifyCodeLoading" #label>
                  <IconPulseSpinner class="h-6 w-6 stroke-white" />
                </template>
                <template v-else #label>Verify Code</template>
              </Button>
              <Button
                v-show="timeUntilNextCodeRequest === 0"
                @click="handleRequestVerificationCode(authStore.mfa_channel)"
                label
                size="large"
                class="w-full"
                variant="default-color-outlined"
              >
                <template v-if="isRequestCodeLoading" #label>
                  <IconPulseSpinner class="h-6 w-6 stroke-white" />
                </template>
                <template v-else #label>Request Another Code</template>
              </Button>
            </div>
          </div>
          <!-- Turnstyle widget -->
          <div class="my-4" data-theme="light" id="turnstile"></div>
        </div>
        <div class="text-lighter text-base absolute bottom-6 md:bottom-12">
          <p class="">
            Need help?
            <a
              :href="MFAGuide"
              class="font-semibold"
              download="MFA_Guide"
              rel="noreferrer noopener"
            >
              Multi Factor Authentication Guide
            </a>
          </p>
          <p class="mt-1">
            Lost your MFA Method?
            <a
              href="https://phxcapitalgroup.com/contact-us-about-it-support/"
              rel="noreferrer noopener"
              target="_blank"
              class="font-semibold"
            >
              Contact Us
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import AuthStepper from "./AuthStepper.vue";
import { useAuthStore, useUIStore, useInvestorStore } from "@/stores";
import { IconPulseSpinner } from "@/components/icons";
import { Button, Icon, Banner } from "@/components";
import MFAGuide from "@/assets/documents/MFA_guide.pdf";

const router = useRouter();
const authStore = useAuthStore();
const investorStore = useInvestorStore();
const UIStore = useUIStore();
const timeUntilNextCodeRequest = ref(30);

const inputs = ref([]);
const selectedMethod = ref("");
const sendCodeError = ref("");
const isVerifyCodeLoading = ref(false);
const isRequestCodeLoading = ref(false);
const verification_code = ref(["", "", "", "", "", ""]);
const isFirstRun = ref(false);
let interval = null;

onMounted(async () => {
  window.scrollTo(0, 0);
  if (!authStore.signUpFields.email && !authStore.signInFields.email) {
    router.push("/sign-in");
  }

  if (authStore.mfa_channel) {
    timeUntilNextCodeRequest.value = 30;
    interval = setInterval(() => {
      if (timeUntilNextCodeRequest.value > 0) {
        timeUntilNextCodeRequest.value--;
      } else {
        clearInterval(interval);
      }
    }, 1000);
  }
  inputs.value = inputs.value.slice(0, 6);
  turnstile.ready(function () {
    if (isFirstRun.value === false) {
      isFirstRun.value = true;
      turnstile.render("#turnstile", {
        sitekey:
          import.meta.env.VITE_ENV === "Production"
            ? "0x4AAAAAAAbuzA10_ip9GLl-"
            : "1x00000000000000000000AA",
        callback: function (token) {
          authStore.turnstileToken = token;
          authStore.isTurnstileValid = true;
        },
      });
    }
  });
});

onUnmounted(() => {
  authStore.isTurnstileValid = true;
});

watch(
  () => authStore.mfa_channel,
  () => {
    clearInterval(interval);
    turnstile.ready(function () {
      if (isFirstRun.value === false) {
        isFirstRun.value = true;
        turnstile.render("#turnstile", {
          sitekey:
            import.meta.env.VITE_ENV === "Production"
              ? "0x4AAAAAAAbuzA10_ip9GLl-"
              : "1x00000000000000000000AA",
          callback: function (token) {
            authStore.turnstileToken = token;
            authStore.isTurnstileValid = true;
          },
        });
      }
    });
    if (authStore.mfa_channel) {
      verification_code.value = ["", "", "", "", "", ""];
      timeUntilNextCodeRequest.value = 30;
      interval = setInterval(() => {
        if (timeUntilNextCodeRequest.value > 0) {
          timeUntilNextCodeRequest.value--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    }
  }
);

// let timeout = null;
// watch(
//   () => sendCodeError.value,
//   (newValue) => {
//     if (timeout) {
//       clearTimeout(timeout);
//     }
//     if (newValue) {
//       timeout = setTimeout(() => {
//         sendCodeError.value = false;
//       }, 3000);
//     }
//   }
// );

const isFormValid = computed(() => {
  return verification_code.value.join("").length === 6;
});

function onInput(index) {
  if (verification_code.value[index].length === 1 && index < 5) {
    inputs.value[index + 1].focus();
  }
}

function onBackspace(index) {
  if (index > 0 && verification_code.value[index] === "") {
    inputs.value[index - 1].focus();
  }
}

function onPaste(event) {
  event.preventDefault();
  const paste = (event.clipboardData || window.clipboardData).getData("text");
  if (paste.length === 6 && /^\d+$/.test(paste)) {
    // Ensure it's a 6-digit numeric string
    for (let i = 0; i < 6; i++) {
      verification_code.value[i] = paste[i];
    }
    inputs.value[5].focus(); // Focus the last input after pasting
  }
}

async function handleVerifyCode() {
  try {
    isVerifyCodeLoading.value = true;
    if (!authStore.isTurnstileValid) {
      sendCodeError.value =
        "Please complete the security check above to confirm that you are a human.";
      return;
    }

    const code = verification_code.value.join("");
    const verifyResult = await authStore.verifyMFACodeAndSignin(code);
    if (verifyResult.session_id) {
      authStore.is_verified = true;
      authStore.activeSignupStep = 5;
      if (authStore.isSignup) {
        await authStore.insertAccountAndContactOnSignup();
      } else {
        await investorStore.setInvestorData();
      }
      router.push(authStore.isSignup ? "/sign-up/questionnaire" : "/dashboard");
    } else if (
      verifyResult.error &&
      verifyResult.error === "Unexpected Error: Max check attempts reached"
    ) {
      sendCodeError.value =
        "You have made too many incorrect attempts. Please wait a few minutes before trying again.";
    } else if (verifyResult.status === 409) {
      sendCodeError.value = "Error signing up. Email already in use.";
    } else {
      sendCodeError.value = "Please check the code you entered is correct, or request a new one.";
    }
  } catch (err) {
    sendCodeError.value = "Please check the code you entered is correct, or request a new one.";
  } finally {
    isVerifyCodeLoading.value = false;
  }
}

function handleBack() {
  if (authStore.isSignup) {
    authStore.activeSignupStep = 3;
    router.push("/sign-up/acknowledgements");
  } else {
    authStore.mfa_channel = null;
    authStore.activeSignupStep = 1;
    router.push("/sign-in");
  }
}

async function handleRequestVerificationCode(channel) {
  try {
    isRequestCodeLoading.value = true;
    selectedMethod.value = channel; // Set the clicked method
    if (!authStore.isTurnstileValid) {
      sendCodeError.value =
        "Please complete the security check above to confirm that you are a human.";
      return;
    }

    const sendCodeResult = await authStore.sendVerificationCode(channel);
    if (sendCodeResult.status !== "pending") {
      sendCodeError.value = "Failed to send verification code. Please try again.";
      return;
    }
    authStore.mfa_channel = channel;
  } catch (err) {
    sendCodeError.value = "Failed to send verification code. Please try again.";
  } finally {
    isRequestCodeLoading.value = false;
    selectedMethod.value = ""; // Clear the selected method after request
  }
}
</script>
