<template>
  <div>
    <!-- New account or existing account is check or existing account has no plaid id -->
    <Button
      v-if="!plaidAccountsLoading"
      @click="handler.open()"
      label
      variant="default-gray-outlined"
      :size="size"
      id="plaid-link-button"
    >
      <template #label>Connect Bank Account</template>
    </Button>
    <div v-else class="animate-spin">
      <Icon name="LoaderCircle" class="h-8 w-8 text-primary-6 text-opacity-50" :stroke-width="2" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useInvestmentStore, useAuthStore } from "@/stores";
import { Button, Icon } from "@/components";

const investmentStore = useInvestmentStore();
const authStore = useAuthStore();

const handler = ref(null);
const plaidAccountsLoading = ref(false);

onMounted(async () => {
  try {
    let method = "POST";
    const headers = {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      session_id: authStore.currentUser.session_id,
      user_id: authStore.currentUser.id,
    };
    let body = {
      endpoint: "generate_link_token",
      data: { user_id: authStore.currentUser.id },
    };
    let options = {
      method: method,
      headers,
    };
    if (method === "PUT" || method === "POST") {
      options.body = JSON.stringify(body);
    }
    let response = await fetch(
      `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/call-plaid-endpoint`,
      options
    );
    // TODO: if not 200 code response, clear state and redirect to signin
    let link_token_data = await response.json();
    let link_token = link_token_data ? link_token_data.link_token : "";
    handler.value = Plaid.create({
      clientName: "Phoenix Capital Group",
      accountSubtypes: {
        depository: ["checking", "savings"],
      },
      token: link_token,
      onSuccess: async (public_token, metadata) => {
        plaidAccountsLoading.value = true;
        options = {
          method: method,
          headers,
        };
        body = {
          endpoint: "item_public_token_exchange",
          data: {
            public_token: public_token,
            user_id: authStore.currentUser.id,
            accountType: props.accountType,
          },
        };
        if (method === "PUT" || method === "POST") {
          options.body = JSON.stringify(body);
        }
        let result = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/call-plaid-endpoint`,
          options
        );
        let accessToken = await result.json();
        // let accessToken = await authStore.currentUser.functions.CallPlaidEndpoint('item_public_token_exchange', {public_token: public_token, user_id: authStore.currentUser.id, accountType: props.accountType});
        //}
        await investmentStore.getAccountsFromPlaid(props.accountType, accessToken);
        emit("account-linked");
        plaidAccountsLoading.value = false;
      },
      onLoad: () => {},
      onExit: (err, metadata) => {},
      onEvent: (eventName, metadata) => {},
      //receivedRedirectUri: window.location.href,
    });
  } catch (err) {
    console.error(err);
  }
});

const props = defineProps({
  accountType: String,
  size: {
    type: String,
    default: "large",
  },
});

const emit = defineEmits("account-linked");
</script>
