<template>
  <Modal
    @close-modal="$emit('close-modal')"
    customHeight="h-auto"
    customWidth="lg:w-168"
    :open="open"
  >
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <p class="font-medium">Enter Investment Amount</p>
        {{ investmentStore.selectedOffering.type }}
      </div>
    </template>

    <template v-slot:modal-body>
      <div class="flex flex-col p-8">
        <div class="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-2 w-full">
          <div class="w-full">
            <h3 class="font-semibold text-lg">Enter Investment Amount</h3>
            <p class="text-lighter text-sm lg:text-base">One bond equals $1,000 USD</p>
          </div>
          <div>
            <BaseInput
              v-model="investmentStore.currentInvestment.amount"
              placeholder="0.00"
              @input="formatAmount"
              @blur="roundInvestmentAmount"
              label="Investment Amount"
              size="large"
              maxLength="8"
              formatRule="amount"
              required
              class="w-full lg:w-72"
            />
            <div v-if="isInvalidAmount" class="text-red-500 text-sm mt-1">
              Investment amount must be at least ${{
                commaSeparateThousands_2(minimumInvestmentAmount)
              }}.
            </div>
          </div>
        </div>
        <div class="border-b border-platform-outlines my-6"></div>
        <div class="flex flex-col gap-2 w-full">
          <h3 class="font-semibold text-lg">Payment Frequency</h3>
          <div class="flex gap-10">
            <RadioOption
              id="compounded"
              name="paymentFrequency"
              :value="true"
              v-model="investmentStore.currentInvestment.is_compounded"
              label="Compounded Monthly"
              description="Interest and principal are paid at maturity"
              class="w-1/2"
            />
            <RadioOption
              id="monthly"
              name="paymentFrequency"
              :value="false"
              v-model="investmentStore.currentInvestment.is_compounded"
              label="Interest Paid Monthly"
              description="Interest is paid every month"
              class="w-1/2"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label>Cancel</template>
        </Button>
        <Button
          size="large"
          :disabled="isInvalidAmount"
          label
          @click="$emit('close-modal'), $emit('set-investment-data')"
        >
          <template #label>Next</template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { computed, ref } from "vue";

import { Button, Modal, RadioOption, BaseInput } from "@/components";
import { useInvestmentStore } from "@/stores";
import { commaSeparateThousands_2 } from "@/utilities";

const investmentStore = useInvestmentStore();
const minimumInvestmentAmount = ref(0);

const emit = defineEmits(["close-modal"]);

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const isInvalidAmount = computed(() => {
  const selectedOffering = investmentStore.selectedOffering;
  const hasAccreditedInvestments = investmentStore.hasAccreditedInvestments;
  minimumInvestmentAmount.value =
    selectedOffering.type === "Reg D" ? (hasAccreditedInvestments ? 1000 : 25000) : 5000;
  const enteredAmount = Number(investmentStore.currentInvestment.amount.replace(/,/g, ""));
  return enteredAmount < minimumInvestmentAmount.value;
});

function roundInvestmentAmount() {
  const numberAmount = Number(investmentStore.currentInvestment.amount.replace(/,/g, ""));
  const roundedAmount = Math.round(numberAmount / 1000) * 1000;
  const formattedAmount = commaSeparateThousands_2(roundedAmount.toString());
  investmentStore.currentInvestment.amount = formattedAmount;
}

function formatAmount() {
  investmentStore.currentInvestment.amount = commaSeparateThousands_2(
    investmentStore.currentInvestment.amount
  );
}
</script>
