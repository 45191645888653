import * as Realm from "realm-web";
import { isEqual } from "lodash";

import { ALL_50_STATES } from "@/constants";

const {
  BSON: { ObjectId },
} = Realm;

export function applySchema(record, schema) {
  const parsedRecord = { ...record };
  delete parsedRecord._id;
  delete parsedRecord.created_date;

  for (let field of schema) {
    const fieldName = field.field_name;
    const fieldType = field.field_type;
    const numberType = field.number_type;

    if (fieldName in record) {
      // property already exists on record, format value
      let value_to_use = record[fieldName];

      if (fieldType === "date") {
        if (value_to_use) {
          value_to_use = new Date(value_to_use);
        } else {
          value_to_use = null;
        }
      } else if (fieldType == "number") {
        if (numberType == "percentage" && typeof value_to_use === "number") {
          if (value_to_use > 1) {
            value_to_use = value_to_use / 100;
          }
          // set 4 digit precision
          let num4_as_string = value_to_use.toFixed(4);
          value_to_use = parseFloat(num4_as_string);
        } else if (numberType == "decimal" && typeof value_to_use === "number") {
          let num2_as_string = value_to_use.toFixed(2);
          value_to_use = parseFloat(num2_as_string);
        } else if (numberType == "currency") {
          if (typeof value_to_use === "string" && value_to_use.includes(",")) {
            value_to_use = Number(value_to_use.replace(/,/g, ""));
          }
        }
      } else if (field.is_object_id && value_to_use) {
        value_to_use = ObjectId(value_to_use);
      } else if (fieldType === "array" && value_to_use) {
        //TODO: Loop through array and set schema for each item?
      } else if (fieldType === "object" && value_to_use) {
        //TODO: Set schema for fields that are part of a nested object
      } else if (fieldType === "password") {
        value_to_use = btoa(value_to_use);
      }

      if (fieldName === "state" && parsedRecord[fieldName]) {
        value_to_use = value_to_use.value;
      }

      // hard-coded for id_info & suitability_questionnaire temporarily
      if (
        fieldName === "id_info" &&
        parsedRecord[fieldName] !== null &&
        Object.keys(parsedRecord[fieldName]).length > 0
      ) {
        value_to_use = {
          id_number: parsedRecord[fieldName].id_number,
          id_issued_by: parsedRecord[fieldName].id_issued_by,
          id_issued_date: new Date(parsedRecord[fieldName].id_issued_date),
          id_expiration_date: new Date(parsedRecord[fieldName].id_expiration_date),
        };
      }
      if (
        fieldName === "suitability_info" &&
        parsedRecord[fieldName] !== null &&
        Object.keys(parsedRecord[fieldName]).length > 0
      ) {
        const suitabilityInfo = parsedRecord[fieldName];

        const currentIncome =
          typeof suitabilityInfo.current_income === "string"
            ? Number(suitabilityInfo.current_income.replace(/,/g, ""))
            : suitabilityInfo.current_income;

        const currentNetWorth =
          typeof suitabilityInfo.current_net_worth === "string"
            ? Number(suitabilityInfo.current_net_worth.replace(/,/g, ""))
            : suitabilityInfo.current_net_worth;

        const currentLiquidNetWorth =
          typeof suitabilityInfo.current_liquid_net_worth === "string"
            ? Number(suitabilityInfo.current_liquid_net_worth.replace(/,/g, ""))
            : suitabilityInfo.current_liquid_net_worth;

        value_to_use = {
          ...suitabilityInfo,
          current_income: currentIncome,
          current_net_worth: currentNetWorth,
          current_liquid_net_worth: currentLiquidNetWorth,
        };
      }

      parsedRecord[fieldName] = value_to_use;
    } else {
      // property does not exist on record, set default here based on type
      if (fieldType === "date" || fieldType === "object" || field.is_object_id) {
        parsedRecord[fieldName] = null;
      } else if (fieldType === "array" || fieldType === "array_of_ids") {
        parsedRecord[fieldName] = [];
      } else if (fieldType == "number") {
        parsedRecord[fieldName] = 0;
      } else if (fieldType == "boolean") {
        parsedRecord[fieldName] = false;
      } else {
        // this is the default for text, long text and dropdowns
        parsedRecord[fieldName] = "";
      }
    }
  }

  return parsedRecord;
}

export function parseDocumentsFieldsToProperType(documents) {
  function isValidObjectId(value) {
    if (value instanceof ObjectId) {
      return true;
    }
    return typeof value === "string" && value.length === 24 && /^[0-9a-fA-F]{24}$/.test(value);
  }

  function isValidDate(value) {
    // Check if the value is a string and not purely numeric
    return (
      (typeof value === "string" && isNaN(Number(value)) && !isNaN(Date.parse(value))) ||
      value instanceof Date // Check if value is an actual Date object
    );
  }
  function parseObject(obj) {
    const parsedObject = {};

    for (const [key, value] of Object.entries(obj)) {
      if (
        isValidObjectId(value) &&
        key !== "user_id" &&
        key !== "modified_by" &&
        key !== "created_by_id" &&
        key !== "updated_by_id"
      ) {
        parsedObject[key] = ObjectId(value);
      } else if (isValidDate(value)) {
        parsedObject[key] = new Date(value).toISOString().slice(0, 10); // Convert to ISO string for date inputs
      } else if (
        value &&
        (key === "ssn" || key === "ein" || key === "account_number" || key === "routing_number")
      ) {
        parsedObject[key] = atob(value); // Decode base64 string
      } else if (typeof value === "object" && value !== null && !Array.isArray(value)) {
        parsedObject[key] = parseObject(value);
      } else if (Array.isArray(value)) {
        parsedObject[key] = value.map((item) => {
          if (isValidObjectId(item)) {
            return ObjectId(item);
          } else if (isValidDate(item)) {
            return new Date(item).toISOString().slice(0, 10);
          } else if (typeof item === "object" && item !== null) {
            return parseObject(item);
          }
          return item;
        });
      } else {
        if (key === "state" && value) {
          const state = ALL_50_STATES.find((state) => state.value === value);
          parsedObject[key] = state;
        } else if ((key === "id_info" || key === "suitability_info") && !value) {
          parsedObject[key] = {};
        } else {
          parsedObject[key] = value;
        }
      }
    }

    return parsedObject;
  }

  return documents.map((document) => parseObject(document));
}

export function autoCapitalize(input) {
  return input
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function getObjectDifferences(obj1, obj2) {
  const differences = {};

  function compareObjects(o1, o2, path = "") {
    Object.keys(o1).forEach((key) => {
      const fullPath = path ? `${path}.${key}` : key;

      if (typeof o1[key] === "object" && o1[key] !== null && !Array.isArray(o1[key])) {
        compareObjects(o1[key], o2[key], fullPath);
      } else if (!isEqual(o1[key], o2[key])) {
        differences[fullPath] = { original: o1[key], current: o2[key] };
      }
    });
  }

  compareObjects(obj1, obj2);

  return differences;
}

export function commaSeparateThousands(num) {
  if (!num) return 0;
  // Split the number on the decimal point
  let parts = num.toString().split(".");

  // Add commas to the part before the decimal
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Rejoin the whole number and the decimal part (if it exists)
  return parts.join(".");
}

export function commaSeparateThousands_2(num) {
  return num
    .toString()
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function convertJsonToCsvFileAndDownload(data, fileName) {
  const convertJsonToCsv = (data) => {
    const array = typeof data != "object" ? JSON.parse(data) : data;

    let maxKeysObject = array.reduce((prev, current) => {
      return Object.keys(current).length > Object.keys(prev).length ? current : prev;
    }, {});

    let headers = Object.keys(maxKeysObject).join(",");
    let str = headers + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let header of Object.keys(maxKeysObject)) {
        if (line !== "") line += ",";
        line += array[i][header] ? array[i][header] : "";
      }
      str += line + "\r\n";
    }

    return str;
  };

  const csvData = convertJsonToCsv(data);
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `${fileName}.csv`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return "";

  // Strip all characters from the input except digits
  let cleaned = phoneNumber.replace(/\D/g, "");

  // Limit the number of digits to 10
  if (cleaned.length > 10) {
    cleaned = cleaned.substring(0, 10);
  }

  // Based on the length of the string, add formatting as necessary
  const size = cleaned.length;
  if (size === 0) {
    return cleaned;
  } else if (size < 4) {
    return "(" + cleaned;
  } else if (size < 7) {
    return "(" + cleaned.substring(0, 3) + ") " + cleaned.substring(3, 6);
  } else {
    return (
      "(" +
      cleaned.substring(0, 3) +
      ") " +
      cleaned.substring(3, 6) +
      "-" +
      cleaned.substring(6, 10)
    );
  }
}

export function formatSsn(ssn) {
  if (!ssn) return ssn;

  // Remove all non-numeric characters
  let cleaned = ssn.replace(/\D/g, "");

  // Apply formatting: XXX-XX-XXXX
  if (cleaned.length > 3 && cleaned.length <= 5) {
    cleaned = cleaned.replace(/(\d{3})(\d{1,2})/, "$1-$2");
  } else if (cleaned.length > 5) {
    cleaned = cleaned.replace(/(\d{3})(\d{2})(\d{1,4})/, "$1-$2-$3");
  }

  return cleaned;
}

export function formatEIN(ein) {
  if (!ein) return ein;

  // Remove all non-numeric characters
  let cleaned = ein.replace(/\D/g, "");

  // Apply formatting: XX-XXXXXXX
  if (cleaned.length > 2) {
    cleaned = cleaned.replace(/(\d{2})(\d{1,7})/, "$1-$2");
  }

  return cleaned;
}

export function isValidEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

// function that turns an integer in months into a string in years and months
export function monthsToYears(months) {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  if (years === 0) {
    return `${remainingMonths} months`;
  } else if (remainingMonths === 0) {
    return `${years} year${years > 1 ? "s" : ""}`;
  } else {
    return `${years} years and ${remainingMonths} months`;
  }
}

export function flattenSchema(schema, config = "default") {
  // Flatten the schema
  const flattened = schema.map((item) => {
    // Destructure the item to separate record_detail_config and the rest of the fields
    const { record_detail_config, ...rest } = item;

    // Extract the required config or default if not provided
    const selectedConfig = record_detail_config[config] || record_detail_config["default"];

    // Merge the selected config fields into the rest of the item fields
    return {
      ...rest,
      ...selectedConfig,
    };
  });

  // Sort the flattened array based on record_detail_group_order
  flattened.sort((a, b) => (a.record_detail_group_order || 0) - (b.record_detail_group_order || 0));

  // Return the sorted array
  return flattened;
}

export function validateField(fieldName, value) {
  if (!value) return false;

  const validationRules = {
    phone: (value) => /^\(\d{3}\) \d{3}-\d{4}$/.test(value),
    ssn: (value) => /^\d{3}-\d{2}-\d{4}$/.test(value),
    email: (value) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value),
    ein: (value) => /^\d{2}-\d{7}$/.test(value),
    amount: (value) => /^\d{1,3}(,\d{3})*$/.test(value),
    date_of_birth: (value) => {
      const dob = new Date(value);
      const today = new Date();

      let age = today.getFullYear() - dob.getFullYear();
      const monthDifference = today.getMonth() - dob.getMonth();
      const dayDifference = today.getDate() - dob.getDate();

      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
      }

      return age >= 18;
    },
  };

  const validationRule = validationRules[fieldName];
  if (validationRule) {
    return validationRule(value);
  }

  return true;
}

export function fillMissingMonths(data) {
  const result = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  for (let i = 0; i < data.length - 1; i++) {
    let current = data[i];
    let next = data[i + 1];

    // Add the first object to the result
    if (i === 0) {
      //result.push(current);
    }

    let currentYear = current.year;
    let currentMonth = parseInt(current.month, 10);

    // Generate missing months between current and next
    while (currentYear < next.year || (currentYear === next.year && currentMonth < next.month)) {
      result.push({
        runningTotal: current.runningTotal,
        year: currentYear,
        month: currentMonth < 10 ? "0" + currentMonth : currentMonth.toString(), // Format month with leading zero if necessary
      });

      currentMonth++;
      if (currentMonth > 12) {
        currentMonth = 1;
        currentYear++;
      }
    }
  }

  // Add the last object to the result
  result.push(data[data.length - 1]);

  // Extend to the current month
  let last = result[result.length - 1];

  let lastYear = last.year;
  let lastMonth = parseInt(last.month, 10);

  while (lastYear < currentYear || (lastYear === currentYear && lastMonth < currentMonth)) {
    lastMonth++;
    if (lastMonth > 12) {
      lastMonth = 1;
      lastYear++;
    }
    result.push({
      runningTotal: last.runningTotal,
      year: lastYear,
      month: lastMonth < 10 ? "0" + lastMonth : lastMonth.toString(), // Format month with leading zero if necessary
    });
  }

  return result;
}

export function decryptField(field) {
  if (!field) {
    return "";
  }
  let decodedBase64 = atob(field);
  return decodedBase64;
}

export function encryptField(field) {
  if (!field) {
    return "";
  }
  let base64 = btoa(field);
  return base64;
}
