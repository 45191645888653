import { createRouter, createWebHistory } from "vue-router";
import {
  Signin,
  Signup,
  SetPassword,
  Acknowledgements,
  Questionnaire,
  MFA,
  Dashboard,
  InvestmentDetails,
  MaintenanceScreen,
  ForgotPassword,
  ChangePassword,
  InvestmentFlow,
  InvestorProfile,
  InvestorDocuments,
  InvestmentSucess,
} from "@/pages";
import { useAuthStore, useCrudStore } from "@/stores";

let routes = [];

if (import.meta.env.VITE_MAINTANENCE_MODE === "true") {
  routes = [
    {
      path: "/:pathMatch(.*)*",
      redirect: "/maintenance",
      meta: { requires_auth: false },
    },
    {
      path: "/maintenance",
      name: "MaintenanceScreen",
      component: MaintenanceScreen,
      meta: { requires_auth: false },
    },
  ];
} else {
  routes = [
    {
      path: "/:catchAll(.*)",
      redirect: "/portfolio",
    },
    {
      path: "/",
      redirect: "/portfolio",
    },
    {
      path: "/portfolio",
      name: "portfolio",
      component: Dashboard,
      meta: { requires_auth: true },
    },
    {
      path: "/investment-details",
      name: "investment-details",
      component: InvestmentDetails,
      meta: { requires_auth: true },
    },
    {
      path: "/make-an-investment",
      name: "make-an-investment",
      component: InvestmentFlow,
      meta: { requires_auth: true },
    },
    {
      path: "/thank-you",
      name: "thank-you",
      component: InvestmentSucess,
      meta: { requires_auth: true },
    },
    {
      path: "/profile",
      name: "profile",
      component: InvestorProfile,
      meta: { requires_auth: true },
    },
    {
      path: "/documents",
      name: "documents",
      component: InvestorDocuments,
      meta: { requires_auth: true },
    },
    {
      path: "/sign-in",
      name: "signin",
      component: Signin,
      meta: { requires_auth: false },
    },
    {
      path: "/sign-up",
      name: "signup",
      component: Signup,
      meta: { requires_auth: false },
    },
    {
      path: "/sign-up/set-password",
      name: "set-password",
      component: SetPassword,
      meta: { requires_auth: false },
    },
    {
      path: "/sign-up/acknowledgements",
      name: "acknowledgements",
      component: Acknowledgements,
      meta: { requires_auth: false },
    },
    {
      path: "/sign-up/questionnaire",
      name: "questionnaire",
      component: Questionnaire,
      meta: { requires_auth: true },
    },
    {
      path: "/sign-up/auth-verification",
      name: "signup-auth-verification",
      component: MFA,
      meta: { requires_auth: false },
    },
    {
      path: "/sign-in/auth-verification",
      name: "signin-auth-verification",
      component: MFA,
      meta: { requires_auth: false },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPassword,
      meta: { requires_auth: false },
    },
    {
      path: "/change-password",
      name: "change-password",
      component: ChangePassword,
      meta: { requires_auth: false },
    },
  ];
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Variable to store previous route
let previousRoute = null;

// Global beforeEach guard for previous route storing
router.beforeEach((to, from, next) => {
  previousRoute = from; // Capture the previous route
  next();
});

if (import.meta.env.VITE_MAINTENANCE !== "true") {
  router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();

    if (authStore.currentUser) {
      // Handle authenticated users trying to manually go to guest routes
      if (to.matched.some((record) => record.meta.requires_auth === false)) {
        next("/dashboard");
      } else {
        next();
      }
    } else {
      // Handle guest routes
      if (to.matched.some((record) => record.meta.requires_auth === false)) {
        next();
      } else {
        // Default case
        next("/sign-in");
      }
    }
  });
}

export default router;

export { previousRoute };
