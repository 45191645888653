<template>
  <div>
    <div class="flex items-center justify-between w-full mb-4 lg:pr-2 text-dark">
      <div class="flex gap-4 items-center w-full lg:mt-4">
        <div class="hidden md:block">
          <CompletedStepsCircle
            :currentStep="investmentStore.currentStep - 2"
            :totalSteps="investmentStore.investmentSteps.length"
          />
        </div>

        <div>
          <p class="text-sm text-lighter uppercase hidden md:block">New Investment</p>
          <p class="text-xl font-bold">
            {{ investmentStore.currentInvestment.type }},
            {{ investmentStore.selectedOffering.interest_rate }}%,
            {{ investmentStore.selectedOffering.bond_term / 12 }} Year{{
              investmentStore.selectedOffering.bond_term / 12 > 1 ? "s" : ""
            }}
          </p>
        </div>
      </div>

      <div class="lg:flex items-center justify-between w-full hidden">
        <div
          v-for="(step, index) in Array(investmentStore.investmentSteps.length).fill(0)"
          :key="index"
          @click="handleSetActiveStep(index + 3)"
          class="relative cursor-pointer"
          :class="index < investmentStore.investmentSteps.length - 1 ? 'w-full' : ''"
        >
          <div class="flex items-center">
            <div
              class="rounded-full bg-white border"
              :class="[
                index + 3 === investmentStore.currentStep
                  ? 'border-blue-500 border-2 min-h-4 min-w-4'
                  : index + 3 < investmentStore.currentStep
                    ? 'border-blue-500 !bg-blue-500 min-w-4 min-h-4 h-4 w-4'
                    : 'border-neutral-gray-6 border-2 h-2 w-2 min-w-2 min-h-2',
                { 'cursor-pointer': index + 3 < investmentStore.currentStep },
              ]"
            >
              <Icon
                v-if="index + 3 < investmentStore.currentStep"
                name="Check"
                :stroke-width="2"
                class="h-3.5 w-3.5 text-white"
              />
            </div>
            <div
              v-if="index < investmentStore.investmentSteps.length - 1"
              class="w-full border-b-2 h-1 mx-1"
              :class="
                index + 3 < investmentStore.currentStep
                  ? 'border-blue-500'
                  : 'border-neutral-gray-5'
              "
            ></div>
          </div>
          <p
            class="absolute whitespace-nowrap"
            :class="[
              index + 3 === investmentStore.currentStep ? 'font-bold top-7' : 'top-5 text-lighter',
              index === investmentStore.investmentSteps.length - 1 ? '-left-6' : '-left-3',
            ]"
          >
            Step {{ index + 1 }}
          </p>
        </div>
      </div>
      <!-- Mobile/Tablet Collapse Steps CTA -->
      <Button
        @click="isStepsCollapsed = !isStepsCollapsed"
        icon
        label
        is-icon-only-on-mobile
        size="small"
        variant="subtle"
        class="lg:hidden"
      >
        <template #icon>
          <Icon
            :name="!isStepsCollapsed ? 'ChevronUp' : 'ChevronDown'"
            class="h-4 w-4 min-h-4 min-w-4 text-primary-6"
          />
        </template>
        <template #label>
          <p class="mt-0.5 text-sm">{{ isStepsCollapsed ? "Expand" : "Collapse" }}</p></template
        >
      </Button>
    </div>
    <transition name="expand">
      <div
        v-if="!isStepsCollapsed"
        class="w-full flex flex-col items-start border border-neutral-gray-4 p-4 rounded-md gap-4 mb-6 lg:hidden"
      >
        <div
          v-for="(step, index) in investmentStore.investmentSteps"
          :key="index"
          @click="handleSetActiveStep(index + 3)"
          class="flex items-center gap-4 relative"
        >
          <div
            :class="investmentStore.currentStep === index + 3 ? 'font-semibold' : 'text-lighter'"
          >
            Step {{ index + 1 }} - {{ step }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { Icon, Button } from "@/components";
import { useInvestmentStore } from "@/stores";
import CompletedStepsCircle from "./CompletedStepsCircle.vue";

const investmentStore = useInvestmentStore();

const isStepsCollapsed = ref(false);

function handleSetActiveStep(step) {
  if (step < investmentStore.currentStep) {
    investmentStore.currentStep = step;
  }
}
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: all 0.3s ease-in-out;
}
.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}
.expand-enter-to,
.expand-leave-from {
  max-height: 500px;
  opacity: 1;
}
</style>
