<template>
  <div v-if="isLoading">
    <LoadingSpinner :show="isLoading" />
  </div>
  <DocumentPreviewModal
    :open="previewModalOpen"
    @close-modal="previewModalOpen = false"
    :file="selectedFile"
  />
  <UploadModal
    :open="isShowUploadModal"
    @close-modal="isShowUploadModal = false"
    @upload-docs="handleUpload"
    :categoryOptions="file_category_options"
  />
  <ConfirmDeleteModal
    :open="isShowDeleteModal"
    @close-modal="isShowDeleteModal = false"
    @confirm-delete="handleDelete"
    message="Are you sure you want to delete this file? The action cannot be undone."
  />
  <div v-if="investorStore.contact" class="w-full flex flex-col h-full">
    <div class="flex flex-col md:flex-row md:items-end gap-4 justify-between mb-4">
      <BaseInput
        type="select"
        v-model="fileCategoryFilter"
        size="large"
        label="Filter by category"
        showFullText
        :options="['All', ...file_category_options]"
        class="w-full md:!w-64"
      />
      <div class="flex items-center gap-2">
        <Button
          @click="isShowUploadModal = true"
          label
          icon
          variant="default-gray-outlined"
          class="w-full md:w-auto"
        >
          <template #icon>
            <Icon name="Upload" :stroke-width="2" class="text-primary-6 h-4 w-4" />
          </template>
          <template #label>Upload</template>
        </Button>
        <Button
          v-if="!isEditMode"
          @click="handleToggleEditMode"
          label
          icon
          variant="default-gray-outlined"
          class="w-full md:w-auto"
        >
          <template #icon>
            <Icon name="Pencil" :stroke-width="2" class="text-primary-6 h-4 w-4" />
          </template>
          <template #label>Edit</template>
        </Button>
        <div v-else class="flex items-center gap-2">
          <Button @click="handleToggleEditMode" label variant="default-gray-outlined">
            <template #label>Cancel</template>
          </Button>
          <Button @click="handleSaveDocuments" label icon>
            <template #icon>
              <Icon name="Save" :stroke-width="2" class="text-white h-4 w-4" />
            </template>
            <template #label>Save</template>
          </Button>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-between grow">
      <div>
        <Table
          @setSortHeader="(header, props) => setSortHeader(header, props)"
          :headers="tableHeaders"
          :tableData="documentsToShow"
          :fieldsWithCustomStyles="['file_category']"
          actions_always_sticky
          class="max-w-full"
        >
          <template v-slot:file_category="entry">
            <div>
              <BaseInput
                type="select"
                :disabled="!isEditMode"
                class="min-w-42"
                v-model="entry.row.file_category"
                :options="file_category_options"
                showFullText
              />
            </div>
          </template>
          <template v-slot:table-ctas="entry">
            <div class="flex items-center space-x-2">
              <Button
                @click="handlePreview(entry.entry)"
                icon
                size="small"
                variant="default-gray-outlined"
              >
                <template #icon>
                  <Icon name="Eye" class="h-4 w-4" :strokeWidth="2" />
                </template>
              </Button>
              <Button
                @click="downloadFile_R2(entry.entry)"
                icon
                size="small"
                variant="default-gray-outlined"
              >
                <template #icon>
                  <Icon name="Download" class="h-4 w-4" :strokeWidth="2" />
                </template>
              </Button>
              <Button
                @click="(selectedFileForDeletion = entry.entry), (isShowDeleteModal = true)"
                icon
                size="small"
                variant="default-gray-outlined"
              >
                <template #icon>
                  <Icon name="Trash" class="h-4 w-4 text-functional-error-default" />
                </template>
              </Button>
            </div>
          </template>
        </Table>
        <div v-if="!documentsToShow.length" class="flex flex-col items-center justify-center mt-8">
          <EmptyState
            :description="
              fileCategoryFilter === 'All'
                ? 'Upload Documents'
                : `No documents found in the category ${fileCategoryFilter}`
            "
            icon_name="Files"
          />
          <Button @click="isShowUploadModal = true" label icon variant="default-color-outlined">
            <template #icon>
              <Icon name="Upload" :stroke-width="2" class="text-primary-6 h-4 w-4" />
            </template>
            <template #label>Upload</template>
          </Button>
        </div>
      </div>
      <Pagination
        v-if="documentsToShow.length > 0"
        show-count
        :total-category-results="totalCategoryResults"
        :page-size="pageSize"
        :page-num="currentPage"
        @change-page="currentPage = $event"
        class="mt-8"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { cloneDeep, isEqual } from "lodash";

import { useInvestorStore, useUIStore, useCrudStore, useAuthStore } from "@/stores";
import {
  Button,
  Icon,
  DocumentPreviewModal,
  BaseInput,
  LoadingSpinner,
  Table,
  Pagination,
  ConfirmDeleteModal,
  EmptyState,
} from "@/components";
import useFileUtility from "@/composables/useFileUtility";
import UploadModal from "./UploadModal.vue";

const investorStore = useInvestorStore();
const UIStore = useUIStore();
const crudStore = useCrudStore();
const authStore = useAuthStore();

const { uploadFile_R2, downloadFile_R2, fetchFileForPreview_R2, deleteFile_R2 } = useFileUtility();

const previewModalOpen = ref(false);
const selectedFile = ref(null);
const selectedFileForDeletion = ref(null);
const isShowUploadModal = ref(false);
const isShowDeleteModal = ref(false);
const isEditMode = ref(false);
const isLoading = ref(false);
const fileCategoryFilter = ref("All");
const original_general_documents = ref([]);
const file_category_options = ref([]);
const currentPage = ref(1);
const pageSize = ref(10);

const tableHeaders = ref([
  { field_name: "created_date", label: "Created Date", field_type: "date" },
  { field_name: "file_category", label: "Category" },
  { field_name: "file_type", label: "File Type" },
  { field_name: "file_name", label: "Name" },
  { field_name: "actions", label: "Actions" },
]);

onMounted(async () => {
  window.scrollTo(0, 0);
  const fileCategorySchema = await crudStore.findOne("Schema", {
    collection_name: "Documents",
    field_name: "file_category",
  });
  file_category_options.value =
    fileCategorySchema?.record_detail_config?.default?.dropdown_options || [];
});

watch(
  () => investorStore.general_documents,
  () => {
    original_general_documents.value = cloneDeep(investorStore.general_documents);
  },
  { immediate: true }
);

const documentsToShow = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;

  if (fileCategoryFilter.value === "All") {
    return investorStore.general_documents.slice(start, end);
  } else {
    const filteredDocuments = investorStore.general_documents.filter(
      (f) => f.file_category === fileCategoryFilter.value
    );
    return filteredDocuments.slice(start, end);
  }
});

const totalCategoryResults = computed(() => {
  if (fileCategoryFilter.value === "All") {
    return investorStore.general_documents.length;
  } else {
    return investorStore.general_documents.filter(
      (f) => f.file_category === fileCategoryFilter.value
    ).length;
  }
});

async function handleSaveDocuments() {
  isEditMode.value = false;
  const updatedDocuments = investorStore.general_documents.filter((f) => {
    const originalDoc = original_general_documents.value.find(
      (ogf) => ogf._id?.toString() === f._id?.toString()
    );

    // If the document is new or has changes
    if (!originalDoc || !isEqual(originalDoc, f)) {
      // Check if file_category changed from "Accreditation Support" to something else
      if (
        originalDoc &&
        originalDoc.file_category === "Accreditation Support" &&
        f.file_category !== "Accreditation Support"
      ) {
        // Update the type field along with file_category
        f.type = "General Documents";
      } else if (
        originalDoc &&
        originalDoc.file_category !== "Accreditation Support" &&
        f.file_category === "Accreditation Support"
      ) {
        f.type = "Accreditation";
      }

      return true;
    }
    return false;
  });
  original_general_documents.value = cloneDeep(investorStore.general_documents);
  for (let doc of updatedDocuments) {
    await investorStore.updateGeneralDocument(doc);
  }
}

function handleToggleEditMode() {
  isEditMode.value = !isEditMode.value;
  if (!isEditMode.value) {
    investorStore.general_documents = cloneDeep(original_general_documents.value);
  }
}

async function handlePreview(file) {
  const fileUrl = await fetchFileForPreview_R2(file);
  selectedFile.value = { ...file, file_url: fileUrl };
  previewModalOpen.value = true;
}

async function handleDelete() {
  investorStore.general_documents = investorStore.general_documents.filter(
    (f) => f._id?.toString() !== selectedFileForDeletion.value?._id?.toString()
  );
  await deleteFile_R2(selectedFileForDeletion.value);
}

async function handleUpload(files, fileCategory) {
  try {
    isLoading.value = true;
    const newFiles = [];
    for (let file of files) {
      const fileDoc = await uploadFile_R2({
        file: file,
        type: fileCategory === "Accreditation Support" ? "Accreditation" : "General Documents",
        details: {
          file_category: fileCategory,
          visible_to_compliance: false,
          user_id: authStore.currentUser.id,
          contact_id: { $oid: investorStore.contact._id },
          account_id: { $oid: investorStore.contact.account_id },
        },
      });
      if (fileDoc) {
        newFiles.push(fileDoc);
      }
    }
    investorStore.general_documents = [...newFiles, ...investorStore.general_documents];
  } catch (err) {
    console.error(err);
    UIStore.animateNotificationAlert({
      type: "error",
      icon: "X",
      message: "Failed to upload file, please try again.",
    });
  } finally {
    isLoading.value = false;
  }
}

async function setSortHeader(header, props) {
  UIStore.setSortHeader(header, props);
  await investorStore.fetchGeneralDocuments();
  original_general_documents.value = cloneDeep(investorStore.general_documents);
}
</script>
