<template>
  <ConfirmDeleteModal
    :open="isShowConfirmCancelRolloverModal"
    @close-modal="isShowConfirmCancelRolloverModal = false"
    @confirm-delete="handleCancelRolloverInvestment"
    message="Are you sure you want to cancel this investment? This action cannot be undone."
    confirmText="Cancel Investment"
  />
  <DocumentPreviewModal
    :open="previewModalOpen"
    @close-modal="previewModalOpen = false"
    :file="previewDoc"
  />
  <EditBankDrawer
    :open="editBankDrawerOpen"
    @close-drawer="editBankDrawerOpen = false"
    @account-updated="updateBankAccounts"
    :account_type="editBankType"
    source="Investment"
  />
  <!-- RECORD DETAIL -->
  <div v-if="!page_is_loading" class="mb-8">
    <div class="flex flex-col px-3 sm:px-8 py-2">
      <!-- Icon Above -->
      <div
        class="flex items-center justify-center self-start justify-self-auto pt-2 pr-2 cursor-pointer"
        @click="router.push('/dashboard')"
      >
        <Icon name="ChevronLeft" class="h-4 w-4 m-1.5" />
        <span class="text-lighter mt-0.5">Back to {{ backTitle }}</span>
      </div>
      <Header class="mb-6" :header-nuggets="UIStore.headerNuggets">
        <template #header-icon>
          <!-- Icon to Side -->
          <!-- <div
            class="flex items-center justify-center self-start justify-self-auto py-2 pr-2 cursor-pointer"
            @click="router.push('/dashboard')"
          >
            <Icon name="ChevronLeft" class="h-5.5 w-5.5" />
          </div> -->
          <div class="p-1 rounded-lg bg-opacity-20 bg-functional-success-default">
            <IconInvestment class="h-6 w-6 fill-functional-success-default" />
          </div>
        </template>
        <template #header-title>
          <div class="flex flex-row items-center uppercase font-bold text-2xl gap-3">
            <span>INVESTMENT DETAILS</span>
            <div
              v-if="
                investmentStore.investmentDetails.able_to_rollover &&
                investmentStore.investmentDetails.status !== 'Bonds Matured'
              "
              class="bg-opacity-20 bg-functional-warning-default text-functional-warning-hover rounded-2xl py-0.5 px-2 text-sm capitalize font-normal"
            >
              Rollover Available
            </div>
          </div>
        </template>
        <template #header-ctas> </template>
      </Header>

      <!-- TABS -->
      <div class="flex mb-4">
        <div
          v-for="tab in availableTabs"
          @click="activeTab = tab.name"
          :key="tab"
          class="border-b-3 p-3 text-xs cursor-pointer uppercase font-semibold flex gap-2 tracking-wide items-center justify-center"
          :class="[
            activeTab == tab.name
              ? 'text-primary-6 border-functional-warning-default bg-secondary-6 bg-opacity-20'
              : 'text-primary-2 border-platform-outlines hover:bg-gray-100',
          ]"
        >
          <Icon
            v-if="tab.is_lucide"
            :name="tab.icon"
            class="h-5.5 w-5.5"
            :class="[activeTab == tab.name ? 'stroke-primary-6' : 'stroke-primary-2']"
          />
          <component
            v-else
            :is="iconMapping[tab.icon]"
            class="h-5.5 w-5.5"
            :class="[activeTab == tab.name ? 'fill-primary-6' : 'fill-primary-2']"
          />
          <span>{{ tab.name }}</span>
        </div>
      </div>

      <!----- TAB CONTENT ----->
      <!-- Investment Info -->
      <div v-if="activeTab === 'Investment Info'" class="flex flex-col gap-4">
        <div class="w-full flex gap-12 flex-wrap">
          <div>
            <div class="text-xs text-lighter uppercase">Amount</div>
            <div class="text-xl text-primary-6 font-semibold">
              ${{ commaSeparateThousands(investmentStore.investmentDetails.amount) }}
            </div>
          </div>
          <div>
            <div class="text-xs text-lighter uppercase">Bonds</div>
            <div class="text-xl text-primary-6 font-semibold">
              {{ investmentStore.investmentDetails.bonds }}
            </div>
          </div>
          <div>
            <div class="text-xs text-lighter uppercase">Interest Rate</div>
            <div class="text-xl text-primary-6 font-bold">
              {{ offering.interest_rate ? offering.interest_rate + "%" : "-" }}
            </div>
          </div>
          <div>
            <div class="text-xs text-lighter uppercase">Status</div>
            <div
              class="bg-opacity-10 rounded-xl py-1 px-3 w-fit flex items-center justify-center text-sm"
              :class="
                investmentStore.investmentDetails.status == 'Created'
                  ? 'bg-custom-light-blue text-custom-light-blue'
                  : investmentStore.investmentDetails.status == 'Settled'
                    ? 'bg-custom-lime-green text-custom-lime-green'
                    : 'bg-functional-warning-default text-functional-warning-hover'
              "
            >
              {{ investmentStore.investmentDetails.status }}
            </div>
          </div>
          <div>
            <div class="text-xs text-lighter uppercase">Maturity Date</div>
            <div class="text-xl text-primary-6 font-semibold">
              {{
                investmentStore.investmentDetails?.maturity_date
                  ? new Date(investmentStore.investmentDetails?.maturity_date)?.toLocaleDateString(
                      "en-us",
                      { timeZone: "UTC" }
                    )
                  : "-"
              }}
            </div>
          </div>
          <div
            v-if="
              investmentStore.investmentDetails.type === 'Entity' ||
              investmentStore.investmentDetails?.type === 'Joint'
            "
          >
            <div class="text-xs text-lighter uppercase">
              {{
                investmentStore.investmentDetails.type === "Entity"
                  ? "Associated Entity"
                  : "Associated Joint Contact"
              }}
            </div>
            <div class="text-xl text-primary-6 font-semibold">
              {{ getAssociatedNameForEntityJointInvestment(investmentStore.investmentDetails) }}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 w-full gap-4">
          <div class="flex flex-col grow gap-4">
            <!-- TODO: Achieve filtering down in the data model instead. -->
            <div
              v-if="investmentStore.fetchedAccordionData"
              v-for="section of investmentStore.fetchedAccordionData.filter(
                (section) => section.name == 'Details'
              )"
              class="rounded-lg border-platform-outlines border px-4 py-4"
            >
              <div class="text-dark font-bold text-lg flex items-center w-full">
                <div class="grow">{{ section.name }}</div>
                <div class="grow flex justify-end">
                  <IconChevronUp
                    class="h-5.5 w-5.5 cursor-pointer"
                    @click="section.collapsed = !section.collapsed"
                    :class="section.collapsed ? 'rotate-180' : 'rotate-0'"
                  />
                </div>
              </div>
              <!-- TODO: better solution for accordion collapsing transition -->
              <div
                class="grid grid-cols-1 md:grid-cols-2 gap-4 transition-all duration-300 overflow-hidden"
                :class="section.collapsed ? 'max-h-0' : 'max-h-[200vh]'"
              >
                <!-- TODO: Achieve filtering down in the data model instead. -->
                <div
                  v-for="field of section.fields.filter(
                    (field) =>
                      !['payment_method', 'cancelled_date', 'funded_date'].includes(
                        field.field_name
                      )
                  )"
                  class="flex flex-col gap-0.5 border-platform-outlines border-b py-1"
                >
                  <span class="text-sm text-lighter truncate">{{ field.name }}</span>
                  <span v-if="field.v_model" class="truncate">{{ field.v_model }}</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col grow gap-4">
            <!-- Sub -->
            <div class="rounded-lg border-platform-outlines border px-4 py-4">
              <div class="text-dark font-bold text-lg mb-2 flex items-center">
                <div class="grow">Sub Agreement</div>
                <IconChevronUp
                  class="h-5.5 w-5.5 cursor-pointer"
                  @click="subAgreementCollapsed = !subAgreementCollapsed"
                  :class="subAgreementCollapsed ? 'rotate-180' : 'rotate-0'"
                />
              </div>
              <div
                class="grid grid-cols-1 gap-2 transition-all duration-300 overflow-hidden"
                :class="[
                  subAgreementCollapsed ? 'max-h-0' : 'max-h-[200vh]',
                  subAgreements.length == 0 ? 'sm:grid-cols-1' : 'sm:grid-cols-2',
                ]"
              >
                <div v-if="subAgreements.length == 0">
                  <div
                    class="col-start-1 bg-neutral-gray-6 bg-opacity-20 rounded-md items-center flex px-2 gap-2 h-8 w-full"
                  >
                    <IconAttachment class="w-6 h-6" />
                    <span class="text-sm lg:text-base text-nowrap">No Subscription Agreement</span>
                  </div>
                </div>
                <div
                  v-else
                  v-for="sub of subAgreements"
                  class="col-start-1 bg-custom-light-blue bg-opacity-20 rounded-md items-center flex p-2 gap-2 h-8 w-full"
                >
                  <IconAttachment class="w-6 h-6" />
                  <div
                    class="underline text-sm w-full overflow-hidden truncate"
                    @click="previewSubAgreement(sub)"
                  >
                    <span class="cursor-pointer">{{ sub.file_name }}</span>
                  </div>
                  <Icon
                    :name="download_processing ? 'Loader' : 'Download'"
                    class="justify-self-end cursor-pointer w-6 h-6"
                    @click="downloadSubAgreement(sub)"
                  />
                  <!-- <IconFileDownload
                    class="justify-self-end cursor-pointer w-6 h-6"
                    @click="downloadSubAgreement(sub)"
                  /> -->
                </div>
                <!-- <div
                  class="col-start-1 sm:col-start-2 sm:row-start-1 sm:justify-self-end row-span-2"
                >
                  <Button
                    variant="default-gray-outlined"
                    :is-icon-only-on-mobile="true"
                    icon
                    label
                    class="w-full"
                    size="large"
                  >
                    <template #icon> <IconRefresh class="h-4 w-4" /> </template>
                    <template #label> Replace Sub Agreement </template>
                  </Button>
                </div> -->
              </div>
            </div>
            <!-- Offering -->
            <div class="rounded-lg border-platform-outlines border px-4 py-4">
              <div class="text-dark font-bold text-lg flex items-center w-full">
                <div class="grow">Offering</div>
                <div class="grow flex justify-end">
                  <IconChevronUp
                    class="h-5.5 w-5.5 cursor-pointer"
                    @click="offeringCollapsed = !offeringCollapsed"
                    :class="offeringCollapsed ? 'rotate-180' : 'rotate-0'"
                  />
                </div>
              </div>
              <div
                class="grid grid-cols-1 md:grid-cols-2 gap-4 transition-all duration-300 overflow-hidden"
                :class="offeringCollapsed ? 'max-h-0' : 'max-h-[200vh]'"
              >
                <div class="flex flex-col gap-0.5 border-platform-outlines border-b py-1">
                  <span class="text-sm text-lighter truncate"> OFFERING </span>
                  <span v-if="offering.type == 'Reg A'" class="truncate">{{ offering.type }}</span>
                  <span v-else-if="offering.type && offering.name" class="truncate">{{
                    offering.type +
                    ` ${offering.name.includes("Series") ? "" : "Series "}` +
                    offering.name
                  }}</span>
                  <span v-else>-</span>
                </div>
                <div class="flex flex-col gap-0.5 border-platform-outlines border-b py-1">
                  <span class="text-sm text-lighter truncate"> TERM </span>
                  <span v-if="offering.bond_term" class="truncate">{{
                    monthsToYears(offering.bond_term)
                  }}</span>
                  <span v-else>-</span>
                </div>
                <div class="flex flex-col gap-0.5 border-platform-outlines border-b py-1">
                  <span class="text-sm text-lighter truncate"> INTEREST RATE </span>
                  <span v-if="offering.interest_rate" class="truncate"
                    >{{ offering.interest_rate }}%</span
                  >
                  <span v-else>-</span>
                </div>
                <div
                  v-if="investmentStore.fetchedAccordionData"
                  v-for="field of investmentStore.fetchedAccordionData
                    .find((section) => section.name == 'Offering')
                    ?.fields.filter((field) => field.field_name != 'offering_id')"
                  class="flex flex-col gap-0.5 border-platform-outlines border-b py-1"
                >
                  <span class="text-sm text-lighter truncate">{{ field.name }}</span>
                  <span v-if="field.v_model" class="truncate">{{ field.v_model }}</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="activeTab == 'Earnings'"><InvestmentsEarningTab /></div>
      <div v-else-if="activeTab == 'Banking Info'">
        <InvestmentBankingTab :banks="banks" @open-bank-drawer="handleOpenBankDrawer" />
      </div>
      <div v-else-if="activeTab === 'Rollover'" class="flex flex-col mt-6">
        <p class="font-medium text-primary-4">
          This investment is nearing its maturity date, and any available funds can be reinvested.
          Any amount not invested will be distributed at maturity.
        </p>
        <div class="flex items-start justify-between mt-6 gap-4">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            <div>
              <p class="uppercase text-xs text-primary-2">Available Rollover</p>
              <p class="text-custom-1 font-bold">
                ${{ commaSeparateThousands(investmentStore.investmentDetails.rollover_available) }}
              </p>
            </div>
            <div>
              <p class="uppercase text-xs text-primary-2">Amount at maturity</p>
              <p class="text-custom-1 font-bold">
                ${{ commaSeparateThousands(investmentStore.investmentDetails.amount_at_maturity) }}
              </p>
            </div>
            <div>
              <p class="uppercase text-xs text-primary-2">Time Until Maturity</p>
              <p class="text-custom-1 font-bold">{{ daysUntilMaturity }}</p>
            </div>
            <div>
              <p class="uppercase text-xs text-primary-2">Amount Reinvested</p>
              <p class="text-custom-1 font-bold">
                ${{ commaSeparateThousands(investmentStore.investmentDetails.amount_reinvested) }}
              </p>
            </div>
          </div>
          <Button @click="handleReinvest" label size="large">
            <template #label>Reinvest</template>
          </Button>
        </div>
        <div class="border-b border-platform-outlines w-full my-4"></div>
        <!-- Rollover Investments Table -->
        <div
          v-if="filteredRolloverInvestments.length > 0"
          class="p-4 rounded-md border border-platform-outlines transition-all duration-300 overflow-hidden"
          :class="!isRolloverExpanded ? 'max-h-14' : 'max-h-[200vh]'"
        >
          <div
            class="flex justify-between cursor-pointer"
            @click="isRolloverExpanded = !isRolloverExpanded"
          >
            <p class="font-bold">Rollovers</p>
            <Icon
              :name="isRolloverExpanded ? 'ChevronUp' : 'ChevronDown'"
              class="text-primary-6 h-6 w-6"
              :stroke-width="2"
            />
          </div>
          <Table
            :tableData="filteredRolloverInvestments"
            :headers="rollover_table_headers"
            :rounded_bottom="false"
            class="w-full overflow-auto mt-4"
            :fieldsWithCustomStyles="['status', '_id', 'details']"
            selectAllIsDisabled
            actions_always_sticky
          >
            <template v-slot:status="data">
              <div class="ml-4 truncate">
                <div
                  class="bg-opacity-10 rounded-xl py-1 px-2 w-fit flex items-center justify-center"
                  :class="
                    data.row.status == 'Created'
                      ? 'bg-custom-light-blue text-custom-light-blue'
                      : data.row.status == 'Settled'
                        ? 'bg-custom-lime-green text-custom-lime-green'
                        : data.row.status === 'Canceled'
                          ? 'bg-functional-danger-default text-functional-danger-hover'
                          : 'bg-functional-warning-default text-functional-warning-hover'
                  "
                >
                  {{ data.row.status }}
                </div>
              </div>
            </template>
            <template v-slot:_id="data">
              <div class="ml-4 truncate text-gold text-sm">
                <RouterLink :to="`/investment-details?investmentId=${data.row._id}`">
                  {{ data.row._id }}
                </RouterLink>
              </div>
            </template>
            <template v-slot:details="data">
              <a
                :href="`/investment-details?investmentId=${data.row._id}`"
                class="text-sm font-medium text-primary-6 p-4 underline cursor-pointer"
              >
                View
              </a>
            </template>
            <template v-slot:table-ctas="data">
              <Button
                @click="
                  (isShowConfirmCancelRolloverModal = true),
                    (selectedRolloverForCancelation = data.entry)
                "
                variant="subtle"
                size="small"
                icon
              >
                <template #icon>
                  <Icon
                    name="Trash"
                    :stroke-width="2.5"
                    class="text-functional-error-default h-4 w-4"
                  />
                </template>
              </Button>
            </template>
          </Table>
        </div>
        <div v-else>
          <EmptyState
            :description="`This investment currently has no rollover child investments.`"
            icon_name="RedoDot"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- LOADING -->
  <div v-else-if="page_is_loading">
    <LoadingSpinner :show="true" message="Loading..." :hide-overlay="true" />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { previousRoute } from "@/router";
import { ObjectID } from "bson";

import { useCrudStore, useInvestmentStore, useUIStore, useInvestorStore } from "@/stores";
import {
  Button,
  LoadingSpinner,
  Header,
  Modal,
  Table,
  Icon,
  ConfirmDeleteModal,
  EditBankDrawer,
  DocumentPreviewModal,
  EmptyState,
} from "@/components";
import {
  IconChevronUp,
  IconInvestment,
  IconAttachment,
  IconFileDownload,
} from "@/components/icons";
import { iconMapping } from "@/constants/iconMapping";
import { commaSeparateThousands, monthsToYears, commaSeparateThousands_2 } from "@/utilities";
import useFileUtility from "@/composables/useFileUtility";
import InvestmentsEarningTab from "./InvestmentsEarningTab.vue";
import InvestmentBankingTab from "./InvestmentsBankingTab.vue";

const {
  downloadFile_R2,
  getFile_R2,
  fetchFileForPreview_R2,
  uploadFile_R2,
  deleteFile_R2,
  parseCSV,
} = useFileUtility();

const crudStore = useCrudStore();
const investmentStore = useInvestmentStore();
const UIStore = useUIStore();
const investorStore = useInvestorStore();

const route = useRoute();
const router = useRouter();

const activeTab = ref("Investment Info");
const subAgreementCollapsed = ref(false);
const offeringCollapsed = ref(false);

const previewDoc = ref(false);
const previewModalOpen = ref(false);
const download_processing = ref(false);

const subAgreements = ref([]);
const offering = ref([]);
const banks = ref({ funding_account: {}, distribution_account: {} });
const page_is_loading = ref(false);
const investment_modal_open = ref(false);

const editBankDrawerOpen = ref(false);
const editBankType = ref(null);

const isRolloverExpanded = ref(true);
const isShowConfirmCancelRolloverModal = ref(false);
const selectedRolloverForCancelation = ref(null);

const rollover_table_headers = ref([
  { field_name: "details", label: "Details", field_type: "", number_type: "" },
  { field_name: "signature_date", label: "Transaction Date", field_type: "date" },
  { field_name: "_id", label: "Investment ID" },
  { field_name: "type", label: "Type" },
  { field_name: "status", label: "Status" },
  { field_name: "amount", label: "Amount", field_type: "number", number_type: "currency" },
  { field_name: "bonds", label: "Bonds", field_type: "number" },
  { field_name: "interest_rate", label: "Rate", field_type: "number", number_type: "percentage" },
  {
    field_name: "inception_to_date_interest_paid",
    label: "ITD Earned",
    field_type: "number",
    number_type: "currency",
  },
  { field_name: "actions", label: "Actions" },
]);

const filteredRolloverInvestments = computed(() => {
  const rolloverInvestments = investmentStore.transactionsData.filter((i) => {
    return (
      i.parent_investment &&
      i.parent_investment?.toString() === investmentStore.investmentDetails?._id?.toString() &&
      i.status !== "Canceled"
    );
  });
  return rolloverInvestments;
});

const availableTabs = computed(() => {
  // default tabs
  const tabs = [
    { name: "Investment Info", icon: "IconInvestment", is_lucide: false },
    { name: "Earnings", icon: "HandCoins", is_lucide: true },
    { name: "Banking Info", icon: "Landmark", is_lucide: true },
  ];
  // declare conditinal tabs
  const rollover_tab = { name: "Rollover", icon: "RedoDot", is_lucide: true };
  // const title_transfer_tab = { name: "Transfer Details", icon: "Handshake", is_lucide: true };
  // assign rollover if available rollover
  if (
    investmentStore.investmentDetails.status !== "Bonds Matured" &&
    investmentStore.investmentDetails?.able_to_rollover
  ) {
    tabs.push(rollover_tab);
  }

  return tabs;
});

const backTitle = computed(() => {
  return previousRoute?.path && previousRoute?.path != "/"
    ? previousRoute.path.replace(/^\//, "").replace(/^\w/, (c) => c.toUpperCase())
    : "Dashboard";
});

const daysUntilMaturity = computed(() => {
  if (!investmentStore.investmentDetails.maturity_date) return "N/A";
  const maturityDate = new Date(investmentStore.investmentDetails.maturity_date);
  const currentDate = new Date();

  const differenceInTime = maturityDate - currentDate;
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

  return differenceInDays > 0 ? `${differenceInDays} Days` : "Matured";
});

onMounted(async () => {
  // window.addEventListener("click", handleClickOutside);
  await onMountedFunction();
});

onBeforeUnmount(() => {
  // window.removeEventListener("click", handleClickOutside);
});

watch(
  () => route.query.investmentId,
  async () => {
    //console.log(route, route.query.recordId);
    if (route.fullPath.includes("/investments")) {
      //console.log("running onMounted");
      await onMountedFunction();
    }
  }
);

watch(
  () => investmentStore.updateTic,
  async (newValue) => {
    let current_tab = activeTab.value;
    await onMountedFunction();
    activeTab.value = current_tab;
  }
);

const handleClickOutside = (event) => {
  // console.log("click outside");
  // if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
  //   dropdownOpen.value = false;
  // }
};

async function onMountedFunction() {
  page_is_loading.value = true;
  investmentStore.currentPageCollection = "Investments";
  await investmentStore.setCurrentPageConfig();

  if (route.query.investmentId) {
    let investmentId = route.query.investmentId;
    investmentStore.investmentDetails = await crudStore.findOne("Investments", {
      _id: investmentId,
    });

    const all_promises = [
      investmentStore.setDistributionsForSingleInvestment(investmentStore.investmentDetails._id),
      investmentStore.setNuggets("Investments"),
      investmentStore.setData("Investments", investmentStore.investmentDetails._id),
      investmentStore.fetchSubAgreements(investmentStore.investmentDetails._id),
      investmentStore.fetchOffering(investmentStore.investmentDetails.offering_id),
      investmentStore.fetchBanks(
        investmentStore.investmentDetails.funding_account,
        investmentStore.investmentDetails.distribution_account
      ),
    ];

    const [_, __, ___, subAgreementsResult, offeringResult, banksResult] =
      await Promise.all(all_promises);

    subAgreements.value = subAgreementsResult;
    offering.value = offeringResult;
    banks.value = banksResult;

    // await investmentStore.setNuggets("Investments");
    // await investmentStore.setData("Investments", investmentStore.investmentDetails._id);
    // subAgreements.value = await investmentStore.fetchSubAgreements(
    //   investmentStore.investmentDetails._id
    // );
    // offering.value = await investmentStore.fetchOffering(
    //   investmentStore.investmentDetails.offering_id
    // );
  } else {
    //This will be the logic for no investmentId
    router.push("/portfolio");
  }
  page_is_loading.value = false;
}

function openInvestmentModal() {
  investment_modal_open.value = true;
}

async function downloadSubAgreement(entry) {
  download_processing.value = true;
  await downloadFile_R2(entry);
  download_processing.value = false;
}

async function previewSubAgreement(entry) {
  const fileUrl = await fetchFileForPreview_R2(entry);
  previewDoc.value = { entry, file_url: fileUrl };
  previewModalOpen.value = true;
}

async function handleCancelRolloverInvestment() {
  const id = selectedRolloverForCancelation.value._id;
  selectedRolloverForCancelation.value = null;
  investmentStore.transactionsData = investmentStore.transactionsData.filter((i) => i._id !== id);
  await investmentStore.cancelRolloverInvestment(ObjectID(id));
  investmentStore.investmentDetails = await crudStore.findOne("Investments", {
    _id: investmentStore.investmentDetails._id,
  });
}

function handleReinvest() {
  investmentStore.selected_rollover_investment = investmentStore.investmentDetails;
  investmentStore.currentInvestment.amount = commaSeparateThousands_2(
    investmentStore.selected_rollover_investment.rollover_available
  );
  investmentStore.currentInvestment.payment_method = "Existing Investment";
  router.push("/make-an-investment");
}

async function handleOpenBankDrawer(type) {
  UIStore.isDrawerLoading = true;
  editBankType.value = type;
  editBankDrawerOpen.value = true;
  if (type == "Funding") {
    investorStore.active_bank_account = banks.value.funding_account;
    investorStore.active_finance_method = investmentStore.investmentDetails.payment_method;
  } else if (type == "Distribution") {
    investorStore.active_bank_account = banks.value.distrubution_account;
    investorStore.active_finance_method = investmentStore.investmentDetails.distribution_method;
  }

  await investorStore.fetchBankAccounts(investmentStore.investmentDetails.account_id);
  UIStore.isDrawerLoading = false;
}

function getAssociatedNameForEntityJointInvestment(investment) {
  if (investment.type === "Entity") {
    const entity = investorStore.entity_accounts.find(
      (entity) => entity?._id?.toString() === investment.entity_account_id?.toString()
    );
    return entity?.name;
  } else if (investment.type === "Joint") {
    const joint_contact = investorStore.joint_contacts.find(
      (c) => c?._id?.toString() === investment.joint_contact_id?.toString()
    );
    return `${joint_contact?.first_name} ${joint_contact?.last_name}`;
  }
}
</script>
