<template>
  <div class="relative bg-white h-screen flex flex-col">
    <div
      v-if="!isMaintenanceMode"
      class="fixed top-0 z-50 w-full"
      :class="{ 'lg:hidden': route.path === '/sign-in' || route.path === '/sign-up' }"
    >
      <TopNavigation />
    </div>
    <main
      class="pt-14 grow w-full"
      :class="[
        { 'lg:pt-0': route.path === '/sign-in' || route.path === '/sign-up' },
        {
          '': isAuthenticatedRoute && route.path !== '/sign-up/questionnaire',
        },
        {
          'pb-value-31 md:pb-0':
            isAuthenticatedRoute &&
            route.path !== '/make-an-investment' &&
            route.path !== '/sign-up/questionnaire' &&
            route.path !== '/thank-you',
        },
      ]"
    >
      <div
        class="fixed right-0 top-16 z-50 flex w-full flex-col gap-2 px-2 md:w-auto lg:mr-2 lg:px-0"
        :class="route.path === '/sign-in' || route.path === '/sign-up' ? 'top-4' : 'top-16'"
      >
        <Notification
          v-for="alert in UIStore.alertList"
          :key="alert.id"
          :alert="alert"
          @close-alert="UIStore.removeNotification(alert.id)"
        />
      </div>
      <router-view />
      <!-- Fixed bottom make investment CTA -->
      <div
        v-if="
          isAuthenticatedRoute &&
          route.path !== '/make-an-investment' &&
          route.path !== '/sign-up/questionnaire' &&
          route.path !== '/thank-you'
        "
        class="md:hidden fixed bottom-0 w-full bg-white p-4 h-value-31 z-50 flex items-center justify-center"
      >
        <Button @click="router.push('/make-an-investment')" size="large" label class="w-full">
          <template #label>Make an Investment</template>
        </Button>
      </div>
    </main>
  </div>
</template>

<script setup>
import { onMounted, watch, computed } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";

import { useUIStore, useAuthStore } from "@/stores";
import { TopNavigation, Notification, Button } from "@/components";

const route = useRoute();
const router = useRouter();

const UIStore = useUIStore();
const authStore = useAuthStore();

const isMaintenanceMode = import.meta.env.VITE_MAINTANENCE_MODE == "true";
const isAuthenticatedRoute = computed(() => route.meta.requires_auth && authStore.is_verified);

onMounted(async () => {
  try {
    if (localStorage.getItem("currentUser")) {
      authStore.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      authStore.is_verified = true;
      await authStore.initializeData();
    } else {
      authStore.is_verified = false;
      authStore.currentUser = null;
    }
    UIStore.setupAlertWatcher();
  } catch (err) {
    console.error(err);
  }
});

watch(
  () => authStore.invalidSession,
  async function () {
    if (authStore.invalidSession) {
      window.localStorage.clear();
      authStore.currentUser = null;
      router.push("/sign-in");
    }
  }
);
</script>
