<template>
  <div>
    <Teleport to="body">
      <AddEntityJointSideDrawer
        @close-drawer="isSideDrawerOpen = false"
        @handle-save="handleAddNewEntity"
        :open="isSideDrawerOpen"
        :record="newEntityTemplate"
        type="Entity"
      />
    </Teleport>
    <AddBeneficiaryModal
      :open="isBeneficiaryModalOpen"
      @close-modal="isBeneficiaryModalOpen = false"
      @handle-add-beneficiary="initializeOwnerBeneficialState"
      fromProfile
    />
    <ConfirmDeleteModal
      :open="isShowDeleteModal"
      @close-modal="isShowDeleteModal = false"
      @confirm-delete="handleDeleteEntity"
      message="Are you sure you want to delete this entity? This cannot be undone."
    />
    <div
      v-if="investorStore.active_profile_tab === 'Entity'"
      class="w-full flex flex-col justify-between h-full text-primary-6"
    >
      <div class="flex items-center justify-between mb-10">
        <p class="font-bold text-xl">Entity Accounts</p>
        <Button
          @click="
            (isSideDrawerOpen = true),
              (investorStore.supressErrors = false),
              (investorStore.active_entity_account = newEntityTemplate)
          "
          label
          variant="default-gray-outlined"
        >
          <template #label>Add Entity Account</template>
        </Button>
      </div>
      <div class="flex flex-col gap-6">
        <div
          v-for="(entity, entityIndex) in investorStore.entity_accounts"
          :key="entityIndex"
          class="border border-platform-outlines rounded-md flex flex-col items-center p-4 transition-all duration-500 overflow-hidden"
          :class="expandedEntities[entityIndex] ? 'max-h-[200vh]' : 'max-h-14'"
        >
          <div
            class="flex justify-between items-center w-full cursor-pointer"
            @click="toggleExpand(entityIndex)"
          >
            <div class="flex items-center gap-2">
              <p class="font-bold">{{ entity.name }}</p>
              <p class="text-lighter text-sm">
                {{ getEntitiesSignedInvestmentCount(entity) }} signed investment<span>
                  {{ getEntitiesSignedInvestmentCount(entity) !== 1 ? "s" : "" }}</span
                >
              </p>
            </div>
            <Icon
              :name="expandedEntities[entityIndex] ? 'ChevronUp' : 'ChevronDown'"
              class="text-primary-6 h-6 w-6"
              :stroke-width="2"
            />
          </div>
          <transition name="expand-transition" @enter="enter">
            <div v-if="expandedEntities[entityIndex]" class="flex flex-col w-full mt-6">
              <template v-for="(schema, index) of schemaToUse" :key="index">
                <Form
                  :fields="schema.fields"
                  :record="entity"
                  :header="schema.group_name"
                  :subHeader="
                    index === 0
                      ? 'Please provide information about your entity.'
                      : index === 1
                        ? 'Phoenix requires a physical address. PO Boxes and mail drops are not valid. If you use a PO Box for receiving mail, please reach out to Investor Relations at (303) 376-9778 to have your account updated.'
                        : ''
                  "
                  :isDisabled="!editModeState[`${index}${entityIndex}`]"
                  :isFormValid="isFormValid(entityIndex)"
                  @toggleEditMode="toggleEditMode(`${index}${entityIndex}`, entityIndex)"
                  @handleSave="handleSave(`${index}${entityIndex}`, entityIndex)"
                  fromProfile
                  isSmall
                  type="entity"
                />
                <div class="border-b border-neutral-gray-4 w-full my-4 md:my-8"></div>
              </template>
              <div class="flex flex-col gap-2">
                <div class="flex items-center justify-between">
                  <p class="font-bold text-light">Beneficial Owners or Beneficiaries</p>
                  <!-- <label for="owner_is_beneficiary" class="text-lighter inline-flex">
                    <input
                      id="owner_is_beneficiary"
                      type="checkbox"
                      v-model="isOwnerBeneficial[entityIndex]"
                      :disabled="!editModeState[`${index}${entityIndex}`]"
                      class="text-primary-6 accent-current mr-2"
                    />
                    <p class="mt-1">I am a beneficial owner or beneficiary</p>
                  </label> -->
                  <Button
                    @click="handleAddNewBeneficialOwner(entity)"
                    label
                    icon
                    variant="default-gray-outlined"
                    class="px-4"
                    :disabled="getEntitiesSettledInvestmentCount(entity) > 0"
                  >
                    <template #icon>
                      <Icon name="UserPlus" :stroke-width="2" class="h-4 w-4" />
                    </template>
                    <template #label>
                      <p class="mt-0.5">Add Beneficial Owner</p>
                    </template>
                  </Button>
                </div>
                <div
                  v-for="(beneficial_owner, beneficialOwnerIndex) in beneficialOwnersByEntity[
                    entity._id
                  ]"
                  :key="beneficialOwnerIndex"
                  class="h-value-40 w-full flex items-center justify-between px-4 bg-neutral-gray-2 rounded-md"
                >
                  <div class="flex gap-4">
                    <Icon name="User" class="h-5 w-5 text-primary-6" :stroke-width="2.5" />
                    <p>{{ `${beneficial_owner.first_name} ${beneficial_owner.last_name}` }}</p>
                  </div>
                  <div class="flex items-center gap-1">
                    <Button
                      @click="handleEditBeneficialOwner(beneficial_owner)"
                      icon
                      variant="default-gray-outlined"
                    >
                      <template #icon>
                        <Icon name="Pencil" class="h-4 w-4 text-primary-6" :strokeWidth="2" />
                      </template>
                    </Button>
                    <div class="relative">
                      <div
                        v-if="beneficial_owner.show_delete_warning"
                        class="filter-warning absolute right-0 -top-value-41 border z-50 flex h-auto w-60 animate-slideup flex-col gap-3 rounded-md bg-white p-3 text-sm shadow-lg"
                      >
                        <p>
                          Are you sure you want to remove this beneficiary? This action cannot be
                          undone.
                        </p>
                        <div class="flex items-center gap-2">
                          <Button
                            @click="
                              handleDeleteBeneficialOwner(
                                entity,
                                beneficial_owner,
                                beneficialOwnerIndex
                              )
                            "
                            label
                            icon
                            variant="default-gray-outlined"
                            size="small"
                            class="!w-full"
                          >
                            <template #icon>
                              <Icon
                                name="Trash"
                                class="h-4 w-4 text-functional-error-default"
                                :strokeWidth="2.5"
                              />
                            </template>
                            <template #label>Remove</template>
                          </Button>
                          <Button
                            @click="delete beneficial_owner.show_delete_warning"
                            label
                            variant="subtle"
                            size="small"
                          >
                            <template #label>Cancel</template>
                          </Button>
                        </div>
                      </div>
                      <Button
                        @click="handleShowDeleteWarning(beneficial_owner)"
                        icon
                        variant="error-gray-outlined"
                      >
                        <template #icon>
                          <Icon
                            name="Trash"
                            class="h-4 w-4 text-functional-error-default"
                            :strokeWidth="2.5"
                          />
                        </template>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                v-if="getEntitiesSignedInvestmentCount(entity) === 0"
                @click="(isShowDeleteModal = true), (entity_to_delete = entity)"
                icon
                label
                variant="error-color-outlined"
                class="w-32 mt-4 min-h-10.5"
              >
                <template #label>
                  <p class="text-functional-error-default mt-0.5">Delete Entity</p>
                </template>
                <template #icon>
                  <Icon
                    name="Trash"
                    class="text-functional-error-default h-4 w-4"
                    :stroke-width="2.5"
                  />
                </template>
              </Button>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed, reactive } from "vue";
import { cloneDeep } from "lodash";
import { ObjectId } from "bson";

import { useInvestmentStore, useInvestorStore, useCrudStore } from "@/stores";
import { Button, Icon, Form, ConfirmDeleteModal } from "@/components";
import { getObjectDifferences, validateField } from "@/utilities";
import { NEW_BENEFICIAL_OWNER_TEMPLATE, ENTITY_TEMPLATE } from "@/constants";
import AddEntityJointSideDrawer from "./AddEntityJointSideDrawer.vue";
import AddBeneficiaryModal from "../MakeAnInvestment/AddBeneficiaryModal.vue";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const crudStore = useCrudStore();

const original_entity_accounts = ref([]);
const editModeState = ref({});
const expandedEntities = ref({});
const isBeneficiaryModalOpen = ref(false);
const beneficialOwnersByEntity = reactive({});
const newEntityTemplate = ref(cloneDeep(ENTITY_TEMPLATE));
const isShowDeleteModal = ref(false);
const entity_to_delete = ref(null);
const isSideDrawerOpen = ref(false);
const isOwnerBeneficial = ref([]);

onMounted(() => {
  window.scrollTo(0, 0);
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

watch(
  () => investorStore.entity_accounts,
  () => {
    initializeOwnerBeneficialState();
  },
  { immediate: true }
);

watch(
  () => investorStore.active_profile_tab,
  () => {
    original_entity_accounts.value.forEach((entity, index) => {
      investorStore.entity_accounts[index] = cloneDeep(entity);
      editModeState.value = {};
      expandedEntities.value[index] = false;
    });
  }
);

const schemaToUse = computed(() => {
  return investmentStore.entity_info_schema;
});

const isFormValid = (index) => {
  const entity = investorStore.entity_accounts[index];
  if (!entity) {
    return false;
  }

  if (!schemaToUse.value || schemaToUse.value.length === 0) {
    return false;
  }

  const fieldsToValidate = schemaToUse.value.flatMap((schema) =>
    schema.fields.filter((f) => f.is_required)
  );

  if (investorStore.hasSpecialError === "ein") {
    return false;
  }

  const isValid = fieldsToValidate.every((field) => {
    const value =
      field.belongs_to_nested_object && field.nested_object_name
        ? entity[field.nested_object_name]?.[field.field_name]
        : entity[field.field_name];

    return validateField(field.field_name, value);
  });

  return isValid;
};

const hasAnyValueChanged = (index) => {
  const differences = getObjectDifferences(
    investorStore.entity_accounts[index],
    original_entity_accounts.value[index]
  );
  return Object.keys(differences).length > 0;
};

function initializeOwnerBeneficialState() {
  investorStore.entity_accounts.forEach((entity, index) => {
    original_entity_accounts.value[index] = cloneDeep(entity);
    const owners = investorStore.allContacts.filter((contact) => {
      if (Array.isArray(contact?.entity_ids)) {
        const stringEntityIds = contact.entity_ids.map((id) => id.toString());
        return stringEntityIds.includes(entity._id.toString()) && contact.type !== "Regular";
      }
      return false;
    });

    beneficialOwnersByEntity[entity._id] = owners;
    // Check if the owner is already a beneficial owner of the entity
    const stringOwnerContactEntityIds = investorStore.contact?.entity_ids.map((id) =>
      id.toString()
    );
    const isBeneficial = stringOwnerContactEntityIds.includes(entity?._id?.toString());
    isOwnerBeneficial.value[index] = isBeneficial;
  });
}

// function populateBeneficialOwnersByEntity() {
//   investorStore.entity_accounts.forEach((entity) => {
//     const owners = investorStore.allContacts.filter((contact) => {
//       if (Array.isArray(contact?.entity_ids)) {
//         const stringEntityIds = contact.entity_ids.map((id) => id.toString());
//         return stringEntityIds.includes(entity._id.toString()) && contact.type !== "Regular";
//       }
//       return false;
//     });

//     beneficialOwnersByEntity[entity._id] = owners;
//   });
// }

function handleAddNewEntity(newEntity) {
  original_entity_accounts.value.push(cloneDeep(newEntity));
  initializeOwnerBeneficialState();
}

async function handleDeleteEntity() {
  isShowDeleteModal.value = false;
  investorStore.entity_accounts = investorStore.entity_accounts.filter(
    (c) => c._id?.toString() !== entity_to_delete.value._id?.toString()
  );
  investorStore.allAccounts = investorStore.allAccounts.filter(
    (c) => c._id?.toString() !== entity_to_delete.value._id?.toString()
  );
  await crudStore.deleteOne("Accounts", { _id: entity_to_delete.value._id });
  entity_to_delete.value = null;
}

function handleAddNewBeneficialOwner(entity) {
  investorStore.active_entity_account = entity;
  investorStore.isEditingBeneficiary = false;
  isBeneficiaryModalOpen.value = true;
}

function handleEditBeneficialOwner(beneficialOwner) {
  investorStore.isEditingBeneficiary = true;
  investorStore.selectedBeneficiary = beneficialOwner;
  isBeneficiaryModalOpen.value = true;
}

async function handleDeleteBeneficialOwner(entity, beneficialOwner, index) {
  const beneficialOwnersForEntity = beneficialOwnersByEntity[entity._id];
  delete beneficialOwner.show_delete_warning;
  // Store a reference to the beneficial owner to be deleted
  const deletedBeneficialOwner = beneficialOwnersForEntity[index];

  beneficialOwnersForEntity.splice(index, 1);
  try {
    // Only proceed with deletion if the beneficial owner is not of type 'Joint' and has an _id
    if (beneficialOwner.type !== "Joint" && beneficialOwner._id) {
      if (beneficialOwner.entity_ids.length === 1) {
        // delete contact
        await crudStore.deleteOne("Contacts", { _id: beneficialOwner._id });
        // remove contact from allContacts
        investorStore.allContacts = investorStore.allContacts.filter(
          (c) => c?._id?.toString() !== beneficialOwner?._id.toString()
        );
      } else {
        // update contact to remove the entity_id
        const query = { _id: beneficialOwner._id };
        const newEntityIds = beneficialOwner.entity_ids.filter(
          (id) => id.toString() !== entity._id.toString()
        );
        const update = { $set: { entity_ids: newEntityIds } };
        const result = await crudStore.updateOne("Contacts", query, update);
        // update contact in allContacts
        const contactIndex = investorStore.allContacts.findIndex(
          (c) => c._id === beneficialOwner._id
        );
        investorStore.allContacts[contactIndex].entity_ids = investorStore.allContacts[
          contactIndex
        ].entity_ids.filter((id) => id !== entity._id);
      }
    } else if (beneficialOwner.type === "Joint" && beneficialOwner._id) {
      // update contact to remove the entity_id
      const query = { _id: beneficialOwner._id };
      const newEntityIds = beneficialOwner.entity_ids.filter(
        (id) => id.toString() !== entity._id.toString()
      );
      const update = { $set: { entity_ids: newEntityIds } };
      const result = await crudStore.updateOne("Contacts", query, update);
      // update contact in allContacts
      const contactIndex = investorStore.allContacts.findIndex(
        (c) => c._id === beneficialOwner._id
      );
      investorStore.allContacts[contactIndex].entity_ids = investorStore.allContacts[
        contactIndex
      ].entity_ids.filter((id) => id.toString() !== entity?._id?.toString());
    }
  } catch (err) {
    console.error(err);
    investorStore.beneficial_owner_contacts.splice(index, 0, deletedBeneficialOwner);
  }
}

function toggleExpand(entityIndex) {
  expandedEntities.value[entityIndex] = !expandedEntities.value[entityIndex];
}

function toggleEditMode(index, entityIndex) {
  editModeState.value[index] = !editModeState.value[index];
  if (!editModeState.value[index]) {
    investorStore.supressErrors = true;
    investorStore.entity_accounts[entityIndex] = cloneDeep(
      original_entity_accounts.value[entityIndex]
    );
  } else {
    investorStore.supressErrors = false;
  }
}

async function handleSave(index, entityIndex) {
  editModeState.value[index] = false;
  if (hasAnyValueChanged(entityIndex)) {
    original_entity_accounts.value[entityIndex] = cloneDeep(
      investorStore.entity_accounts[entityIndex]
    );
    await investorStore.upsertEntityAccount(investorStore.entity_accounts[entityIndex]);
  }
}

function getEntitiesSignedInvestmentCount(entity) {
  if (!entity?._id) {
    return 0;
  }
  return investmentStore.transactionsData.filter(
    (i) => i?.entity_account_id?.toString() === entity?._id?.toString() && i?.status !== "Canceled"
  )?.length;
}

function getEntitiesSettledInvestmentCount(entity) {
  if (!entity?._id) {
    return 0;
  }
  return investmentStore.transactionsData.filter(
    (i) => i?.entity_account_id?.toString() === entity?._id?.toString() && i?.status === "Settled"
  )?.length;
}

function handleClickOutside(e) {
  if (!e.target.closest(".filter-warning")) {
    investorStore.beneficial_owner_contacts.forEach((contact) => {
      contact.show_delete_warning = false;
    });
  }
}

function handleShowDeleteWarning(beneficialOwner) {
  for (const entityId in beneficialOwnersByEntity) {
    if (beneficialOwnersByEntity.hasOwnProperty(entityId)) {
      for (const owner of beneficialOwnersByEntity[entityId]) {
        owner.show_delete_warning = false;
      }
    }
  }
  beneficialOwner.show_delete_warning = true;
}

function enter(el) {
  el.style.height = el.scrollHeight + "px";
}
</script>

<style scoped>
.expand-transition-enter-active,
.expand-transition-leave-active {
  transition: height 0.5s ease;
}

.expand-transition-enter-from,
.expand-transition-leave-to {
  height: 0;
}
</style>
