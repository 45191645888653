<template>
  <div
    v-if="investmentStore.offerings"
    class="w-full h-full flex flex-col"
    :class="investmentStore.currentStep !== 2 ? 'p-4 md:px-6 lg:px-10' : ''"
  >
    <SelectOffering v-if="investmentStore.currentStep === 1" />
    <SelectInvestmentType v-if="investmentStore.currentStep === 2" />
    <div
      v-if="investmentStore.currentStep > 2 && investmentStore.selectedOffering"
      class="flex flex-col w-full lg:h-full mt-16 md:mt-0 grow px-2 md:px-0"
    >
      <InvestmentSteps />
      <PersonalInfo v-if="shouldMountComponent('Personal Information')" />
      <SuitabilityQuestionnaire v-if="shouldMountComponent('Suitability Questionnaire')" />
      <ESignatureAcknowledgements v-if="shouldMountComponent('E-Signature Acknowledgements')" />
      <JointInfo v-if="shouldMountComponent('Joint Information')" />
      <EntityInfo v-if="shouldMountComponent('Entity Information')" />
      <AccreditationDetails v-if="shouldMountComponent('Accreditation Details')" />
      <CompleteYourInvestment v-if="shouldMountComponent('Banking')" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch } from "vue";

import { useInvestmentStore, useInvestorStore } from "@/stores";

import InvestmentSteps from "./InvestmentSteps.vue";
import SelectOffering from "./SelectOffering.vue";
import SelectInvestmentType from "./SelectInvestmentType.vue";
import PersonalInfo from "./PersonalInfo.vue";
import SuitabilityQuestionnaire from "./SuitabilityQuestionnaire.vue";
import ESignatureAcknowledgements from "./ESignatureAcknowledgements.vue";
import AccreditationDetails from "./AccreditationDetails.vue";
import CompleteYourInvestment from "./CompleteYourInvestment.vue";
import JointInfo from "./JointInfo.vue";
import EntityInfo from "./EntityInfo.vue";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

onMounted(async () => {
  investmentStore.currentStep = 1;
});

watch(
  () => investorStore.contact,
  async () => {
    if (investorStore.contact && investorStore.contact?.current_step !== "10") {
      investorStore.contact.current_step = "10";
      await investorStore.updateCurrentStep("10");
    }
  }
);

watch(
  () => investmentStore.currentStep,
  async () => {
    if (investmentStore.currentStep > 2) {
      if (
        investmentStore.investmentSteps[investmentStore.currentStep - 3] !== "Banking" &&
        investorStore.contact?.current_step !== "20"
      ) {
        investorStore.contact.current_step = "20";
        await investorStore.updateCurrentStep("20");
      } else if (
        investmentStore.investmentSteps[investmentStore.currentStep - 3] === "Banking" &&
        investorStore.contact?.current_step !== "30"
      ) {
        investorStore.contact.current_step = "30";
        await investorStore.updateCurrentStep("30");
      }
    } else {
      if (investorStore.contact && investorStore.contact?.current_step !== "10") {
        investorStore.contact.current_step = "10";
        await investorStore.updateCurrentStep("10");
      }
    }
  }
);

function shouldMountComponent(stepName) {
  if (stepName === "Banking") {
    return (
      investmentStore.currentStep - 3 === investmentStore.investmentSteps.indexOf(stepName) ||
      investmentStore.currentStep - 3 ===
        investmentStore.investmentSteps.indexOf("Review and Sign Agreement")
    );
  }
  return investmentStore.currentStep - 3 === investmentStore.investmentSteps.indexOf(stepName);
}
</script>
