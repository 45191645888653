import {
  IconArrowUpward,
  IconBell,
  IconCampaign,
  IconCheckmark,
  IconChevronLeft,
  IconCircleCheck,
  IconCopyAll,
  IconExpandLess,
  IconExpandMore,
  IconHamburgerMenu,
  IconLink,
  IconPhxCapLogo,
  IconPulseSpinner,
  IconQuestionMark,
  IconSearch,
  IconSearchOff,
  IconVisibility,
  IconVisibilityOff,
  IconWarning,
  IconX,
  IconInvestment,
} from "@/components/icons";

export const iconMapping = {
  IconArrowUpward: IconArrowUpward,
  IconBell: IconBell,
  IconCampaign: IconCampaign,
  IconCheckmark: IconCheckmark,
  IconChevronLeft: IconChevronLeft,
  IconCircleCheck: IconCircleCheck,
  IconCopyAll: IconCopyAll,
  IconExpandLess: IconExpandLess,
  IconExpandMore: IconExpandMore,
  IconHamburgerMenu: IconHamburgerMenu,
  IconLink: IconLink,
  IconPhxCapLogo: IconPhxCapLogo,
  IconPulseSpinner: IconPulseSpinner,
  IconQuestionMark: IconQuestionMark,
  IconSearch: IconSearch,
  IconSearchOff: IconSearchOff,
  IconVisibility: IconVisibility,
  IconVisibilityOff: IconVisibilityOff,
  IconWarning: IconWarning,
  IconX: IconX,
  IconInvestment: IconInvestment,
};
