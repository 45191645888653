<template>
  <div class="px-3 py-5 md:px-[1.375rem] lg:px-10 md:py-6">
    <div class="flex justify-between items-center mb-5">
      <div>
        <p class="text-xs font-leaguespartan leading-tight font-medium text-primary-2 uppercase">
          Welcome back,
          {{ displayedInvestorName }}
        </p>
        <p class="text-2xl font-leaguespartan leading-tight font-bold text-text-dark uppercase">
          your PROFILE
        </p>
      </div>
    </div>
    <div>
      <TabList
        :tabList="tabsToShow"
        @set-active-tab="handleSetActiveTab"
        :activeTab="investorStore.active_profile_tab"
      />
    </div>
    <div class="mt-4">
      <PersonalInfoForm v-show="investorStore.active_profile_tab === 'Personal'" />
      <JointInfoForm
        v-show="
          investorStore.active_profile_tab === 'Joint' && investmentStore.hasSignedJointInvestment
        "
      />
      <EntityInfoForm
        v-show="
          investorStore.active_profile_tab === 'Entity' && investmentStore.hasSignedEntityInvestment
        "
      />
      <SuitabilityQuestionnaireForm
        v-show="
          investorStore.active_profile_tab === 'Questionnaire' &&
          investmentStore.hasAccreditedInvestments
        "
      />
      <BankingInfoForm v-show="investorStore.active_profile_tab === 'Banking'" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, watch, ref } from "vue";

import { TabList, Icon, Button } from "@/components";
import { useInvestorStore, useUIStore, useInvestmentStore } from "@/stores";
import PersonalInfoForm from "./PersonalInfoForm.vue";
import SuitabilityQuestionnaireForm from "./SuitabilityQuestionnaireForm.vue";
import JointInfoForm from "./JointInfoForm.vue";
import EntityInfoForm from "./EntityInfoForm.vue";
import BankingInfoForm from "./BankingInfoForm.vue";

const investorStore = useInvestorStore();
const investmentStore = useInvestmentStore();
const UIStore = useUIStore();

const displayedInvestorName = ref("");

onMounted(() => {
  window.scrollTo(0, 0);
  // Initialize displayed investor name
  if (investorStore.contact) {
    displayedInvestorName.value = `${investorStore.contact.first_name} ${investorStore.contact.last_name}`;
  }
  investmentStore.setSchemaForInvestmentFlow();
});

const tabsToShow = computed(() => {
  const tabs = ["Personal"];

  if (investmentStore.hasSignedJointInvestment) {
    tabs.push("Joint");
  }
  if (investmentStore.hasSignedEntityInvestment) {
    tabs.push("Entity");
  }
  tabs.push("Banking");
  if (investmentStore.hasAccreditedInvestments) {
    tabs.push("Questionnaire");
  }
  return tabs;
});

function handleSetActiveTab(tab) {
  investorStore.active_profile_tab = tab;
}

watch(
  () => investorStore.contact,
  (newContact) => {
    if (newContact) {
      displayedInvestorName.value = `${newContact.first_name} ${newContact.last_name}`;
    }
  },
  { immediate: true }
);
</script>
