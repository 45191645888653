<template>
  <div class="relative inline-block text-left">
    <Menu>
      <div>
        <MenuButton as="template">
          <slot name="button-content" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute mt-2 w-fit min-w-32 origin-top-right rounded-md bg-white shadow-lg focus:outline-none overflow-hidden"
          :class="position"
        >
          <div v-for="(group, index) in actions" :key="index" class="py-0.5">
            <MenuItem
              v-for="(action, index) in group"
              :key="index"
              v-slot="{ active }"
              :disabled="action.disabled"
            >
              <button
                @click="handleActionClick(action)"
                :class="[
                  active ? 'bg-[#FBF2CC] font-semibold' : '',
                  'group flex w-full items-center px-2 py-2 text-sm',
                ]"
              >
                <component
                  :is="action.icon"
                  class="mr-2 h-5 w-5 text-primary-6"
                  aria-hidden="true"
                />
                {{ action.label }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

const props = defineProps({
  actions: {
    type: Array,
    required: true,
    default: () => [],
  },
  position: {
    type: String,
    default: "right-0",
  },
});

const emit = defineEmits(["action-click"]);

const handleActionClick = (action) => {
  if (!action.disabled && action.onClick) {
    action.onClick();
    emit("action-click", action);
  }
};
</script>
