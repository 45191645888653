<template>
  <div class="text-primary-6">
    <h1 class="font-bold text-xl uppercase text-primary-6">
      Step {{ investmentStore.currentStep - 2 }}: Review
    </h1>
    <p class="text-lighter text-sm mt-2 mb-6">
      Investment Type: {{ investmentStore.currentInvestment.type }} • Offering Type:
      {{ investmentStore.selectedOffering.type }}
      ({{ investmentStore.selectedOffering.interest_rate }}% -
      {{
        investmentStore.selectedOffering.bond_term === 12
          ? "1 Year"
          : investmentStore.selectedOffering.bond_term / 12 + " Years"
      }}
      - {{ investmentStore.currentInvestment.is_compounded ? "Compounded" : "Paid Monthly" }}) •
      Amount to Invest: ${{ investmentStore.currentInvestment.amount }}
    </p>
    <!-- Offering -->
    <div class="w-full my-4 lg:my-8">
      <div class="flex items-center justify-between cursor-pointer mb-3">
        <h2 class="font-medium text-lg">Offering</h2>
        <div class="flex gap-4 items-center">
          <Button
            @click.stop="investmentStore.currentStep = 1"
            variant="subtle"
            label
            icon
            size="small"
            isIconOnlyOnMobile
          >
            <template #label>Edit</template>
            <template #icon><Icon name="Pencil" class="h-3 w-3" :stroke-width="2" /></template>
          </Button>
          <Button @click="toggleSection('offering')" icon variant="subtle" size="small">
            <template #icon>
              <Icon
                :name="isSectionOpen('offering') ? 'ChevronUp' : 'ChevronDown'"
                class="h-4 w-4"
              />
            </template>
          </Button>
        </div>
      </div>
      <transition name="expand">
        <div v-show="isSectionOpen('offering')" class="grid lg:grid-cols-4 grid-cols-2 gap-4">
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Type</p>
            <p class="font-medium">{{ investmentStore.currentInvestment.type }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Amount</p>
            <p class="font-medium">${{ investmentStore.currentInvestment.amount }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Interest Rate</p>
            <p class="font-medium">{{ investmentStore.selectedOffering.interest_rate }}%</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Offering</p>
            <p class="font-medium">
              {{
                `${investmentStore.selectedOffering.type} Series ${investmentStore.selectedOffering.name}`
              }}
            </p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Bonds</p>
            <p class="font-medium">
              {{
                investmentStore.currentInvestment.amount
                  ? Number(investmentStore.currentInvestment.amount.replace(/,/g, "")) / 1000
                  : 0
              }}
            </p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Term</p>
            <p class="font-medium">
              {{ investmentStore.selectedOffering.bond_term / 12 }} Year<span
                v-if="investmentStore.selectedOffering.bond_term > 12"
                >s</span
              >
            </p>
          </div>
        </div>
      </transition>
    </div>
    <div class="border-b border-neutral-gray-4 w-full my-6"></div>
    <!-- Personal -->
    <div class="w-full my-4 lg:my-8">
      <div class="flex items-center justify-between cursor-pointer mb-3">
        <h2 class="font-medium text-lg">1. Personal Information</h2>
        <div class="flex gap-4 items-center">
          <Button
            @click.stop="investmentStore.currentStep = 3"
            variant="subtle"
            label
            icon
            size="small"
            isIconOnlyOnMobile
          >
            <template #label>Edit</template>
            <template #icon><Icon name="Pencil" class="h-3 w-3" :stroke-width="2" /></template>
          </Button>
          <Button @click="toggleSection('personal')" icon variant="subtle" size="small">
            <template #icon>
              <Icon
                :name="isSectionOpen('personal') ? 'ChevronUp' : 'ChevronDown'"
                class="h-4 w-4"
              />
            </template>
          </Button>
        </div>
      </div>
      <transition name="expand">
        <div v-show="isSectionOpen('personal')" class="grid lg:grid-cols-4 grid-cols-2 gap-4">
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">First Name</p>
            <p class="font-medium">{{ investorStore.contact.first_name }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Last Name</p>
            <p class="font-medium">{{ investorStore.contact.last_name }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">City</p>
            <p class="font-medium">{{ investorStore.contact.city }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Zip Code</p>
            <p class="font-medium">{{ investorStore.contact.zip_code }}</p>
          </div>
          <div
            v-if="investmentStore.selectedOffering.type === 'Reg D'"
            class="border-b border-neutral-gray-4 py-1"
          >
            <p class="uppercase text-xs mb-1 text-lighter">ID Number</p>
            <p class="font-medium">{{ investorStore.contact.id_info.id_number }}</p>
          </div>
          <div
            v-if="investmentStore.selectedOffering.type === 'Reg D'"
            class="border-b border-neutral-gray-4 py-1"
          >
            <p class="uppercase text-xs mb-1 text-lighter">ID Issued By</p>
            <p class="font-medium">{{ investorStore.contact.id_info.id_issued_by }}</p>
          </div>
          <div
            v-if="investmentStore.selectedOffering.type === 'Reg D'"
            class="border-b border-neutral-gray-4 py-1"
          >
            <p class="uppercase text-xs mb-1 text-lighter">ID Issued Date</p>
            <p class="font-medium">
              {{
                new Date(investorStore.contact.id_info.id_issued_date).toLocaleDateString("en-US", {
                  timeZone: "UTC",
                })
              }}
            </p>
          </div>
          <div
            v-if="investmentStore.selectedOffering.type === 'Reg D'"
            class="border-b border-neutral-gray-4 py-1"
          >
            <p class="uppercase text-xs mb-1 text-lighter">ID Expiration Date</p>
            <p class="font-medium">
              {{
                new Date(investorStore.contact.id_info.id_expiration_date).toLocaleDateString(
                  "en-US",
                  { timeZone: "UTC" }
                )
              }}
            </p>
          </div>
        </div>
      </transition>
    </div>
    <div class="border-b border-neutral-gray-4 w-full my-6"></div>
    <!-- Investor Questionnaire -->
    <div
      v-if="investmentStore.investmentSteps.includes('Suitability Questionnaire')"
      class="w-full my-4 lg:my-8"
    >
      <div class="flex items-center justify-between cursor-pointer mb-3">
        <h2 class="font-medium text-lg">2. Investor Questionnaire</h2>
        <div class="flex gap-4 items-center">
          <Button
            @click="
              investmentStore.currentStep =
                investmentStore.investmentSteps.indexOf('Suitability Questionnaire') + 3
            "
            variant="subtle"
            label
            icon
            size="small"
            isIconOnlyOnMobile
          >
            <template #label>Edit</template>
            <template #icon><Icon name="Pencil" class="h-3 w-3" :stroke-width="2" /></template>
          </Button>
          <Button @click="toggleSection('questionnaire')" icon variant="subtle" size="small">
            <template #icon>
              <Icon
                :name="isSectionOpen('questionnaire') ? 'ChevronUp' : 'ChevronDown'"
                class="h-4 w-4"
              />
            </template>
          </Button>
        </div>
      </div>
      <transition name="expand">
        <div v-show="isSectionOpen('questionnaire')" class="grid lg:grid-cols-4 grid-cols-2 gap-4">
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Questions</p>
            <p class="font-semibold">
              {{ QUESTIONNAIRE_QUESTIONS_ANSWERS.length }} Questions Answered
            </p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Current Income</p>
            <p class="font-medium">${{ investorStore.contact.suitability_info.current_income }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Net Worth</p>
            <p class="font-medium">
              ${{ investorStore.contact.suitability_info.current_net_worth }}
            </p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Liquid Net Worth</p>
            <p class="font-medium">
              ${{ investorStore.contact.suitability_info.current_liquid_net_worth }}
            </p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Income Source</p>
            <p class="font-medium">{{ investorStore.contact.suitability_info.source_of_income }}</p>
          </div>
        </div>
      </transition>
    </div>
    <div
      v-if="investmentStore.investmentSteps.includes('Suitability Questionnaire')"
      class="border-b border-neutral-gray-4 w-full my-6"
    ></div>
    <!-- Joint -->
    <div
      v-if="investmentStore.investmentSteps.includes('Joint Information')"
      class="w-full my-4 lg:my-8"
    >
      <div class="flex items-center justify-between cursor-pointer mb-3">
        <h2 class="font-medium text-lg">
          {{ investmentStore.investmentSteps.indexOf("Joint Information") + 1 }}. Joint Information
        </h2>
        <div class="flex gap-4 items-center">
          <Button
            @click="
              investmentStore.currentStep =
                investmentStore.investmentSteps.indexOf('Joint Information') + 3
            "
            variant="subtle"
            label
            icon
            size="small"
            isIconOnlyOnMobile
          >
            <template #label>Edit</template>
            <template #icon><Icon name="Pencil" class="h-3 w-3" :stroke-width="2" /></template>
          </Button>
          <Button @click="toggleSection('joint')" icon variant="subtle" size="small">
            <template #icon>
              <Icon :name="isSectionOpen('joint') ? 'ChevronUp' : 'ChevronDown'" class="h-4 w-4" />
            </template>
          </Button>
        </div>
      </div>
      <transition name="expand">
        <div v-show="isSectionOpen('joint')" class="grid lg:grid-cols-4 grid-cols-2 gap-4">
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">First Name</p>
            <p class="font-medium">{{ investorStore.active_joint_contact.first_name }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Last Name</p>
            <p class="font-medium">{{ investorStore.active_joint_contact.last_name }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">City</p>
            <p class="font-medium">{{ investorStore.active_joint_contact.city }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Zip Code</p>
            <p class="font-medium">{{ investorStore.active_joint_contact.zip_code }}</p>
          </div>
          <div
            v-if="investmentStore.selectedOffering.type === 'Reg D'"
            class="border-b border-neutral-gray-4 py-1"
          >
            <p class="uppercase text-xs mb-1 text-lighter">ID Number</p>
            <p class="font-medium">{{ investorStore.active_joint_contact.id_info.id_number }}</p>
          </div>
          <div
            v-if="investmentStore.selectedOffering.type === 'Reg D'"
            class="border-b border-neutral-gray-4 py-1"
          >
            <p class="uppercase text-xs mb-1 text-lighter">ID Issued By</p>
            <p class="font-medium">{{ investorStore.active_joint_contact.id_info.id_issued_by }}</p>
          </div>
          <div
            v-if="investmentStore.selectedOffering.type === 'Reg D'"
            class="border-b border-neutral-gray-4 py-1"
          >
            <p class="uppercase text-xs mb-1 text-lighter">ID Issued Date</p>
            <p class="font-medium">
              {{
                new Date(
                  investorStore.active_joint_contact.id_info.id_issued_date
                ).toLocaleDateString("en-US", { timeZone: "UTC" })
              }}
            </p>
          </div>
          <div
            v-if="investmentStore.selectedOffering.type === 'Reg D'"
            class="border-b border-neutral-gray-4 py-1"
          >
            <p class="uppercase text-xs mb-1 text-lighter">ID Expiration Date</p>
            <p class="font-medium">
              {{
                new Date(
                  investorStore.active_joint_contact.id_info.id_expiration_date
                ).toLocaleDateString("en-US", { timeZone: "UTC" })
              }}
            </p>
          </div>
        </div>
      </transition>
    </div>
    <div
      v-if="investmentStore.investmentSteps.includes('Joint Information')"
      class="border-b border-neutral-gray-4 w-full my-6"
    ></div>
    <!-- Entity -->
    <div
      v-if="investmentStore.investmentSteps.includes('Entity Information')"
      class="w-full my-4 lg:my-8"
    >
      <div class="flex items-center justify-between cursor-pointer mb-3">
        <h2 class="font-medium text-lg">
          {{ investmentStore.investmentSteps.indexOf("Entity Information") + 1 }}. Entity
          Information
        </h2>
        <div class="flex gap-4 items-center">
          <Button
            @click="
              investmentStore.currentStep =
                investmentStore.investmentSteps.indexOf('Entity Information') + 3
            "
            variant="subtle"
            label
            icon
            size="small"
            isIconOnlyOnMobile
          >
            <template #label>Edit</template>
            <template #icon><Icon name="Pencil" class="h-3 w-3" :stroke-width="2" /></template>
          </Button>
          <Button @click="toggleSection('entity')" icon variant="subtle" size="small">
            <template #icon>
              <Icon :name="isSectionOpen('entity') ? 'ChevronUp' : 'ChevronDown'" class="h-4 w-4" />
            </template>
          </Button>
        </div>
      </div>
      <transition name="expand">
        <div v-show="isSectionOpen('entity')" class="grid lg:grid-cols-4 grid-cols-2 gap-4">
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Entity Name</p>
            <p class="font-medium">{{ investorStore.active_entity_account.name }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Entity Type</p>
            <p class="font-medium">{{ investorStore.active_entity_account.entity_type }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">EIN</p>
            <p class="font-medium">{{ investorStore.active_entity_account.ein }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Phone</p>
            <p class="font-medium">{{ investorStore.active_entity_account.phone }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">State</p>
            <p class="font-medium">{{ investorStore.active_entity_account.state.name }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">City</p>
            <p class="font-medium">{{ investorStore.active_entity_account.city }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Zip Code</p>
            <p class="font-medium">{{ investorStore.active_entity_account.zip_code }}</p>
          </div>
        </div>
      </transition>
    </div>
    <div
      v-if="investmentStore.investmentSteps.includes('Entity Information')"
      class="border-b border-neutral-gray-4 w-full my-6"
    ></div>
    <!-- Accreditation Details -->
    <div
      v-if="investmentStore.investmentSteps.includes('Accreditation Details')"
      class="w-full my-4 lg:my-8"
    >
      <div class="flex items-center justify-between cursor-pointer mb-3">
        <h2 class="font-medium text-lg">
          {{ investmentStore.investmentSteps.indexOf("Accreditation Details") + 1 }}. Accreditation
          Details
        </h2>
        <div class="flex gap-4 items-center">
          <Button
            @click="
              investmentStore.currentStep =
                investmentStore.investmentSteps.indexOf('Accreditation Details') + 3
            "
            variant="subtle"
            label
            icon
            size="small"
            isIconOnlyOnMobile
          >
            <template #label>Edit</template>
            <template #icon><Icon name="Pencil" class="h-3 w-3" :stroke-width="2" /></template>
          </Button>
          <Button @click="toggleSection('accreditation')" icon variant="subtle" size="small">
            <template #icon>
              <Icon
                :name="isSectionOpen('accreditation') ? 'ChevronUp' : 'ChevronDown'"
                class="h-4 w-4"
              />
            </template>
          </Button>
        </div>
      </div>
      <transition name="expand">
        <div v-show="isSectionOpen('accreditation')" class="grid lg:grid-cols-4 grid-cols-2 gap-4">
          <div class="border-b border-neutral-gray-4 py-1">
            <p class="uppercase text-xs mb-1 text-lighter">Files</p>
            <p class="font-medium">
              {{ investorStore.accreditation_documents.length }} Files Uploaded
            </p>
          </div>
        </div>
      </transition>
    </div>
    <div
      v-if="investmentStore.investmentSteps.includes('Accreditation Details')"
      class="border-b border-neutral-gray-4 w-full my-6"
    ></div>
    <!-- Banking -->
    <div
      v-if="
        investmentStore.investmentSteps.includes('Banking') &&
        !investmentStore.currentInvestment?.type?.includes('IRA')
      "
      class="w-full my-4 lg:my-8"
    >
      <div class="flex items-center justify-between cursor-pointer mb-3">
        <h2 class="font-medium text-lg">
          {{ investmentStore.investmentSteps.indexOf("Banking") + 1 }}. Funding and Distribution
        </h2>
        <div class="flex gap-4 items-center">
          <Button
            @click="
              investmentStore.currentStep = investmentStore.investmentSteps.indexOf('Banking') + 3
            "
            variant="subtle"
            label
            icon
            size="small"
            isIconOnlyOnMobile
          >
            <template #label>Edit</template>
            <template #icon><Icon name="Pencil" class="h-3 w-3" :stroke-width="2" /></template>
          </Button>
          <Button @click="toggleSection('complete')" icon variant="subtle" size="small">
            <template #icon>
              <Icon
                :name="isSectionOpen('complete') ? 'ChevronUp' : 'ChevronDown'"
                class="h-4 w-4"
              />
            </template>
          </Button>
        </div>
      </div>
      <transition name="expand">
        <div v-show="isSectionOpen('complete')" class="grid lg:grid-cols-4 grid-cols-2 gap-4">
          <div class="border-b border-neutral-gray-4 py-1 lg:col-span-2">
            <p class="uppercase text-xs mb-1 text-lighter">Funding Method</p>
            <p v-if="investmentStore.currentInvestment.funding_account" class="font-medium">
              {{ activeFundingAccount.financial_institution_name }} -
              {{ `****${activeFundingAccountNumber.slice(-4)}` }}
            </p>
            <p v-else class="font-medium">{{ investmentStore.currentInvestment.payment_method }}</p>
          </div>
          <div class="border-b border-neutral-gray-4 py-1 lg:col-span-2">
            <p class="uppercase text-xs mb-1 text-lighter">Distribution Method</p>
            <p v-if="investmentStore.currentInvestment.distribution_account" class="font-medium">
              {{ activeDistributionAccount.financial_institution_name }} -
              {{ `****${activeDistributionAccountNumber.slice(-4)}` }}
            </p>
            <p v-else class="font-medium">
              {{ investmentStore.currentInvestment.distribution_method }}
            </p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import { useInvestmentStore, useInvestorStore } from "@/stores";
import { Button, Icon } from "@/components";
import { QUESTIONNAIRE_QUESTIONS_ANSWERS } from "@/constants";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

const openSections = ref({
  offering: true,
  personal: false,
  questionnaire: false,
  joint: false,
  entity: false,
  accreditation: false,
  complete: false,
});

const activeFundingAccount = computed(() => {
  return investmentStore.bankAccounts.find(
    (a) => a._id?.toString() === investmentStore.currentInvestment.funding_account.toString()
  );
});

const activeFundingAccountNumber = computed(() => {
  if (activeFundingAccount.value?.account_number) {
    const decodedAccountNumber = atob(activeFundingAccount.value.account_number);
    return decodedAccountNumber.trim(); // Ensure it's trimmed and clean of extra characters
  }
  return "";
});

const activeDistributionAccount = computed(() => {
  return investmentStore.bankAccounts.find(
    (a) => a._id?.toString() === investmentStore.currentInvestment.distribution_account.toString()
  );
});

const activeDistributionAccountNumber = computed(() => {
  if (activeDistributionAccount.value?.account_number) {
    const decodedAccountNumber = atob(activeDistributionAccount.value.account_number);
    return decodedAccountNumber.trim(); // Ensure it's trimmed and clean of extra characters
  }
  return "";
});

const toggleSection = (section) => {
  openSections.value[section] = !openSections.value[section];
};

const isSectionOpen = (section) => {
  return openSections.value[section];
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: all 0.3s ease;
}
.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}
.expand-enter-to,
.expand-leave-from {
  max-height: 500px;
  opacity: 1;
}
</style>
