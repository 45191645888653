<template>
  <div class="flex flex-col w-full h-full px-5 py-4 md:py-6 lg:px-10">
    <div
      class="flex flex-col w-full lg:items-center lg:justify-center lg:h-full pt-16 md:pt-24 md:border lg:border-none rounded-md md:p-8 lg:p-0 grow"
    >
      <button
        type="button"
        @click="investmentStore.currentStep = 1"
        class="flex items-center justify-center gap-1.5 absolute top-24 left-4 md:left-10"
      >
        <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
        <p>Back</p>
      </button>
      <h3 class="font-bold text-auth-size mb-8 md:mb-6 text-left md:text-center">
        Select the type of investment <br class="hidden md:block lg:hidden" />
        you would like to make today:
      </h3>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div
          v-for="investmentType in INVESTMENT_TYPES"
          :key="investmentType.name"
          @click="handleSetInvestmentType(investmentType.name)"
          class="flex flex-col gap-2 w-full md:min-h-value-43 md:max-w-[400px] md:border md:p-4 rounded-lg justify-between"
          :class="{
            'opacity-50 cursor-not-allowed': isDisabled(investmentType.name),
            'cursor-pointer': !isDisabled(investmentType.name),
          }"
        >
          <div>
            <div class="flex gap-4 mb-4">
              <Icon :name="investmentType.icon" class="h-6 w-6 text-primary-6" :strokeWidth="2" />
              <p class="font-bold text-primary-6 mt-0.5">{{ investmentType.name }}</p>
            </div>
            <p class="text-lighter">{{ investmentType.description }}</p>
          </div>

          <div class="mt-2">
            <Button
              @click="handleSetInvestmentType(investmentType.name)"
              label
              icon
              size="large"
              class="w-full"
              :disabled="isDisabled(investmentType.name)"
            >
              <template #label>Select</template>
            </Button>
            <div class="md:hidden border-b border-neutral-gray-4 w-full mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

import { useInvestmentStore, useInvestorStore } from "@/stores";
import { Button, Icon } from "@/components";
import { INVESTMENT_TYPES, IRA_QUESTIONNAIRE } from "@/constants";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

onMounted(() => {
  window.scrollTo(0, 0);
});

function isDisabled(investmentType) {
  if (investmentStore.selected_rollover_investment) {
    const selectedType = investmentStore.selected_rollover_investment.type;

    // If the selected investment or the current one is an IRA, check if both are IRAs
    const isSelectedIRA = selectedType.includes("IRA");
    const isCurrentIRA = investmentType.includes("IRA");

    if (isSelectedIRA && isCurrentIRA) {
      return false;
    }

    return selectedType !== investmentType;
  }

  return false;
}

async function handleSetInvestmentType(investmentType) {
  if (isDisabled(investmentType)) return; // Prevent setting type if it is disabled

  if (investmentType.includes("IRA")) {
    if (!investorStore.contact.ira_questionnaire) {
      investorStore.contact.ira_questionnaire = {};
      IRA_QUESTIONNAIRE.forEach((question) => {
        investorStore.contact.ira_questionnaire[question.field_name] = "";
      });
    }
  }
  investmentStore.currentInvestment.type =
    investmentType === "IRA" ? "Traditional IRA" : investmentType;
  investmentStore.setInvestmentSteps();
  investmentStore.currentStep = 3;
  await investmentStore.updateInvestmentType();
}
</script>
