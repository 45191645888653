<template>
  <div class="md:h-full w-full p-6">
    <div
      class="flex flex-col w-full md:border border-platform-outlines rounded-md h-full items-center md:justify-center mt-12 md:mt-0"
    >
      <button
        type="button"
        @click="handleBack"
        class="flex items-center justify-center gap-1.5 absolute top-24 left-6 md:left-10"
      >
        <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" />
        Back
      </button>
      <div class="w-full md:max-w-[23.813rem] mt-4 md:mt-0">
        <AuthStepper :numSteps="9" />
        <h3 class="font-bold text-2xl mb-6 text-primary-6">Acknowledgements</h3>
        <div class="flex flex-col gap-4 mb-6 w-full">
          <div v-for="cb_input in acknowledgements" class="flex items-start">
            <BaseInput
              v-model="authStore.signUpFields[cb_input.name]"
              type="boolean"
              class="!w-0 mt-0.5"
              @keyup.enter="handleNextStep"
              :isCheckbox="true"
              :id="cb_input.name"
            />
            <p class="ml-12 text-sm text-lighter">
              <span
                @click="
                  authStore.signUpFields[cb_input.name] = !authStore.signUpFields[cb_input.name]
                "
                class="cursor-pointer"
              >
                {{ cb_input.label }} <span> </span>
              </span>

              <a
                v-if="cb_input.name === 'consent_cb'"
                :href="cb_input.href"
                class="text-gold"
                download="RegD_Welcome_Packet"
              >
                {{ cb_input.action_text }}
              </a>
              <a
                v-else-if="cb_input.name === 'privacy_cb'"
                :href="cb_input.href"
                class="text-gold"
                target="_blank"
              >
                {{ cb_input.action_text }}
              </a>
            </p>
          </div>
        </div>
        <div class="flex flex-col items-center w-full gap-4">
          <Button
            @click="handleNextStep"
            @keyup.enter="handleNextStep"
            :disabled="!isFormValid"
            label
            size="large"
            class="w-full"
          >
            <template v-if="isLoading" #label>
              <IconPulseSpinner class="h-6 w-6 stroke-white" />
            </template>
            <template v-else #label>Next</template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import { useRouter } from "vue-router";

import AuthStepper from "./AuthStepper.vue";
import { useAuthStore } from "@/stores";
import { BaseInput, Button, Icon } from "@/components";
import { IconPulseSpinner } from "@/components/icons";
import RegD_Welcome_Packet from "@/assets/documents/Reg_D_Welcome_Packet_new.pdf";

const router = useRouter();
const authStore = useAuthStore();
const isLoading = ref(false);

const acknowledgements = ref([
  {
    name: "consent_cb",
    label:
      "I have read and understand the information outlined by the company's broker-dealer in the",
    href: RegD_Welcome_Packet,
    action_text: "Welcome Packet.",
  },
  {
    name: "privacy_cb",
    label: "I consent to the",
    href: "https://phxcapitalgroup.com/privacy/",
    action_text: "Privacy Policy.",
  },
  {
    name: "message_cb",
    label:
      "I consent to receive automated SMS messages and/or email marketing about my investment or relevant offers by or on behalf of Phoenix Capital Group Holdings and/or trusted affiliated companies at the phone number and email provided. I understand that consent is not a condition of purchase. Message and data rates may apply.",
  },
]);

onMounted(() => {
  window.scrollTo(0, 0);
  if (!authStore.signUpFields.email) {
    router.push("/sign-up");
  }
});

const isFormValid = computed(() => {
  return (
    authStore.signUpFields.consent_cb &&
    authStore.signUpFields.message_cb &&
    authStore.signUpFields.privacy_cb
  );
});

async function handleNextStep() {
  if (!isFormValid.value) return;

  isLoading.value = true;
  authStore.mfa_channel = "Email";
  await authStore.sendVerificationCode("Email");

  authStore.activeSignupStep = 4;
  isLoading.value = false;
  router.push("/sign-up/auth-verification");
}

function handleBack() {
  authStore.activeSignupStep = 2;
  router.push("/sign-up/set-password");
}
</script>
