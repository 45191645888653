<template>
  <component :is="icon" :stroke-width="strokeWidth" />
</template>

<script setup>
import { computed } from "vue";
import * as icons from "lucide-vue-next";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  strokeWidth: {
    type: Number,
    default: 2,
  },
});

const icon = computed(() => icons[props.name]);
</script>
