<template>
  <nav
    class="flex h-14 items-center bg-primary-6 px-4"
    :class="!isShowAuthNavigation ? 'lg:justify-start justify-center' : 'justify-between'"
  >
    <Menu
      :actions="mobileActionsList"
      :position="'left-0'"
      :class="isShowAuthNavigation ? 'block lg:hidden' : 'hidden'"
    >
      <template #button-content>
        <div class="flex items-center justify-center h-8 w-8 cursor-pointer">
          <Icon name="Menu" class="h-6 w-6 text-white" :strokeWidth="2" />
        </div>
      </template>
    </Menu>

    <div class="flex items-center gap-[60px] justify-between">
      <!-- PHX LOGO -->
      <div
        class="absolute left-1/2 transform -translate-x-1/2 text-center lg:relative lg:left-auto lg:transform-none lg:translate-x-0 lg:text-left"
      >
        <RouterLink to="/">
          <img :src="phxLogo" alt="" class="h-[34.6px] w-full object-contain" />
        </RouterLink>
      </div>

      <!-- Router Links -->
      <div v-if="isShowAuthNavigation" class="items-center hidden lg:flex">
        <RouterLink
          v-for="nav_route in navItems"
          :to="nav_route.path"
          class="hover:bg-[#041626] flex justify-center h-14 items-center transition-colors duration-200 ease-in-out relative"
          :class="nav_route.path === route.fullPath ? 'bg-[#385165]' : ''"
        >
          <p
            class="text-white p-4"
            :class="nav_route.path === route.fullPath ? 'font-semibold' : 'font-medium'"
          >
            {{ nav_route.name }}
          </p>
          <div
            v-if="nav_route.path === route.fullPath"
            class="bg-secondary-8 h-[4px] absolute bottom-0 w-full"
          ></div>
        </RouterLink>
        <a
          v-if="isShowAuthNavigation"
          href="https://phxcapitalgroup.com/contact-us-about-it-support/"
          target="_blank"
          class="hover:bg-[#041626] h-14 flex justify-center items-center transition-colors duration-200 ease-in-out relative"
        >
          <p class="text-white p-4 font-medium">Contact</p>
        </a>
      </div>
    </div>

    <div
      v-if="isShowAuthNavigation && investorStore.contact"
      class="flex gap-4 relative items-center"
    >
      <Button
        @click="router.push('/make-an-investment')"
        label
        size="medium"
        class="!bg-neutral-gray-1 !text-primary-6 !font-semibold hidden md:block !border-2 !border-gold"
      >
        <template #label>Make an Investment</template>
      </Button>
      <p class="text-white font-light hidden lg:block">
        {{ displayedInvestorName }}
      </p>
      <Menu :actions="actionsList">
        <template #button-content>
          <div
            class="flex items-center justify-center rounded-full h-7 w-7 bg-primary-5 cursor-pointer"
          >
            <Icon name="User" class="h-5 w-5 text-white" :strokeWidth="2" />
          </div>
        </template>
      </Menu>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import { Icon, Menu, Button } from "@/components";
import { useAuthStore, useInvestorStore } from "@/stores";
import phxLogo from "@/assets/images/PHX_Revised_Logo.png";

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const investorStore = useInvestorStore();
const displayedInvestorName = ref("");

watch(
  () => investorStore.contact,
  (newContact) => {
    if (newContact) {
      displayedInvestorName.value = `${newContact.first_name} ${newContact.last_name}`;
      if (
        investorStore.contact?.signup_questionnaire &&
        Object.values(investorStore.contact?.signup_questionnaire).some((x) => !x) &&
        actionsList.value[0].length === 1
      ) {
        actionsList.value[0].unshift({
          label: "Questionnaire",
          onClick: () => {
            router.push("/sign-up/questionnaire");
          },
        });
      }
    }
  },
  { immediate: true }
);

const isShowAuthNavigation = computed(() => {
  if (authStore.currentUser && investorStore.contact) {
    return true;
  }
});

const navItems = ref([
  { name: "Portfolio", path: "/portfolio" },
  { name: "Profile", path: "/profile" },
  { name: "Documents", path: "/documents" },
]);

const actionsList = ref([
  [
    {
      label: "Logout",
      onClick: () => {
        handleSignOut();
      },
    },
  ],
]);

const mobileActionsList = ref([
  [
    {
      label: "Dashboard",
      onClick: () => {
        router.push("/dashboard");
      },
    },
    {
      label: "Profile",
      onClick: () => {
        router.push("/profile");
      },
    },
    {
      label: "Documents",
      onClick: () => {
        router.push("/documents");
      },
    },
    {
      label: "Contact Us",
      onClick: () => {
        window.location.replace("https://www.phxcapitalgroup.com/contact/");
      },
    },
  ],
]);

async function handleSignOut() {
  await authStore.signOut();
  router.push("/sign-in");
}
</script>
