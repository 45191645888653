<template>
  <div v-if="UIStore.isLoading">
    <LoadingSpinner :show="UIStore.isLoading" />
  </div>
  <DocumentPreviewModal
    :open="previewModalOpen"
    @close-modal="previewModalOpen = false"
    :file="selectedFile"
  />
  <div class="flex flex-col w-full h-full lg:my-6">
    <button
      type="button"
      @click="handleBack"
      class="md:hidden flex items-center justify-center gap-1.5 text-primary-6 absolute top-24 left-5"
    >
      <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
      <p>Back</p>
    </button>
    <div
      class="flex flex-col grow items-center lg:pt-10 lg:pb-8 lg:border border-neutral-gray-4 w-full rounded-md h-full"
    >
      <div class="w-full lg:w-[70%] mx-auto justify-between flex flex-col h-full">
        <div>
          <h1 class="font-bold text-xl uppercase text-primary-6">
            Step {{ investmentStore.currentStep - 2 }}-{{ accreditation_substep }}: Accreditation
            Details
          </h1>
          <p class="text-lighter text-sm mt-2 mb-6">
            Investment Type: {{ investmentStore.currentInvestment.type }} • Offering Type:
            {{ investmentStore.selectedOffering.type }}
            ({{ investmentStore.selectedOffering.interest_rate }}% -
            {{
              investmentStore.selectedOffering.bond_term === 12
                ? "1 Year"
                : investmentStore.selectedOffering.bond_term / 12 + " Years"
            }}
            - {{ investmentStore.currentInvestment.is_compounded ? "Compounded" : "Paid Monthly" }})
            • Amount to Invest: ${{ investmentStore.currentInvestment.amount }}
          </p>
          <div v-if="accreditation_substep === 1">
            <div>
              <h3 class="font-bold text-primary-6">Are you an accredited investor?</h3>
              <div class="w-full text-sm flex items-start gap-4 mt-2">
                <input
                  id="accredited_investor_yes"
                  type="checkbox"
                  v-model="investorStore.contact.are_you_an_accredited_investor"
                  class="accent-secondary-6 mt-0.5 h-5 w-5 cursor-pointer"
                />
                <label for="accredited_investor_yes" class="text-lighter text-base cursor-pointer">
                  Yes, I have an annual income greater than $200,000, joint income greater than
                  $300,000 or net worth greater than $1 million (excluding primary residence).
                </label>
              </div>
              <div class="border-b border-neutral-gray-4 w-full mt-4"></div>
            </div>
            <div class="mt-4">
              <h3 class="font-bold mb-2">Upload Accreditation Documents</h3>
              <p class="text-lighter">
                Regulation D Offerings require Phoenix to validate accreditation documentation which
                could be:
              </p>
              <ul class="list-disc pl-4 text-lighter mt-2">
                <li v-for="accreditation_qualifier in ACCREDITATION_QUALIFIERS">
                  {{ accreditation_qualifier }}
                </li>
              </ul>
            </div>
            <div>
              <div v-if="investorStore.accreditation_documents.length" class="mt-4">
                <ul class="flex flex-col gap-6">
                  <li
                    class="flex w-full items-center justify-between rounded-md border border-dashed border-primary-3 bg-primary-6 bg-opacity-10 px-5 py-3"
                    v-for="(file, index) in investorStore.accreditation_documents"
                    :key="index"
                  >
                    <div class="flex items-center gap-2 mr-2 truncate">
                      <Icon name="FileUp" class="h-5 w-5 text-cTeal" :stroke-width="2" />
                      <p class="truncate">
                        {{ file.name || file.file_name }}
                      </p>
                    </div>
                    <div class="flex items-center gap-2">
                      <Button
                        @click="handlePreview(file)"
                        icon
                        size="small"
                        variant="default-color-outlined"
                      >
                        <template #icon>
                          <Icon name="Eye" class="h-4 w-4 text-primary-6" :stroke-width="2" />
                        </template>
                      </Button>
                      <Button
                        @click="downloadFile_R2(file)"
                        icon
                        size="small"
                        variant="default-color-outlined"
                      >
                        <template #icon>
                          <Icon name="Download" class="h-4 w-4 text-primary-6" :stroke-width="2" />
                        </template>
                      </Button>
                      <div class="relative">
                        <div
                          v-if="file.show_delete_warning"
                          class="filter-warning absolute right-0 top-10 border z-50 flex h-auto w-60 animate-slideup flex-col gap-3 rounded-md bg-white p-3 text-sm shadow-lg"
                        >
                          <p>
                            Are you sure you want to delete this file? This action cannot be undone.
                          </p>
                          <div class="flex items-center gap-2">
                            <Button
                              @click="handleDelete(file)"
                              label
                              icon
                              variant="default-color-outlined"
                              size="small"
                              class="!w-full"
                            >
                              <template #icon>
                                <Icon
                                  name="Trash"
                                  class="h-4 w-4 text-functional-error-default"
                                  :strokeWidth="2"
                                />
                              </template>
                              <template #label>Remove</template>
                            </Button>
                            <Button
                              @click="delete file.show_delete_warning"
                              label
                              variant="subtle"
                              size="small"
                            >
                              <template #label>Cancel</template>
                            </Button>
                          </div>
                        </div>
                        <Button
                          @click="file.show_delete_warning = true"
                          icon
                          variant="error-color-outlined"
                          size="small"
                        >
                          <template #icon>
                            <Icon
                              name="Trash"
                              class="h-3.5 w-3.5 text-functional-error-default"
                              :stroke-width="3"
                            />
                          </template>
                        </Button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="border-b border-neutral-gray-4 w-full my-6"></div>
              <FileDropper @files-selected="handleFiles" />
            </div>
          </div>
          <div v-else>
            <div
              v-for="(section, index) in filteredEsignatureOptions"
              :key="index"
              class="flex flex-col gap-4"
            >
              <div class="relative">
                <h3 class="font-bold text-lg text-primary-6">
                  {{ section.type.replace(/_/g, " ").toUpperCase() }}
                  <span class="text-sm text-lighter font-normal"
                    >(at least 1 selection is required)</span
                  >
                </h3>
                <!-- <Icon
                  v-if="section.type === 'benefit_plan_investor'"
                  @click="isBenefitPlanInvestorInfoOpen = !isBenefitPlanInvestorInfoOpen"
                  name="CircleHelp"
                  class="circle-help text-lighter h-4 w-4 absolute left-1/2 top-1 cursor-pointer"
                />
                <div
                  v-if="isBenefitPlanInvestorInfoOpen"
                  class="bg-neutral-gray-4 rounded text-sm text-lighter p-3 flex absolute top-6 right-6 animate-slideup z-50 open-info shadow-lg max-w-96"
                >
                  <div class="relative">
                    <p class="w-[95%]">
                      The term “Benefit Plan Investor” means a benefit plan investor within the
                      meaning of U.S. Department of Labor Regulation 29 C.F.R. Section 2510.3-101,
                      which includes (i) any employee benefit plan (as defined in Section 3(3) of
                      ERISA), whether or not such plan is subject to Title I of ERISA (which
                      includes both U.S. and Non-U.S. plans, plans of governmental entities as well
                      as private employers, church plans and certain assets held in connection with
                      nonqualified deferred compensation plans); (ii) any plan described in Code
                      Section 4975(e)(1) (which includes a trust described in Code Section 401(a)
                      which forms a part of a plan, which trust or plan is exempt from tax under
                      Code Section 501(a), a plan described in Code Section 403(a), an individual
                      retirement account described in Code Sections 408(a) or 408A, an individual
                      retirement annuity described in Code Section 408(b), a medical savings account
                      described in Code Section 220(d), and an education individual retirement
                      account described in Code Section 530); and (iii) any entity whose underlying
                      assets include plan assets by reason of a plan's investment in the entity
                      (generally because twenty-five percent (25%) or more of a class of interests
                      in the entity is owned by plans). Benefit Plan Investors also include that
                      portion of any insurance company's general account assets that are considered
                      “plan assets” and the assets of any insurance company separate account or bank
                      common or collective trust in which plans invest. 100% of an investor’s
                      interests whose underlying assets include “plan assets,” such as a fund
                      investor, shall be treated as “plan assets” by the Trustees for purposes of
                      meeting an exemption under the Department of Labor regulation.
                    </p>
                    <Icon
                      name="X"
                      class="text-lighter h-3.5 w-3.5 absolute right-0 top-0 cursor-pointer"
                      @click="isBenefitPlanInvestorInfoOpen = !isBenefitPlanInvestorInfoOpen"
                    />
                  </div>
                </div> -->
              </div>

              <div
                v-for="(option, idx) in section.options"
                :key="idx"
                class="flex items-start mb-4"
              >
                <input
                  type="checkbox"
                  :id="`option-${index}-${idx}`"
                  v-model="investorStore.reg_d_acknowledgements[index > 0 ? idx + 14 : idx]"
                  class="text-primary-6 accent-current min-h-4 min-w-4 mt-[.3rem]"
                />
                <div class="ml-3">
                  <label
                    :for="`option-${index}-${idx}`"
                    class="text-sm text-lighter cursor-pointer"
                  >
                    {{ option }}
                  </label>
                  <div
                    v-if="
                      investmentStore.currentInvestment.type !== 'Entity' &&
                      idx === 3 &&
                      investorStore.reg_d_acknowledgements[3]
                    "
                    class="flex space-x-2 items-center mt-2"
                  >
                    <Icon
                      name="Flag"
                      class="h-4 w-4 text-functional-error-default animate-bounce"
                      :stroke-width="2"
                    />
                    <p class="text-red-500 font-medium text-sm">
                      Your current selection is not appropriate for the majority of investors, are
                      you sure?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="investmentStore.currentInvestment?.type === 'Entity'"
              class="border-b border-neutral-gray-4 w-full mb-6 mt-2"
            ></div>
            <p
              v-if="investmentStore.currentInvestment?.type === 'Entity'"
              class="text-xs text-lighter"
            >
              The term “Benefit Plan Investor” means a benefit plan investor within the meaning of
              U.S. Department of Labor Regulation 29 C.F.R. Section 2510.3-101, which includes (i)
              any employee benefit plan (as defined in Section 3(3) of ERISA), whether or not such
              plan is subject to Title I of ERISA (which includes both U.S. and Non-U.S. plans,
              plans of governmental entities as well as private employers, church plans and certain
              assets held in connection with nonqualified deferred compensation plans); (ii) any
              plan described in Code Section 4975(e)(1) (which includes a trust described in Code
              Section 401(a) which forms a part of a plan, which trust or plan is exempt from tax
              under Code Section 501(a), a plan described in Code Section 403(a), an individual
              retirement account described in Code Sections 408(a) or 408A, an individual retirement
              annuity described in Code Section 408(b), a medical savings account described in Code
              Section 220(d), and an education individual retirement account described in Code
              Section 530); and (iii) any entity whose underlying assets include plan assets by
              reason of a plan's investment in the entity (generally because twenty-five percent
              (25%) or more of a class of interests in the entity is owned by plans). Benefit Plan
              Investors also include that portion of any insurance company's general account assets
              that are considered “plan assets” and the assets of any insurance company separate
              account or bank common or collective trust in which plans invest. 100% of an
              investor’s interests whose underlying assets include “plan assets,” such as a fund
              investor, shall be treated as “plan assets” by the Trustees for purposes of meeting an
              exemption under the Department of Labor regulation.
            </p>
          </div>
        </div>

        <div
          class="w-full flex gap-4 flex-col-reverse md:flex-row justify-between items-center border-t border-neutral-gray-4 pt-6 mt-6"
        >
          <button @click="handleBack" type="button" class="flex items-center text-primary-6 gap-2">
            <Icon name="ChevronLeft" class="hidden md:block h-5 w-5 text-primary-6 mb-0.5" />
            <p>Back</p>
          </button>
          <Button
            @click="handleNext"
            :disabled="!isFormValid"
            label
            icon
            size="large"
            class="w-full md:w-value-42"
          >
            <template #label>Next</template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";

import {
  useInvestmentStore,
  useInvestorStore,
  useCrudStore,
  useUIStore,
  useAuthStore,
} from "@/stores";
import useFileUtility from "@/composables/useFileUtility";
import { Button, Icon, FileDropper, DocumentPreviewModal, LoadingSpinner } from "@/components";
import { ACCREDITATION_QUALIFIERS, ESIGNATURE_OPTIONS } from "@/constants";
import ESignatureAcknowledgements from "./ESignatureAcknowledgements.vue";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const crudStore = useCrudStore();
const UIStore = useUIStore();
const authStore = useAuthStore();

const { uploadFile_R2, downloadFile_R2, fetchFileForPreview_R2, deleteFile_R2 } = useFileUtility();

const previewModalOpen = ref(false);
const selectedFile = ref(null);
const accreditation_substep = ref(1);
const isBenefitPlanInvestorInfoOpen = ref(false);

onMounted(async () => {
  window.scrollTo(0, 0);
  if (investmentStore.currentInvestment.type === "Entity") {
    investorStore.reg_d_acknowledgements =
      investorStore.active_entity_account.reg_d_entity_acknowledgements || [];
  } else {
    investorStore.reg_d_acknowledgements =
      investorStore.contact.reg_d_personal_acknowledgements || [];
  }
});

const filteredEsignatureOptions = computed(() => {
  const investmentType = investmentStore.currentInvestment.type;

  if (investmentType === "Individual" || investmentType === "Joint") {
    return ESIGNATURE_OPTIONS.filter(
      (option) => option.type === "investor_acknowledgement_and_representation"
    );
  } else if (investmentType === "Entity") {
    return ESIGNATURE_OPTIONS.filter(
      (option) =>
        option.type === "entity_acknowledgements" || option.type === "benefit_plan_investor"
    );
  }
  return [];
});

const areNeededOptionsSelected = computed(() => {
  if (investmentStore.currentInvestment.type === "Entity") {
    const first14 = investorStore.reg_d_acknowledgements.slice(0, 14);
    const last2 = investorStore.reg_d_acknowledgements.slice(14);
    return first14.some((value) => value) && (last2[0] || last2[1]);
  }
  return investorStore.reg_d_acknowledgements.some((value) => value);
});

const isFormValid = computed(() => {
  if (accreditation_substep.value === 1) {
    return investorStore.contact.are_you_an_accredited_investor;
  }
  return areNeededOptionsSelected.value;
});

watch(
  filteredEsignatureOptions,
  (newOptions) => {
    const optionsLength = newOptions.reduce((total, section) => total + section.options.length, 0);
    initializeAcknowledgements(optionsLength);
  },
  { immediate: true }
);

async function handleFiles(files) {
  try {
    UIStore.isLoading = true;
    let newAccreditation = [];
    for (let file of files) {
      const accreditation = await uploadFile_R2({
        file,
        type: "Accreditation",
        details: {
          file_category: "Accreditation Support",
          visible_to_compliance: false,
          user_id: authStore.currentUser.id,
          contact_id: { $oid: investorStore.contact._id },
          account_id: { $oid: investorStore.contact.account_id },
        },
      });
      newAccreditation.push(accreditation);
      investorStore.general_documents.unshift(accreditation);
    }
    investorStore.accreditation_documents = [
      ...newAccreditation,
      ...investorStore.accreditation_documents,
    ];
  } finally {
    UIStore.isLoading = false;
  }
}

async function handlePreview(file) {
  const fileUrl = await fetchFileForPreview_R2(file);
  selectedFile.value = { ...file, file_url: fileUrl };
  previewModalOpen.value = true;
}

async function handleDelete(file) {
  investorStore.accreditation_documents = investorStore.accreditation_documents.filter(
    (f) => f._id?.toString() !== file._id?.toString()
  );
  investorStore.general_documents = investorStore.general_documents.filter(
    (f) => f._id?.toString() !== file._id?.toString()
  );
  await deleteFile_R2(file);
}

function initializeAcknowledgements(optionsLength) {
  if (
    !investorStore.reg_d_acknowledgements ||
    investorStore.reg_d_acknowledgements.length !== optionsLength
  ) {
    investorStore.reg_d_acknowledgements = Array(optionsLength).fill(false);
  }
}

function handleBack() {
  if (accreditation_substep.value !== 1) {
    accreditation_substep.value -= 1;
  } else {
    investmentStore.currentStep -= 1;
  }
}

async function handleNext() {
  if (accreditation_substep.value === 1) {
    // TODO: Refactor to a store function that will update teh contact and send accreditation documents to the server
    if (investmentStore.currentInvestment.type?.includes("IRA")) {
      investmentStore.currentStep += 1;
    } else {
      accreditation_substep.value += 1;
    }
    const query = { _id: investorStore.contact._id };
    const update = {
      $set: {
        are_you_an_accredited_investor: investorStore.contact.are_you_an_accredited_investor,
      },
    };
    await crudStore.updateOne("Contacts", query, update);
  } else {
    investmentStore.currentStep += 1;
    const ackType = investmentStore.currentInvestment.type.toLowerCase();
    await investorStore.updateRegDAcknowledgements(
      ackType,
      ackType === "entity" ? investorStore.active_entity_account : null
    );
  }
}
</script>

<style scoped>
input[type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode for checkmark */
  font-size: 1rem;
  color: white;
  position: absolute;
  top: 0.1rem;
  left: 0.25rem;
}
</style>
