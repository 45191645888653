<template>
  <Pie
    v-if="!UIStore.isChartLoading"
    :class="'h-96 w-full'"
    id="bar-chart"
    :options="chartOptionsDynamic"
    :data="chartDataDynamic"
  />
  <div v-else class="flex min-h-50 items-center justify-center p-10">
    <LoadingSpinner :show="UIStore.isChartLoading" hideOverlay position="relative" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { LoadingSpinner } from "@/components";
import { useUIStore } from "@/stores";
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

const UIStore = useUIStore();

const chartOptionsDynamic = computed(() => {
  return props.chartOptions;
});

const chartDataDynamic = computed(() => {
  return JSON.parse(JSON.stringify(props.chartData));
});

const props = defineProps({
  parent: String,
  chartData: Object,
  chartOptions: Object,
});
</script>
