<template>
  <Modal
    :open="open"
    @close-modal="$emit('close-modal')"
    customHeight="h-auto max-h-[80vh] no-scrollbar"
    customWidth="lg:w-168"
  >
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <p class="font-bold mt-1">Upload Documents</p>
      </div>
    </template>

    <template v-slot:modal-body>
      <div class="p-4 md:px-10 md:py-8">
        <BaseInput
          type="select"
          v-model="fileCategory"
          required
          size="large"
          placeholder="Select Document Type"
          label="Document Type"
          showFullText
          :options="categoryOptions"
          class="!w-64 mb-4"
        />
        <div v-if="fileCategory === 'Accreditation Support'" class="mb-6">
          <div class="border-platform-outlines border-b my-6"></div>
          <div
            class="border border-platform-outlines bg-white rounded-md flex flex-col items-center p-4 transition-all overflow-hidden"
          >
            <div
              class="flex justify-between items-center w-full cursor-pointer"
              @click="isAccreditationInfoExpanded = !isAccreditationInfoExpanded"
            >
              <p class="font-bold">Accreditation Document Requirements</p>

              <Icon
                :name="isAccreditationInfoExpanded ? 'ChevronUp' : 'ChevronDown'"
                class="text-primary-6 h-6 w-6"
              />
            </div>
            <transition name="expand">
              <div v-if="isAccreditationInfoExpanded" class="flex flex-col w-full mt-6">
                <p class="text-lighter text-sm">
                  Regulation D Offerings require us to validate accreidtation documentation which
                  could be:
                </p>
                <ul class="list-disc pl-4 text-lighter text-sm mt-2">
                  <li v-for="accreditation_qualifier in ACCREDITATION_QUALIFIERS">
                    {{ accreditation_qualifier }}
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
        <div v-if="generalFiles.length" class="my-4">
          <ul class="flex flex-col gap-2">
            <li
              class="flex w-full items-center justify-between rounded-md border border-dashed border-primary-3 bg-primary-6 bg-opacity-10 px-5 py-1.5"
              v-for="(file, index) in generalFiles"
              :key="index"
            >
              <div class="flex items-center gap-2 mr-2 truncate">
                <Icon name="FileUp" class="h-5 w-5 text-cTeal" :stroke-width="2" />
                <p class="truncate">
                  {{ file.name }}
                </p>
              </div>
              <Button icon size="small" variant="error-color-outlined">
                <template #icon>
                  <Icon
                    name="Trash"
                    class="h-3.5 w-3.5 text-functional-error-default"
                    @click="generalFiles.splice(index, 1)"
                    :stroke-width="3"
                  />
                </template>
              </Button>
            </li>
          </ul>
        </div>
        <FileDropper @files-selected="handleFiles" :customHeight="'h-72'" />
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template>
        </Button>
        <Button
          size="large"
          :disabled="!generalFiles.length || !fileCategory"
          label
          @click="handleUploadDocs"
        >
          <template #label>Upload</template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref } from "vue";

import { Button, Icon, Modal, BaseInput, FileDropper } from "@/components";
import { ACCREDITATION_QUALIFIERS } from "@/constants";

const emit = defineEmits(["close-modal", "upload-docs"]);

const fileCategory = ref("");
const generalFiles = ref([]);
const isAccreditationInfoExpanded = ref(false);

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  categoryOptions: {
    type: Array,
    required: true,
  },
});

function handleFiles(files) {
  generalFiles.value = [...generalFiles.value, ...files];
}

function handleUploadDocs() {
  emit("upload-docs", generalFiles.value, fileCategory.value);
  emit("close-modal");
  setTimeout(() => {
    generalFiles.value = [];
    fileCategory.value = "";
  }, 100);
}
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: all 0.3s ease;
}
.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}
.expand-enter-to,
.expand-leave-from {
  max-height: 500px;
  opacity: 1;
}
</style>
