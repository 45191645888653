<template>
  <Modal
    :open="open"
    @close-modal="$emit('close-modal')"
    customHeight="h-value-15 md:h-value-23 lg:h-160"
    customWidth="lg:w-144"
  >
    <template v-slot:modal-header>
      <p class="text-lg font-semibold text-primary-6">Add Beneficial Owner</p>
    </template>

    <template v-slot:modal-body>
      <div class="py-8 px-10 text-primary-6">
        <div v-if="!investorStore.isEditingBeneficiary">
          <h3 class="font-bold mb-3">Pick Beneficial Owner Contact</h3>
          <div class="bg-neutral-gray-2 bg-opacity-70 rounded-lg flex flex-col gap-3">
            <label
              v-for="(contact, index) in allBeneficialAndJointContacts"
              :key="index"
              class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
              :class="
                investorStore.selectedBeneficiary?.ssn == contact.ssn
                  ? 'border-gold'
                  : 'border-platform-outlines'
              "
              @click="investorStore.selectedBeneficiary = contact"
            >
              <RadioOption
                :id="index"
                name="activeJointContact"
                :value="contact"
                v-model="investorStore.selectedBeneficiary"
                :label="`${contact.first_name} ${contact.last_name}`"
                :description="contact.email ? `${contact.email} (Joint)` : 'Beneficial Owner'"
              />
            </label>
            <label
              class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
              :class="
                investorStore.selectedBeneficiary?.is_new
                  ? 'border-gold'
                  : 'border-platform-outlines'
              "
              @click="investorStore.selectedBeneficiary = cloneDeep(NEW_BENEFICIAL_OWNER_TEMPLATE)"
            >
              <RadioOption
                id="newBeneficialOwnerContact"
                name="newBeneficialOwnerContact"
                :value="cloneDeep(NEW_BENEFICIAL_OWNER_TEMPLATE)"
                v-model="investorStore.selectedBeneficiary"
                label="New Beneficial Owner"
                description="Create a new Beneficial Owner"
              />
            </label>
          </div>
        </div>
        <div v-else>
          <template v-for="(schema, index) of schemaToUse" :key="index">
            <Form
              :fields="schema.fields"
              :record="investorStore.selectedBeneficiary"
              :header="schema.group_name"
              :isSmall="true"
              :subHeader="
                index === 1
                  ? 'Your physical address. PO Boxes and mail drops are not valid.'
                  : index === 2
                    ? 'Your passport or government issued ID (driver\'s license).'
                    : ''
              "
              type="beneficial owner"
            />
            <div
              v-if="index !== schemaToUse.length - 1"
              class="border-b border-neutral-gray-5 w-full my-4 md:my-6"
            ></div>
          </template>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label>Cancel</template>
        </Button>
        <Button
          size="large"
          label
          :disabled="investorStore.isEditingBeneficiary ? !isFormValid : false"
          @click="
            investorStore.isEditingBeneficiary ? handleSaveBeneficiary() : handleAddBeneficiary()
          "
        >
          <template #label>
            {{ investorStore.isEditingBeneficiary ? "Save" : "Add" }}
          </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { cloneDeep, isEqual } from "lodash";

import { useInvestmentStore, useInvestorStore } from "@/stores";
import { Button, Modal, RadioOption, Form } from "@/components";
import { getObjectDifferences, validateField } from "@/utilities";
import { NEW_BENEFICIAL_OWNER_TEMPLATE } from "@/constants";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

const emit = defineEmits(["close-modal", "handle-add-beneficiary"]);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  fromProfile: {
    type: Boolean,
    default: false,
  },
});

const schemaToUse = computed(() => {
  const filterFields = (schema) => {
    return schema.map((group) => ({
      group_name: group.group_name,
      fields: group.fields.filter(
        (field) =>
          field.field_name !== "email" &&
          field.field_name !== "phone" &&
          field.field_name !== "occupation" &&
          field.field_name !== "employer"
      ),
    }));
  };

  // If not from profile, check if the selected offering type is "Reg D"
  if (!props.fromProfile) {
    if (investmentStore?.selectedOffering?.type === "Reg D") {
      return filterFields(investmentStore.accredited_owner_contact_schema);
    } else {
      return filterFields(investmentStore.non_accredited_owner_contact_schema);
    }
  } else {
    // If from profile, check if the user has accredited investments
    if (investmentStore.hasAccreditedInvestments) {
      return filterFields(investmentStore.accredited_owner_contact_schema);
    } else {
      return filterFields(investmentStore.non_accredited_owner_contact_schema);
    }
  }
});

const allBeneficialAndJointContacts = computed(() => {
  const activeEntityId = investorStore.active_entity_account?._id?.toString();

  // If there's no active entity account, return all contacts of type "Joint" or "Beneficial Owner"
  if (!activeEntityId) {
    const allAvailableContacts = investorStore.allContacts
      .filter((contact) => contact.type === "Joint" || contact.type === "Beneficial Owner")
      .filter((contact) =>
        investorStore.tempBeneficialOwners.every((unsaved) => unsaved.ssn !== contact.ssn)
      );

    return allAvailableContacts;
  }

  // Otherwise, filter out contacts that are not tied to the active entity account's _id
  return investorStore.allContacts.filter((contact) => {
    // Check if contact has entity_ids and filter out those already tied to the activeEntityId
    if (Array.isArray(contact.entity_ids)) {
      const contactEntityIds = contact.entity_ids.map((id) => id.toString());
      // Return true if the contact's entity_ids do not include the activeEntityId
      return (
        !contactEntityIds.includes(activeEntityId) &&
        (contact.type === "Joint" || contact.type === "Beneficial Owner")
      );
    }

    // If contact has no entity_ids, include it if it's of type "Joint" or "Beneficial Owner"
    return contact.type === "Joint" || contact.type === "Beneficial Owner";
  });
});

const isFormValid = computed(() => {
  if (!schemaToUse.value || schemaToUse.value.length === 0 || !investorStore.selectedBeneficiary) {
    return false;
  }

  const fieldsToValidate = schemaToUse.value.flatMap((schema) =>
    schema.fields.filter((f) => f.is_required)
  );

  if (investorStore.hasSpecialError === "ssn" || investorStore.hasSpecialError === "id_number") {
    return false;
  }

  const isValid = fieldsToValidate.every((field) => {
    const value =
      field.belongs_to_nested_object && field.nested_object_name
        ? investorStore.selectedBeneficiary[field.nested_object_name]?.[field.field_name]
        : investorStore.selectedBeneficiary[field.field_name];

    return validateField(field.field_name, value);
  });

  return isValid;
});

async function handleAddBeneficiary() {
  if (investorStore.selectedBeneficiary.is_new) {
    investorStore.isEditingBeneficiary = true;
  } else {
    emit("close-modal");
    if (!investorStore.active_entity_account?._id) {
      investorStore.tempBeneficialOwners.push(investorStore.selectedBeneficiary);
    } else {
      await investorStore.upsertBeneficialOwner(investorStore.selectedBeneficiary);
      emit("handle-add-beneficiary", investorStore.selectedBeneficiary);
    }
  }
}

async function handleSaveBeneficiary() {
  emit("close-modal");
  if (investorStore.selectedBeneficiary.is_new && !investorStore.active_entity_account?._id) {
    if (
      investorStore.tempBeneficialOwners.some(
        (owner) => owner.ssn === investorStore.selectedBeneficiary.ssn
      )
    ) {
      return;
    }
    investorStore.tempBeneficialOwners.push(investorStore.selectedBeneficiary);
    setTimeout(() => {
      investorStore.selectedBeneficiary = null;
    }, 1000);
  } else {
    await investorStore.upsertBeneficialOwner(investorStore.selectedBeneficiary);
  }
}
</script>
