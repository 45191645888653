<template>
  <div class="relative">
    <div class="relative flex bg-white overflow-x-auto no-scrollbar">
      <template v-for="(tab, index) in tabList" :key="index">
        <div
          class="relative flex text-xs font-bold"
          :class="
            activeTab === tab
              ? 'border-b-[3px] border-tab-gold-border text-primary-6'
              : 'border-b-[3px] border-gray text-primary-2'
          "
        >
          <div
            class="flex h-full cursor-pointer items-center justify-center gap-2 p-3 text-sm"
            @click="$emit('set-active-tab', tab)"
          >
            <Icon :name="getIconName(tab)" class="text-primary-2 h-6 w-6" :stroke-width="1.5" />
            <p class="text-primary whitespace-nowrap uppercase text-xs mt-1">
              {{ tab.name || tab }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@/components";

const props = defineProps({
  tabList: {
    type: Array,
    required: true,
  },
  activeTab: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["set-active-tab"]);

function getIconName(tab) {
  switch (tab) {
    case "Personal":
      return "User";
    case "Suitability":
    case "Questionnaire":
      return "FileQuestion";
    case "Joint":
      return "UsersRound";
    case "Banking":
      return "Landmark";
    case "Entity":
      return "BriefcaseBusiness";
    case "Documents":
      return "FileText";
    case "Recommended":
      return "Star";
    case "All Offerings":
      return "Files";
    default:
      return "User";
  }
}
</script>
