import tp_img1 from "@/assets/images/LarryGossen_TP.png";
import tp_img2 from "@/assets/images/Sandor_TP.png";
import tp_img3 from "@/assets/images/RozaliaPap_TP.png";
import tp_img4 from "@/assets/images/Robert_TP.png";
import tp_img5 from "@/assets/images/ToddG_TP.png";
import tp_img6 from "@/assets/images/Phil_TP.png";
import tp_img7 from "@/assets/images/Neil_TP.png";

export const TRUST_PILOT_REVIEWS = [
  {
    name: "Larry G.",
    quote: "Execellent Communications",
    link: "https://www.trustpilot.com/reviews/66b278dbbd1383461051c899",
    img: tp_img1,
    full_text:
      "The communications with PCG staff was very clear and timely. They consistently followed up with us to ensure we had what we needed to make the transfers happen. It was a seamless process. I felt very confident in our investment choices.",
  },
  {
    name: "Sandor",
    quote: "I have been an investor with PCG for 2 months",
    link: "https://www.trustpilot.com/reviews/6306a0917f7a8621ee48b041",
    img: tp_img2,
    full_text:
      "I have been an investor with PCG for 2 months, and have received 2 monthly interest payments at 0.75% monthly rate. I decided to buy more bonds because the stock market delivers more and more frustration contrary to the 9% yearly interest coming from PCG. Whenever I needed help my #1 person to turn to has been Matt Willer. He deserves a 6 star rating. I wish PCG could clone him for every department they have. His knowledge and attitude guarantee long term success for the company.",
  },
  {
    name: "Rozalia P.",
    quote: "Happy to have found this great company",
    link: "https://www.trustpilot.com/reviews/654131959c4b85f55a13dfed",
    img: tp_img3,
    full_text:
      "It's been a year now since I decided to invest in this very intriguing company. It took me 6 months to research them before making the step. I find Phoenix Capital Group LLC of high integrity, easy to connect with, and monthly payments are on the clock every time. Thank you to the whole team, especially Matt. Rozalia P.",
  },
  {
    name: "Robert",
    quote: "Phoenix Capital Group lives up to their word!",
    link: "https://www.trustpilot.com/reviews/63768365252cba2c02dbe723",
    img: tp_img4,
    full_text:
      "Lately I had been dissatisfied with the performance of the stock market this year and my investment kept shrinking. I was hoping to find something better and looked at several opportunities. When I saw Phoenix Capital Group and its offerings, I signed up for the webinar right away. We were in the middle of selling our home, so I didn't jump on the opportunity as fast as I wanted, and we also had some questions. Matt answered them all the next day through a personal email video. The sale of our house and the move several states away took a bit longer than I hoped, but after we got all settled in two months later, I made a substantial investment, and we got our first monthly check within the first two weeks. Everything went exactly as Matt and Cameron had said, and we couldn't be happier. Looking forward to these monthly checks and some future investing with Phoenix Capital Group.",
  },
  {
    name: "Todd G.",
    quote: "A Class Act",
    link: "https://www.trustpilot.com/reviews/65a7439a4e16a27b42fa540f",
    img: tp_img5,
    full_text:
      "From the start, the team was extremely courteous and responsive to all my questions and concerns about moving my IRA funds and setting up an account with Phoenix. Those human to human skills are extremely meaningful to me, especially in a numbers-are-important environment. I was equally touched by the thoughtful and beautiful Yeti cup for Christmas last month. It was an unexpected, classy gesture and I was hoping I'd get a chance to thank the team for creating and continuing such a welcoming, genial atmosphere with your investors. It's really refreshing!",
  },
  {
    name: "Phil N.",
    quote: "They passed my review",
    link: "https://www.trustpilot.com/reviews/654132b3f59b42212d508525",
    img: tp_img6,
    full_text:
      "Very pleased with the level of investor service provided. A year ago when I was a new/prospective investor with Phoenix I wore them out with questions as I worked to understand this alternate investment, which was new to me. Being a CPA, I wanted to challenge myself to clearly understand the underlying risks, and to identify any issues lurking under the surface. To the Company's benefit, they showed great patience and had knowledgeable resources available to answer all of my questions.",
  },
  {
    name: "Neil M.",
    quote: "Peace of Mind with Phoenix Capital Group",
    link: "https://www.trustpilot.com/reviews/646551162c3be2ffd7e986cf",
    img: tp_img7,
    full_text:
      "I have been investing for 20 years across a variety of different investments. Some have been good and some not so good. I found PCG on Facebook and thought I was do some research. They sounded good but don't they all when they are touting for investment. I then sat through one of their quarterly updates and I was surprised with the transparency and openness when answering questions, so I took the plunge. Its now 10 months on and I am thrilled with my investments. I have three bonds and getting ready to invest in another. They do what they say and deliver on a good return.",
  },
];

export const CHART_OPTIONS = {
  plugins: {
    title: {
      display: false,
      text: "Distribution Amounts",
      align: "start",
      font: { size: 14 },
    },
    legend: {
      display: true,
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          const value = tooltipItem.raw;
          return (
            "$" +
            Number(value)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        display: true,
      },
      grid: { display: false },
    },
    y: {
      stacked: true,
      ticks: {
        display: false,
      },
      title: {
        display: true,
        text: "Valuation",
        font: { size: 14 },
        color: "#6a7c8c",
      },
      grid: { display: true },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

export const JOINT_TEMPLATE = {
  is_new: true,
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  date_of_birth: "",
  ssn: "",
  occupation: "",
  employer: "",
  country: "United States",
  city: "",
  street1: "",
  street2: "",
  state: "",
  zip_code: "",
  is_owner: false,
  is_beneficial_owner: false,
  type: "Joint",
  id_info: {},
};

export const ENTITY_TEMPLATE = {
  is_new: true,
  name: "",
  phone: "",
  email: "",
  date_of_birth: "",
  ein: "",
  entity_type: "",
  country: "United States",
  city: "",
  street1: "",
  street2: "",
  state: "",
  zip_code: "",
  type: "Entity",
};

export const NEW_BENEFICIAL_OWNER_TEMPLATE = {
  is_new: true,
  uuid: crypto.randomUUID(),
  first_name: "",
  last_name: "",
  date_of_birth: "",
  ssn: "",
  country: "United States",
  state: "",
  street1: "",
  street2: "",
  city: "",
  zip_code: "",
  is_owner: false,
  is_beneficial_owner: true,
  type: "Beneficial Owner",
  id_info: {},
};

export const CONTACT_DOCUMENT_FIELD_ORDER = [
  "user_id",
  "account_id",
  "first_name",
  "last_name",
  "email",
  "is_owner",
  "phone",
  "street1",
  "street2",
  "country",
  "city",
  "state",
  "zip_code",
  "date_of_birth",
  "ssn",
];

export const ACCOUNT_DOCUMENT_FIELD_ORDER = [
  "user_id",
  "name",
  "email",
  "phone",
  "street1",
  "street2",
  "country",
  "city",
  "state",
  "zip_code",
  "ein",
  "entity_type",
];

export const INVESTMENT_DOCUMENT_FIELD_ORDER = [
  "signature_date",
  "user_id",
  "account_id",
  "contact_id",
  "offering_id",
  "status",
  "type",
  "amount",
  "is_compounded",
  "current_nav",
  "bonds",
  "payment_method",
  "cancelled_date",
  "maturity_date",
  "close_date",
  "funded_date",
  "inception_to_date_interest_paid",
  "year_to_date_interest_paid",
  "all_contacts_identity_verified",
];

export const ALL_50_STATES = [
  { name: "Alabama", value: "AL" },
  { name: "Alaska", value: "AK" },
  { name: "Arizona", value: "AZ" },
  { name: "Arkansas", value: "AR" },
  { name: "California", value: "CA" },
  { name: "Colorado", value: "CO" },
  { name: "Connecticut", value: "CT" },
  { name: "Delaware", value: "DE" },
  { name: "District Of Columbia", value: "DC" },
  { name: "Florida", value: "FL" },
  { name: "Georgia", value: "GA" },
  { name: "Hawaii", value: "HI" },
  { name: "Idaho", value: "ID" },
  { name: "Illinois", value: "IL" },
  { name: "Indiana", value: "IN" },
  { name: "Iowa", value: "IA" },
  { name: "Kansas", value: "KS" },
  { name: "Kentucky", value: "KY" },
  { name: "Louisiana", value: "LA" },
  { name: "Maine", value: "ME" },
  { name: "Maryland", value: "MD" },
  { name: "Massachusetts", value: "MA" },
  { name: "Michigan", value: "MI" },
  { name: "Minnesota", value: "MN" },
  { name: "Mississippi", value: "MS" },
  { name: "Missouri", value: "MO" },
  { name: "Montana", value: "MT" },
  { name: "Nebraska", value: "NE" },
  { name: "Nevada", value: "NV" },
  { name: "New Hampshire", value: "NH" },
  { name: "New Jersey", value: "NJ" },
  { name: "New Mexico", value: "NM" },
  { name: "New York", value: "NY" },
  { name: "North Carolina", value: "NC" },
  { name: "North Dakota", value: "ND" },
  { name: "Ohio", value: "OH" },
  { name: "Oklahoma", value: "OK" },
  { name: "Oregon", value: "OR" },
  { name: "Pennsylvania", value: "PA" },
  { name: "Rhode Island", value: "RI" },
  { name: "South Carolina", value: "SC" },
  { name: "South Dakota", value: "SD" },
  { name: "Tennessee", value: "TN" },
  { name: "Texas", value: "TX" },
  { name: "Utah", value: "UT" },
  { name: "Vermont", value: "VT" },
  { name: "Virginia", value: "VA" },
  { name: "Washington", value: "WA" },
  { name: "West Virginia", value: "WV" },
  { name: "Wisconsin", value: "WI" },
  { name: "Wyoming", value: "WY" },
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const INVESTMENT_TYPES = [
  {
    name: "Individual",
    icon: "User",
    description:
      "An individual account is an investment account that belongs only to you. No one else has rights or ownership over it.",
  },
  {
    name: "Joint",
    icon: "Users",
    description:
      "A joint account is an investment account that belongs to you and someone else. You can both make decisions over the assets. Examples of a joint account include spouse or partner.",
  },
  {
    name: "Entity",
    icon: "BriefcaseBusiness",
    description:
      "An entity account is an investment account that belongs to an organization or structure other than an individual or joint. This includes partnerships, corporations, trusts, etc. The structure of the entity dictates who has rights or ownership over it.",
  },
  {
    name: "IRA",
    icon: "BriefcaseBusiness",
    description:
      "An IRA is an Individual Retirement Account that provides tax advantages for your retirement savings. You can investment with Phoenix Capital Group through an existing Traditional, SEP, Self-Directed, Roth, or other IRA.",
  },
];

// for suitability questionnaire
export const INCOME_FIELDS = [
  {
    field_name: "current_net_worth",
    label: "Net Worth",
    number_type: "currency",
    type: "text",
    belongs_to_nested_object: true,
    nested_object_name: "suitability_info",
    is_required: true,
  },
  {
    field_name: "current_liquid_net_worth",
    label: "Liquid Net Worth",
    number_type: "currency",
    type: "text",
    belongs_to_nested_object: true,
    nested_object_name: "suitability_info",
    is_required: true,
  },
  {
    field_name: "current_income",
    label: "Current Income",
    number_type: "currency",
    type: "text",
    belongs_to_nested_object: true,
    nested_object_name: "suitability_info",
    is_required: true,
  },
  {
    field_name: "source_of_income",
    label: "Income Source",
    type: "text",
    belongs_to_nested_object: true,
    nested_object_name: "suitability_info",
    is_required: true,
  },
];

export const PERSONAL_FIELDS = [
  {
    field_name: "first_name",
    label: "First Name",
    type: "text",
    section: "personal_info",
    is_required: true,
  },
  {
    field_name: "last_name",
    label: "Last Name",
    type: "text",
    section: "personal_info",
    is_required: true,
  },
  {
    field_name: "phone",
    label: "Phone Number",
    type: "text",
    formatting: "phone",
    section: "personal_info",
    is_required: true,
  },
  {
    field_name: "email",
    label: "Email",
    type: "text",
    section: "personal_info",
    read_only: true,
    is_required: true,
  },
  {
    field_name: "date_of_birth",
    label: "Date of Birth",
    type: "date",
    placeholder: "MM/DD/YYYY",
    section: "personal_info",
    is_required: true,
  },
  {
    field_name: "ssn",
    label: "Social Security Number",
    type: "password",
    section: "personal_info",
    maxLength: "11",
    is_required: true,
  },
  {
    field_name: "occupation",
    label: "Occupation",
    type: "text",
    accreditedOnly: true,
    section: "personal_info",
    is_required: true,
  },
  {
    field_name: "employer",
    label: "Employer",
    type: "text",
    accreditedOnly: true,
    section: "personal_info",
    is_required: true,
  },
  {
    field_name: "country",
    label: "Country",
    type: "select",
    dropdownOptions: ["United States"],
    read_only: true,
    section: "residential_address",
    is_required: true,
  },
  {
    field_name: "city",
    label: "City",
    type: "text",
    section: "residential_address",
    is_required: true,
  },
  {
    field_name: "street1",
    label: "Street",
    type: "text",
    section: "residential_address",
    is_required: true,
  },
  {
    field_name: "street2",
    label: "Street 2 (Optional)",
    type: "text",
    section: "residential_address",
  },
  {
    field_name: "state",
    label: "State",
    type: "select",
    dropdownOptions: [
      { value: "AL", name: "Alabama" },
      { value: "AK", name: "Alaska" },
      { value: "AZ", name: "Arizona" },
      { value: "AR", name: "Arkansas" },
      { value: "CA", name: "California" },
      { value: "CO", name: "Colorado" },
      { value: "CT", name: "Connecticut" },
      { value: "DE", name: "Delaware" },
      { value: "DC", name: "District Of Columbia" },
      { value: "FL", name: "Florida" },
      { value: "GA", name: "Georgia" },
      { value: "HI", name: "Hawaii" },
      { value: "ID", name: "Idaho" },
      { value: "IL", name: "Illinois" },
      { value: "IN", name: "Indiana" },
      { value: "IA", name: "Iowa" },
      { value: "KS", name: "Kansas" },
      { value: "KY", name: "Kentucky" },
      { value: "LA", name: "Louisiana" },
      { value: "ME", name: "Maine" },
      { value: "MD", name: "Maryland" },
      { value: "MA", name: "Massachusetts" },
      { value: "MI", name: "Michigan" },
      { value: "MN", name: "Minnesota" },
      { value: "MS", name: "Mississippi" },
      { value: "MO", name: "Missouri" },
      { value: "MT", name: "Montana" },
      { value: "NE", name: "Nebraska" },
      { value: "NV", name: "Nevada" },
      { value: "NH", name: "New Hampshire" },
      { value: "NJ", name: "New Jersey" },
      { value: "NM", name: "New Mexico" },
      { value: "NY", name: "New York" },
      { value: "NC", name: "North Carolina" },
      { value: "ND", name: "North Dakota" },
      { value: "OH", name: "Ohio" },
      { value: "OK", name: "Oklahoma" },
      { value: "OR", name: "Oregon" },
      { value: "PA", name: "Pennsylvania" },
      { value: "RI", name: "Rhode Island" },
      { value: "SC", name: "South Carolina" },
      { value: "SD", name: "South Dakota" },
      { value: "TN", name: "Tennessee" },
      { value: "TX", name: "Texas" },
      { value: "UT", name: "Utah" },
      { value: "VT", name: "Vermont" },
      { value: "VA", name: "Virginia" },
      { value: "WA", name: "Washington" },
      { value: "WV", name: "West Virginia" },
      { value: "WI", name: "Wisconsin" },
      { value: "WY", name: "Wyoming" },
    ],
    section: "residential_address",
    is_required: true,
  },
  {
    field_name: "zip_code",
    label: "Zip Code",
    type: "text",
    section: "residential_address",
    is_required: true,
  },
  {
    field_name: "id_number",
    label: "ID Number",
    type: "text",
    subpath: "id_info",
    section: "id_information",
    is_required: true,
  },
  {
    field_name: "id_issued_by",
    label: "ID Issued By",
    type: "text",
    subpath: "id_info",
    section: "id_information",
    is_required: true,
  },
  {
    field_name: "id_issued_date",
    label: "ID Issued Date",
    type: "date",
    subpath: "id_info",
    section: "id_information",
    is_required: true,
  },
  {
    field_name: "id_expiration_date",
    label: "ID Expiration Date",
    type: "date",
    subpath: "id_info",
    section: "id_information",
    is_required: true,
  },
];

export const JOINT_FIELDS = [
  {
    field_name: "first_name",
    label: "First Name",
    type: "text",
    section: "joint_info",
    is_required: true,
  },
  {
    field_name: "last_name",
    label: "Last Name",
    type: "text",
    section: "joint_info",
    is_required: true,
  },
  {
    field_name: "phone",
    label: "Phone Number",
    type: "text",
    formatting: "phone",
    section: "joint_info",
    is_required: true,
  },
  { field_name: "email", label: "Email", type: "text", section: "joint_info", is_required: true },
  {
    field_name: "date_of_birth",
    label: "Date of Birth",
    type: "date",
    placeholder: "MM/DD/YYYY",
    section: "joint_info",
    is_required: true,
  },
  {
    field_name: "ssn",
    label: "Social Security Number",
    type: "password",
    section: "joint_info",
    maxLength: "11",
    is_required: true,
  },
  {
    field_name: "occupation",
    label: "Occupation",
    type: "text",
    accreditedOnly: true,
    section: "joint_info",
    is_required: true,
  },
  {
    field_name: "employer",
    label: "Employer",
    type: "text",
    accreditedOnly: true,
    section: "joint_info",
    is_required: true,
  },
  {
    field_name: "country",
    label: "Country",
    type: "select",
    dropdownOptions: ["United States"],
    read_only: true,
    section: "residential_address",
    is_required: true,
  },
  {
    field_name: "city",
    label: "City",
    type: "text",
    section: "residential_address",
    is_required: true,
  },
  {
    field_name: "street1",
    label: "Street",
    type: "text",
    section: "residential_address",
    is_required: true,
  },
  {
    field_name: "street2",
    label: "Street 2 (Optional)",
    type: "text",
    section: "residential_address",
  },
  {
    field_name: "state",
    label: "State",
    type: "select",
    is_required: true,
    dropdownOptions: [
      { value: "AL", name: "Alabama" },
      { value: "AK", name: "Alaska" },
      { value: "AZ", name: "Arizona" },
      { value: "AR", name: "Arkansas" },
      { value: "CA", name: "California" },
      { value: "CO", name: "Colorado" },
      { value: "CT", name: "Connecticut" },
      { value: "DE", name: "Delaware" },
      { value: "DC", name: "District Of Columbia" },
      { value: "FL", name: "Florida" },
      { value: "GA", name: "Georgia" },
      { value: "HI", name: "Hawaii" },
      { value: "ID", name: "Idaho" },
      { value: "IL", name: "Illinois" },
      { value: "IN", name: "Indiana" },
      { value: "IA", name: "Iowa" },
      { value: "KS", name: "Kansas" },
      { value: "KY", name: "Kentucky" },
      { value: "LA", name: "Louisiana" },
      { value: "ME", name: "Maine" },
      { value: "MD", name: "Maryland" },
      { value: "MA", name: "Massachusetts" },
      { value: "MI", name: "Michigan" },
      { value: "MN", name: "Minnesota" },
      { value: "MS", name: "Mississippi" },
      { value: "MO", name: "Missouri" },
      { value: "MT", name: "Montana" },
      { value: "NE", name: "Nebraska" },
      { value: "NV", name: "Nevada" },
      { value: "NH", name: "New Hampshire" },
      { value: "NJ", name: "New Jersey" },
      { value: "NM", name: "New Mexico" },
      { value: "NY", name: "New York" },
      { value: "NC", name: "North Carolina" },
      { value: "ND", name: "North Dakota" },
      { value: "OH", name: "Ohio" },
      { value: "OK", name: "Oklahoma" },
      { value: "OR", name: "Oregon" },
      { value: "PA", name: "Pennsylvania" },
      { value: "RI", name: "Rhode Island" },
      { value: "SC", name: "South Carolina" },
      { value: "SD", name: "South Dakota" },
      { value: "TN", name: "Tennessee" },
      { value: "TX", name: "Texas" },
      { value: "UT", name: "Utah" },
      { value: "VT", name: "Vermont" },
      { value: "VA", name: "Virginia" },
      { value: "WA", name: "Washington" },
      { value: "WV", name: "West Virginia" },
      { value: "WI", name: "Wisconsin" },
      { value: "WY", name: "Wyoming" },
    ],
    section: "residential_address",
  },
  {
    field_name: "zip_code",
    label: "Zip Code",
    type: "text",
    section: "residential_address",
    is_required: true,
  },
  {
    field_name: "id_number",
    label: "ID Number",
    type: "text",
    subpath: "id_info",
    section: "id_information",
    is_required: true,
  },
  {
    field_name: "id_issued_by",
    label: "ID Issued By",
    type: "text",
    subpath: "id_info",
    section: "id_information",
    is_required: true,
  },
  {
    field_name: "id_issued_date",
    label: "ID Issued Date",
    type: "date",
    subpath: "id_info",
    section: "id_information",
    is_required: true,
  },
  {
    field_name: "id_expiration_date",
    label: "ID Expiration Date",
    type: "date",
    subpath: "id_info",
    section: "id_information",
    is_required: true,
  },
];

export const ENTITY_FIELDS = [
  {
    field_name: "name",
    label: "Entity Name",
    type: "text",
    section: "entity_info",
    is_required: true,
  },
  {
    field_name: "entity_type",
    label: "Entity Type",
    type: "select",
    is_required: true,
    dropdownOptions: [
      "LLC",
      "Corporation",
      "Revocable Partnership",
      "Revocable Trust",
      "Irrevocable Trust",
      "Qualified Pension or Profit Sharing Plan",
      "Partnership",
      "Defined Benefit Plan",
      "Trust",
      "Other",
    ],
    section: "entity_info",
  },
  {
    field_name: "phone",
    label: "Phone Number",
    type: "text",
    formatting: "phone",
    section: "entity_info",
    is_required: true,
  },
  {
    field_name: "ein",
    label: "EIN",
    type: "password",
    section: "entity_info",
    maxLength: "16",
    is_required: true,
  },
  {
    field_name: "country",
    label: "Country",
    type: "select",
    dropdownOptions: ["United States"],
    read_only: true,
    section: "entity_address",
    is_required: true,
  },
  {
    field_name: "city",
    label: "City",
    type: "text",
    section: "entity_address",
    is_required: true,
  },
  {
    field_name: "street1",
    label: "Street",
    type: "text",
    section: "entity_address",
    is_required: true,
  },
  {
    field_name: "street2",
    label: "Street 2 (Optional)",
    type: "text",
    section: "entity_address",
  },
  {
    field_name: "state",
    label: "State",
    type: "select",
    is_required: true,
    dropdownOptions: [
      { value: "AL", name: "Alabama" },
      { value: "AK", name: "Alaska" },
      { value: "AZ", name: "Arizona" },
      { value: "AR", name: "Arkansas" },
      { value: "CA", name: "California" },
      { value: "CO", name: "Colorado" },
      { value: "CT", name: "Connecticut" },
      { value: "DE", name: "Delaware" },
      { value: "DC", name: "District Of Columbia" },
      { value: "FL", name: "Florida" },
      { value: "GA", name: "Georgia" },
      { value: "HI", name: "Hawaii" },
      { value: "ID", name: "Idaho" },
      { value: "IL", name: "Illinois" },
      { value: "IN", name: "Indiana" },
      { value: "IA", name: "Iowa" },
      { value: "KS", name: "Kansas" },
      { value: "KY", name: "Kentucky" },
      { value: "LA", name: "Louisiana" },
      { value: "ME", name: "Maine" },
      { value: "MD", name: "Maryland" },
      { value: "MA", name: "Massachusetts" },
      { value: "MI", name: "Michigan" },
      { value: "MN", name: "Minnesota" },
      { value: "MS", name: "Mississippi" },
      { value: "MO", name: "Missouri" },
      { value: "MT", name: "Montana" },
      { value: "NE", name: "Nebraska" },
      { value: "NV", name: "Nevada" },
      { value: "NH", name: "New Hampshire" },
      { value: "NJ", name: "New Jersey" },
      { value: "NM", name: "New Mexico" },
      { value: "NY", name: "New York" },
      { value: "NC", name: "North Carolina" },
      { value: "ND", name: "North Dakota" },
      { value: "OH", name: "Ohio" },
      { value: "OK", name: "Oklahoma" },
      { value: "OR", name: "Oregon" },
      { value: "PA", name: "Pennsylvania" },
      { value: "RI", name: "Rhode Island" },
      { value: "SC", name: "South Carolina" },
      { value: "SD", name: "South Dakota" },
      { value: "TN", name: "Tennessee" },
      { value: "TX", name: "Texas" },
      { value: "UT", name: "Utah" },
      { value: "VT", name: "Vermont" },
      { value: "VA", name: "Virginia" },
      { value: "WA", name: "Washington" },
      { value: "WV", name: "West Virginia" },
      { value: "WI", name: "Wisconsin" },
      { value: "WY", name: "Wyoming" },
    ],
    section: "entity_address",
  },
  {
    field_name: "zip_code",
    label: "Zip Code",
    type: "text",
    section: "entity_address",
    is_required: true,
  },
];

export const BENEFICIAL_OWNER_FIELDS = [
  {
    field_name: "first_name",
    label: "First Name",
    type: "text",
    section: "beneficial_owner_info",
    is_required: true,
  },
  {
    field_name: "last_name",
    label: "Last Name",
    type: "text",
    section: "beneficial_owner_info",
    is_required: true,
  },
  {
    field_name: "date_of_birth",
    label: "Date of Birth",
    type: "date",
    placeholder: "MM/DD/YYYY",
    section: "beneficial_owner_info",
    is_required: true,
  },
  {
    field_name: "ssn",
    label: "Social Security Number",
    type: "password",
    section: "beneficial_owner_info",
    is_required: true,
    maxLength: "11",
  },
  {
    field_name: "country",
    label: "Country",
    type: "select",
    dropdownOptions: ["United States"],
    read_only: true,
    section: "bo_residential_address",
    is_required: true,
  },
  {
    field_name: "state",
    label: "State",
    type: "select",
    is_required: true,
    dropdownOptions: [
      { value: "AL", name: "Alabama" },
      { value: "AK", name: "Alaska" },
      { value: "AZ", name: "Arizona" },
      { value: "AR", name: "Arkansas" },
      { value: "CA", name: "California" },
      { value: "CO", name: "Colorado" },
      { value: "CT", name: "Connecticut" },
      { value: "DE", name: "Delaware" },
      { value: "DC", name: "District Of Columbia" },
      { value: "FL", name: "Florida" },
      { value: "GA", name: "Georgia" },
      { value: "HI", name: "Hawaii" },
      { value: "ID", name: "Idaho" },
      { value: "IL", name: "Illinois" },
      { value: "IN", name: "Indiana" },
      { value: "IA", name: "Iowa" },
      { value: "KS", name: "Kansas" },
      { value: "KY", name: "Kentucky" },
      { value: "LA", name: "Louisiana" },
      { value: "ME", name: "Maine" },
      { value: "MD", name: "Maryland" },
      { value: "MA", name: "Massachusetts" },
      { value: "MI", name: "Michigan" },
      { value: "MN", name: "Minnesota" },
      { value: "MS", name: "Mississippi" },
      { value: "MO", name: "Missouri" },
      { value: "MT", name: "Montana" },
      { value: "NE", name: "Nebraska" },
      { value: "NV", name: "Nevada" },
      { value: "NH", name: "New Hampshire" },
      { value: "NJ", name: "New Jersey" },
      { value: "NM", name: "New Mexico" },
      { value: "NY", name: "New York" },
      { value: "NC", name: "North Carolina" },
      { value: "ND", name: "North Dakota" },
      { value: "OH", name: "Ohio" },
      { value: "OK", name: "Oklahoma" },
      { value: "OR", name: "Oregon" },
      { value: "PA", name: "Pennsylvania" },
      { value: "RI", name: "Rhode Island" },
      { value: "SC", name: "South Carolina" },
      { value: "SD", name: "South Dakota" },
      { value: "TN", name: "Tennessee" },
      { value: "TX", name: "Texas" },
      { value: "UT", name: "Utah" },
      { value: "VT", name: "Vermont" },
      { value: "VA", name: "Virginia" },
      { value: "WA", name: "Washington" },
      { value: "WV", name: "West Virginia" },
      { value: "WI", name: "Wisconsin" },
      { value: "WY", name: "Wyoming" },
    ],
    section: "bo_residential_address",
  },
  {
    field_name: "street1",
    label: "Street",
    type: "text",
    section: "bo_residential_address",
    is_required: true,
  },
  {
    field_name: "street2",
    label: "Street 2 (Optional)",
    type: "text",
    section: "bo_residential_address",
  },
  {
    field_name: "city",
    label: "City",
    type: "text",
    section: "bo_residential_address",
    is_required: true,
  },
  {
    field_name: "zip_code",
    label: "Zip Code",
    type: "text",
    section: "bo_residential_address",
    is_required: true,
  },
  {
    field_name: "id_number",
    label: "ID Number",
    type: "text",
    subpath: "id_info",
    section: "bo_id_information",
    is_required: true,
  },
  {
    field_name: "id_issued_by",
    label: "ID Issued By",
    type: "text",
    subpath: "id_info",
    section: "bo_id_information",
    is_required: true,
  },
  {
    field_name: "id_issued_date",
    label: "ID Issued Date",
    type: "date",
    subpath: "id_info",
    section: "bo_id_information",
    is_required: true,
  },
  {
    field_name: "id_expiration_date",
    label: "ID Expiration Date",
    type: "date",
    subpath: "id_info",
    section: "bo_id_information",
    is_required: true,
  },
];

export const QUESTIONNAIRE_QUESTIONS_ANSWERS = [
  {
    field_name: "investment_objective",
    question: "My overall investment objective",
    answers: [
      "Capital Preservation- you seek to preserve capital and are willing to accept a lower rate of return in exchange",
      "Income- you seek investments with periodic distributions in exchange for capital appreciation",
      "Growth & Income- you seek a moderate increase in investment value in combination with some income",
      "Growth- you seek growth in the value of your investment",
      "Aggressive Growth – seek investment opportunities with very high growth potential",
    ],
  },
  {
    field_name: "investment_experience",
    question: "My relevant investment experience",
    answers: [
      "Less than 2 years",
      "Between 2 and 5 years",
      "Between 5 and 10 years",
      "Greater than 10 years",
    ],
  },
  {
    field_name: "risk_willingness",
    question: "My willingness to accept risk for this investment",
    answers: ["Conservative", "Moderate", "Significant", "Speculative"],
  },
  {
    field_name: "equities_allocation",
    question: "My current portfolio investment allocation for equities",
    answers: [
      "None",
      "Less than $250,000 USD",
      "Greater than $250,000 USD",
      "Prefer not to answer",
    ],
  },
  {
    field_name: "bonds_allocation",
    question: "My current portfolio investment allocation for bonds",
    answers: [
      "None",
      "Less than $250,000 USD",
      "Greater than $250,000 USD",
      "Prefer not to answer",
    ],
  },
  {
    field_name: "real_estate_allocation",
    question: "My current portfolio investment allocation for real estate",
    answers: [
      "None",
      "Less than $250,000 USD",
      "Greater than $250,000 USD",
      "Prefer not to answer",
    ],
  },
  {
    field_name: "other_investments_allocation",
    question:
      "My current portfolio investment allocation for other investments (private securities, alternative investments, etc.)",
    answers: [
      "None",
      "Less than $250,000 USD",
      "Greater than $250,000 USD",
      "Prefer not to answer",
    ],
  },
  {
    field_name: "overall_portfolio_percentage",
    question: "This corporate bond investment is what percentage of your overall portfolio?",
    answers: ["0% – 10%", "10% - 25%", "26% and over", "Prefer not to answer"],
  },
  {
    field_name: "annual_living_expenses",
    question: "My approximate annual living expenses",
    answers: [
      "Less than $50,000",
      "Between $50,000 and $100,000",
      "Greater than $100,000",
      "Not applicable-if investing as an entity",
      "Prefer not to answer",
    ],
  },
  {
    field_name: "marginal_tax_rate",
    question: "My marginal personal tax rate",
    answers: ["Less than 20%", "Between 20%-30%", "Greater than 30%", "Not applicable"],
  },
  {
    field_name: "significant_liquid_net_worth",
    question:
      "I have significant liquid net worth, excluding the funds for my investment, to maintain my quality of life and understand that this investment is illiquid, and I may not be able to access invested funds for an extended amount of time.",
    answers: ["Yes", "No"],
  },
  {
    field_name: "risky_investment_understanding",
    question:
      "I understand that an investment in private securities has risks, is not guaranteed, and that this it is an illiquid investment with set terms that may not align with my overall investment strategy as answered in Question 1.",
    answers: ["Yes", "No"],
  },
  {
    field_name: "investment_time_horizon",
    question: "What is your time horizon for this investment?",
    answers: ["1-5 years", "6-10 years", "Over 10 years"],
  },
  {
    field_name: "self_responsibility",
    question:
      "I have conducted my own due diligence, I am making this investment on my own accord, and I will not hold anyone other than myself responsible for any losses that may result from this investment choice.",
    answers: ["Yes", "No"],
  },
];

export const ESIGNATURE_OPTIONS = [
  {
    type: "investor_acknowledgement_and_representation",
    options: [
      "I have an individual net worth, or joint net worth with my spouse (or spousal equivalent), of more than $1 million excluding primary residence (see the Memorandum under “SUITABILITY STANDARDS”) but including home furnishings and personal automobiles.",
      "I have individual income in excess of $200,000 or joint income with my spouse (or spousal equivalent) in excess of $300,000, in each of the two most recent years and I have a reasonable expectation of reaching the same income level in the current year.",
      "I am a holder in good standing of one or more of certain professional certifications or designations, including the Financial Industry Regulatory Authority, Inc. Licensed General Securities Representative (Series 7), Licensed Investment Adviser Representative (Series 65), or Licensed Private Securities Offerings Representative (Series 82) certifications.",
      "I am one or more of the following: director, officer, or general partner of the Company or person serving in a similar capacity, or any director, officer, general partner, or person serving in a similar capacity of the general partner of the Company.",
    ],
  },
  {
    type: "entity_acknowledgements",
    options: [
      "Any entity in which all of the equity owners are accredited investors.",
      "Any entity with investments (as defined in Section 2a51-1(b) of the Investment Company Act) exceeding $5,000,000, not formed for the specific purpose of acquiring Bonds.",
      "Any investment adviser registered under the Investment Advisers Act of 1940, as amended (the “Advisers Act”), or an exempt reporting adviser (as defined in Section 203(l) or Section 203(m) of the Advisers Act), or a state-registered investment adviser.",
      "Any trust, with total assets over $5,000,000, not formed for the specific purpose of acquiring Bonds and whose purchase is directed by a person who has such knowledge and experience in financial and business matters that he or she is capable of evaluating the merits and risks of an investment in the Bonds as described in Rule 506(b)(2)(ii) under the Securities Act.",
      "Any family client of a family office with total assets of at least $5,000,000, not formed for the specific purpose of acquiring Bonds and whose purchase is directed by a person who has such knowledge and experience in financial and business matters that the family office is capable of evaluating the merits and risks of an investment in Bonds as described in Section 202(a)(11)(G)-1(b) under the Advisers Act.",
      "Any broker-dealer registered under Section 15 of the Securities Exchange Act of 1934, as amended.",
      "Any investment company registered under the Investment Company Act or a business development company (as defined in Section 2(a)(48) of the Investment Company Act).",
      "Any small business investment company licensed by the Small Business Administration under Section 301(c) or (d) or the Small Business Investment Act of 1958, as amended.",
      "Any rural business investment company (as defined in Section 384A of the Consolidated Farm and Rural Development Act).",
      "Any employee benefit plan within the meaning of ERISA, if the investment decision is made by a plan fiduciary (as defined in Section 3(21) of ERISA), which is either a bank, savings and loan association, insurance company, or registered investment advisor, or if such employee benefit plan has total assets over $5,000,000 or, if a self-directed plan, with investment decisions made solely by persons who are accredited investors.",
      "Any private business development company (as defined in Section 202(a)(22) of the Advisers Act).",
      "Any bank as defined in Section 3(a)(2) of the Securities Act, any savings and loan association or other institution as defined in Section 3(a)(5)(A) of the Securities Act whether acting in its individual or fiduciary capacity, or any insurance company as defined in Section 2(13) of the Securities Act.",
      "Any plan established and maintained by a state, its political subdivisions, or any agency or instrumentality of a state or its political subdivisions, for the benefit of its employees, if such plan has total assets of more than $5,000,000.",
      "Any organization described in Code Section 501(c)(3), corporation, Massachusetts or similar business trust, partnership, or limited liability company, not formed for the specific purpose of acquiring Bonds, with total assets over $5,000,000.",
    ],
  },
  {
    type: "benefit_plan_investor",
    options: [
      "The entity is purchasing the Bonds with funds that constitute, directly or indirectly, the assets of a Benefit Plan Investor (defined below). The entity hereby represents and warrants that its investment in the Company: (i) does not violate and is not otherwise inconsistent with the terms of any legal document constituting or governing the employee benefit plan; (ii) has been duly authorized and approved by all necessary parties; and (iii) is in compliance with all applicable laws.",
      "The entity is not purchasing the Bonds with funds that constitute, directly or indirectly, the assets of a “Benefit Plan Investor” (defined below).",
    ],
  },
];

export const ACCREDITATION_QUALIFIERS = [
  "A letter from CPA, Attorney, or Financial Professional with an active Series 7, Series 65, or Series 82 license.",
  "Proof of assets worth more than $1 million by reviewing specific types of documentation dated within the last 90 days, such as bank statements, brokerage statements, certificates of deposit, or tax assessments.",
  "Individual - Proof of income for 2 years of at least $200,000 by reviewing copies of any Internal Revenue Service form that reports income, such as Form W-2, Form 1099, Schedule K-1 of Form 1065, or a filed Form 1040.",
  "Joint - Proof of income for 2 years of at least $300,000 by reviewing copies of any Internal Revenue Service form that reports income, such as Form W-2, Form 1099, Schedule K-1 of Form 1065, or a filed Form 1040.",
];

export const IRA_QUESTIONNAIRE = [
  {
    question: "Do you currently have an IRA account?",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    field_name: "has_ira_account",
    type: "radio",
    is_required: true,
  },
  {
    question: "Are you in a self-directed IRA?",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    field_name: "in_self_directed_ira",
    type: "radio",
    is_required: true,
  },
  {
    question:
      "Do you have other Qualified funds you wish to invest (i.e. 401k from a previous employer, etc.)",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    field_name: "has_other_qualified_funds",
    type: "radio",
    is_required: true,
  },
  {
    question: "If you selected Yes to Question 1, please enter the name of your current Custodian",
    field_name: "custodian",
    type: "text",
  },
];
