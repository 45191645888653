<template>
  <div class="flex flex-col items-center">
    <!-- Circle Progress Indicator -->
    <svg viewBox="0 0 36 36" class="circular-chart w-10 h-10">
      <path
        class="circle-bg"
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        class="circle"
        :stroke-dasharray="circleDashArray"
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
      />
    </svg>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  currentStep: Number,
  totalSteps: Number,
});

const circleDashArray = computed(() => {
  const percentage = (props.currentStep / props.totalSteps) * 100;
  return `${percentage}, 100`;
});
</script>

<style scoped>
.circle-bg {
  fill: none;
  stroke: #e6e6e6;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 3.8;
  stroke: #3498db; /* Blue color */
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
}
</style>
