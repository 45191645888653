<template>
  <div class="h-full w-full p-6">
    <div
      class="flex flex-col w-full md:border border-platform-outlines rounded-md h-full items-center md:justify-center mt-12 md:mt-0"
    >
      <button
        type="button"
        @click="handleBack"
        class="flex items-center justify-center gap-1.5 absolute top-24 left-6 md:left-10"
      >
        <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
        Back
      </button>
      <div class="w-full md:max-w-[23.813rem] mt-4 md:mt-0">
        <AuthStepper :numSteps="9" />
        <h3 class="font-bold text-2xl mb-8 text-primary-6">Create a password</h3>
        <div class="flex flex-col gap-4 mb-6 w-full">
          <div>
            <BaseInput
              v-model="authStore.signUpFields.password"
              label="Password"
              placeholder="Enter your password"
              size="large"
              type="password"
              required
              @focus="typingPassword = true"
              @blur="validatePassword(), matchPasswords()"
              @input="validatePassword(), matchPasswords()"
              @keydown.enter="handleNextStep"
            />

            <p v-if="typingPassword" class="mt-2 text-sm">
              <span :class="getCriteriaClass(passwordCriteria.length)">8 Characters · </span>
              <span :class="getCriteriaClass(passwordCriteria.uppercase)">
                1 Uppercase Letter ·
              </span>
              <span :class="getCriteriaClass(passwordCriteria.number)">1 Number · </span>
              <span :class="getCriteriaClass(passwordCriteria.special)">1 Special Character</span>
            </p>
          </div>
          <div>
            <BaseInput
              v-model="authStore.signUpFields.confirm_password"
              label="Confirm Password"
              placeholder="Enter your password"
              size="large"
              type="password"
              required
              @blur="matchPasswords"
              @input="matchPasswords"
              @keydown.enter="handleNextStep"
            />
            <p v-if="!passwordsMatch" class="mt-2 text-sm text-red-500">Passwords do not match.</p>
          </div>
        </div>
        <Button
          @click="handleNextStep"
          @keydown.enter="handleNextStep"
          :disabled="!isFormValid"
          label
          size="large"
          class="w-full"
        >
          <template #label>Next</template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores";
import { BaseInput, Button, Icon } from "@/components";
import AuthStepper from "./AuthStepper.vue";

const router = useRouter();
const authStore = useAuthStore();

const typingPassword = ref(false);
const passwordsMatch = ref(true);
const passwordCriteria = ref({
  length: false,
  uppercase: false,
  number: false,
  special: false,
});

onMounted(() => {
  window.scrollTo(0, 0);
  if (!authStore.signUpFields.email) {
    router.push("/sign-up");
  }
  validatePassword();
});

const isFormValid = computed(() => {
  return (
    passwordCriteria.value.length &&
    passwordCriteria.value.uppercase &&
    passwordCriteria.value.number &&
    passwordCriteria.value.special &&
    passwordsMatch.value
  );
});

function handleNextStep() {
  if (!isFormValid.value) return;
  authStore.activeSignupStep = 3;
  router.push("/sign-up/acknowledgements");
}

function handleBack() {
  authStore.activeSignupStep = 1;
  router.push("/sign-up");
}

// Ensure password meets requirements
function validatePassword() {
  const pwd = authStore.signUpFields.password;
  passwordCriteria.value.length = pwd.length >= 8;
  passwordCriteria.value.uppercase = /[A-Z]/.test(pwd);
  passwordCriteria.value.number = /\d/.test(pwd);
  passwordCriteria.value.special = /[!@#$%^&*(),.?":{}|<>]/.test(pwd);
}

function getCriteriaClass(isValid) {
  return isValid ? "text-green-500" : "text-red-500";
}

// Ensure password and confirm password fields match
function matchPasswords() {
  if (authStore.signUpFields.password === authStore.signUpFields.confirm_password) {
    passwordsMatch.value = true;
  } else {
    passwordsMatch.value = false;
  }
}
</script>
