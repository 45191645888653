<template>
  <div
    @click="$emit('select-offering', offering)"
    class="bg-white rounded-lg p-4 col-span-1 border-platform-outlines flex flex-col gap-1 border hover:shadow-full w-full min-w-[18.75rem] lg:min-w-[0]"
    :class="[
      isAmountFieldsInvalid && !ignoreDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
      investmentStore.selectedOffering === offering
        ? 'border-primary-6'
        : 'border-platform-outlines',
    ]"
  >
    <h3 class="text-lg font-semibold">{{ offering.bond_term / 12 }}-Year Term Bond</h3>
    <div class="flex items-center gap-2">
      <button type="button" :class="pillStyles(pill)">
        <p class="mt-[1px]">{{ offering.type }}</p>
      </button>
      <!-- <button type="button" v-for="pill in offeringCategories" :class="pillStyles(pill)">
        <p class="mt-[1px]">{{ pill }}</p>
      </button> -->
    </div>
    <hr class="border-platform-outlines my-2" />
    <div class="flex justify-between items-center">
      <p class="text-lighter whitespace-nowrap">Minimum Investment</p>
      <p>${{ commaSeparateThousands_2(offering.minimum_investment) }}</p>
    </div>
    <hr class="border-platform-outlines my-2" />
    <div class="flex justify-between items-center">
      <p class="text-lighter whitespace-nowrap">Total Paid to Investors</p>
      <p>${{ commaSeparateThousands_2(offering.total_raised) }}</p>
    </div>
    <hr class="border-platform-outlines my-2" />
    <div class="flex justify-between items-center">
      <p class="text-lighter whitespace-nowrap">Annual Yield</p>
      <p>{{ offering.interest_rate }}%</p>
    </div>
    <hr class="border-platform-outlines my-2" />
    <div class="flex justify-between items-center">
      <p class="text-lighter whitespace-nowrap">Anticipated Earnings</p>
      <p>
        ${{
          offering.anticipated_earnings ? commaSeparateThousands(offering.anticipated_earnings) : 0
        }}
      </p>
    </div>
    <hr class="border-platform-outlines my-2" />
    <Button
      @click="$emit('handle-next', offering)"
      label
      size="large"
      :disabled="isAmountFieldsInvalid && !ignoreDisabled"
      :class="isAmountFieldsInvalid && !ignoreDisabled ? '!bg-[#8c8c8c]' : ''"
    >
      <template #label>
        {{
          remainingInvestment > 0 && !ignoreDisabled
            ? `Invest $${commaSeparateThousands_2(remainingInvestment)} More to Unlock`
            : "Invest"
        }}
      </template>
    </Button>
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from "vue";

import { useInvestmentStore } from "@/stores";
import { Button } from "@/components";
import { commaSeparateThousands_2, commaSeparateThousands } from "@/utilities";

const investmentStore = useInvestmentStore();

const props = defineProps({
  offering: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  ignoreDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["select-offering", "handle-next"]);

onMounted(() => {
  investmentStore.getAnticipatedEarnings();
});

const offeringCategories = computed(() => {
  const categories = ["Most Popular", "Fit your investor profile", "Best Value"];
  // pick 2 random categories plus the type
  return [props.offering.type, ...categories.sort(() => Math.random() - 0.5).slice(0, 1)];
});

const remainingInvestment = computed(() => {
  const currentAmount = Number(investmentStore.currentInvestment.amount.replace(/,/g, ""));
  let minimumInvestment = 1000;

  const selectedOffering = props.offering;

  if (selectedOffering) {
    if (selectedOffering.type === "Reg D") {
      minimumInvestment = investmentStore.hasAccreditedInvestments ? 1000 : 25000;
    } else if (selectedOffering.type === "Reg A" || selectedOffering.type === "S1") {
      minimumInvestment = 1000;
    }
  }

  const remaining = minimumInvestment - currentAmount;

  // If the current amount is greater than or equal to the minimum, no remaining amount is needed
  return remaining > 0 ? remaining : 0;
});

const isAmountFieldsInvalid = computed(() => {
  const currentAmount = Number(investmentStore.currentInvestment.amount.replace(/,/g, ""));

  // Check if a rollover investment is selected
  const hasRolloverInvestment = !!investmentStore.selected_rollover_investment;

  // Get available rollover amount if applicable
  const rolloverAvailable = hasRolloverInvestment
    ? Number(investmentStore.selected_rollover_investment.rollover_available)
    : null;

  // Determine the minimum investment based on the selected offering type
  const selectedOffering = props.offering;

  // Default minimum investment
  let minimumInvestment = 1000;

  if (selectedOffering) {
    if (selectedOffering.type === "Reg D") {
      minimumInvestment = investmentStore.hasAccreditedInvestments ? 1000 : 25000;
    } else if (selectedOffering.type === "Reg A" || selectedOffering.type === "S1") {
      minimumInvestment = 1000;
    }
  }

  // Check if the current amount is invalid (below minimum required)
  const isAmountInvalid =
    !investmentStore.currentInvestment.amount ||
    investmentStore.currentInvestment.amount.length < 4 ||
    currentAmount < minimumInvestment;

  // Check if compounded status is invalid
  const isCompoundedInvalid =
    investmentStore.currentInvestment.is_compounded === null ||
    investmentStore.currentInvestment.is_compounded === undefined;

  // Check if the entered amount exceeds the available rollover amount
  const isRolloverAmountInvalid = hasRolloverInvestment && currentAmount > rolloverAvailable;

  // Return true if any of these conditions are invalid
  return isAmountInvalid || isCompoundedInvalid || isRolloverAmountInvalid;
});

function pillStyles(category) {
  switch (category) {
    case "Reg D":
      return "bg-secondary-6 bg-opacity-20 text-secondary-6 text-xs lg:text-sm py-0.5 rounded-full px-3 whitespace-nowrap";
    case "Reg A+":
      return "bg-secondary-6 bg-opacity-20 text-secondary-6 text-xs lg:text-sm py-0.5 rounded-full px-3 whitespace-nowrap";
    case "S1":
      return "bg-primary-1 bg-opacity-20 text-primary-1 text-xs lg:text-sm py-0.5 rounded-full px-3 whitespace-nowrap";
    case "Most Popular":
      return "bg-functional-error-default bg-opacity-20 text-functional-error-default text-xs lg:text-sm py-0.5 rounded-full px-3 whitespace-nowrap";
    case "Fit your investor profile":
      return "bg-secondary-6 bg-opacity-20 text-secondary-6 text-xs lg:text-sm py-0.5 rounded-full px-3 whitespace-nowrap";
    case "Best Value":
      return "bg-primary-1 bg-opacity-20 text-primary-6 text-xs lg:text-sm py-0.5 rounded-full px-3 whitespace-nowrap";
    default:
      return "bg-secondary-6 bg-opacity-20 text-secondary-6 text-xs lg:text-sm py-0.5 rounded-full px-3 whitespace-nowrap";
  }
}
</script>
