import { defineStore } from "pinia";

import { useCrudStore, useInvestorStore, useInvestmentStore } from "@/stores";
import { CONTACT_DOCUMENT_FIELD_ORDER, ACCOUNT_DOCUMENT_FIELD_ORDER } from "@/constants";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    //user data
    currentUser: null,
    activeSignupStep: 1,
    isSignup: false,
    signUpFields: {
      first_name: "",
      last_name: "",
      name: "", // For storing the full name on the account document
      email: "",
      phone: "",
      password: "",
      confirm_password: "",
      message_cb: false,
      privacy_cb: false,
      consent_cb: false,
      signup_questionnaire: {
        are_you_an_accredited_investor: "",
        main_investment_goal: "",
        ira_or_savings: "",
        has_annuities: "",
        anticipated_investment_range: "",
      },
    },
    signInFields: {
      email: "",
      password: "",
      phone: "",
    },
    is_verified: false,
    mfa_channel: null,
    verification_code: ["", "", "", "", "", ""],
    invalidSession: false,

    //turnstile
    turnstileToken: "",
    isTurnstileValid: false,
  }),
  actions: {
    async initializeData() {
      const investorStore = useInvestorStore();
      const investmentStore = useInvestmentStore();
      try {
        let promises = [
          investorStore.setInvestorData(),
          investmentStore.fetchOfferings(),
          investmentStore.getInvestorTransactions(),
        ];
        await Promise.allSettled(promises);
      } catch (err) {
        console.error(err);
      }
    },
    async insertAccountAndContactOnSignup() {
      const crudStore = useCrudStore();
      const investorStore = useInvestorStore();
      try {
        const contact_account_schema = await crudStore.find(
          "Schema",
          {
            collection_name: { $in: ["Contacts", "Accounts"] },
            field_name: { $ne: "_id" },
            nested_object_name: { $in: [null, ""] },
          },
          { collection_name: 1, field_type: 1, field_name: 1 }
        );
        const contactSchema = contact_account_schema.filter(
          (schema) => schema.collection_name === "Contacts"
        );
        const accountSchema = contact_account_schema.filter(
          (schema) => schema.collection_name === "Accounts"
        );

        const createDocument = (schema, order) => {
          let doc = {};
          for (let fieldName of order) {
            let field = schema.find((s) => s.field_name === fieldName);
            if (field) {
              doc[field.field_name] =
                this.signUpFields[field.field_name] || getDefaultFieldValue(field.field_type);
            }
          }
          // Add remaining fields
          for (let field of schema) {
            if (!(field.field_name in doc)) {
              doc[field.field_name] =
                this.signUpFields[field.field_name] || getDefaultFieldValue(field.field_type);
            }
          }
          return doc;
        };

        const getDefaultFieldValue = (fieldType) => {
          switch (fieldType) {
            case "string":
            case "dropdown":
              return "";
            case "number":
              return 0;
            case "boolean":
              return false;
            case "array_of_ids":
              return [];
            case "date":
            case "object":
            case "array":
              return null;
            default:
              return "";
          }
        };

        // Create initial account document
        let accountDoc = createDocument(accountSchema, ACCOUNT_DOCUMENT_FIELD_ORDER);
        accountDoc.email = accountDoc.email.toLowerCase();
        accountDoc.user_id = this.currentUser.id;
        accountDoc.country = "United States";
        accountDoc.type = "Regular";
        accountDoc.created_date = new Date();
        accountDoc.created_by_id = this.currentUser.id;
        accountDoc.created_by_name = this.signUpFields.name;
        accountDoc.updated_date = new Date();
        accountDoc.updated_by_id = this.currentUser.id;
        accountDoc.updated_by_name = this.signUpFields.name;

        const { insertedId: accountId } = await crudStore.insertOne("Accounts", accountDoc);

        // Create initial contact document
        let contactDoc = createDocument(contactSchema, CONTACT_DOCUMENT_FIELD_ORDER);
        contactDoc.email = contactDoc.email.toLowerCase();
        contactDoc.user_id = this.currentUser.id;
        contactDoc.account_id = accountId;
        contactDoc.is_owner = true;
        contactDoc.id_info = {};
        contactDoc.suitability_info = {};
        contactDoc.type = "Regular";
        contactDoc.country = "United States";
        contactDoc.created_date = new Date();
        contactDoc.created_by_id = this.currentUser.id;
        contactDoc.created_by_name = this.signUpFields.name;
        contactDoc.updated_date = new Date();
        contactDoc.updated_by_id = this.currentUser.id;
        contactDoc.updated_by_name = this.signUpFields.name;
        const { insertedId: contactId } = await crudStore.insertOne("Contacts", contactDoc);

        contactDoc._id = contactId;
        investorStore.contact = contactDoc;
        accountDoc._id = accountId;
        investorStore.account = accountDoc;
      } catch (err) {
        console.error(err, "from insertAccountAndContactOnSignup");
      }
    },
    async signOut() {
      const investorStore = useInvestorStore();
      const invesmentStore = useInvestmentStore();
      try {
        // make call to remove user session from KV storage
        await fetch(`${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/sign-out`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: this.currentUser.id,
            session_id: this.currentUser.session_id,
            email: investorStore.contact.email,
            contact_id: investorStore.contact._id,
          },
        });
        this.currentUser = null;
        this.is_verified = false;
        localStorage.removeItem("currentUser");
        investorStore.$reset();
        invesmentStore.$reset();
        this.$reset();
      } catch (err) {
        console.error(err);
      }
    },
    async sendVerificationCode(channel) {
      try {
        const formattedEmail = this.isSignup
          ? this.signUpFields?.email?.toLowerCase()?.trim()
          : this.signInFields?.email?.toLowerCase()?.trim();
        const parsedPhoneNumber = `+1${this.isSignup ? this.signUpFields?.phone?.replace(/\D/g, "") : this.signInFields?.phone?.replace(/\D/g, "")}`;

        const channelDict = {
          Email: "email",
          "Text Message": "sms",
          "Phone Call": "call",
        };

        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/investor-mfa?type=request`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              origin_token: this.turnstileToken,
            },
            body: JSON.stringify({
              to: channel === "Email" ? formattedEmail : parsedPhoneNumber,
              channel: channelDict[channel],
              email: formattedEmail,
              password: this.isSignup ? this.signUpFields.password : this.signInFields.password,
              isSignup: this.isSignup,
            }),
          }
        );
        const result = await response.json();
        return result;
      } catch (err) {
        console.error(err);
      }
    },
    async verifyMFACodeAndSignin(code) {
      const investmentStore = useInvestmentStore();

      try {
        const formattedEmail = this.isSignup
          ? this.signUpFields?.email?.toLowerCase()?.trim()
          : this.signInFields?.email?.toLowerCase()?.trim();
        const parsedPhoneNumber = `+1${this.isSignup ? this.signUpFields?.phone?.replace(/\D/g, "") : this.signInFields?.phone?.replace(/\D/g, "")}`;

        const to = this.mfa_channel === "Email" ? formattedEmail : parsedPhoneNumber;
        const channel =
          this.mfa_channel === "Email"
            ? "email"
            : this.mfa_channel === "Text Message"
              ? "sms"
              : "call";
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/investor-mfa?type=verify`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              origin_token: this.turnstileToken,
            },
            body: JSON.stringify({
              to,
              code,
              channel,
              email: formattedEmail,
              password: this.isSignup ? this.signUpFields.password : this.signInFields.password,
              isSignup: this.isSignup,
            }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          this.currentUser = null;
          localStorage.removeItem("currentUser");
          throw new Error(errorData.error);
        }

        const result = await response.json();

        this.currentUser = result;
        localStorage.setItem("currentUser", JSON.stringify(result));
        await investmentStore.fetchOfferings();

        return result;
      } catch (err) {
        console.error(err);
      }
    },
    async sendPasswordResetEmail(email) {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/send-password-reset-email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              origin_token: this.turnstileToken,
            },
            body: JSON.stringify({
              email: email.toLowerCase(),
            }),
          }
        );

        // if (!response.ok) {
        //   const errorData = await response.json();
        //   throw new Error(errorData.error);
        // }

        return true;
      } catch (err) {
        // console.error(err);
        return false;
      }
    },
    async changePassword(token, tokenId, password) {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/reset-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              origin_token: this.turnstileToken,
            },
            body: JSON.stringify({
              token,
              tokenId,
              password,
            }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }

        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    async validateUser(authType, sourceType, email, password) {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/validate-credentials`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              origin_token: this.turnstileToken,
            },
            body: JSON.stringify({
              authType,
              sourceType,
              email: email.toLowerCase().trim(),
              password,
            }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }

        return await response.json();
      } catch (err) {
        // console.error(err);
        return false;
      }
    },
    async sendCustomLog({ error, error_type, error_summary, error_source }) {
      try {
        if (this.currentUser) {
          await this.currentUser.functions.Create_Custom_Log(
            this.currentUser.id,
            error_type,
            error_summary,
            this.serializeError(error),
            error_source
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
    serializeError(error) {
      if (error instanceof Error) {
        return {
          message: error.message || "No error message provided.",
          stack: error.stack || "No stack trace provided.",
          name: error.name || "No error name provided.",
        };
      } else if (typeof error === "object" && error !== null) {
        return error;
      } else if (typeof error === "string") {
        try {
          const parsed = JSON.parse(error);
          return typeof parsed === "object" ? parsed : { message: error };
        } catch (e) {
          return { message: error };
        }
      } else {
        return { message: "Unrecognized error format", data: String(error) };
      }
    },
  },
});
