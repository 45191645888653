<template>
  <div class="h-full w-full p-6">
    <div
      class="flex flex-col w-full md:border border-platform-outlines rounded-md h-full items-center md:justify-center mt-12 md:mt-0"
    >
      <button
        type="button"
        @click="handleBack"
        class="flex items-center justify-center gap-1.5 absolute top-24 left-6 md:left-10"
      >
        <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
        <a href="/sign-in">Back</a>
      </button>
      <div v-if="emailSuccess" class="flex flex-col items-center mt-4 md:mt-0">
        <div
          class="rounded-full bg-[#469346] bg-opacity-[3%] h-[5.5rem] w-[5.5rem] mb-4 flex items-center justify-center"
        >
          <div
            class="rounded-full bg-[#469346] bg-opacity-10 h-[4.5rem] w-[4.5rem] flex items-center justify-center"
          >
            <Icon name="MailCheck" class="h-[40px] w-[40px] text-[#469346]" />
          </div>
        </div>
        <h3 class="font-medium text-light mb-4">Instructions Sent!</h3>
        <p class="text-lighter text-baseleading-7 text-center text-sm mb-4">
          If your email was found in our system, you will receive an email for reset instructions.
        </p>
        <Button
          @click="router.push('/sign-in')"
          label
          icon
          size="large"
          class="w-full"
          variant="white"
        >
          <template #label>Back to Sign In</template>
        </Button>
      </div>
      <div v-else class="w-full md:max-w-[23.813rem] mt-4 md:mt-0">
        <h3 class="font-bold text-2xl mb-2 text-primary-6">Reset Password</h3>
        <h3 class="text-lighter mb-6">
          Please enter your email and we will send instructions on how to change your password
        </h3>
        <div class="flex flex-col mb-6 w-full">
          <BaseInput
            v-model="forgotPasswordEmail"
            label="Email"
            placeholder="Type Here"
            size="large"
            formatRule="email"
            errorMessage="Invalid email"
            @keydown.enter="handleSubmitForgotPasswordEmail"
          />
        </div>
        <div class="flex flex-col items-center w-full gap-4">
          <Button
            @click="handleSubmitForgotPasswordEmail"
            @keydown.enter="handleSubmitForgotPasswordEmail"
            :disabled="!forgotPasswordEmail || !isValidEmail(forgotPasswordEmail)"
            label
            size="large"
            class="w-full"
          >
            <template v-if="isLoading" #label>
              <IconPulseSpinner class="h-6 w-6 stroke-white" />
            </template>
            <template #label>Submit</template>
          </Button>
        </div>
        <Transition
          enter-from-class="opacity-0 translate-x-4"
          enter-to-class="opacity-100 translate-x-0"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="opacity-0 translate-x-4"
          enter-active-class="transition ease-in-out duration-300"
          leave-active-class="transition ease-in-out duration-300"
        >
          <Banner type="error" v-if="submitError" hideClose class="mt-4">
            <template #content>
              <p>{{ submitError }}</p>
            </template>
          </Banner>
        </Transition>
        <!-- Turnstyle widget -->
        <div class="mt-4" data-theme="light" id="turnstile"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores";
import { BaseInput, Button, Icon, Banner } from "@/components";
import { IconPulseSpinner } from "@/components/icons";
import { isValidEmail } from "@/utilities";

const router = useRouter();
const authStore = useAuthStore();

const forgotPasswordEmail = ref("");
const emailError = ref("");
const submitError = ref("");
const emailSuccess = ref(false);
const isLoading = ref(false);
const isFirstRun = ref(false);

onMounted(() => {
  window.scrollTo(0, 0);
  turnstile.ready(function () {
    if (isFirstRun.value === false) {
      isFirstRun.value = true;
      turnstile.render("#turnstile", {
        sitekey:
          import.meta.env.VITE_ENV === "Production"
            ? "0x4AAAAAAAbuzA10_ip9GLl-"
            : "1x00000000000000000000AA",
        callback: function (token) {
          authStore.turnstileToken = token;
          authStore.isTurnstileValid = true;
        },
      });
    }
  });
});

onUnmounted(() => {
  authStore.isTurnstileValid = false;
});

let timeout = null;
watch(
  () => submitError.value,
  (newValue) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (newValue) {
      timeout = setTimeout(() => {
        submitError.value = false;
      }, 3000);
    }
  }
);

async function handleSubmitForgotPasswordEmail() {
  try {
    if (!authStore.isTurnstileValid) {
      submitError.value =
        "Please complete the security check above to confirm that you are a human.";
      return;
    }

    if (!forgotPasswordEmail.value) return;
    isLoading.value = true;
    submitError.value = false;
    const emailSentResponse = await authStore.sendPasswordResetEmail(forgotPasswordEmail.value);
    if (!emailSentResponse) {
      submitError.value = "Error sending password reset email. Please try again.";
    } else {
      emailSuccess.value = true;
    }
  } catch (err) {
    submitError.value =
      "Error sending password reset email. Please ensure your email address is correct and try again.";
  } finally {
    isLoading.value = false;
  }
}
</script>
