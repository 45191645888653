<template>
  <div class="overflow-x-auto" id="table-container" ref="scrollContainer" @scroll="handleScroll">
    <table
      v-if="!UIStore.isLoading"
      class="relative left-0 min-w-full overflow-auto"
      :id="'table' + tableContext"
    >
      <thead
        id="table-headers"
        class="sticky top-0 z-10 overflow-auto whitespace-nowrap bg-neutral-gray-2"
      >
        <tr :class="isWhiteHeader ? '' : 'divide-x'">
          <th
            v-for="(header, index) of headers"
            :key="header"
            scope="col"
            :id="'header' + index + tableContext"
            ref="tableHeaders"
            class="sticky top-0 min-h-9 border-b border-neutral-gray-4 px-4 py-2.5 text-xs font-leaguespartan leading-tight font-medium text-primary-primary-2"
            :class="[
              isWhiteHeader ? 'bg-white' : 'bg-neutral-gray-2',
              actions_always_sticky ? 'last:sticky last:right-0' : '',
              hide_id && header.label === 'ID' ? 'hidden' : '',
              header.label !== 'Agreement' && header.label !== 'Actions'
                ? isResizable
                  ? 'resize-handle border-l'
                  : ''
                : '',
              resizingColumnIndex === index - 1 ? '' : 'grow-0',
            ]"
            style="width: 200"
            @mousedown="isResizable ? initResize($event, index - 1) : null"
          >
            <div
              v-if="header?.field_name === 'checkbox'"
              class="flex items-center space-x-value-8 overflow-visible"
            >
              <input
                v-if="!selectAllIsDisabled"
                type="checkbox"
                @change="$emit('selectAll')"
                id="select-all"
                class="accent-secondary-6"
              />
              <div class="text-left text-xxs font-semibold text-primary-1">
                {{ header.label.toUpperCase() }}
              </div>
            </div>
            <button
              v-else-if="header.label !== 'Agreement' && header.label !== 'Actions' && !hideSort"
              @click="$emit('setSortHeader', header, props)"
              class="group flex items-center space-x-2"
            >
              <div class="text-left text-xxs font-semibold text-primary-1">
                {{ header.label.toUpperCase() }}
              </div>
              <span
                :class="
                  JSON.stringify(UIStore.sortHeader?.label) == JSON.stringify(header.label)
                    ? 'visible'
                    : 'invisible'
                "
                class="group-hover:visible group-focus:visible"
              >
                <IconArrowUpward
                  v-if="
                    UIStore.sortAscending &&
                    JSON.stringify(UIStore.sortHeader?.label) == JSON.stringify(header.label)
                  "
                  class="pointer-events-none h-4 w-4 fill-lighter group-hover:visible group-focus:visible"
                />
                <IconArrowUpward
                  v-else
                  class="pointer-events-none h-4 w-4 rotate-180 fill-lighter group-hover:visible group-focus:visible"
                />
              </span>
            </button>
            <div v-else class="text-left text-xxs font-semibold text-primary-1">
              {{ header.label.toUpperCase() }}
            </div>
          </th>
        </tr>
      </thead>

      <tbody
        v-if="!UIStore.isLoading"
        class="relative left-0 divide-y divide-gray-200 overflow-visible bg-white"
      >
        <tr
          v-for="(entry, index) in tableData"
          :key="entry"
          class="relative overflow-visible even:border-b even:border-t even:border-neutral-gray-2 even:bg-neutral-gray-2"
        >
          <td
            v-for="header in headers"
            :key="header"
            class="relative overflow-visible whitespace-nowrap text-xs font-medium"
            :class="[
              hide_id && header.field_name === '_id' ? 'hidden' : '',
              actions_always_sticky ? 'last:sticky last:right-0 last:p-0' : 'truncate',
              header.label === 'Agreement' || header.label === 'Actions' ? 'w-10' : 'max-w-40',
            ]"
          >
            <div>
              <template v-if="isCustomStyled(header)">
                <div>
                  <slot :name="header.field_name" :row="entry"></slot>
                </div>
              </template>
              <div
                v-else-if="
                  (entry[header.field_name] || entry[header.field_name] == 0) &&
                  header.label !== 'Agreement' &&
                  header.label !== 'Actions'
                "
                @mouseover="
                  mouseEnter(index + ' ' + entry[header.field_name] + header.field_name, $event)
                "
                @mouseleave="mouseLeave()"
                class="relative left-0 overflow-visible p-4 text-sm font-leaguespartan leading-tight text-primary-6"
                :class="[
                  entry[header.field_name]
                    ? 'text-primary-1'
                    : entry[header.field_name]
                      ? 'text-gold'
                      : 'text-dark',
                  whitespace_preline ? 'whitespace-pre-line' : 'truncate',
                ]"
              >
                {{
                  header.field_type === "date" &&
                  new Date(entry[header.field_name]) !== "Invalid Date" &&
                  !isNaN(new Date(entry[header.field_name]))
                    ? formatDateValue(entry, header)
                    : header.number_type === "currency" &&
                        typeof entry[header.field_name] === "number"
                      ? "$" + commaSeparateThousands(entry[header.field_name].toFixed(2))
                      : header.number_type === "percentage" &&
                          typeof entry[header.field_name] === "number"
                        ? commaSeparateThousands((entry[header.field_name] * 100).toFixed(2)) + "%"
                        : typeof entry[header.field_name] === "number"
                          ? commaSeparateThousands(entry[header.field_name])
                          : entry[header.field_name] === ""
                            ? "-"
                            : entry[header.field_name]
                }}
              </div>
              <div
                v-else-if="header.label === 'Agreement' || header.label === 'Actions'"
                class="w-full bg-white px-4 py-2.5"
                :class="[
                  actions_always_sticky ? 'border-l' : '',
                  UIStore.isSideDrawerOpen ? '' : '',
                ]"
              >
                <slot name="table-ctas" :entry="entry"></slot>
              </div>
              <div v-else class="p-4">
                <!-- Empty cell for missing data -->
                -
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="UIStore.isLoading && use_table_loader"
      class="flex min-h-50 items-center justify-center p-10"
    >
      <LoadingSpinner :show="UIStore.isLoading" hideOverlay position="relative" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";

import { useUIStore } from "@/stores";
import { IconArrowUpward } from "@/components/icons";
import { Button, LoadingSpinner } from "@/components";
import { commaSeparateThousands } from "@/utilities";
import { useRoute } from "vue-router";

const emit = defineEmits(["scroll-bottom", "setSortHeader", "selectAll"]);
const UIStore = useUIStore();
const route = useRoute();

const props = defineProps({
  tableData: Array,
  headers: Array,
  parent: String,
  useCustomSort: Boolean,
  hideSort: Boolean,
  fixTable: Boolean,
  fieldsWithCustomStyles: {
    type: Array,
    default: () => [],
  },
  hide_id: {
    type: Boolean,
    default: false,
  },
  use_table_loader: {
    type: Boolean,
    default: true,
  },
  fixedColumnAmount: {
    type: Number,
    default: 6, // Provide a default value that makes sense for your application
  },
  actions_always_sticky: {
    type: Boolean,
    default: false,
  },
  rounded_bottom: {
    type: Boolean,
    default: true,
  },
  extendAbove: {
    type: Boolean,
    default: false,
  },
  hideTopBorder: {
    type: Boolean,
    default: false,
  },
  isResizable: {
    type: Boolean,
    default: false,
  },
  resetSort: {
    type: Boolean,
    default: true,
  },
  set_sort_to_null: {
    type: Boolean,
    default: true,
  },
  whitespace_preline: {
    type: Boolean,
    default: false,
  },
  headersSticky: {
    type: Boolean,
    default: false,
  },
  tableContext: {
    type: String,
    default: null,
  },
  selectAllIsDisabled: {
    type: Boolean,
    default: false,
  },
  isWhiteHeader: {
    type: Boolean,
    default: false,
  },
});

const scrollContainer = ref(null);
const tooltip_to_show = ref(null);
const newOffsetX = ref(0);
const tableHeaders = ref([]);
let tableWidth;

onMounted(() => {
  if (props.resetSort) {
    if (props.set_sort_to_null) {
      UIStore.sortAscending = null;
    }
  }
  if (props.isResizable) {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  }
});

// Get table column widths from session storage
watch(
  () => tableHeaders.value.length,
  function () {
    tableWidth = Number(document.getElementById("table" + props.tableContext).offsetWidth);
    // persistant column resizing
    let recordId = route.query.recordId;
    if (recordId) {
      if (window.sessionStorage.getItem(recordId + "_cols")) {
        let tableColsInSessionStore = JSON.parse(window.sessionStorage.getItem(recordId + "_cols"));
        tableColsInSessionStore.forEach((col) => {
          document.getElementById("table" + props.tableContext).width =
            tableWidth + col.width + "px";
          document.getElementById(col.col_id).width = col.width + "px";
        });
      }
    }
  }
);

onUnmounted(() => {
  if (props.isResizable) {
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  }
});

let timer = 0;

function mouseEnter(value, event) {
  timer = setTimeout(() => {
    tooltip_to_show.value = value;
  }, 1000);
}

function mouseLeave() {
  tooltip_to_show.value = null;
  clearTimeout(timer);
}

function isCustomStyled(header) {
  return props.fieldsWithCustomStyles.includes(header.field_name);
}

let isResizing = ref(false);
let startX = ref(0);
let startWidth = ref(0);
let resizingColumnIndex = ref(-1);
let newWidth = ref(0);

const initResize = (event, index) => {
  isResizing.value = true;
  startX.value = event.pageX;
  startWidth.value = document.getElementById("header" + index + props.tableContext).offsetWidth;
  resizingColumnIndex.value = index;
  tableWidth = Number(document.getElementById("table" + props.tableContext).offsetWidth);
};

const onMouseMove = (event) => {
  if (isResizing.value) {
    let offsetX = event.pageX - startX.value;
    newWidth.value = startWidth.value + offsetX;
    document.getElementById("table" + props.tableContext).width = tableWidth + offsetX + "px";
    if (newWidth.value > 0) {
      document.getElementById("header" + resizingColumnIndex.value + props.tableContext).width =
        newWidth.value + "px";
    }
  }
};

const onMouseUp = () => {
  if (isResizing.value) {
    isResizing.value = false;
    let recordId = route.query.recordId;
    if (recordId) {
      let tableColsInSessionStore = [];
      // if we already have an array of col widths
      if (window.sessionStorage.getItem(recordId + "_cols")) {
        tableColsInSessionStore = JSON.parse(window.sessionStorage.getItem(recordId + "_cols"));
        // Find the index of the element in the array
        const index = tableColsInSessionStore.findIndex(
          (obj) => obj.col_id === "header" + resizingColumnIndex.value + props.tableContext
        );

        if (index !== -1) {
          // If element is found, update the specified property in the array
          tableColsInSessionStore[index].width = newWidth.value;
        } else {
          // If element is not found, push a new object to the array
          tableColsInSessionStore.push({
            col_id: "header" + resizingColumnIndex.value + props.tableContext,
            width: newWidth.value,
          });
        }
      } else {
        // we are starting new array
        tableColsInSessionStore.push({
          col_id: "header" + resizingColumnIndex.value + props.tableContext,
          width: newWidth.value,
        });
      }

      window.sessionStorage.setItem(recordId + "_cols", JSON.stringify(tableColsInSessionStore));
    }
  }
};

const handleScroll = () => {
  if (!scrollContainer.value) return;
  if (
    scrollContainer.value.scrollTop + scrollContainer.value.clientHeight >=
    scrollContainer.value.scrollHeight
  ) {
    emit("scroll-bottom");
  }
};

function formatDateValue(entry, header) {
  const fieldName = header.field_name;
  const value = new Date(entry[fieldName]);

  // Check for created_date and updated_date
  if (
    fieldName === "created_date" ||
    fieldName === "updated_date" ||
    ((fieldName === "old_value" || fieldName === "new_value") &&
      (entry?.field_name === "created_date" || entry?.field_name === "updated_date"))
  ) {
    return value.toLocaleDateString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  } else {
    return value.toLocaleDateString("en-us", {
      timeZone: "UTC",
    });
  }
}
</script>
<style scoped>
th:after,
th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

th:before {
  top: -1px;
  border-top: 1px solid #f0f0f0;
}

.table-container {
  overflow-x: auto;
}

.gradient {
  background-color: gray;
  -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
}

.resizable-table table {
  /* Ensure the table layout is fixed for resizing to work */
  border-collapse: collapse;
}

.resize-handle {
  position: relative;
  padding-right: 16px; /* Adjust padding to accommodate resize handle */
}

.resize-handle::after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  width: 8px; /* Width of the resize handle */
  cursor: ew-resize; /* Horizontal resize cursor */
}
</style>
