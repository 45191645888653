import { defineStore } from "pinia";
import { useAuthStore } from "./useAuthStore";

const CRUD_URL = import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT;

export const useCrudStore = defineStore("crudStore", {
  state: () => ({}),
  actions: {
    async find(collection, filter = {}, projection = null, sort = null, skip = null, limit = null) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!currentUser) throw new Error("User is not authenticated");

        const request_body = {
          collection,
          filter,
          ...(projection && { projection }),
          ...(sort && { sort }),
          ...(skip !== null && { skip }),
          ...(limit !== null && { limit }),
        };

        const response = await fetch(`${CRUD_URL}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify(request_body),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error finding records from the ${collection} collection for ${authStore.currentUser.id} -
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - find",
        });
        return err;
      }
    },
    async findOne(collection, filter = {}) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      // console.log("findOne", collection, filter);
      try {
        if (!collection) throw new Error("Collection is required");
        if (!currentUser) throw new Error("User is not authenticated");

        const response = await fetch(`${CRUD_URL}/find-one`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, filter }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error finding one record from the ${collection} collection for ${authStore.currentUser.id} - 
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - findOne",
        });
        return err;
      }
    },
    async insertOne(collection, document) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!document) throw new Error("No document object found for insert one operation");

        const response = await fetch(`${CRUD_URL}/insert-one`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, document }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error inserting one record to the ${collection} collection for ${authStore.currentUser.id} -`,
          error_source: "crudStore - insertOne",
        });
        return err;
      }
    },
    async insertMany(collection, documents) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!documents) throw new Error("No documents found for insert many operation");

        const response = await fetch(`${CRUD_URL}/insert-many`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, documents }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error inserting many records to the ${collection} collection for ${authStore.currentUser.id} -`,
          error_source: "crudStore - insertMany",
        });
        return err;
      }
    },
    async updateOne(collection, filter = {}, update = null, is_upsert = false) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!update) throw new Error("No update object found for update one operation");

        const response = await fetch(`${CRUD_URL}/update-one`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, filter, update, is_upsert }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error updating one record in the ${collection} collection for ${authStore.currentUser.id} -
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - updateOne",
        });
        return err;
      }
    },
    async updateMany(collection, filter = {}, update = null, is_upsert = false) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!update) throw new Error("No update object found for update one operation");

        const response = await fetch(`${CRUD_URL}/update-many`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, filter, update, is_upsert }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error updating many records in the ${collection} collection for ${authStore.currentUser.id} -
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - updateMany",
        });
        return err;
      }
    },
    async deleteOne(collection, filter = {}) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");

        const response = await fetch(`${CRUD_URL}/delete-one`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, filter }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error deleting one record from the ${collection} collection for ${authStore.currentUser.id} -
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - deleteOne",
        });
        return err;
      }
    },
    async deleteMany(collection, filter = {}) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");

        const response = await fetch(`${CRUD_URL}/delete-many`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, filter }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error deleting many records from the ${collection} collection for ${authStore.currentUser.id} -
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - deleteMany",
        });
        return err;
      }
    },
    async aggregate(collection, pipeline) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!pipeline) throw new Error("Pipeline is required");

        const response = await fetch(`${CRUD_URL}/aggregate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, pipeline }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error with aggregation operation on the ${collection} collection for ${authStore.currentUser.id} -
          - Query: ${JSON.stringify(pipeline)}`,
          error_source: "crudStore - aggregate",
        });
        return err;
      }
    },
    async fetchSchemaForCollection(collection) {
      try {
        const schemaProjection = {
          field_name: 1,
          field_type: 1,
          record_detail_config: 1,
          accredited_only: 1,
          number_type: 1,
          label: 1,
          read_only: 1,
          is_object_id: 1,
          belongs_to_nested_object: 1,
          nested_object_name: 1,
          _id: 0,
        };
        const excludedFields = [
          "created_by_id",
          "updated_by_id",
          "created_by_name",
          "updated_by_name",
          "created_date",
          "updated_date",
          "_id",
        ];
        return await this.find(
          "Schema",
          {
            collection_name: collection,
            field_name: { $nin: excludedFields },
            nested_object_name: { $in: ["", null] },
          },
          schemaProjection
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
});
