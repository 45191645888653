<template>
  <Modal
    @close-modal="$emit('close-modal')"
    customHeight="h-140 md:h-auto"
    customWidth="lg:w-180"
    :open="open"
  >
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <p class="font-medium text-lg text-primary-6">Select Investment for Rollover</p>
      </div>
    </template>

    <template v-slot:modal-body>
      <div class="p-4 md:px-10 md:py-8">
        <h3 class="text-lg mb-4 font-medium text-primary-6">Pick Existing Investment</h3>
        <div class="bg-white rounded-lg p-3">
          <Table
            :tableData="filteredRolloverInvestments"
            :headers="rollover_table_headers"
            class="w-full overflow-auto"
            :fieldsWithCustomStyles="['select', 'interest_rate', 'bond_term']"
            selectAllIsDisabled
            isWhiteHeader
          >
            <template v-slot:select="entry">
              <div class="px-4 flex items-center gap-2">
                <Button @click="handleSelectRollover(entry.row)" label size="small">
                  <template #label>
                    <p class="font-normal">Select</p>
                  </template>
                </Button>
                <Icon
                  v-if="
                    investmentStore.selected_rollover_investment &&
                    investmentStore.selected_rollover_investment._id === entry.row._id
                  "
                  name="CircleCheck"
                  class="h-5 w-5 text-functional-success-default"
                  :stroke-width="2"
                />
              </div>
            </template>
            <template v-slot:interest_rate="entry">
              <div class="px-4">
                <p>{{ entry.row.interest_rate * 100 }}%</p>
              </div>
            </template>
            <template v-slot:bond_term="entry">
              <div class="px-4">
                <p>{{ entry.row.bond_term }} Year<span v-if="entry.row.bond_term > 1">s</span></p>
              </div>
            </template>
          </Table>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label>Cancel</template>
        </Button>
        <Button
          size="large"
          :disabled="!selectedInvestment"
          label
          @click="
            $emit('select-rollover', selectedInvestment),
              (selectedInvestment = null),
              $emit('close-modal')
          "
        >
          <template #label>Save</template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { computed, ref } from "vue";

import { Button, Modal, Table, Icon } from "@/components";
import { useInvestmentStore } from "@/stores";
import { commaSeparateThousands_2 } from "@/utilities";

const investmentStore = useInvestmentStore();

const emit = defineEmits(["close-modal", "select-rollover"]);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const rollover_table_headers = ref([
  { field_name: "select", label: "" },
  { field_name: "type", label: "Type" },
  { field_name: "rollover_available", label: "Rollover Available", number_type: "currency" },
  { field_name: "interest_rate", label: "Rate" },
  { field_name: "bond_term", label: "Term" },
  { field_name: "maturity_date", label: "Maturity", field_type: "date" },
]);
const selectedInvestment = ref(null);

const filteredRolloverInvestments = computed(() => {
  const rolloverInvestments = investmentStore.transactionsData.filter((i) => {
    return i.able_to_rollover && i.status === "Settled" && i.rollover_available > 0;
  });
  return rolloverInvestments;
});

function handleSelectRollover(investment) {
  emit("close-modal");
  investmentStore.selected_rollover_investment = investment;
  investmentStore.currentInvestment.amount = commaSeparateThousands_2(
    investment.rollover_available
  );
}
</script>
