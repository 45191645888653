<template>
  <RateLiftModal
    :open="isRateLiftModalOpen"
    :amountToNextTier="nextTierInfo?.amountNeeded || 0"
    :currentTier="regDTier"
    @close-modal="isRateLiftModalOpen = false"
  />
  <div v-if="investorStore.contact" class="w-full h-full pb-[3.5rem] lg:pb-0">
    <!-- Top Banners for possibly sign up questionnaire and rate lift information -->
    <div>
      <Banner
        @close="investorStore.isSignupBannerShowing = false"
        type="info"
        v-if="investorStore.isSignupBannerShowing"
        class="mb-3"
        iconTop
      >
        <template #content>
          <div>
            <h3 class="font-semibold">Get personalized suggestions</h3>
            <p class="text-lighter text-sm">
              Let us get to know you better so we can offer you the investment opporitunities that
              suit you
              <span
                @click="
                  router.push('/sign-up/questionnaire'),
                    (investmentStore.is_post_signup_questionnaire = true)
                "
                class="text-medium underline text-light cursor-pointer"
              >
                Click here to fill out the questionnaire
              </span>
            </p>
          </div>
        </template>
      </Banner>
      <Banner type="success" hideClose customIconName="DollarSign" class="md:mb-6" iconTop>
        <template #content>
          <div class="w-full flex flex-col md:flex-row justify-between">
            <div v-if="investmentStore.selectedOffering?.type !== 'Reg D'">
              <p class="font-bold">Are you accredited?</p>
              <p class="text-lighter text-sm">
                Select one of our accredited offerings to see if you qualify for a rate lift!
              </p>
            </div>
            <div v-else>
              <p v-if="!nextTierInfo?.maxTier" class="font-bold">
                Get to Tier {{ regDTier + 1 }}! - Increase annual yield by 0.5% on any accredited
                offering!
              </p>
              <p v-else class="font-bold">
                {{ nextTierInfo?.message }}
              </p>
              <p v-if="!nextTierInfo?.maxTier" class="text-lighter text-sm">
                Invest ${{ (nextTierInfo?.amountNeeded / 1000).toFixed() }}k more
              </p>
              <p v-else class="text-lighter text-sm">Max tier reached!</p>
            </div>
          </div>
        </template>
        <template #actions>
          <Button
            @click="isRateLiftModalOpen = true"
            label
            class="w-full md:w-[13.438rem] mt-4 md:mt-0"
            size="large"
            variant="default-color-outlined"
          >
            <template #label>Learn More</template>
          </Button>
        </template>
      </Banner>
    </div>
    <div class="flex lg:gap-8">
      <div class="hidden lg:block lg:min-w-[20.75rem] lg:sticky lg:top-20 lg:self-start z-50">
        <InvestmentAmount />
      </div>
      <Teleport to="body">
        <div class="lg:hidden z-50 fixed w-full bottom-0">
          <InvestmentAmountMobile />
        </div>
        <div
          class="lg:hidden fixed inset-0 bg-neutral-gray-13 bg-opacity-80 mt-[3.5em] transition-opacity duration-300 ease-in-out"
          :class="
            investmentStore.isAmountCollapsed ? 'opacity-0 pointer-events-none' : 'opacity-100 z-10'
          "
        />
      </Teleport>

      <div class="w-full lg:w-[calc(100%-24.75rem)]">
        <div class="pb-6">
          <div class="flex items-center gap-4 my-6 md:my-0 md:mb-6">
            <h1 class="font-bold uppercase text-2xl text-primary-6">Offerings</h1>
          </div>
          <!-- <div class="md:hidden">
            <TabList
              :tabList="['Recommended', 'All Offerings']"
              @set-active-tab="activeOfferingTab = $event"
              :activeTab="activeOfferingTab"
            />
          </div> -->
          <!-- Accredited Offerings -->
          <div
            class="relative"
            :class="activeOfferingTab !== 'Recommended' ? '' : 'hidden md:block'"
          >
            <div class="my-6">
              <h4 class="font-bold text-[1.375rem] text-primary-6">Regulation D Offerings</h4>
              <ul class="text-sm text-lighter">
                <li class="font-semibold">Issued by PCGH</li>
                <li>
                  Open to Accredited Investors Only
                  <!-- <Icon name="Info" class="h-5 w-5 text-neutral-gray-6" :strokeWidth="2" /> -->
                </li>
                <li>$25K Minimum Investment</li>
                <li>
                  View our Reg D PPM
                  <a
                    href="https://phxcapitalgroup.com/investment-offerings/"
                    target="_blank"
                    class="text-primary-6 underline font-medium"
                  >
                    here
                  </a>
                </li>
              </ul>
            </div>
            <div
              class="bg-neutral-gray-2 rounded-md w-full p-4 flex md:grid md:grid-cols-2 xl:grid-cols-3 gap-4 overflow-x-auto md:overflow-hidden no-scrollbar"
              ref="regDOfferingsScrollContainer"
            >
              <OfferingCard
                v-for="offering in reg_d_offerings"
                :offering="offering"
                @select-offering="investmentStore.selectedOffering = offering"
                @handle-next="handleNext"
              />
            </div>
            <div
              v-if="reg_d_offerings.length > 1"
              class="flex items-center justify-center gap-2 my-4 md:hidden w-full max-w-[75%] mx-auto"
            >
              <span
                v-for="(offering, index) in reg_d_offerings"
                :key="index"
                @click="scrollToOffering(index, regDOfferingsScrollContainer, activeRegDIndex)"
                :class="{
                  'bg-primary-6': activeRegDIndex === index,
                  'bg-neutral-gray-4': activeRegDIndex !== index,
                }"
                class="cursor-pointer grow h-1 w-12 rounded-full transition-colors"
              />
            </div>
          </div>
          <!-- Offerings For You -->
          <!-- <div
            class="relative"
            :class="activeOfferingTab === 'Recommended' ? '' : 'hidden md:block'"
          >
            <h3 class="font-bold text-[1.375rem] my-6 md:mt-0 text-primary-6">Offerings for You</h3>
            <div
              class="bg-neutral-gray-2 rounded-md w-full p-4 flex md:grid md:grid-cols-2 xl:grid-cols-3 gap-4 overflow-x-auto md:overflow-hidden no-scrollbar"
              ref="suggestedOfferingsScrollContainer"
            >
              <OfferingCard
                v-for="offering in suggested_offerings"
                :offering="offering"
                @select-offering="investmentStore.selectedOffering = offering"
                @handle-next="handleNext"
              />
            </div>
            <div
              class="flex items-center justify-center gap-2 mt-4 md:hidden w-full max-w-[75%] mx-auto"
            >
              <span
                v-for="(offering, index) in suggested_offerings"
                :key="index"
                @click="
                  scrollToOffering(index, suggestedOfferingsScrollContainer, activeOfferingIndex)
                "
                :class="{
                  'bg-primary-6': activeOfferingIndex === index,
                  'bg-neutral-gray-4': activeOfferingIndex !== index,
                }"
                class="cursor-pointer grow h-1 w-12 rounded-full transition-colors"
              />
            </div>
          </div> -->
          <!-- S1 Offerings-->
          <!-- <div
            class="relative"
            :class="activeOfferingTab !== 'Recommended' ? '' : 'hidden md:block'"
          >
            <div class="my-6">
              <h3 class="font-bold text-[1.375rem] text-primary-6">S1 Offerings (New!)</h3>
              <ul class="text-sm text-lighter">
                <li class="font-semibold">Issued by PCGH</li>
                <li>Open to all investors; Accredited and Non-Accredited</li>
                <li>$5K Minimum Investment</li>
                <li>
                  View our S1 Offering Circular
                  <a
                    href="https://phxcapitalgroup.com/investment-offerings/"
                    target="_blank"
                    class="text-primary-6 underline font-medium"
                    >here
                  </a>
                </li>
              </ul>
            </div>
            <div
              class="bg-neutral-gray-2 rounded-md w-full p-4 flex md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 overflow-x-auto md:overflow-hidden no-scrollbar"
              ref="s1OfferingsScrollContainer"
            >
              <OfferingCard
                v-for="offering in s1_offerings"
                :offering="offering"
                @select-offering="investmentStore.selectedOffering = offering"
                @handle-next="handleNext"
              />
            </div>
            <div
              v-if="s1_offerings.length > 1"
              class="flex items-center justify-center gap-2 my-4 md:hidden w-full max-w-[75%] mx-auto"
            >
              <span
                v-for="(offering, index) in s1_offerings"
                :key="index"
                @click="scrollToOffering(index, s1OfferingsScrollContainer, activeS1Index)"
                :class="{
                  'bg-primary-6': activeS1Index === index,
                  'bg-neutral-gray-4': activeS1Index !== index,
                }"
                class="cursor-pointer grow h-1 w-12 rounded-full transition-colors"
              />
            </div>
          </div> -->
          <!-- Reg A Offerings-->
          <!-- <div
            class="relative"
            :class="activeOfferingTab !== 'Recommended' ? '' : 'hidden md:block'"
          >
            <div class="my-6">
              <h3 class="font-bold text-[1.375rem] text-primary-6">Regulation A+ Offerings</h3>
              <ul class="text-sm text-lighter">
                <li class="font-semibold">Issued by PCGH</li>
                <li>Open to all investors; Accredited and Non-Accredited</li>
                <li>$5K Minimum Investment</li>
                <li>
                  View our S1 Offering Circular
                  <a
                    href="https://phxcapitalgroup.com/investment-offerings/"
                    target="_blank"
                    class="text-primary-6 underline font-medium"
                    >here
                  </a>
                </li>
              </ul>
            </div>
            <div
              v-if="hasVisibleRegAOfferings"
              class="bg-neutral-gray-2 rounded-md w-full p-4 flex md:grid md:grid-cols-2 xl:grid-cols-3 gap-4 overflow-x-auto md:overflow-hidden no-scrollbar"
              ref="regAOfferingsScrollContainer"
            >
              <OfferingCard
                v-for="offering in reg_a_offerings"
                :offering="offering"
                @select-offering="investmentStore.selectedOffering = offering"
                @handle-next="handleNext"
              />
            </div>
            <Banner v-else type="info" hideClose iconTop hideIconOnMobile>
              <template #content>
                <div>
                  <h3 class="font-semibold">No Regulation A+ Offerings Available</h3>
                  <p class="text-lighter text-sm">
                    Thank you for considering Phoenix Capital Group for your investment
                    opportunities. While we are currently not accepting new Regulation A+
                    investments, we appreciate your interest. We are actively working on exciting
                    developments and enhancements. To stay updated on our offerings and be among the
                    first to know when we resume accepting investments, we invite you to join our
                    waitlist. Your patience is valued, and we appreciate your interest and
                    commitment to our vision.
                  </p>
                  <div class="flex items-end gap-4 mt-4">
                    <BaseInput
                      label="Phone Number"
                      placeholder="(000) 000-0000"
                      v-model="investorStore.contact.phone"
                      class="w-full lg:w-64"
                      size="large"
                      @input="
                        investorStore.contact.phone = formatPhoneNumber(investorStore.contact.phone)
                      "
                    />
                    <Button
                      label
                      size="large"
                      :disabled="investorStore.contact?.phone?.length !== 14"
                    >
                      <template #label>Submit</template>
                    </Button>
                  </div>
                </div>
              </template>
            </Banner>
            <div
              v-if="reg_a_offerings.length > 1"
              class="flex items-center justify-center gap-2 my-4 md:hidden w-full max-w-[75%] mx-auto"
            >
              <span
                v-for="(offering, index) in reg_a_offerings"
                :key="index"
                @click="scrollToOffering(index, regAOfferingsScrollContainer, activeRegAIndex)"
                :class="{
                  'bg-primary-6': activeRegAIndex === index,
                  'bg-neutral-gray-4': activeRegAIndex !== index,
                }"
                class="cursor-pointer grow h-1 w-12 rounded-full transition-colors"
              />
            </div>
          </div> -->

          <div class="border-b border-platform-outlines w-full my-4 col-span-2"></div>
          <TrustPilotReviews />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";
import { debounce } from "lodash";

import { useInvestmentStore, useInvestorStore } from "@/stores";
import { Banner, BaseInput, Button, TabList } from "@/components";
import { formatPhoneNumber } from "@/utilities";
import InvestmentAmount from "./InvestmentAmount.vue";
import InvestmentAmountMobile from "./InvestmentAmountMobile.vue";
import OfferingCard from "./OfferingCard.vue";
import TrustPilotReviews from "./TrustPilotReviews.vue";
import RateLiftModal from "./RateLiftModal.vue";

const router = useRouter();
const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

// Refs for scroll containers and active indices
const suggestedOfferingsScrollContainer = ref(null);
const regAOfferingsScrollContainer = ref(null);
const regDOfferingsScrollContainer = ref(null);
const s1OfferingsScrollContainer = ref(null);

const activeOfferingIndex = ref(0);
const activeRegAIndex = ref(0);
const activeRegDIndex = ref(0);
const activeS1Index = ref(0);
const totalInvestedPlusFundedPlusAmount = ref(0);
const isRateLiftModalOpen = ref(false);
const activeOfferingTab = ref("All Offerings");

const s1_offerings = computed(() => {
  return investmentStore.offerings
    .filter((offering) => offering.type === "S1")
    .sort((a, b) => a.interest_rate - b.interest_rate);
});

const reg_a_offerings = computed(() => {
  return investmentStore.offerings
    .filter((offering) => offering.type === "Reg A")
    .sort((a, b) => a.interest_rate - b.interest_rate);
});

const reg_d_offerings = computed(() => {
  const bondTerms = [1, 3, 5, 7, 11];

  return bondTerms
    .map((term) => {
      // Filter the offerings by bond term and tier
      const offeringsByTerm = investmentStore.offerings.filter(
        (offering) => offering.type === "Reg D" && offering.bond_term / 12 === term
      );

      // Find the appropriate tier for the bond term
      const tieredOffering = offeringsByTerm.find((offering) => offering.tier === regDTier.value);

      // If no exact tier match, fall back to the highest available tier
      return tieredOffering || offeringsByTerm.find((offering) => offering.tier <= regDTier.value);
    })
    .filter(Boolean); // Filter out undefined values
});

const suggested_offerings = computed(() => {
  // const s1First = s1_offerings.value[0];
  const regAFirst = reg_a_offerings.value[0];
  const regDFirst = reg_d_offerings.value[0];
  const regDLast = reg_d_offerings.value[reg_d_offerings.value.length - 1];
  return [regAFirst, regDFirst, regDLast].filter(Boolean); // Filter out undefined values
});

const nextTierInfo = computed(() => {
  const selectedOffering = investmentStore.selectedOffering;

  if (selectedOffering) {
    const currentAmount = totalInvestedPlusFundedPlusAmount.value;

    // Determine the next tier threshold and rate lift
    if (currentAmount < 500000) {
      return {
        amountNeeded: 500000 - currentAmount,
        rateLift: 0.5,
        maxTier: false,
      };
    } else if (currentAmount < 1000000) {
      return {
        amountNeeded: 1000000 - currentAmount,
        rateLift: 0.5,
        maxTier: false,
      };
    } else if (currentAmount >= 1000000) {
      return {
        maxTier: true,
        message: "You've reached the highest tier with a 1% annual yield increase!",
      };
    }
  }

  return null;
});

const regDTier = computed(() => {
  if (totalInvestedPlusFundedPlusAmount.value >= 1000000) {
    return 3;
  } else if (totalInvestedPlusFundedPlusAmount.value >= 500000) {
    return 2;
  } else {
    return 1;
  }
});

const hasVisibleRegAOfferings = computed(() => {
  return investmentStore.offerings.some((offering) => offering.type === "Reg A");
});

onMounted(() => {
  window.scrollTo(0, 0);
  investmentStore.fetchUnsignedInvestment();
  investmentStore.setSchemaForInvestmentFlow();

  if (!investmentStore.currentInvestment?.amount) {
    investmentStore.currentInvestment.amount = "25,000";
  }

  if (!investmentStore.selectedOffering) {
    investmentStore.selectedOffering = reg_d_offerings.value[0];
  }

  if (suggestedOfferingsScrollContainer.value) {
    suggestedOfferingsScrollContainer.value.addEventListener("scroll", () => {
      onScroll(suggestedOfferingsScrollContainer, activeOfferingIndex, suggested_offerings);
    });
  }
  if (regAOfferingsScrollContainer.value) {
    regAOfferingsScrollContainer.value.addEventListener("scroll", () => {
      onScroll(regAOfferingsScrollContainer, activeRegAIndex, reg_a_offerings);
    });
  }
  if (regDOfferingsScrollContainer.value) {
    regDOfferingsScrollContainer.value.addEventListener("scroll", () => {
      onScroll(regDOfferingsScrollContainer, activeRegDIndex, reg_d_offerings);
    });
  }
  if (s1OfferingsScrollContainer.value) {
    s1OfferingsScrollContainer.value.addEventListener("scroll", () => {
      onScroll(s1OfferingsScrollContainer, activeS1Index, s1_offerings);
    });
  }
});

onUnmounted(() => {
  if (suggestedOfferingsScrollContainer.value) {
    suggestedOfferingsScrollContainer.value.removeEventListener("scroll", onScroll);
  }
  if (regAOfferingsScrollContainer.value) {
    regAOfferingsScrollContainer.value.removeEventListener("scroll", onScroll);
  }
  if (regDOfferingsScrollContainer.value) {
    regDOfferingsScrollContainer.value.removeEventListener("scroll", onScroll);
  }
  if (s1OfferingsScrollContainer.value) {
    s1OfferingsScrollContainer.value.removeEventListener("scroll", onScroll);
  }
});

const calculateTotalInvested = debounce(() => {
  const allFundedInvestmentsAmount = investmentStore.transactionsData.reduce((acc, transaction) => {
    if (transaction.status === "Funded") {
      return acc + transaction.amount;
    }
    return acc;
  }, 0);

  const totalInvested = investorStore.account?.total_invested || 0;

  totalInvestedPlusFundedPlusAmount.value =
    totalInvested +
    allFundedInvestmentsAmount +
    Number(investmentStore.currentInvestment.amount.replace(/,/g, ""));
}, 300);

watch(
  () => investmentStore.currentInvestment.amount,
  () => {
    calculateTotalInvested();
  },
  { immediate: true }
);

// TODO: This needs to account for rollovers not being new capital
watch(
  () => totalInvestedPlusFundedPlusAmount.value,
  () => {
    const selectedOffering = investmentStore.selectedOffering;

    if (selectedOffering && selectedOffering.type === "Reg D") {
      const matchingOffering = reg_d_offerings.value.find(
        (offering) =>
          offering.bond_term === selectedOffering.bond_term && offering.tier === regDTier.value
      );

      if (matchingOffering && matchingOffering._id !== selectedOffering._id) {
        investmentStore.selectedOffering = matchingOffering;
      }
    }
  }
);

watch(
  () => investmentStore.selected_rollover_investment,
  () => {
    if (investmentStore.selected_rollover_investment) {
      investmentStore.currentInvestment.payment_method = "Existing Investment";
      investmentStore.currentInvestment.funding_account = null;
    } else {
      investmentStore.currentInvestment.payment_method = "";
    }
  }
);

function onScroll(containerRef, activeIndexRef, offerings) {
  if (containerRef.value) {
    const container = containerRef.value;
    const offeringWidth = container.firstElementChild.offsetWidth;
    const scrollPosition = container.scrollLeft;

    // Calculate which offering is closest to the center of the scroll container
    const newIndex = Math.round(scrollPosition / offeringWidth);
    activeIndexRef.value = Math.max(0, Math.min(newIndex, offerings.value.length - 1));
  }
}

function scrollToOffering(index, containerRef, activeIndexRef) {
  if (containerRef) {
    const container = containerRef;
    const offeringWidth = container.firstElementChild.offsetWidth;

    container.scrollTo({
      left: offeringWidth * index,
      behavior: "smooth",
    });
    activeIndexRef = index;
  }
}

async function handleNext(offerring) {
  investmentStore.selectedOffering = offerring;
  if (
    !investmentStore.selectedOffering?.can_compound &&
    investmentStore.currentInvestment?.is_compounded
  ) {
    investmentStore.currentInvestment.is_compounded = false;
  }
  investmentStore.currentStep = 2;
  await investmentStore.insertUpdateUnsignedInvestment();
}
</script>
