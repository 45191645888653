<template>
  <div class="md:h-full w-full p-6">
    <div
      class="flex flex-col w-full md:border border-platform-outlines rounded-md h-full items-center md:justify-center mt-12 md:mt-0"
    >
      <button
        type="button"
        @click="handleBack"
        class="flex items-center justify-center gap-1.5 absolute top-24 left-6 md:left-10"
      >
        <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
        <a href="/sign-in">Back</a>
      </button>
      <div class="w-full md:max-w-[23.813rem] mt-4 md:mt-0">
        <h3 class="font-bold text-2xl mb-8 text-primary-6">Reset Password</h3>
        <div class="flex flex-col gap-4 mb-6 w-full">
          <div>
            <BaseInput
              v-model="password"
              label="Password"
              placeholder="Enter your password"
              size="large"
              type="password"
              required
              @focus="typingPassword = true"
              @blur="validatePassword"
              @input="validatePassword"
              @keydown.enter="handleSubmitChangePassword"
            />

            <p v-if="typingPassword" class="mt-2 text-sm">
              <span :class="getCriteriaClass(passwordCriteria.length)">8 Characters · </span>
              <span :class="getCriteriaClass(passwordCriteria.uppercase)">
                1 Uppercase Letter ·
              </span>
              <span :class="getCriteriaClass(passwordCriteria.number)">1 Number · </span>
              <span :class="getCriteriaClass(passwordCriteria.special)">1 Special Character</span>
            </p>
          </div>
          <div>
            <BaseInput
              v-model="confirmPassword"
              label="Confirm Password"
              placeholder="Enter your password"
              size="large"
              type="password"
              required
            />
            <p v-if="password !== confirmPassword" class="mt-2 text-sm text-red-500">
              Passwords do not match.
            </p>
          </div>
        </div>
        <div class="flex flex-col items-center w-full gap-4">
          <Button
            @click="handleSubmitChangePassword"
            @keydown.enter="handleSubmitChangePassword"
            :disabled="!isFormValid"
            label
            size="large"
            class="w-full"
          >
            <template v-if="isLoading" #label>
              <IconPulseSpinner class="h-6 w-6 stroke-white" />
            </template>
            <template v-else #label>Submit</template>
          </Button>
        </div>
        <p v-if="submitError" class="text-functional-error-default mt-2"></p>
        <!-- Turnstyle widget -->
        <div class="mt-4" data-theme="light" id="turnstile"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useAuthStore, useUIStore } from "@/stores";
import { BaseInput, Button, Icon } from "@/components";
import { IconPulseSpinner } from "@/components/icons";

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const UIStore = useUIStore();

const password = ref("");
const confirmPassword = ref("");
const submitError = ref("");
const resetSuccess = ref(false);
const typingPassword = ref(false);
const isLoading = ref(false);
const isFirstRun = ref(false);
const passwordCriteria = ref({
  length: false,
  uppercase: false,
  number: false,
  special: false,
});

onMounted(() => {
  window.scrollTo(0, 0);
  validatePassword();
  if (!route.query.token || !route.query.tokenId) {
    router.push("/sign-in");
  }
  turnstile.ready(function () {
    if (isFirstRun.value === false) {
      isFirstRun.value = true;
      turnstile.render("#turnstile", {
        sitekey:
          import.meta.env.VITE_ENV === "Production"
            ? "0x4AAAAAAAbuzA10_ip9GLl-"
            : "1x00000000000000000000AA",
        callback: function (token) {
          authStore.turnstileToken = token;
          authStore.isTurnstileValid = true;
        },
      });
    }
  });
});

onUnmounted(() => {
  authStore.isTurnstileValid = false;
});

const isFormValid = computed(() => {
  return (
    passwordCriteria.value.length &&
    passwordCriteria.value.uppercase &&
    passwordCriteria.value.number &&
    passwordCriteria.value.special &&
    password.value === confirmPassword.value
  );
});

// Ensure password meets requirements
function validatePassword() {
  const pwd = password.value;
  passwordCriteria.value.length = pwd.length >= 8;
  passwordCriteria.value.uppercase = /[A-Z]/.test(pwd);
  passwordCriteria.value.number = /\d/.test(pwd);
  passwordCriteria.value.special = /[!@#$%^&*(),.?":{}|<>]/.test(pwd);
}

function getCriteriaClass(isValid) {
  return isValid ? "text-green-500" : "text-red-500";
}

async function handleSubmitChangePassword() {
  try {
    if (!authStore.isTurnstileValid) {
      submitError.value =
        "Please complete the security check above to confirm that you are a human.";
      return;
    }

    if (!isFormValid.value) return;
    isLoading.value = true;
    const token = route.query.token;
    const tokenId = route.query.tokenId;
    submitError.value = false;
    if (!token || !tokenId) {
      submitError.value = true;
      return;
    }
    const changePasswordResult = await authStore.changePassword(token, tokenId, password.value);
    if (!changePasswordResult) {
      submitError.value = "Error changing password. Please try again.";
    } else {
      UIStore.animateNotificationAlert({
        message: "Password has been Successfully updated!",
        icon: "Key",
      });
      router.push("/sign-in");
    }
  } catch (err) {
    submitError.value = "Error changing password. Please try again.";
  } finally {
    isLoading.value = false;
  }
}

let timeout = null;
watch(
  () => submitError.value,
  (newValue) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (newValue) {
      timeout = setTimeout(() => {
        submitError.value = false;
      }, 3000);
    }
  }
);
</script>
