<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#ae2smj2fja)">
      <path
        d="M2.666 10.666c-.733 0-1.333-.6-1.333-1.333V2.666c0-.733.6-1.333 1.333-1.333h6.667c.733 0 1.333.6 1.333 1.333m-4 2.667h6.667c.736 0 1.333.597 1.333 1.333v6.667c0 .736-.597 1.333-1.333 1.333H6.666a1.333 1.333 0 0 1-1.333-1.333V6.666c0-.736.597-1.333 1.333-1.333z"
        stroke="#06253F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="ae2smj2fja">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
