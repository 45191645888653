<template>
  <div class="px-3 py-5 md:px-[1.375rem] lg:px-10 md:py-6 flex flex-col h-full">
    <div class="flex justify-between items-center mb-5">
      <div>
        <p class="text-xs font-leaguespartan leading-tight font-medium text-primary-2 uppercase">
          Welcome back,
          {{ displayedInvestorName }}
        </p>
        <p class="text-2xl font-leaguespartan leading-tight font-bold text-text-dark uppercase">
          your DOCUMENTS
        </p>
      </div>
    </div>
    <div class="grow">
      <GeneralDocuments v-if="investorStore.active_documents_tab === 'Documents'" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch, ref } from "vue";

import { useInvestorStore } from "@/stores";
import GeneralDocuments from "./GeneralDocuments.vue";

const investorStore = useInvestorStore();

const displayedInvestorName = ref("");

onMounted(() => {
  window.scrollTo(0, 0);
  // Initialize displayed investor name
  if (investorStore.contact) {
    displayedInvestorName.value = `${investorStore.contact.first_name} ${investorStore.contact.last_name}`;
  }
});

watch(
  () => investorStore.contact,
  (newContact) => {
    if (newContact) {
      displayedInvestorName.value = `${newContact.first_name} ${newContact.last_name}`;
    }
  },
  { immediate: true }
);
</script>
