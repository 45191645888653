<template>
  <div class="flex flex-col w-full h-full lg:my-6">
    <button
      type="button"
      @click="handleBack"
      class="md:hidden flex items-center justify-center gap-1.5 text-primary-6 absolute top-24 left-5"
    >
      <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
      <p>Back</p>
    </button>
    <div
      class="flex flex-col grow items-center lg:pt-10 lg:pb-8 lg:border border-neutral-gray-4 w-full rounded-md h-full"
    >
      <div class="w-full lg:w-[70%] mx-auto flex flex-col justify-between h-full">
        <div>
          <h1 class="font-bold text-xl uppercase text-primary-6">
            Step {{ investmentStore.currentStep - 2 }}-{{ ji_substep }}: Joint Information
          </h1>
          <p class="text-lighter text-sm mt-2 mb-6">
            Investment Type: {{ investmentStore.currentInvestment.type }} • Offering Type:
            {{ investmentStore.selectedOffering.type }}
            ({{ investmentStore.selectedOffering.interest_rate }}% -
            {{
              investmentStore.selectedOffering.bond_term === 12
                ? "1 Year"
                : investmentStore.selectedOffering.bond_term / 12 + " Years"
            }}
            - {{ investmentStore.currentInvestment.is_compounded ? "Compounded" : "Paid Monthly" }})
            • Amount to Invest: ${{ investmentStore.currentInvestment.amount }}
          </p>
          <div v-if="ji_substep === 1 && investmentStore.hasSignedJointInvestment && !isEditMode">
            <h3 class="font-bold text-lg mb-2 md:mb-8">Select Joint Investor</h3>
            <div class="bg-neutral-gray-2 bg-opacity-70 p-4 rounded-lg flex flex-col gap-6">
              <label
                v-for="(joint_contact, index) in investorStore.joint_contacts"
                :key="joint_contact.email"
                class="bg-white border border-platform-outlines flex items-center justify-between min-h-18 rounded-md p-3 pl-6 cursor-pointer"
                :class="{
                  'border-gold': investorStore.active_joint_contact?.email === joint_contact.email,
                }"
                @click="investmentStore.active_joint_contact = joint_contact"
              >
                <div>
                  <RadioOption
                    :id="`${index}`"
                    name="activeJointContact"
                    :value="joint_contact"
                    v-model="investorStore.active_joint_contact"
                    :label="`${joint_contact.first_name} ${joint_contact.last_name}`"
                    :description="joint_contact.email"
                  />
                  <p
                    v-if="
                      joint_contact._id &&
                      joint_contact?._id?.toString() ===
                        investorStore.active_joint_contact?._id?.toString() &&
                      isContactMissingRequiredField(investorStore.active_joint_contact)
                    "
                    class="ml-10 text-sm text-functional-error-default"
                  >
                    You are missing a required field. Click Edit to complete.
                  </p>
                </div>

                <div class="flex gap-4 items-center">
                  <p
                    class="whitespace-nowrap hidden md:block"
                    v-if="
                      joint_contact?._id?.toString() ===
                      investorStore.active_joint_contact?._id?.toString()
                    "
                  >
                    <span class="font-semibold">{{
                      numberOfSignedInvestmentsForActiveJointContact
                    }}</span>
                    Signed Investment<span
                      v-if="numberOfSignedInvestmentsForActiveJointContact !== 1"
                      >s</span
                    >
                  </p>
                  <Button
                    @click="
                      (isEditMode = true), (investorStore.active_joint_contact = joint_contact)
                    "
                    label
                    variant="default-gray-outlined"
                  >
                    <template #label>Edit</template>
                  </Button>
                </div>
              </label>
              <label
                class="bg-white border border-platform-outlines flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
                :class="{ 'border-gold': investorStore.active_joint_contact?.email === '' }"
                @click="investmentStore.active_joint_contact = newJointContactTemplate"
              >
                <RadioOption
                  id="newJointContact"
                  name="newJointContact"
                  :value="newJointContactTemplate"
                  v-model="investorStore.active_joint_contact"
                  label="New Joint Investor"
                  description="Create another Joint Investor"
                />
              </label>
            </div>
          </div>
          <template v-else v-for="(schema, index) of schemaToUse" :key="index">
            <Form
              v-if="ji_substep === index + 1"
              :fields="schema.fields"
              :record="investorStore.active_joint_contact"
              :header="schema.group_name"
              :subHeader="
                index === 1
                  ? 'Phoenix requires a physical address. PO Boxes and mail drops are not valid. If you use a PO Box for receiving mail, please reach out to Investor Relations at (303) 376-9778 to have your account updated.'
                  : index === 2
                    ? 'Your passport or government issued ID (driver\'s license).'
                    : ''
              "
              type="joint"
              :isNew="!investorStore.active_joint_contact?._id"
            />
          </template>
          <Banner v-if="ji_substep === 1" type="warning" class="mt-6" hideClose>
            <template #content>
              <div class="flex items-center">
                <p>
                  For Joint Investments, a second signature will be required. Phoenix will send the
                  agreement to the Joint Investors email address.
                </p>
              </div>
            </template>
          </Banner>
        </div>
        <div
          class="w-full flex gap-4 flex-col-reverse md:flex-row justify-between items-center border-t border-neutral-gray-4 pt-6 mt-6"
        >
          <button @click="handleBack" type="button" class="flex items-center text-primary-6 gap-2">
            <Icon name="ChevronLeft" class="hidden md:block h-5 w-5 text-primary-6 mb-0.5" />
            <p>Back</p>
          </button>
          <Button
            @click="handleNext"
            :disabled="!isFormValid"
            label
            icon
            size="large"
            class="w-full md:w-value-42"
          >
            <template #label>Next</template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { cloneDeep } from "lodash";

import { useInvestmentStore, useInvestorStore } from "@/stores";
import { Button, Icon, Form, Banner, RadioOption } from "@/components";
import { JOINT_TEMPLATE } from "@/constants";
import { getObjectDifferences, validateField } from "@/utilities";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

const ji_substep = ref(1);
const original_active_joint_contact = ref({});
const isEditMode = ref(false);
const newJointContactTemplate = ref({ ...JOINT_TEMPLATE });

onMounted(async () => {
  window.scrollTo(0, 0);
  original_active_joint_contact.value = cloneDeep(investorStore.active_joint_contact);
});

const schemaToUse = computed(() => {
  return investmentStore.selectedOffering.type === "Reg D"
    ? investmentStore.accredited_joint_contact_schema
    : investmentStore.non_accredited_joint_contact_schema;
});

const numberOfSignedInvestmentsForActiveJointContact = computed(() => {
  return investmentStore.transactionsData.filter((investment) => {
    return (
      investment.joint_contact_id &&
      investment.joint_contact_id?.toString() ===
        investorStore.active_joint_contact?._id?.toString()
    );
  }).length;
});

const isAccreditedOffering = computed(() => {
  return investmentStore.selectedOffering?.type === "Reg D";
});

const isFormValid = computed(() => {
  if (!schemaToUse.value || schemaToUse.value.length < ji_substep.value) {
    return false;
  }

  if (
    investorStore.active_joint_contact.is_new &&
    !isEditMode.value &&
    investmentStore.hasSignedJointInvestment
  ) {
    return true;
  }

  if (
    investorStore.hasSpecialError === "ssn" ||
    investorStore.hasSpecialError === "email" ||
    investorStore.hasSpecialError === "id_number"
  ) {
    return false;
  }

  const currentSchema = schemaToUse.value[ji_substep.value - 1];
  const fieldsToValidate = currentSchema.fields.filter((f) => f.is_required);

  return fieldsToValidate.every((field) => {
    const value =
      field.belongs_to_nested_object && field.nested_object_name
        ? investorStore.active_joint_contact[field.nested_object_name]?.[field.field_name]
        : investorStore.active_joint_contact[field.field_name];

    return validateField(field.field_name, value);
  });
});

function isContactMissingRequiredField(contact) {
  if (!contact) {
    return false;
  }

  const currentSchema = schemaToUse.value[ji_substep.value - 1];
  const fieldsToValidate = currentSchema.fields.filter((f) => f.is_required);

  return fieldsToValidate.some((field) => {
    const value =
      field.belongs_to_nested_object && field.nested_object_name
        ? contact[field.nested_object_name]?.[field.field_name]
        : contact[field.field_name];

    return !validateField(field.field_name, value);
  });
}

function hasAnyValueChanged() {
  const differences = getObjectDifferences(
    investorStore.active_joint_contact,
    original_active_joint_contact.value
  );

  // Log the detected differences
  if (Object.keys(differences).length > 0) {
    return true;
  } else {
    return false;
  }
}

watch(
  () => investorStore.active_joint_contact,
  (newContact) => {
    original_active_joint_contact.value = cloneDeep(newContact);
  },
  { immediate: true }
);

watch(
  () => ji_substep.value,
  () => {
    window.scrollTo(0, 0);
  }
);

async function handleBack() {
  if (ji_substep.value === 1) {
    if (isEditMode.value && investmentStore.hasSignedJointInvestment) {
      if (!investorStore.active_joint_contact?._id) {
        investorStore.active_joint_contact.is_new = true;
      }
      isEditMode.value = false;
      return;
    }
    investmentStore.currentStep -= 1;
  } else {
    ji_substep.value -= 1;
  }
}

async function handleNext() {
  const hasChange = hasAnyValueChanged();
  const currentStep = ji_substep.value;
  if (
    investorStore.active_joint_contact.is_new &&
    !isEditMode.value &&
    investmentStore.hasSignedJointInvestment
  ) {
    isEditMode.value = true;
    return;
  }

  // Check if we can allow the skipping the acknowledgement step
  if (investorStore.doesActiveJointHaveSignedInvestments && !isEditMode.value) {
    const reg_d_acks = investorStore.contact?.reg_d_personal_acknowledgements || [];

    const hasCompletedESignatureAcks = reg_d_acks.some((ack) => ack);

    // Handle if Reg D offering is selected but acks are incomplete
    if (investmentStore.selectedOffering.type === "Reg D" && !hasCompletedESignatureAcks) {
      investmentStore.currentStep += 1;
      await investmentStore.updateInvestmentIds();
      return;
    }

    investmentStore.currentStep = investmentStore.investmentSteps.length + 2;
    await investmentStore.updateInvestmentIds();
    return;
  }

  if (isAccreditedOffering.value && ji_substep.value === 3) {
    investmentStore.currentStep += 1;
  } else if (!isAccreditedOffering.value && ji_substep.value === 2) {
    investmentStore.currentStep += 1;
  } else {
    ji_substep.value += 1;
  }

  if (hasChange) {
    original_active_joint_contact.value = cloneDeep(investorStore.active_joint_contact);
    if (!investorStore.active_joint_contact._id) {
      if (isAccreditedOffering.value && currentStep === 3) {
        await investorStore.upsertJointContact(investorStore.active_joint_contact);
      } else if (!isAccreditedOffering.value && currentStep === 2) {
        await investorStore.upsertJointContact(investorStore.active_joint_contact);
      }
    } else {
      await investorStore.upsertJointContact(investorStore.active_joint_contact);
    }
  }
  await investmentStore.updateInvestmentIds();
}
</script>
