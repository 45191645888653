<template>
  <div class="w-full flex flex-col justify-between h-full text-primary-6">
    <!-- Funding Accounts -->
    <div
      v-if="investmentStore.bankAccounts.length > 0"
      class="grid grid-cols-1 lg:grid-cols-2 gap-4 justify-center items-center"
    >
      <div class="col-span-1">
        <h2 class="text-lg font-bold">Bank Accounts</h2>
        <p class="text-lighter text-sm lg:text-base">
          Below are all financial accounts available to use for funding or distribution. To add an
          additional bank account, click on the Connect Bank Account button.
        </p>
      </div>
      <div class="flex justify-end col-span-1 items-center gap-4">
        <PlaidLink accountType="Funding" size="medium" />
      </div>
      <div class="flex flex-col gap-4 lg:col-span-2 col-span-1 w-full">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-neutral-gray-2 rounded-md p-3">
          <div
            v-for="(bankingOption, index) in !showMoreFunding
              ? investmentStore?.bankAccounts?.slice(0, 4)
              : investmentStore.bankAccounts"
            :key="bankingOption.id"
          >
            <!-- Plaid Accounts will go here -->
            <label
              class="border bg-white flex items-center justify-between h-32 rounded-md p-3 px-6"
            >
              <BankingOption
                :id="'' + index"
                :name="'FundingAccount' + index"
                :value="bankingOption._id"
                v-model="selectedBankAccountId"
                :label="bankingOption.financial_institution_name"
                :description="bankingOption.account_type"
                :account_num="bankingOption.account_number"
              />
              <img
                v-if="bankingOption.logo"
                class="h-16 w-16"
                :src="'data:image/png;base64, ' + bankingOption.logo + ''"
                alt="Red dot"
              />
              <div v-else class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                <Icon name="Landmark" class="h-12 w-12 text-neutral-gray-8-" :stroke-width="1" />
              </div>
            </label>
          </div>
          <button
            v-if="investmentStore.bankAccounts?.length > 4"
            @click="showMoreFunding = !showMoreFunding"
            class="text-center lg:col-span-2"
          >
            <span v-if="!showMoreFunding">
              Show More ({{
                investmentStore.bankAccounts?.slice(4, investmentStore.bankAccounts?.length)
                  ?.length
              }})
            </span>
            <span v-else>Show Less</span>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col items-center">
      <EmptyState icon_name="Landmark" description="No banking information to show." />
      <div class="flex justify-end col-span-1 gap-2">
        <PlaidLink accountType="Funding" size="medium" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

import {
  useInvestmentStore,
  useCrudStore,
  useAuthStore,
  useUIStore,
  useInvestorStore,
} from "@/stores";
import { Button, EditBankDrawer, Icon, BankingOption, EmptyState } from "@/components";
import PlaidLink from "../MakeAnInvestment/PlaidLink.vue";

const investmentStore = useInvestmentStore();
const UIStore = useUIStore();
const crudStore = useCrudStore();
const authStore = useAuthStore();
const investorStore = useInvestorStore();

const editBankDrawerOpen = ref(false);
const editBankType = ref("");

const showMoreFunding = ref(false);
const showMoreDistribution = ref(false);
const selectedBankAccountId = ref(null);

onMounted(async () => {
  investmentStore.bankAccounts = await crudStore.aggregate("BankAccounts", [
    {
      $match: {
        user_id: authStore.currentUser.id,
      },
    },
    { $sort: { created_date: -1 } },
  ]);
});
</script>
