<template>
  <div
    v-if="
      investmentStore.investmentDetails.payment_method ||
      investmentStore.investmentDetails.distribution_method
    "
    class="grid grid-cols-1 sm:grid-cols-2 w-full sm:py-4 divide-y-2 sm:divide-x-2 sm:divide-y-0"
  >
    <div class="flex flex-col pb-10 sm:pr-10 sm:pb-0 gap-8 justify-between">
      <div class="flex flex-row items-center min-h-12">
        <div class="flex flex-col gap-1">
          <span class="font-bold grow">Funding Account</span>
          <p class="text-lighter">The account below was used to fund this investment.</p>
        </div>
      </div>
      <div class="bg-neutral-gray-2 p-4 rounded-2xl h-36">
        <div
          class="border border-platform-outlines rounded-xl bg-white p-4 flex flex-row items-center h-full"
        >
          <div
            v-if="investmentStore.investmentDetails.payment_method == 'ACH'"
            class="flex flex-col grow"
          >
            <span class="text-primary font-bold">{{
              banks.funding_account.financial_institution_name
            }}</span>
            <span class="text-light">{{ banks.funding_account.account_type }} account</span>
            <span class="text-light">**** {{ banks.funding_account.mask }}</span>
          </div>
          <div v-else class="grow">
            <span>{{ investmentStore.investmentDetails.payment_method }}</span>
          </div>
          <div v-if="investmentStore.investmentDetails.payment_method == 'ACH'">
            <img
              v-if="banks.funding_account.logo"
              class="h-12 w-12"
              :src="'data:image/png;base64, ' + banks.funding_account.logo + ''"
              alt="Red dot"
            />
          </div>
          <div v-else>
            <Icon
              v-if="investmentStore.investmentDetails.payment_method == 'Wire'"
              name="DollarSign"
              class="h-8 w-8 m-2"
            />
            <Icon
              v-else-if="investmentStore.investmentDetails.payment_method == 'Existing Investment'"
              name="DollarSign"
              class="h-8 w-8 m-2"
            />
            <Icon
              v-else-if="investmentStore.investmentDetails.payment_method == 'Check'"
              name="Banknote"
              class="h-8 w-8 m-2"
            />
            <IconLandmark v-else class="h-8 w-8" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col pb-10 sm:pl-10 sm:pb-0 gap-8 justify-between pt-4 sm:pt-0">
      <div class="flex flex-row items-center min-h-12 gap-4">
        <div class="flex flex-col gap-1 grow">
          <span class="font-bold grow">Distribution Account</span>
          <p class="text-lighter max-w-[95%]">
            Phoenix distributes interest monthly on the 10th of each month. We prefer to make
            payments via ACH. Select the method and account where you want the interest payment
            deposited. If you wish to change the account, click Edit to add another bank account.
          </p>
        </div>
        <Button
          class="self-start justify-self-start"
          variant="default-gray-outlined"
          size="large"
          :label="true"
          @click="$emit('open-bank-drawer', 'Distribution')"
          ><template #label> <div class="text-base">Edit</div> </template>
        </Button>
      </div>
      <div class="bg-neutral-gray-2 p-4 rounded-2xl h-36">
        <div
          class="border border-platform-outlines rounded-xl bg-white p-4 flex flex-row items-center h-full"
        >
          <div
            v-if="investmentStore.investmentDetails.distribution_method == 'ACH'"
            class="flex flex-col grow"
          >
            <span class="text-primary font-bold">{{
              banks.distrubution_account.financial_institution_name
            }}</span>
            <span class="text-light">{{ banks.distrubution_account.account_type }} account</span>
            <span class="text-light">**** {{ banks.distrubution_account.mask }}</span>
          </div>
          <div v-else class="grow">
            <span>{{ investmentStore.investmentDetails.distribution_method }}</span>
          </div>
          <div v-if="banks.distrubution_account.logo">
            <img
              class="h-12 w-12"
              :src="'data:image/png;base64, ' + banks.distrubution_account.logo + ''"
              alt="Red dot"
            />
          </div>
          <div v-else>
            <Icon
              v-if="investmentStore.investmentDetails.distribution_method == 'Wire'"
              name="DollarSign"
              class="h-8 w-8 m-2"
            />
            <Icon
              v-else-if="investmentStore.investmentDetails.distribution_method == 'Check'"
              name="Banknote"
              class="h-8 w-8 m-2"
            />
            <IconLandmark v-else class="h-8 w-8" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <EmptyState
      icon_name="Landmark"
      description="This investment does not have any banking information."
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { Icon, Button, EmptyState } from "@/components";
import { IconLandmark } from "@/components/icons";
import { useInvestmentStore, useUIStore, useInvestorStore } from "@/stores";

const investmentStore = useInvestmentStore();
const UIStore = useUIStore();
const investorStore = useInvestorStore();

const props = defineProps({
  banks: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["open-bank-drawer"]);
</script>
