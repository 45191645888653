<template>
  <div v-if="UIStore.isLoading">
    <LoadingSpinner :show="UIStore.isLoading" />
  </div>
  <div class="flex flex-col w-full h-full lg:my-6">
    <button
      type="button"
      @click="handleBack"
      class="md:hidden flex items-center justify-center gap-1.5 text-primary-6 absolute top-24 left-5"
    >
      <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
      <p>Back</p>
    </button>
    <div
      class="flex flex-col grow items-center lg:pt-10 lg:pb-8 lg:border border-neutral-gray-4 w-full rounded-md h-full"
    >
      <div class="w-full lg:w-[70%] mx-auto">
        <BankingInfo
          v-if="
            investmentStore.currentStep === investmentStore.investmentSteps.indexOf('Banking') + 3
          "
        />
        <ReviewInvestment v-else />
        <div
          class="w-full flex gap-4 flex-col-reverse md:flex-row justify-between items-center border-t border-neutral-gray-4 pt-6 mt-6"
        >
          <button @click="handleBack" type="button" class="flex items-center text-primary-6 gap-2">
            <Icon name="ChevronLeft" class="hidden md:block h-5 w-5 text-primary-6 mb-0.5" />
            <p>Back</p>
          </button>
          <Button
            @click="handleNext"
            :disabled="!isBankingInfoValid"
            @keydown.enter="handleNext"
            label
            icon
            size="large"
            class="w-full md:w-value-42"
          >
            <template #label>{{
              investmentStore.currentStep !== investmentStore.investmentSteps.indexOf("Banking") + 3
                ? "Sign"
                : "Review"
            }}</template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { isEqual, cloneDeep } from "lodash";

import { useInvestmentStore, useUIStore, useInvestorStore } from "@/stores";
import { Button, Icon, LoadingSpinner } from "@/components";

import BankingInfo from "./BankingInfo.vue";
import ReviewInvestment from "./ReviewInvestment.vue";

const router = useRouter();

const investmentStore = useInvestmentStore();
const UIStore = useUIStore();
const investorStore = useInvestorStore();

const waitingForSigningLink = ref(false);

onMounted(async () => {
  window.scrollTo(0, 0);
  setTimeout(() => {
    // Fetch signing link if not already present or if changes were detected
    if (
      !investmentStore.currentInvestment?.type?.includes("IRA") &&
      (!investmentStore.signing_link || checkForChanges())
    ) {
      storeOriginalSubInformation();
      getSigningLink();
    }
  }, 1000);
});

watch(
  () => investmentStore.currentStep,
  () => {
    window.scrollTo(0, 0);
  }
);

watch(
  () => investmentStore.signing_link,
  (newLink) => {
    if (waitingForSigningLink.value && newLink) {
      redirectToSigningLink();
    }
  }
);

function handleBack() {
  // if (investmentStore.isReview) {
  //   investmentStore.isReview = false;
  //   return;
  // }
  investmentStore.currentStep -= 1;
}

async function handleNext() {
  try {
    if (
      investmentStore.currentStep ===
      investmentStore.investmentSteps.indexOf("Review and Sign Agreement") + 3
    ) {
      if (investmentStore.currentInvestment?.type?.includes("IRA")) {
        const investmentId = investmentStore.currentInvestment?._id;
        UIStore.isLoading = true;
        await investmentStore.signIRAInvestment();
        setTimeout(() => {
          UIStore.isLoading = false;
          router.push(`/thank-you?investment_id=${investmentId}`);
        }, 2000);
        return;
      } else {
        UIStore.isLoading = true;
        await signSubAgreement();
      }
      return;
    } else {
      investmentStore.currentStep += 1;
      const promises = [];
      promises.push(investmentStore.updateInvestmentPaymentMethodAndJoinedFields());
      if (investmentStore.currentInvestment.voided_check) {
        promises.push(investmentStore.uploadVoidedCheck());
      }
      if (investmentStore.currentInvestment?.type?.includes("IRA")) {
        promises.push(investmentStore.updateInvestmentIRAQuestionnaire());
      }
      if (investmentStore.currentInvestment?.payment_method === "Existing Investment") {
        promises.push(investmentStore.insertUpdateUnsignedInvestment());
      }
      await Promise.all(promises);
    }
  } catch (err) {
    console.error(err);
  }
}

const isBankingInfoValid = computed(() => {
  if (investmentStore.currentStep !== investmentStore.investmentSteps.length - 1) {
    // if we're funding with ACH we need to make sure we have a funding account
    if (investmentStore.currentInvestment?.payment_method === "ACH") {
      if (!investmentStore.currentInvestment?.funding_account) {
        return false;
      }
    }
    // if we're distributing with ACH we need to make sure we have a distribution account or voided check
    if (investmentStore.currentInvestment?.distribution_method === "ACH") {
      if (
        !investmentStore.currentInvestment?.distribution_account &&
        !investmentStore.currentInvestment.voided_check
      ) {
        return false;
      } else if (investmentStore.currentInvestment.voided_check) {
        if (!investmentStore.selectedVoidedCheckFile) {
          return false;
        }
      }
    }

    // if we are not IRA, make sure we have a distribution method and payment method
    if (!investmentStore.currentInvestment?.type?.includes("IRA")) {
      if (
        !investmentStore.currentInvestment?.distribution_method ||
        !investmentStore.currentInvestment?.payment_method
      ) {
        return false;
      }
    }

    // If we are dealing with an IRA investment, make sure the questionnaire is filled out
    if (investmentStore.currentInvestment?.type?.includes("IRA")) {
      const questionnaire = investorStore.contact?.ira_questionnaire;
      const questionOneAnswer = questionnaire?.has_ira_account;

      // If Question 1 is true, check all questions for truthiness
      if (questionOneAnswer) {
        if (Object.values(questionnaire).some((value) => value === null || value === "")) {
          return false;
        }
      } else {
        // If Question 1 is false, only check the first 3 questions
        const firstThreeFields = [
          "has_ira_account",
          "in_self_directed_ira",
          "has_other_qualified_funds",
        ];
        if (
          firstThreeFields.some(
            (field) => questionnaire[field] === null || questionnaire[field] === ""
          )
        ) {
          return false;
        }
      }
    }

    return true;
  } else {
    return true;
  }
});

async function getSigningLink() {
  const signingLink = await investmentStore.getSigningLink();
  if (!signingLink) {
    UIStore.animateNotificationAlert({
      message: "There was an error generating the sub agreement!",
      icon: "ShieldAlert",
      type: "error",
    });
  } else {
    investmentStore.signing_link = signingLink;
  }
}

async function signSubAgreement() {
  if (investmentStore.signing_link) {
    redirectToSigningLink();
  } else {
    waitingForSigningLink.value = true;
  }
}

function redirectToSigningLink() {
  setTimeout(() => {
    UIStore.isLoading = false;
    window.location.href = investmentStore.signing_link;
  }, 4000);
}

function storeOriginalSubInformation() {
  investorStore.original_owner_conatct = cloneDeep(investorStore.contact);
  investorStore.original_joint_contact = cloneDeep(investorStore.active_joint_contact);
  investorStore.original_entity_account = cloneDeep(investorStore.active_entity_account);
  investmentStore.original_current_investment = cloneDeep(investmentStore.currentInvestment);
  investmentStore.original_offering = cloneDeep(investmentStore.selectedOffering);
  investmentStore.original_acknowledgements = cloneDeep(investorStore.reg_d_acknowledgements);
}

function checkForChanges() {
  const changes = [];

  if (!isEqual(investorStore.original_owner_conatct, investorStore.contact)) {
    changes.push(
      ...findDifferences(
        investorStore.original_owner_conatct,
        investorStore.contact,
        "Owner contact: "
      )
    );
  }

  if (!isEqual(investmentStore.original_current_investment, investmentStore.currentInvestment)) {
    changes.push(
      ...findDifferences(
        investmentStore.original_current_investment,
        investmentStore.currentInvestment,
        "Current investment: "
      )
    );
  }

  if (!isEqual(investmentStore.original_offering, investmentStore.selectedOffering)) {
    changes.push(
      ...findDifferences(
        investmentStore.original_offering,
        investmentStore.selectedOffering,
        "Selected offering: "
      )
    );
  }

  if (!isEqual(investorStore.original_joint_contact, investorStore.active_joint_contact)) {
    changes.push(
      ...findDifferences(
        investorStore.original_joint_contact,
        investorStore.active_joint_contact,
        "Joint contact: "
      )
    );
  }

  // Check for changes in entity account information
  if (!isEqual(investorStore.original_entity_account, investorStore.active_entity_account)) {
    changes.push(
      ...findDifferences(
        investorStore.original_entity_account,
        investorStore.active_entity_account,
        "Entity account: "
      )
    );
  }

  if (!isEqual(investmentStore.original_acknowledgements, investorStore.reg_d_acknowledgements)) {
    changes.push(
      ...findDifferences(
        investmentStore.original_acknowledgements,
        investorStore.reg_d_acknowledgements,
        "Acknowledgements: "
      )
    );
  }

  if (changes.length > 0) {
    // console.log("Changes detected:", changes);
    return true;
  }

  return false;
}

// Helper function to find differences between two objects
function findDifferences(original, current, prefix = "") {
  const changes = [];
  for (const key in original) {
    if (Object.prototype.hasOwnProperty.call(original, key)) {
      if (!isEqual(original[key], current[key])) {
        changes.push(`${prefix}${key} changed`);
      }
    }
  }
  return changes;
}
</script>
