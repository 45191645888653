<template>
  <div
    class="flex flex-col justify-between grow items-center md:px-4 lg:px-0 md:pt-10 md:border border-neutral-gray-4 w-full rounded-md md:pb-8 h-full"
  >
    <div class="w-full lg:w-[70%] mx-auto flex flex-col justify-between h-full">
      <div>
        <h1 class="font-bold text-xl uppercase">
          Step {{ investmentStore.currentStep - 2 }}: E-Signature Acknowledgements
        </h1>
        <p class="text-lighter text-sm mt-2 mb-6">
          Investment Type: {{ investmentStore.currentInvestment.type }} • Offering Type:
          {{ investmentStore.selectedOffering.type }}
          ({{ investmentStore.selectedOffering.interest_rate }}% -
          {{
            investmentStore.selectedOffering.bond_term === 12
              ? "1 Year"
              : investmentStore.selectedOffering.bond_term / 12 + " Years"
          }}
          - {{ investmentStore.currentInvestment.is_compounded ? "Compounded" : "Paid Monthly" }}) •
          Amount to Invest: ${{ investmentStore.currentInvestment.amount }}
        </p>
        <div
          v-for="(section, index) in filteredEsignatureOptions"
          :key="index"
          class="flex flex-col gap-4"
        >
          <h3 class="font-bold text-lg">
            {{ section.type.replace(/_/g, " ").toUpperCase() }}
            <span class="text-sm text-lighter font-normal">(at least 1 selection is required)</span>
          </h3>
          <div v-for="(option, idx) in section.options" :key="idx" class="flex items-start mb-4">
            <input
              type="checkbox"
              :id="`option-${index}-${idx}`"
              v-model="investorStore.reg_d_acknowledgements[index > 0 ? idx + 14 : idx]"
              class="text-primary-6 accent-current min-h-4 min-w-4 mt-[.3rem]"
            />
            <div class="ml-3">
              <label :for="`option-${index}-${idx}`" class="text-sm text-lighter cursor-pointer">
                {{ option }}
              </label>
              <div
                v-if="
                  investmentStore.currentInvestment.type !== 'Entity' &&
                  idx === 3 &&
                  investorStore.reg_d_acknowledgements[3]
                "
                class="flex space-x-2 items-center mt-2"
              >
                <Icon
                  name="Flag"
                  class="h-4 w-4 text-functional-error-default animate-bounce"
                  :stroke-width="2"
                />
                <p class="text-red-500 font-medium text-sm">
                  Your current selection is not appropriate for the majority of investors, are you
                  sure?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Back/Next Button -->
      <div
        class="w-full flex gap-4 flex-col-reverse md:flex-row justify-between items-center border-t border-neutral-gray-4 pt-6 mt-6"
      >
        <button @click="handleBack" type="button" class="flex items-center gap-2">
          <Icon name="ChevronLeft" class="hidden md:block h-5 w-5 text-primary-6 mb-1" />
          <p>Back</p>
        </button>
        <Button
          @click="handleNext"
          :disabled="!areNeededOptionsSelected"
          label
          icon
          size="large"
          class="w-full md:w-value-42"
        >
          <template #label>Next</template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, onMounted } from "vue";

import { useInvestmentStore, useInvestorStore } from "@/stores";
import { ESIGNATURE_OPTIONS } from "@/constants";

import { Button, Icon } from "@/components";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

onMounted(() => {
  window.scrollTo(0, 0);
  if (investmentStore.currentInvestment.type === "Entity") {
    investorStore.reg_d_acknowledgements =
      investorStore.active_entity_account.reg_d_entity_acknowledgements || [];
  } else {
    investorStore.reg_d_acknowledgements =
      investorStore.contact.reg_d_personal_acknowledgements || [];
  }
});

const filteredEsignatureOptions = computed(() => {
  const investmentType = investmentStore.currentInvestment.type;

  if (investmentType === "Individual" || investmentType === "Joint") {
    return ESIGNATURE_OPTIONS.filter((option) => option.type === "personal_acknowledgements");
  } else if (investmentType === "Entity") {
    return ESIGNATURE_OPTIONS.filter(
      (option) =>
        option.type === "entity_acknowledgements" || option.type === "benefit_plan_investor"
    );
  }
  return [];
});

const areNeededOptionsSelected = computed(() => {
  if (investmentStore.currentInvestment.type === "Entity") {
    const first14 = investorStore.reg_d_acknowledgements.slice(0, 14);
    const last2 = investorStore.reg_d_acknowledgements.slice(14);
    return first14.some((value) => value) && (last2[0] || last2[1]);
  }
  return investorStore.reg_d_acknowledgements.some((value) => value);
});

watch(
  filteredEsignatureOptions,
  (newOptions) => {
    const optionsLength = newOptions.reduce((total, section) => total + section.options.length, 0);
    initializeAcknowledgements(optionsLength);
  },
  { immediate: true }
);

function initializeAcknowledgements(optionsLength) {
  if (
    !investorStore.reg_d_acknowledgements ||
    investorStore.reg_d_acknowledgements.length !== optionsLength
  ) {
    investorStore.reg_d_acknowledgements = Array(optionsLength).fill(false);
  }
}

// Handle Back Button
function handleBack() {
  investmentStore.currentStep -= 1;
}

// Handle Next Button
async function handleNext() {
  investmentStore.currentStep += 1;
  const ackType = investmentStore.currentInvestment.type.toLowerCase();
  await investorStore.updateRegDAcknowledgements(
    ackType,
    ackType === "entity" ? investorStore.active_entity_account : null
  );
}
</script>
