import { defineStore } from "pinia";
import * as Realm from "realm-web";

import { useCrudStore, useAuthStore } from "@/stores";

const {
  BSON: { ObjectId },
} = Realm;

export const useSchemaStore = defineStore("schemaStore", {
  state: () => ({
    // Schema properties
    all_contact_schema: [],
    all_account_schema: [],
    all_banking_schema: [],
    all_distribution_schema: [],
    all_investment_schema: [],
    all_offerings: [],
    custodian_name_options: [],
    admin_investment_types: [],
    disabled_beneficial_owner_contact_fields: [],
    disabled_joint_contact_fields: [],
    disabled_owner_contact_fields: [],
    disabled_entity_account_fields: [],
    rollover_table_fields: [],
    title_transfer_reasons: [],
    title_transfer_schema: [],
    transfer_banner_headers: [],
    // Schema templates
    accredited_owner_contact_personal_info_schema: [],
    non_accredited_owner_contact_personal_info_schema: [],
    accredited_joint_contact_personal_info_schema: [],
    non_accredited_joint_contact_personal_info_schema: [],
    accredited_ben_owner_contact_personal_info_schema: [],
    non_accredited_ben_owner_contact_personal_info_schema: [],
    entity_information: [],
    suitability_questions: [],
    modal_banking_schema: [],
    funding_account_template: {},
    distribution_account_template: {},
    // Investment types
    investment_types: [],
  }),
  getters: {},
  actions: {
    async setSchema() {
      // Initialize the CRUD store
      const crudStore = useCrudStore();

      try {
        // Fetch the configuration lists based on the references
        const configurationLists = await crudStore.find("Settings", {
          is_list: true,
        });

        this.admin_investment_types = configurationLists.find(
          (config) => config.reference === "admin_investment_types"
        ).options;
        this.disabled_beneficial_owner_contact_fields = configurationLists.find(
          (config) => config.reference === "disabled_beneficial_owner_contact_fields"
        ).options;
        this.disabled_joint_contact_fields = configurationLists.find(
          (config) => config.reference === "disabled_joint_contact_fields"
        ).options;
        this.disabled_owner_contact_fields = configurationLists.find(
          (config) => config.reference === "disabled_owner_contact_fields"
        ).options;
        this.disabled_entity_account_fields = configurationLists.find(
          (config) => config.reference === "disabled_entity_account_fields"
        ).options;
        this.rollover_table_fields = configurationLists.find(
          (config) => config.reference === "rollover_table_fields"
        ).options;
        this.title_transfer_reasons = configurationLists.find(
          (config) => config.reference === "title_transfer_reasons"
        ).options;
        let transfer_banner_headers = configurationLists.find(
          (config) => config.reference === "transfer_banner_headers"
        ).options;

        // Fetch schemas and custodian names concurrently
        const [
          contactSchema,
          accountSchema,
          suitabilitySchema,
          //custodianNames,
          //allCustodians,
          BankingSchema,
          investmentSchema,
          //offerings,
          distributionSchema,
        ] = await Promise.all([
          crudStore.find("Schema", { collection_name: "Contacts" }),
          crudStore.find("Schema", { collection_name: "Accounts" }),
          crudStore.find("Schema", { collection_name: "SuitabilityInfo" }),
          // crudStore.aggregate("Custodians", [
          //   { $group: { _id: null, names: { $addToSet: "$name" } } },
          //   { $project: { _id: 0, names: 1 } },
          // ]),
          // crudStore.aggregate("Custodians", [{ $project: { _id: 1, name: 1 } }]),
          crudStore.find("Schema", { collection_name: "BankAccounts" }),
          crudStore.find("Schema", { collection_name: "Investments" }),
          //crudStore.find("Offerings", {}),
          crudStore.find("Schema", { collection_name: "Distributions" }),
        ]);

        // Assign fetched schemas and custodian names to the store properties
        this.all_contact_schema = contactSchema;
        this.all_account_schema = accountSchema;
        this.all_banking_schema = BankingSchema;
        this.all_investment_schema = investmentSchema;
        //this.all_offerings = offerings;
        this.all_distribution_schema = distributionSchema;

        //this.custodian_name_options = custodianNames[0]?.names || [];
        //this.all_custodians = allCustodians;

        // Helper function to map configuration to schema fields
        const mapConfigToSchema = (config, flattenedSchema, useAccreditedFields) =>
          config.options.map((groupName) => ({
            group_name: groupName,
            fields: flattenedSchema.filter(
              (field) =>
                field.record_detail_group === groupName &&
                (useAccreditedFields || !field.accredited_only)
            ),
          }));

        // Flatten the schemas based on different configurations
        const flattenedOwnerContactSchema = this.flattenSchema(contactSchema, "default");
        const flattenedJointContactSchema = this.flattenSchema(contactSchema, "joint");
        const flattenedBenOwnerSchema = this.flattenSchema(contactSchema, "beneficial_owner");
        const flattenedBankingSchema = this.flattenSchema(BankingSchema, "default");
        const flattenedEntitySchema = this.flattenSchema(accountSchema, "entity");
        // const flattenedSuitabilitySchema = this.flattenSchema(suitabilitySchema, "default");

        this.modal_banking_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "bank_account_fields"),
          flattenedBankingSchema,
          true // Use accredited fields
        );

        // Map configurations to schemas
        this.accredited_owner_contact_personal_info_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "accredited_personal_info"),
          flattenedOwnerContactSchema,
          true // Use accredited fields
        );

        this.non_accredited_owner_contact_personal_info_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "non_accredited_personal_info"),
          flattenedOwnerContactSchema,
          false // Do not use accredited fields
        );

        this.accredited_joint_contact_personal_info_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "accredited_joint_info"),
          flattenedJointContactSchema,
          true // Use accredited fields
        );

        this.non_accredited_joint_contact_personal_info_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "non_accredited_joint_info"),
          flattenedJointContactSchema,
          false // Do not use accredited fields
        );

        this.accredited_ben_owner_contact_personal_info_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "accredited_ben_owner_info"),
          flattenedBenOwnerSchema,
          true // Use accredited fields
        );

        this.non_accredited_ben_owner_contact_personal_info_schema = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "non_accredited_ben_owner_info"),
          flattenedBenOwnerSchema,
          false // Do not use accredited fields
        );

        this.entity_information = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "entity_information"),
          flattenedEntitySchema,
          true // Use accredited fields
        );

        this.suitability_questions = mapConfigToSchema(
          configurationLists.find((config) => config.reference === "suitability_info"),
          flattenedOwnerContactSchema,
          true // Use accredited fields
        );

        //this.getInvestmentTitleTransferFields();
        //this.getTransferBannerFields(transfer_banner_headers);
      } catch (err) {
        console.error(err);
      }
    },
    flattenSchema(schema, config = "default") {
      // Flatten the schema
      const flattened = schema.map((item) => {
        // Destructure the item to separate record_detail_config and the rest of the fields
        const { record_detail_config, ...rest } = item;

        // Extract the required config or default if not provided
        const selectedConfig = record_detail_config[config] || record_detail_config["default"];

        // Merge the selected config fields into the rest of the item fields
        return {
          ...rest,
          ...selectedConfig,
        };
      });

      // Sort the flattened array based on record_detail_group_order
      flattened.sort(
        (a, b) => (a.record_detail_group_order || 0) - (b.record_detail_group_order || 0)
      );

      // Return the sorted array
      return flattened;
    },
    createBankTemplate(type) {
      let flattened_banking_schema = this.flattenSchema(this.all_banking_schema, "default");
      let bank_temp = this.generateDefaultObject(flattened_banking_schema);
      bank_temp = this.addCreatedUpdatedFields(bank_temp, true, true);

      bank_temp.template_type = type;

      bank_temp._id = Math.floor(1000000000 + Math.random() * 9000000000);
      bank_temp.unique_id = Math.floor(1000000000 + Math.random() * 9000000000);
      return bank_temp;
    },
    createJointTemplate() {
      let flattened_joint_schema = this.flattenSchema(this.all_contact_schema, "joint");
      let joint_contact_template = this.generateDefaultObject(flattened_joint_schema);
      joint_contact_template.type = "Joint";
      joint_contact_template = this.addCreatedUpdatedFields(joint_contact_template, true, true);
      //set this to a random 10 digit number
      joint_contact_template._id = Math.floor(1000000000 + Math.random() * 9000000000);

      return joint_contact_template;
    },
    createBenOwnerTemplate() {
      let flattened_ben_owner_schema = this.flattenSchema(
        this.all_contact_schema,
        "beneficial_owner"
      );
      let ben_owner_template = this.generateDefaultObject(flattened_ben_owner_schema);
      ben_owner_template.type = "Beneficial Owner";
      ben_owner_template = this.addCreatedUpdatedFields(ben_owner_template, true, true);
      //set this to a random 10 digit number
      ben_owner_template._id = Math.floor(1000000000 + Math.random() * 9000000000);

      return ben_owner_template;
    },
    createInvestorTemplate() {
      let flattened_investor_schema = this.flattenSchema(this.all_contact_schema, "default");
      let investor_template = this.generateDefaultObject(flattened_investor_schema);
      investor_template.type = "Regular";
      investor_template = this.addCreatedUpdatedFields(investor_template, true, true);
      //set this to a random 10 digit number
      investor_template._id = Math.floor(1000000000 + Math.random() * 9000000000);

      return investor_template;
    },
    createEntityTemplate() {
      let flattened_entity_schema = this.flattenSchema(this.all_account_schema, "entity");
      let entity_template = this.generateDefaultObject(flattened_entity_schema);
      entity_template = this.addCreatedUpdatedFields(entity_template, true, true);
      entity_template.type = "Entity";

      entity_template.beneficial_owners = [];
      return entity_template;
    },
    createOwnerAccountTemplate() {
      let flattened_owner_account_schema = this.flattenSchema(this.all_account_schema, "default");
      let owner_account_template = this.generateDefaultObject(flattened_owner_account_schema);
      owner_account_template = this.addCreatedUpdatedFields(owner_account_template, true, true);
      owner_account_template.type = "Regular";
      return owner_account_template;
    },
    update_password_fields(collection, record, skip_base64_encoding) {
      const crudStore = useCrudStore();

      let schema_to_use = [];
      switch (collection) {
        case "Contacts":
          schema_to_use = this.all_contact_schema;

          break;
        case "Accounts":
          schema_to_use = this.all_account_schema;

          break;
        case "Investments":
          schema_to_use = this.all_investment_schema;

          break;
        case "BankAccounts":
          schema_to_use = this.all_banking_schema;

          break;
        default:
          schema_to_use = [];

          break;
      }
      //before setting this to only be password fields, we need to loop through the keys on the record that was passed, and if it has a field name that is not one of the field_name on the objects in the schema array, then I want to delete the property from the record
      for (const key in record) {
        if (!schema_to_use.some((field) => field.field_name === key)) {
          delete record[key];
        }
      }
      for (const schema of schema_to_use) {
        if (schema.field_type === "date") {
          const value = record[schema.field_name];

          if (value instanceof Date) {
            // Leave the value as it is if it's already a Date object

            continue;
          }

          if (!value) {
            // Set to null if the value is empty or falsy

            record[schema.field_name] = null;
          } else {
            // Attempt to create a new Date object
            const date = new Date(value);
            // Check if the date is valid
            if (isNaN(date.getTime())) {
              console.warn(
                `Field ${schema.field_name} has an invalid date format. Setting value to null.`
              );
              record[schema.field_name] = null;
            } else {
              record[schema.field_name] = date;
            }
          }
        }
      }

      if (!skip_base64_encoding) {
        //filter schema_to_use to only include fields that are password fields
        schema_to_use = schema_to_use.filter((field) => field.field_type === "password");

        for (const field of schema_to_use) {
          try {
            if (field.belongs_to_nested_object) {
              const nestedValue = record[field.nested_object_name]?.[field.field_name];
              if (nestedValue) {
                record[field.nested_object_name][field.field_name] = btoa(nestedValue);
              } else {
                console.warn(
                  `Nested field ${field.nested_object_name}.${field.field_name} is missing or empty.`
                );
              }
            } else {
              const value = record[field.field_name];
              if (value) {
                record[field.field_name] = btoa(value);
              } else {
                console.warn(`Field ${field.field_name} is missing or empty.`);
              }
            }
          } catch (error) {
            console.error(`Error encoding field: ${field.field_name}`, error);
          }
        }
      }

      return record;
    },
    sortObjectProperties(obj) {
      // Get the keys of the object and sort them alphabetically
      const sortedKeys = Object.keys(obj).sort();

      // Create a new object with properties sorted by keys
      const sortedObject = {};
      sortedKeys.forEach((key) => {
        sortedObject[key] = obj[key];
      });

      return sortedObject;
    },
    addCreatedUpdatedFields(record, add_created, add_updated) {
      const authStore = useAuthStore();
      if (add_created) {
        record.created_by_name = `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`;
        record.created_by_id = authStore.currentUser.customData.user_id;
        record.created_date = new Date();
      }
      if (add_updated) {
        record.updated_by_id = authStore.currentUser.customData.user_id;
        record.updated_by_name = `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`;
        record.updated_date = new Date();
      }
      return record;
    },
    compareObjects(obj1, obj2, path = "") {
      let isDifferent = false;
      const differences = [];
      const ignoredFields = [
        "created_by_name",
        "created_date",
        "created_by_id",
        "update_by_name",
        "updated_by_id",
        "updated_date",
      ];

      const isObject = (val) => {
        return val && typeof val === "object" && !Array.isArray(val);
      };

      const compareArrays = (arr1, arr2, parentPath) => {
        if (arr1.length !== arr2.length) return true;

        for (let i = 0; i < arr1.length; i++) {
          const newPath = `${parentPath}[${i}]`;
          if (isObject(arr1[i]) && isObject(arr2[i])) {
            if (this.compareObjects(arr1[i], arr2[i], newPath)) return true;
          } else if (arr1[i] !== arr2[i]) {
            differences.push({ field: newPath, oldValue: arr1[i], newValue: arr2[i] });
            return true;
          }
        }

        return false;
      };

      for (const key in obj1) {
        if (obj1.hasOwnProperty(key) && !ignoredFields.includes(key)) {
          const newPath = path ? `${path}.${key}` : key;

          if (obj2.hasOwnProperty(key)) {
            if (isObject(obj1[key]) && isObject(obj2[key])) {
              if (this.compareObjects(obj1[key], obj2[key], newPath)) isDifferent = true;
            } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
              if (compareArrays(obj1[key], obj2[key], newPath)) isDifferent = true;
            } else if (obj1[key] !== obj2[key]) {
              differences.push({ field: newPath, oldValue: obj1[key], newValue: obj2[key] });
              isDifferent = true;
            }
          } else {
            differences.push({ field: newPath, oldValue: obj1[key], newValue: undefined });
            isDifferent = true;
          }
        }
      }

      for (const key in obj2) {
        if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key) && !ignoredFields.includes(key)) {
          const newPath = path ? `${path}.${key}` : key;
          differences.push({ field: newPath, oldValue: undefined, newValue: obj2[key] });
          isDifferent = true;
        }
      }

      if (isDifferent) {
        // differences.forEach((diff) => {
        //   console.log(
        //     `Field changed: ${diff.field}, Old value: ${diff.oldValue}, New value: ${diff.newValue}`
        //   );
        // });
      }

      return isDifferent;
    },
    generateDefaultObject(schema) {
      let defaultObject = {};

      schema.forEach((field) => {
        let value;
        switch (field.field_type) {
          case "string":
            value = field.default_value || "";
            break;
          case "dropdown":
            value = field.default_value || "";
            break;
          case "password":
            value = "";
            break;
          case "boolean":
            value = field.default_value === "true" ? true : false;
            break;
          case "number":
            value = 0;
            break;
          case "date":
            value = null; // You can use new Date() if you want the current date as default
            break;
          case "array":
            value = [];
            break;
          case "array_of_ids":
            value = [];
            break;
          case "object":
            value = {};
            break;
          default:
            value = null;
        }

        if (field.belongs_to_nested_object) {
          if (!defaultObject[field.nested_object_name]) {
            defaultObject[field.nested_object_name] = {};
          }

          defaultObject[field.nested_object_name][field.field_name] = value;
        } else if (field.field_type !== "object") {
          defaultObject[field.field_name] = value;
        }
      });

      return defaultObject;
    },
    getSchemaByCollection(collection) {
      switch (collection) {
        case "Contacts":
          return this.all_contact_schema;
        case "Accounts":
          return this.all_account_schema;
        case "Investments":
          return this.all_investment_schema;
        case "BankAccounts":
          return this.all_banking_schema;
        default:
          return [];
      }
    },
    getTableHeadersForCollection(collection) {
      // Get the schema for the specified collection
      let schema = this.getSchemaByCollection(collection);

      // Filter schema to include only items where show_in_search_results is true
      let filteredSchema = schema.filter((item) => item.show_in_search_results);

      // Sort the filtered schema by search_result_order
      let sortedSchema = filteredSchema.sort(
        (a, b) => a.search_result_order - b.search_result_order
      );

      // Map the sorted schema to match the format expected from the aggregation
      let tableHeaders = sortedSchema.map((item) => ({
        label: item.label,
        field_name: item.field_name,
        associated_field_name: item.associated_field_name,
        is_association_field: item.is_association_field,
        search_result_order: item.search_result_order,
        field_type: item.field_type,
        number_type: item.number_type,
        value: "", // Default value as per the original aggregation
      }));

      return tableHeaders;
    },
    getSchemaNuggetsForCollection(collection, config) {
      // Get the schema for the specified collection
      let schema = this.getSchemaByCollection(collection);

      // Build the dynamic properties based on the passed config
      // let matchProperty = `record_detail_config.${config}.is_info_nugget`;
      // let sortProperty = `record_detail_config.${config}.info_nugget_order`;
      // let requiredProperty = `record_detail_config.${config}.is_required`;

      // Filter schema to include only items where the dynamic match property is true
      let filteredSchema = schema.filter(
        (item) => item.record_detail_config?.[config]?.is_info_nugget
      );

      // Sort the filtered schema by the dynamic sort property
      let sortedSchema = filteredSchema.sort(
        (a, b) =>
          (a.record_detail_config?.[config]?.info_nugget_order || 0) -
          (b.record_detail_config?.[config]?.info_nugget_order || 0)
      );

      // Map the sorted schema to match the format expected from the aggregation
      let nuggets = sortedSchema.map((item) => ({
        label: item.label,
        field_name: item.field_name,
        type: item.field_type,
        number_type: item.number_type,
        read_only: item.read_only,
        is_required: item.record_detail_config?.[config]?.is_required || false,
        associated_collection: item.associated_collection,
        associated_field_name: item.associated_field_name,
        is_association_field: item.is_association_field,
      }));

      return nuggets;
    },
    setAccordionSchema(collection, pageLayout, config) {
      // Get the schema for the specified collection

      let schema = this.getSchemaByCollection(collection);

      // Extract the grouping order from the page layout

      let groupingOrder = pageLayout.record_detail_config[config].detail_sections;

      this.finalGroupingOrder = groupingOrder;

      // Build dynamic property paths based on the current configuration object

      // Filter schema to include items where the dynamic match property is not empty and exists
      let filteredSchema = schema.filter(
        (item) =>
          item.record_detail_config[config].record_detail_group &&
          item.record_detail_config[config].record_detail_group !== ""
      );

      // Sort the filtered schema by the dynamic sort property
      let sortedSchema = filteredSchema.sort(
        (a, b) =>
          (a.record_detail_config[config].record_detail_group_order || 0) -
          (b.record_detail_config[config].record_detail_group_order || 0)
      );

      // Group the sorted schema by the dynamic group property
      let groupedSchema = groupingOrder.map((group) => {
        let fields = sortedSchema
          .filter((item) => item.record_detail_config[config].record_detail_group === group)
          .map((item) => ({
            name: item.label,
            type: item.field_type,
            field_name: item.field_name,
            number_type: item.number_type,
            read_only: item.read_only,
            is_required: item.record_detail_config[config].is_required || false,
            dropdown_options: item.record_detail_config[config].dropdown_options || [],
            associated_collection: item.associated_collection,
            associated_field_name: item.associated_field_name,
            is_association_field: item.is_association_field,
            associated_collection_query: item.associated_collection_query,
            v_model: "", // Placeholder for Vue.js binding
          }));

        return {
          name: group,
          fields,
        };
      });

      // Remove empty groups (those with no fields)
      let finalAccordionData = groupedSchema.filter((group) => group.fields.length > 0);

      return finalAccordionData;
    },
    getInvestmentTitleTransferFields() {
      let flattened_investment_schema = this.flattenSchema(this.all_investment_schema, "default");

      flattened_investment_schema = flattened_investment_schema.filter(
        (field) => field.record_detail_group === "Transfer Details"
      );

      this.title_transfer_schema = flattened_investment_schema;
    },
    getTransferBannerFields(field_name_array) {
      this.transfer_banner_headers = [];
      for (const field of field_name_array) {
        let matching_schema = this.all_investment_schema.find((item) => item.field_name == field);
        this.transfer_banner_headers.push(matching_schema);
      }

      this.transfer_banner_headers = this.transfer_banner_headers.map((item) => ({
        label: item.label,
        field_name: item.field_name,
        associated_field_name: item.associated_field_name,
        is_association_field: item.is_association_field,
        search_result_order: item.search_result_order,
        field_type: item.field_type,
        number_type: item.number_type,
        value: "", // Default value as per the original aggregation
      }));
    },
    getEarningsTabHeaders(projection_fields) {
      let headers_array = [];
      // console.log(this.all_distribution_schema);
      for (const field of projection_fields) {
        let matching_schema = this.all_distribution_schema.find((item) => item.field_name == field);
        headers_array.push(matching_schema);
      }

      // console.log(headers_array);

      headers_array = headers_array.map((item) => ({
        label: item.label,
        field_name: item.field_name,
        associated_field_name: item.associated_field_name,
        is_association_field: item.is_association_field,
        search_result_order: item.search_result_order,
        field_type: item.field_type,
        number_type: item.number_type,
        value: "", // Default value as per the original aggregation
      }));

      // headers_array = [
      //   {
      //     label: "Distribution Date",
      //     field_name: "distribution_date",
      //     associated_field_name: "",
      //     is_association_field: false,
      //     field_type: "date",
      //     number_type: "",
      //     value: "",
      //   },
      //   {
      //     label: "Amount",
      //     field_name: "amount",
      //     associated_field_name: "",
      //     is_association_field: false,
      //     field_type: "number",
      //     number_type: "currency",
      //     value: "",
      //   },
      //   {
      //     label: "Interest Earned",
      //     field_name: "is_completed",
      //     associated_field_name: "",
      //     is_association_field: false,
      //     field_type: "boolean",
      //     number_type: "",
      //     value: "",
      //   },
      // ];

      return headers_array;
    },
  },
});
