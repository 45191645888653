<template>
  <div class="flex flex-col w-full h-full lg:my-6">
    <button
      type="button"
      @click="investmentStore.currentStep = 2"
      class="md:hidden flex items-center justify-center gap-1.5 absolute top-24 left-5"
    >
      <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
      <p>Back</p>
    </button>
    <div
      class="flex flex-col grow items-center lg:pt-10 lg:pb-8 lg:border border-neutral-gray-4 w-full rounded-md h-full"
    >
      <div class="w-full lg:w-[70%] mx-auto flex flex-col justify-between h-full">
        <h1 class="font-bold text-xl uppercase text-primary-6">
          Step {{ investmentStore.currentStep - 2 }}: Investor Questionnaire
        </h1>
        <p class="text-lighter text-sm mt-2 mb-6">
          Investment Type: {{ investmentStore.currentInvestment.type }} • Offering Type:
          {{ investmentStore.selectedOffering.type }}
          ({{ investmentStore.selectedOffering.interest_rate }}% -
          {{
            investmentStore.selectedOffering.bond_term === 12
              ? "1 Year"
              : investmentStore.selectedOffering.bond_term / 12 + " Years"
          }}
          - {{ investmentStore.currentInvestment.is_compounded ? "Compounded" : "Paid Monthly" }}) •
          Amount to Invest: ${{ investmentStore.currentInvestment.amount }}
        </p>
        <div>
          <Form
            :fields="INCOME_FIELDS"
            :record="investorStore.contact"
            header="Financial Information"
            type="personal"
          />
        </div>
        <div class="border-b border-neutral-gray-4 w-full my-4 md:my-8"></div>
        <div
          v-for="(object, index) of QUESTIONNAIRE_QUESTIONS_ANSWERS"
          :key="object"
          class="flex flex-col gap-2 relative"
        >
          <div class="font-bold">
            {{ index + 1 + ". " + object.question }}
          </div>
          <div
            v-for="(answer, answerIndex) of object.answers"
            :key="answer"
            class="w-full text-left text-gray-700"
          >
            <div class="w-full flex items-center text-sm mb-1 gap-2">
              <input
                :id="answer + answerIndex"
                type="radio"
                :name="object.question"
                v-model="investorStore.contact.suitability_info[object.field_name]"
                :value="answer"
                class="hidden"
              />
              <div
                class="min-w-5 min-h-5 rounded-full border-2 flex items-center justify-center cursor-pointer"
                :class="{
                  'border-gray-300':
                    investorStore.contact.suitability_info[object.field_name] !== answer,
                  'border-secondary-6 bg-secondary-6':
                    investorStore.contact.suitability_info[object.field_name] === answer,
                }"
                @click="investorStore.contact.suitability_info[object.field_name] = answer"
              >
                <div
                  class="min-w-1.5 min-h-1.5 rounded-full"
                  :class="{
                    'bg-primary-6':
                      investorStore.contact.suitability_info[object.field_name] === answer,
                    'bg-transparent':
                      investorStore.contact.suitability_info[object.field_name] !== answer,
                  }"
                ></div>
              </div>
              <label
                @click="investorStore.contact.suitability_info[object.field_name] = answer"
                :for="answer + index"
                class="text-lighter cursor-pointer"
              >
                {{ answer }}
              </label>
            </div>
            <div
              v-if="
                index === 2 &&
                question3Error &&
                ((answer === 'Conservative' &&
                  investorStore.contact.suitability_info?.risk_willingness === 'Conservative') ||
                  (answer === 'Moderate' &&
                    investorStore.contact.suitability_info?.risk_willingness === 'Moderate'))
              "
              class="flex space-x-2 items-center mt-2"
            >
              <Icon
                name="Flag"
                class="h-6 w-6 text-functional-error-default animate-bounce"
                :stroke-width="2"
              />

              <p class="text-functional-error-default text-sm font-medium">
                Your current selection is not appropriate for this investment. Regardless of the
                specific investment type, be it equity or fixed income, the SEC considers all
                Regulation D offerings to be speculative. You must select “Significant” or
                “Speculative” to continue.
              </p>
            </div>
            <div
              v-if="
                index === 0 &&
                question1Error &&
                answer?.includes('Capital') &&
                investorStore.contact.suitability_info?.investment_objective?.includes('Capital')
              "
              class="flex space-x-2 items-center mt-2"
            >
              <Icon
                name="Flag"
                class="h-6 w-6 text-functional-error-default animate-bounce"
                :stroke-width="2"
              />
              <p class="text-functional-error-default text-sm font-medium">
                Your current selection is not appropriate for this investment.
              </p>
            </div>
            <div
              v-if="
                index === 12 &&
                question13Error &&
                answer === investorStore.contact?.suitability_info?.investment_time_horizon
              "
              class="flex space-x-2 items-center mt-2"
            >
              <Icon
                name="Flag"
                class="h-6 w-6 text-functional-error-default animate-bounce"
                :stroke-width="2"
              />

              <p class="text-functional-error-default text-sm font-medium">
                Your current selection is not appropriate for this investment. Your investment time
                horizon must be inline with your current offering term of
                {{ investmentStore.selectedOffering.bond_term / 12 }} year<span
                  v-if="investmentStore.selectedOffering.bond_term / 12 > 1"
                  >s</span
                >.
              </p>
            </div>
            <div
              v-if="
                otherQuestionsError &&
                ((index === 10 &&
                  answer === 'No' &&
                  investorStore.contact.suitability_info.significant_liquid_net_worth === 'No') ||
                  (index === 11 &&
                    answer === 'No' &&
                    investorStore.contact.suitability_info.risky_investment_understanding ===
                      'No') ||
                  (index === 13 &&
                    answer === 'No' &&
                    investorStore.contact.suitability_info.self_responsibility === 'No'))
              "
              class="flex space-x-2 items-center mt-2"
            >
              <Icon
                name="Flag"
                class="h-6 w-6 text-functional-error-default animate-bounce"
                :stroke-width="2"
              />
              <p class="text-red-500 font-medium text-sm">
                Your current selection is not appropriate for this investment. You must select “Yes”
                to continue.
              </p>
            </div>
          </div>
          <div
            v-if="index !== QUESTIONNAIRE_QUESTIONS_ANSWERS.length - 1"
            class="border-b border-neutral-gray-4 w-full mb-4 mt-1"
          ></div>
        </div>
        <div
          class="w-full flex gap-4 flex-col-reverse md:flex-row justify-between items-center border-t border-neutral-gray-4 pt-6 mt-6"
        >
          <button @click="handleBack" type="button" class="flex items-center gap-2">
            <Icon name="ChevronLeft" class="hidden md:block h-5 w-5 text-primary-6 mb-1" />
            <p>Back</p>
          </button>
          <Button
            @click="handleNext"
            :disabled="!isQuestionnaireCompleted"
            label
            icon
            size="large"
            class="w-full md:w-value-42"
          >
            <template #label>Next</template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { cloneDeep, isEqual } from "lodash";

import { useInvestmentStore, useInvestorStore } from "@/stores";
import { Button, Icon, Form } from "@/components";
import { QUESTIONNAIRE_QUESTIONS_ANSWERS, INCOME_FIELDS } from "@/constants";
import { commaSeparateThousands_2 } from "@/utilities";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

const question3Error = ref(false);
const question1Error = ref(false);
const otherQuestionsError = ref(false);
const question13Error = ref(false);
const isQuestionnaireCompleted = ref(false);
const originalSuitabilityInfo = ref({});

onMounted(() => {
  window.scrollTo(0, 0);
  for (let field of INCOME_FIELDS) {
    if (field.number_type === "currency") {
      if (investorStore.contact.suitability_info[field.field_name]) {
        investorStore.contact.suitability_info[field.field_name] = formatAmount(
          investorStore.contact.suitability_info[field.field_name]
        );
      }
    }
  }

  validateFields();
  originalSuitabilityInfo.value = cloneDeep(investorStore.contact.suitability_info);
});

const hasSuitabilityInfoChanged = computed(() => {
  return !isEqual(investorStore.contact.suitability_info, originalSuitabilityInfo.value);
});

// Check if the fields are complete every time one changes
watch(
  () => investorStore.contact?.suitability_info,
  () => {
    validateSpecificQuestions();
    validateFields();
  },
  { deep: true }
);

function validateSpecificQuestions() {
  if (
    investorStore.contact?.suitability_info?.risk_willingness === "Moderate" ||
    investorStore.contact?.suitability_info?.risk_willingness === "Conservative"
  ) {
    question3Error.value = true;
  } else {
    question3Error.value = false;
  }

  if (investorStore.contact.suitability_info?.investment_objective?.includes("Capital")) {
    question1Error.value = true;
  } else {
    question1Error.value = false;
  }

  if (
    investorStore.contact?.suitability_info?.significant_liquid_net_worth === "No" ||
    investorStore.contact?.suitability_info?.risky_investment_understanding === "No" ||
    investorStore.contact?.suitability_info?.self_responsibility === "No"
  ) {
    otherQuestionsError.value = true;
  } else {
    otherQuestionsError.value = false;
  }

  // investment_time_horizon

  const currentOfferingTerm = investmentStore.selectedOffering.bond_term / 12;
  if (
    investorStore.contact?.suitability_info?.investment_time_horizon === "1-5 years" &&
    currentOfferingTerm > 5
  ) {
    question13Error.value = true;
  } else if (
    investorStore.contact?.suitability_info?.investment_time_horizon === "6-10 years" &&
    currentOfferingTerm > 10
  ) {
    question13Error.value = true;
  } else {
    question13Error.value = false;
  }
}

function validateFields() {
  // Check if all radio options have been selected
  const allQuestionsAnswered = QUESTIONNAIRE_QUESTIONS_ANSWERS.every((object) => {
    return (
      investorStore.contact.suitability_info[object.field_name] != null &&
      investorStore.contact.suitability_info[object.field_name] !== "" &&
      investorStore.contact.suitability_info[object.field_name]
    );
  });

  // Validate numeric fields (current_income, liquid net worth, etc.)
  const incomeFieldsValid =
    parseFloat(investorStore.contact.suitability_info?.current_income?.replace(/,/g, "")) >=
      10000 &&
    parseFloat(
      investorStore.contact.suitability_info?.current_liquid_net_worth?.replace(/,/g, "")
    ) <= parseFloat(investorStore.contact.suitability_info?.current_net_worth?.replace(/,/g, ""));

  // Final validation check: all radio options are answered, no errors in specific questions, and income fields are valid
  if (
    allQuestionsAnswered &&
    !question3Error.value &&
    !question1Error.value &&
    !otherQuestionsError.value &&
    !question13Error.value &&
    incomeFieldsValid
  ) {
    isQuestionnaireCompleted.value = true;
  } else {
    isQuestionnaireCompleted.value = false;
  }
}

function formatAmount(val) {
  let newValue = commaSeparateThousands_2(val);
  return newValue;
}

async function handleBack() {
  investmentStore.currentStep -= 1;
  if (hasSuitabilityInfoChanged.value) {
    await investorStore.updateContactSuitabilityInfo();
  }
}

async function handleNext() {
  investmentStore.currentStep += 1;
  if (hasSuitabilityInfoChanged.value) {
    await investorStore.updateContactSuitabilityInfo();
  }
}
</script>
