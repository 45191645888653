<template>
  <Modal
    :open="open"
    @close-modal="$emit('close-modal')"
    customHeight="h-auto"
    customWidth="lg:w-[40rem]"
  >
    <template v-slot:modal-header>
      <p class="text-lg font-semibold text-primary">Increase Annual Yield</p>
    </template>

    <template v-slot:modal-body>
      <div class="p-4 md:py-8 md:px-10">
        <p class="font-bold mb-2">Only Available for Accredited Offerings</p>
        <p>Current Tier: {{ currentTier }}</p>
        <p v-if="amountToNextTier">
          Invest ${{ commaSeparateThousands_2(amountToNextTier) }} more to increase annual yield on
          accredited investments by +0.5%
        </p>
        <p v-else-if="investmentStore.selectedOffering && amountToNextTier === 0">
          You've reached the max tier!
        </p>

        <p v-if="investmentStore.selectedOffering" class="mt-2">
          Current Selection: ${{ investmentStore.currentInvestment.amount }} at
          {{ investmentStore.selectedOffering.interest_rate }}% interest over a
          {{ investmentStore.selectedOffering.bond_term / 12 }} year term
          {{
            investmentStore.currentInvestment.is_compounded ? "compounded monthly" : "paid monthly"
          }}.
        </p>

        <div class="mt-4">
          <Table
            :headers="rateLiftHeaders"
            :tableData="rateLiftData"
            hideSort
            :fields-with-custom-styles="['anticipated_earnings']"
          >
            <template v-slot:anticipated_earnings="entry">
              <div class="ml-4 truncate text-sm">
                {{
                  investmentStore.selectedOffering
                    ? `$${commaSeparateThousands(calculateAnticipatedEarnings(entry.row.tier))}`
                    : "No Offering Selected"
                }}
              </div>
            </template>
          </Table>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label>Cancel</template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { cloneDeep, isEqual } from "lodash";

import { useInvestmentStore, useInvestorStore } from "@/stores";
import { Button, Modal, RadioOption, Form, Table } from "@/components";
import { NEW_BENEFICIAL_OWNER_TEMPLATE } from "@/constants";
import { commaSeparateThousands_2, commaSeparateThousands } from "@/utilities";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();

const rateLiftHeaders = [
  { field_name: "tier", label: "Tier" },
  { field_name: "amount", label: "Total Invested" },
  { field_name: "rate_lift", label: "Rate Lift" },
  { field_name: "anticipated_earnings", label: "Anticipated earnings" },
];

const rateLiftData = [
  {
    tier: "Tier 2",
    amount: "$500,000 - $1,000,000",
    rate_lift: "+0.5%",
    anticipated_earnings: "0",
  },
  { tier: "Tier 3", amount: "$1,000,000+", rate_lift: "+1%", anticipated_earnings: "0" },
];

const emit = defineEmits(["close-modal"]);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  amountToNextTier: {
    type: Number,
  },
  currentTier: {
    type: String,
  },
});

function calculateAnticipatedEarnings(tier) {
  const offering = investmentStore.selectedOffering;
  let interestRate = offering.interest_rate / 100;

  if (tier === "Tier 3") {
    interestRate += 0.01;
  } else if (tier === "Tier 2") {
    interestRate += 0.005;
  }

  const years = offering.bond_term / 12;
  const compoundingFrequency = 12;
  const currentAmount = Number(investmentStore.currentInvestment.amount.replace(/,/g, ""));

  if (investmentStore.currentInvestment.is_compounded) {
    // Calculate compound interest
    const compoundedAmount =
      currentAmount *
      Math.pow(1 + interestRate / compoundingFrequency, compoundingFrequency * years);
    const earnings = compoundedAmount - currentAmount;
    return earnings.toFixed(2);
  } else {
    // Calculate simple interest
    return (currentAmount * interestRate * years).toFixed(2);
  }
}
</script>
